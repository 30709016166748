import { BANK_ID } from 'services/make-deposit/make-deposit';

export const loadThirdPartyCheckout = (
  depositResponse: any,
  amount: string,
  paymentMethodId: any,
) => {
  const performResponse = atob(depositResponse.InnerText);
  const doc = new DOMParser().parseFromString(performResponse, 'text/html');

  // Nuvei + Safecharge
  if (paymentMethodId) {
    const iframe = doc.getElementsByTagName('iframe');
    const iframeURL = iframe[0]?.src;
    return iframeURL;
  }
  // Trustly
  if (paymentMethodId === BANK_ID.TRUSTLY) {
    // 1 - we need to collect user verified data from the decoded string
    const initRegex = /function\s+init\s*\(\s*\)\s*{([\s\S]*)}/;
    const match = performResponse.match(initRegex);
    const initContents = match[1].trim();
    const establishDataRegex = /var establishData = ({[\s\S]*});/;
    const establishDataMatches = establishDataRegex.exec(initContents);
    // trustly verified data
    const trustlyData = JSON.parse(establishDataMatches[1]);
    // trustly options
    const trustlyOptions = {
      closeButton: false,
      dragAndDrop: true,
      widgetContainerId: 'widget',
    };

    // remove script if exist from previous deposits
    const existingTrustlyScript = document.querySelector(
      'script[src*="trustly.one"]',
    );
    if (existingTrustlyScript) {
      existingTrustlyScript.remove();
    }

    // 2 - we need to collect the script url from the decoded string
    const regex =
      /<script.*src=["']([^"']*trustly\.one[^"']*)["'].*><\/script>/i;
    const matchScript = performResponse.match(regex);
    if (matchScript) {
      const scriptSrc = matchScript[1];
      const scriptTag = document.createElement('script');
      // Set the src
      scriptTag.src = scriptSrc;
      // Inject the script element to the head
      document.head.appendChild(scriptTag);
      setTimeout(() => {
        // 3 -init the widget
        window['Trustly'].establish(trustlyData, trustlyOptions);
      }, 500);
    }

    return performResponse;
  }
};
