import { useMutation } from '@tanstack/react-query';
import { Api } from 'common/api';

const postSuspendPlayer = async (body) => {
  const { data, ok } = await Api.post<any>(
    `${API_HOST}/api/strive-proxy/railsapi/v1/user/settings/disable_player_until`,
    {
      data: body,
    },
  );

  if (!ok && data.Message) throw new Error(data.Message);

  return data;
};

export const useSuspendPlayer = () => {
  return useMutation({ mutationFn: postSuspendPlayer });
};
