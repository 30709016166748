import React, { useEffect, useState } from 'react';
import { InCenter, InfoFlexRow } from './styled';
import { AccountHistoryLayout } from './account-history';
import { Spinner } from 'icons/svg';
import { useTransactionHistory } from 'hooks/use-transaction-history';
import PlayAllButton from 'components/organisms/play-all/play-all-button';
import { SearchFilterItem } from 'components/molecules/search-filter/SearchFilterItem';
import { PeriodFilterItem } from 'components/molecules/period-filter/PeriodFilterItem';
import { TransactionsList } from 'components/organisms/account-history/TransactionList';
import { PageInfo } from 'components/molecules/page-info/PageInfo';
import { Paginator } from 'components/molecules/paginator/Paginator';

export default function WagerHistory() {
  const [totalPages, setTotalPages] = useState(1);
  const [userTransactions, setUserTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [period, setPeriod] = useState(30);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const { data: transactionHistory, isLoading } = useTransactionHistory(
    page,
    period,
    dateTo,
    dateFrom,
  );

  useEffect(() => {
    if (transactionHistory?.transactions) {
      setUserTransactions(transactionHistory?.transactions);
      setTotalPages(transactionHistory?.pages);
    }
  }, [transactionHistory]);

  const changePeriod = (p: number) => {
    if (p === period) return;
    setUserTransactions([]);
    setDateTo(null);
    setDateFrom(null);
    setPeriod(p);
    setPage(1);
  };

  const onClickSearch = (searchedDateTo: string, searchedDateFrom: string) => {
    setUserTransactions([]);
    setDateTo(searchedDateTo);
    setDateFrom(searchedDateFrom);
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const firstDate = new Date(searchedDateTo).getTime();
    const secondDate = new Date(searchedDateFrom).getTime();
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    setPeriod(diffDays);
    setPage(1);
  };

  const loadMoreTransactions = () => {
    setPage(page + 1);
    setUserTransactions([]);
  };

  const transactionListLoader = (
    <div className={'container'}>
      <InCenter
        isLoading={true}
        className='mx-auto '
        style={{
          height: 'auto',
          width: '100%',
          borderRadius: '10px',
        }}
      >
        <Spinner size='2em' color='white' />
      </InCenter>
      <div style={{ height: '57px' }}></div>
    </div>
  );

  return (
    <div className={'container'}>
      <AccountHistoryLayout>
        <InfoFlexRow>
          <SearchFilterItem
            onClickSearch={onClickSearch}
            dateTo={dateTo}
            dateFrom={dateFrom}
          />
          <PeriodFilterItem period={period} changePeriod={changePeriod} />

          {isLoading ? (
            transactionListLoader
          ) : (
            <>
              <TransactionsList
                period={period}
                userTransactions={userTransactions}
              />
              {userTransactions?.length > 0 && (
                <PageInfo page={page} totalPages={totalPages} />
              )}
            </>
          )}
          <div className={'d-flex flex-row justify-content-between mt-2'}>
            <Paginator
              totalPages={totalPages}
              page={page}
              onClickNext={loadMoreTransactions}
              onClickPrevious={() => setPage((old) => Math.max(old - 1, 0))}
            />
            <div className={'d-flex gap-2 justify-content-start py-4'}>
              <PlayAllButton />
            </div>
          </div>
        </InfoFlexRow>
      </AccountHistoryLayout>
    </div>
  );
}
