import { Close } from 'icons/svg';
import { Button } from 'components/atoms/button';
import { BspotModal } from 'components/atoms/modal/modal';
import { GameProduct } from 'services/get-game-products/get-game-products';
import { useBonusModalInfo } from 'hooks/use-bonus-modal-info/use-bonus-modal-info';
import { ContentfulRichText } from 'components/organisms/cms/contentful-rich-text/contentful-rich-text';
import { Skeleton } from '@mui/material';
import styled from '@emotion/styled';

const CloseButton = styled(Close)`
  position: absolute;
  right: 0;
`;

export type BonusModalProps = {
  onClose?(): void;
  product: GameProduct;
  show: boolean;
};

export const BonusModal = ({ product, show, onClose }: BonusModalProps) => {
  const { terms, buttonUrl, isError, isLoading } = useBonusModalInfo(product);

  const viewFAQ = () => {
    onClose();
    window.open(buttonUrl);
  };

  const close = () => {
    onClose();
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div style={{ position: 'relative' }}>
        <CloseButton size={'16px'} color={'#ffffff'} onClick={close} />
        {isLoading && (
          <>
            <Skeleton variant='text' height={80} />
            <Skeleton variant='text' height={200} />
          </>
        )}
        {terms && <ContentfulRichText content={terms} />}
        {isError && (
          <>
            <h2 className='mb-0'>
              An error occurred while fetching the bonus information.
            </h2>
            <h3 className='m-0'>Try again later.</h3>
          </>
        )}
        <div className={'d-flex flex-row pt-2'}>
          <Button text='Ts & Cs Apply' onClick={viewFAQ} />
        </div>
      </div>
    </BspotModal>
  );
};
export default BonusModal;
