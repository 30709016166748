import { Routes } from 'common/routes';
import { Button } from 'components/atoms/button';
import { getCookie, setExpireCookie } from 'common/utils';
import { BspotModal } from 'components/atoms/modal/modal';
import { useNavigate } from 'react-router-dom';
import { useShowFreeplayAward } from 'hooks/use-show-freeplay-award';
import { PERSIST_FREEPLAY_MESSAGE } from 'hooks/use-webpush-listener';
import { setFreeplayEligibility } from 'hooks/use-freeplay-tracker';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { useWelcomeContent } from 'hooks/use-welcome-content/use-welcome-content';
import React from 'react';
import { useAuth } from 'contexts/authentication';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';

const ScrollNoBorder = (props) => {
  return (
    <div id='scroll-no-border' style={{ overflowY: 'scroll', height: '550px' }}>
      {props.children}
    </div>
  );
};

export type KycProps = {
  onclose?(): void;
  onplayNow?(): void;
  show: boolean;
  status: number;
  firstName: string;
  playerId: string;
};
export const RegistrationSuccessful = ({
  onclose,
  onplayNow,
  show,
  firstName,
  playerId,
}: KycProps) => {
  const navigate = useNavigate();
  const showFreeplayAward = useShowFreeplayAward();
  const { auth } = useAuth();
  const { data: welcomeContent } = useWelcomeContent();

  const setCookie = () => {
    setExpireCookie(`${playerId}isRegistered`);
    setFreeplayEligibility(Number(playerId));
    localStorage.setItem(`kyc-success-${playerId}`, 'success');
  };

  const onCloseModal = () => {
    setCookie();
    checkFreeplayMessage();
    onclose();
  };

  // checks if freeplay socket message has been received while the modal was displayed
  const checkFreeplayMessage = () => {
    const message = localStorage.getItem(
      `${PERSIST_FREEPLAY_MESSAGE}-${playerId}`,
    );

    if (!message) return;

    showFreeplayAward(firstName, JSON.parse(message));
    localStorage.removeItem(`${PERSIST_FREEPLAY_MESSAGE}-${playerId}`);
  };

  const onPlayNow = () => {
    setCookie();
    checkFreeplayMessage();
    onplayNow();
  };

  return (
    <BspotModal show={show}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{`Welcome ${firstName || ''}`}</h2>
      </div>

      <ScrollNoBorder className={'py-2'}>
        <div className={'d-flex flex-column gap-1'}>
          <div style={{ fontSize: '12px' }} className={'fw-semibold'}>
            Congrats – your account is approved! You now have full access to the
            most exciting gaming site online. Keep an eye out for special
            promotions, new games, big money tournaments and much, much more.
            $10 in Freeplay credits will be added to your account shortly so you
            can start playing right now, no purchase necessary!
          </div>
          <div
            className={'mt-4'}
            style={{
              borderRadius: '4px',
              boxShadow:
                '0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)',
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.13))',
            }}
          >
            <div
              className={
                'd-flex flex-column align-items-center gap-1 p-3 text-center'
              }
            >
              <span>
                <b>Get More Freeplay!</b>
              </span>
              <span style={{ fontSize: '12px' }} className={'fw-bold'}>
                Like Freeplay? Deposit and get $10 in credits and we’ll add
                another $20 in Freeplay to your account. Check your inbox for
                terms and conditions.
              </span>
            </div>
            <div
              className={'d-flex flex-row gap-2 justify-content-center pb-3'}
            >
              <Button text='Play Now' onClick={onPlayNow} />
            </div>
          </div>
          {FeatureFlagsService?.featureFlags?.enableIncomeAccess && (
            <iframe
              style={{ display: 'none' }}
              src={`https://tracking.bspot.com/Processing/Pixels/Registration.ashx?PlayerID=${playerId}`}
            ></iframe>
          )}
        </div>
      </ScrollNoBorder>
    </BspotModal>
  );
};
