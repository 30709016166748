import { useQuery } from '@tanstack/react-query';
import { getDepoStatus } from 'services/make-deposit/make-deposit';

export const useDepositStatus = (depositRequestId) => {
  return useQuery({
    queryKey: ['use-deposit-status', depositRequestId],
    queryFn: () => getDepoStatus(depositRequestId),
    keepPreviousData: false,
    refetchInterval: 2000,
    enabled: !!depositRequestId,
  });
};
