import { useFormContext } from 'react-hook-form';
import { Regexes } from 'common/bspot-shared';
import { FormInput } from 'components/atoms/input/input';
import { Text } from 'components/atoms/text/text';

export function CredentialsForm() {
  const { getValues } = useFormContext();

  return (
    <>
      <FormInput
        autoFocus
        label='E-mail address'
        name='email'
        type='email'
        data-cy='email'
        validation={{
          required: 'This field is required',
          pattern: {
            value: Regexes.Email,
            message: 'Must be valid email',
          },
        }}
      />
      <FormInput
        label='Confirm e-mail'
        name='confirm_email'
        type='email'
        data-cy='confirm_email'
        validation={{
          required: 'This field is required',
          validate: {
            match: (v) => {
              const values = getValues();
              return values?.email === v || 'Emails do not match';
            },
          },
          pattern: {
            value: Regexes.Email,
            message: 'Must be valid email',
          },
          deps: ['email'],
        }}
      />
      <FormInput
        type='password'
        name='password'
        label='Password'
        data-cy='password'
        validation={{
          required: 'This field is required',
          pattern: {
            value: Regexes.Password,
            message: (
              <Text text={'Password rules'}>
                <ol>
                  <li>{'Must be a minimum of 8 characters'}</li>
                  <li>
                    {
                      'Must contain at least 1 lower case letter, 1 upper case letter, 1 number and 1 special character'
                    }
                  </li>
                  <li>
                    {
                      'Cannot contain spaces or any of these special characters: / | [ ] { } < > ( ) -'
                    }
                  </li>
                </ol>
              </Text>
            ),
          },
        }}
      />
      <FormInput
        type='password'
        name='confirm_password'
        data-cy='confirm_password'
        label={'Confirm password'}
        validation={{
          required: 'This field is required',
          validate: {
            match: (v) => {
              const values = getValues();
              return values?.password === v || 'Passwords do not match';
            },
          },
          deps: ['password'],
        }}
      />
    </>
  );
}
