import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { TournamentType } from '../../../../services/tournaments/tournaments';
import { TournamentCard } from '../../tournament-card/tournament-card';
import './styles.css';

type TournamentListProps = {
  tournaments: TournamentType[];
  playerId: string;
  refetchTournaments: () => void;
};
export const TournamentList = ({
  tournaments,
  playerId,
  refetchTournaments,
}: TournamentListProps) => (
  <div className='d-flex flex-column pt-5' style={{ overflowX: 'hidden' }}>
    <div className='d-flex justify-content-center align-items-center'>
      <button
        className='tournament-list-prev-button'
        aria-label='previous item'
      ></button>
      <Swiper
        className='swiper-hp-main'
        navigation={{
          prevEl: '.tournament-list-prev-button',
          nextEl: '.tournament-list-next-button',
        }}
        modules={[Navigation, Pagination]}
        breakpoints={{
          1024: {
            slidesPerView: 1,
            centeredSlides: false,
            slidesPerGroupAuto: false,
          },
        }}
        slidesPerGroupAuto
        centeredSlides
        spaceBetween={8}
        slidesPerView={'auto'}
        grabCursor
        pagination={{
          clickable: true,
          bulletClass: 'tournament-list-bullet',
          bulletActiveClass: 'tournament-list-bullet-active',
          el: '#containerForBullets',
          type: 'bullets',
        }}
      >
        {tournaments.map((tournament) => (
          <SwiperSlide key={tournament.id} className={'swiper-resp'}>
            <TournamentCard
              tournament={tournament}
              refetchTournaments={refetchTournaments}
              playerId={playerId}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <button
        className='tournament-list-next-button'
        aria-label='next item'
      ></button>
    </div>
    <div
      id='containerForBullets'
      className='d-flex justify-content-center gap-4 w-100 mt-4'
    ></div>
  </div>
);
