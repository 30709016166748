import { Checkbox, FormControlLabel } from '@mui/material';
import { InputFeedback, InputWrapper } from 'components/atoms/input/input';
import { ReactNode } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

type CheckboxInput = {
  name?: string;
  control?: Control<FieldValues, any>;
  label?: string | ReactNode;
  rules?: RegisterOptions<FieldValues, any>;
  error?: string;
};

export function CheckboxInput({
  name,
  control,
  label,
  rules,
  error,
}: CheckboxInput) {
  return (
    <InputWrapper hasError={error ? true : false}>
      <FormControlLabel
        sx={{ alignItems: 'flex-start' }}
        control={
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: props }) => (
              <Checkbox
                sx={{
                  marginTop: -1,
                  '&.Mui-checked': {
                    color: 'var(--primary-color)',
                  },
                }}
                {...props}
                checked={props.value || false}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            )}
          />
        }
        label={label}
      />
      {error && <InputFeedback id={`${name}-error`}>{error}</InputFeedback>}
    </InputWrapper>
  );
}
