import { useForm } from 'react-hook-form';
import { AvailablePaymentMethods } from 'components/molecules/available-payment-methods/available-payment-methods';
import { Group } from 'pages/account-history/styled';
import { Text, TextSize } from 'components/atoms/text';
import { AvailableAmounts } from 'components/molecules/available-amounts/available-amounts';
import { Button } from 'components/atoms/button';
import React, { useState } from 'react';
import { WithdrawalMethods } from 'services/withdraw/withdraw';
import { InputAmount } from 'components/molecules/input-amount/input-amount';
import { AccountCard } from 'pages/make-deposit/styled';

export type WithdrawFormProps = {
  withdrawMethods: WithdrawalMethods;
  makeWithdrawal?(e: { amount: string; account: string | number }): void;
};

export function WithdrawForm({
  withdrawMethods,
  makeWithdrawal,
}: WithdrawFormProps) {
  const {
    register,
    unregister,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { account: null, amount: '' },
  });
  register('account', { required: 'Please select the account' });

  const registerAmountControl = (options?) => {
    register('amount', { required: 'Please select the amount', ...options });
  };
  registerAmountControl();
  const amount = getValues('amount');
  const account = getValues('account');

  const [limits, setLimits] = useState(null);

  const onSelectAmount = (amount) => {
    setValue('amount', amount, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onSelectAccount = (acc) => {
    setValue('account', acc.uniq_id, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setLimits(`Minimum withdraw: $${acc.min_withdraw_amount}`);
    unregister('amount');
    registerAmountControl({
      min: {
        message:
          'The amount you entered is outside limits. It must be above the minimum.',
        value: acc.min_withdraw_amount,
      },
      max: {
        message: 'Exceeded Maximum Withdraw Limit',
        value: acc.max_withdraw_amount,
      },
    });
  };

  const onSubmit = () => {
    makeWithdrawal({ account: account, amount: amount });
  };

  return (
    <div className='col-12 mx-auto'>
      <AccountCard>
        <Group className={'pt-1 pb-3'}>
          <Text bold size={TextSize.Body} className={'text-center'}>
            Choose your account
          </Text>
        </Group>
        <AvailablePaymentMethods
          items={withdrawMethods?.accounts}
          isDeposit={false}
          account={account}
          onSelect={onSelectAccount}
        />
        <Group>
          {errors['account'] && (
            <Text bold size={TextSize.Body} color={'#d70022'}>
              {errors['account'].message}
            </Text>
          )}
        </Group>
      </AccountCard>
      <br />
      <AccountCard>
        <div className='col-12 mx-auto  mt-2'>
          <Group className={'pb-2'}>
            <Text bold size={TextSize.Body} className={'text-center'}>
              How much do you want to withdraw?
            </Text>
          </Group>
          <InputAmount amount={amount} onChange={onSelectAmount} />
          <Group className={'pt-1'}>
            {errors['amount'] && (
              <Text bold size={TextSize.Body} color={'#d70022'}>
                {errors['amount'].message}
              </Text>
            )}
          </Group>
          {limits && <Text size={TextSize.Hint}>{limits}</Text>}

          <AvailableAmounts amount={amount} onSelect={onSelectAmount} />

          <Group className={'pt-4 text-center'}>
            <Button
              text={'Confirm'}
              onClick={handleSubmit(onSubmit)}
              disabled={!withdrawMethods?.isAllowed}
            />
          </Group>
        </div>
      </AccountCard>
    </div>
  );
}
