import { Button, ButtonColor } from '../../../../atoms/button';
import { Routes } from '../../../../../common/routes';
import { Meta } from '../../../../../services/tournaments/tournaments';
import { useNavigate } from '../../../../../hooks/use-navigate';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';

export type CtaProps = {
  isOptedIn: boolean;
  meta: Meta;
  playerId: string;
  isActive: boolean;
  ctaLoading: boolean;
  onClickOptIn?(): void;
};

export const Cta = ({
  isOptedIn = false,
  meta,
  playerId,
  isActive,
  onClickOptIn,
  ctaLoading,
}: CtaProps) => {
  const clickPlayNow = () => {
    if (isOptedIn) {
      if (meta?.opt_in_action) {
        const url = meta?.app_opt_in_action;
        const urlObj = new URL(url);
        const path = urlObj.pathname;
        const modifiedPath = path.replace(/((\/[^/]*){4}).*/, '$1');
        postNativeMessage(`Game:${modifiedPath}`);
      } else console.error('The opted in action does not exist');
      return;
    }
  };

  return (
    <div>
      {!playerId && (
        <Button
          id='tournament-cta'
          color={ButtonColor.Secondary}
          className='cta-button'
          onClick={() => postNativeMessage('Login:/en/login')}
          isLoading={ctaLoading}
          isSmall={true}
          text={meta?.logged_out_label || 'Join Now!'}
        />
      )}

      {isOptedIn && (
        <Button
          id='tournament-cta'
          color={ButtonColor.Primary}
          className='cta-button'
          onClick={clickPlayNow}
          disabled={ctaLoading || (!isActive && isOptedIn)}
          isLoading={ctaLoading}
          isSmall={true}
          text={meta?.opted_in_label || 'Play Now!'}
        />
      )}

      {playerId && !isOptedIn && (
        <Button
          id='tournament-cta'
          color={ButtonColor.Secondary}
          className='cta-button'
          onClick={onClickOptIn}
          disabled={ctaLoading}
          isLoading={ctaLoading}
          isSmall={true}
          text={meta?.logged_in_label || 'Opt In'}
        />
      )}
    </div>
  );
};
