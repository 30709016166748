import { Api } from 'common/api';

export type State = {
  id: number;
  code: string;
  name: string;
  value: string | number;
  label: string;
};

const remove = [
  {
    id: 92,
    code: 'TN',
    name: 'Tennessee',
  },
  {
    id: 185,
    code: 'OH',
    name: 'Ohio',
  },
];

export const getStates = async () => {
  const { data, ok } = await Api.get<StriveResponse<State[]>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/provinces`,
  );

  if (!ok) throw new Error('Could not fetch the states');

  const updatedList = data.Data.filter(
    (item) => !remove.some((r) => r.code === item.code),
  );

  const fomattedStates = updatedList.map((state) => ({
    ...state,
    value: state.id,
    label: state.name,
  }));
  return fomattedStates;
};
