export function Tournament({
  width = 22,
  height = 55,
  fill = '#0AEB88',
}: {
  width?: number;
  height?: number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1083_4015)'>
        <path
          d='M17.9 17.3C17.6 17.3 17.4 17.1 17.4 16.8V15.6C17.4 15.4667 17.3333 15.3333 17.2 15.2C17.0667 15.0667 16.9333 15 16.8 15H5.3C5.1 15 4.93333 15.0667 4.8 15.2C4.66667 15.3333 4.6 15.4667 4.6 15.6V16.8C4.6 17.1 4.4 17.3 4.1 17.3H0.6C0.466667 17.3 0.333333 17.3667 0.2 17.5C0.0666667 17.6333 0 17.8 0 18V21.4C0 26.3333 1.7 28.8667 5.1 29C5.2 29 5.4 29.1 5.5 29.2C5.96667 30 6.6 30.7 7.4 31.3C8.13333 31.8333 8.96667 32.1667 9.9 32.3C10.2 32.3 10.4 32.6 10.4 32.8V35.2C10.4 35.5 10.1 35.7 9.9 35.7H8.1V37H13.9V35.7H12.1C11.9 35.7 11.6 35.5 11.6 35.2V32.8C11.6 32.6 11.8 32.3 12.1 32.3C13.0333 32.1667 13.8667 31.8333 14.6 31.3C15.4 30.7 16.0333 30 16.5 29.2C16.6 29.1 16.8 29 16.9 29C20.3 28.8667 22 26.3333 22 21.4V18C22 17.8 21.9333 17.6333 21.8 17.5C21.6667 17.3667 21.5333 17.3 21.4 17.3H17.9ZM5.9 16.8C5.9 16.5 6.1 16.3 6.4 16.3H15.6C15.9 16.3 16.1 16.5 16.1 16.8V26C16.1 27.4 15.6 28.6 14.6 29.6C13.6 30.6 12.4 31.1 11 31.1C9.6 31.1 8.4 30.6 7.4 29.6C6.4 28.6 5.9 27.4 5.9 26V16.8ZM1.3 19.1C1.3 18.8 1.5 18.6 1.8 18.6H4.1C4.4 18.6 4.6 18.8 4.6 19.1V27V27.1C4.5 27.4 4.1 27.5 3.9 27.4C2.16667 26.6667 1.3 24.6667 1.3 21.4V19.1ZM17.4 19.1C17.4 18.8 17.6 18.6 17.9 18.6H20.2C20.5 18.6 20.7 18.8 20.7 19.1V21.4C20.7 24.6667 19.8333 26.6667 18.1 27.4C18 27.4 18 27.5 17.9 27.5C17.6 27.5 17.4 27.2 17.4 27V19.1Z'
          fill={fill}
          stroke={fill}
          strokeWidth='0.2'
        />
      </g>
      <defs>
        <clipPath id='clip0_1083_4015'>
          <rect width='22' height='55' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function TournamentFill({
  width = 22,
  height = 55,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.9 17.2H17.6V16.1C17.6 15.8083 17.4841 15.5285 17.2778 15.3222C17.0715 15.1159 16.7917 15 16.5 15H5.5C5.20826 15 4.92847 15.1159 4.72218 15.3222C4.51589 15.5285 4.4 15.8083 4.4 16.1V17.2H1.1C0.808262 17.2 0.528472 17.3159 0.322183 17.5222C0.115892 17.7285 0 18.0083 0 18.3V21.6C0 26.341 1.98 29.201 5.302 29.3C5.7867 30.1382 6.44988 30.8594 7.2445 31.4126C8.03911 31.9658 8.94571 32.3374 9.9 32.501V34.8H7.7V37H14.3V34.8H12.1V32.501C13.0543 32.3374 13.9609 31.9658 14.7555 31.4126C15.5501 30.8594 16.2133 30.1382 16.698 29.3C19.998 29.19 22 26.33 22 21.6V18.3C22 18.0083 21.8841 17.7285 21.6778 17.5222C21.4715 17.3159 21.1917 17.2 20.9 17.2ZM2.2 21.6V19.4H4.4V26.913C2.442 26.088 2.2 23.03 2.2 21.6ZM17.6 26.913V19.4H19.8V21.6C19.8 23.03 19.558 26.088 17.6 26.913Z'
        fill='#0AEB88'
      />
    </svg>
  );
}
