import { Api } from 'common/api';

export type FeatureFlags = {
  environment: string;
  gameScrollOverlay: boolean;
  enableHeadlinesComponent: boolean;
  disabledWagersStates: string[];
  disabledWagersStartDate: string;
  disabledWagersEndDate: string;
  enableTestimonials: boolean;
  enableLastMonthsWinners: boolean;
  enableBottomNavLoggedOut: boolean;
  enableBottomNavLoggedIn: boolean;

  livechatDeploymentKey: string;
  livechatOrgGuid: string;
  livechatQueue: string;
  smartyEmbeddedKey: string;
  xtremePushKey: string;
  gtmKey: string;
  webpushHref: string;
  balancesPool: number;
  striveSocketHref: string;
  depositAmounts: string;
  enableBalancesPoll: boolean;
  enableTournaments: boolean;
  enableIncomeAccess: boolean;
  gpnRgsHost: string;
  salesforceLiveAgent: boolean;
  cloudfareSiteKey: string;
  enableCloudfare: boolean;
  enableQuickBuyCredits: boolean;
  enableBuy250Credits: boolean;
  depositCountRgs: boolean;
  enableSdkComms: boolean;
  gameOrientation: boolean;
  allowed250States: string;
  allowed250PlayerIds: string;
  enableFreeplayNotification: boolean;
  salesforceTestPlayerIds: string;
  enableChargeUpCredits: boolean;
  chargeUpCreditsTimeout: number;
  enableNpp: boolean;
  enableOnboardingPage: boolean;
};

export type FeatureFlagsServiceType = {
  featureFlags: FeatureFlags | null;
  getFeatureFlags: () => Promise<FeatureFlags>;
};

export const FeatureFlagsService: FeatureFlagsServiceType = {
  featureFlags: null,
  getFeatureFlags: async (): Promise<FeatureFlags> => {
    const { ok, data } = await Api.get<FeatureFlags>(
      API_HOST + '/api/v1/feature-flags?env=' + BUILD_ENV,
    );
    if (!ok) {
      throw new Error('Could not get feature flags');
    }
    FeatureFlagsService.featureFlags = data;
    return data;
  },
};
