import { Close } from 'icons/svg';
import { Button, OutlinedButton } from 'components/atoms/button';
import { BspotModal } from 'components/atoms/modal/modal';
import React from 'react';

export type InsufficientFundsModalProps = {
  onClose?(): void;
  onGoDeposit?(): void;
  show: boolean;
};
export const InsufficientFundsModal = ({
  onClose,
  show,
  onGoDeposit,
}: InsufficientFundsModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  const onDepositNow = () => {
    onGoDeposit();
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Not enough cash'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>

      <div className={'d-flex flex-column gap-1'}>
        <div>
          It looks like you don't have enough balance to purchase credits at
          this time.
        </div>
        <div>
          Don't worry, you can easily add more funds to your account and try
          again.
        </div>
      </div>

      <div className={'d-flex flex-row gap-2'}>
        <OutlinedButton text='Maybe Later' onClick={onCloseModal} />
        <Button text='Deposit now' onClick={onDepositNow} />
      </div>
    </BspotModal>
  );
};
export default InsufficientFundsModal;
