import React from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { FormInput, FormInputProps } from 'components/atoms/input/input';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PhoneNumberMask = React.forwardRef<PatternFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        {...other}
        inputMode='numeric'
        format='(###) ###-####'
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

export const FormPhoneNumberInput = ({
  label,
  name,
  validation,
  ...rest
}: FormInputProps) => {
  return (
    <FormInput
      label={label}
      name={name}
      validation={validation}
      InputProps={{
        inputComponent: PhoneNumberMask as any,
      }}
      {...rest}
    />
  );
};
