// @ts-nocheck
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'components/atoms/button';

export const Card = styled.div`
  display: flex;
  border: 3px solid var(--bg-dark-color);
  background-color: var(--bg-dark-color);
  border-radius: 16px;
  overflow: hidden;
  flex-direction: column;
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
`;

export const TabButton = styled(Button)`
  border: unset;
  border-right: 2px solid var(--bg-dark-color);
  border-bottom: 2px solid var(--bg-dark-color);
  border-radius: 0;
  padding: 1rem;
  flex: 1 0 0;
  background-color: var(--bg-darkish-color);
  color: var(--text-light-color);

  ${(props) =>
    props.active &&
    css`
      color: var(--text-default-color);
      background-color: var(--primary-color);
      border-bottom: 2px solid transparent;
    `}

  &:last-child {
    border-right: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: var(--bg-darkish-color);
`;

export const InfoFlexRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;

  &:last-child {
    border-bottom: 1px solid var(--bg-dark-color);
  }

  ${InfoFlexRow} > & {
    padding: 0 0 1rem;
    border: none;
  }
`;

export const WagerItem = styled.div`
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 0.5rem;

  & > :not(:nth-of-type(3n + 1)) {
    text-align: right;
  }

  @media screen and (max-width: 642px) {
    font-size: 12px;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & > * {
    flex: 1 0 0;
  }
`;
export const GroupWithPadding = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 15px 10px;
  & > * {
    flex: 1 0 0;
  }
`;

export const GroupWithScroll = styled.div`
  height: 500px;
  overflow-y: scroll;
`;

export const FilterItem = styled.button`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  //height: 50px;
  padding: 8px 16px;
  border-radius: 0px;
  //margin: 5px;
  cursor: pointer;
  opacity: 0.8;
  border: 0.5px solid #383838;
  background-color: #383838;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;

  &.btn_first {
    border-right: 0.5px solid transparent;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &.btn_last {
    border-left: 0.5px solid transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.selected {
    opacity: 1;
    border: 0.5px solid var(--primary-color);
    background-color: var(--primary-color);
    color: #000;
  }

  :hover {
    opacity: 1;
    border: 0.5px solid var(--primary-color);
    background-color: var(--primary-color);
    color: #000;
  }
`;

interface InCenterWrapper {
  isLoading?: boolean;
}
export const InCenter = styled.div<InCenterWrapper>`
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 0;
  text-align: center;
  transition: width 0.3s, opacity 0.1s;
  opacity: 0;
  ${(props) =>
    props.isLoading &&
    css`
      width: 2em;
      opacity: 1;
    `}

  svg {
    animation: rotation 0.9s infinite linear;
  }
`;
