import { Text, TextSize } from 'components/atoms/text';

export const SectionTitle = ({ title }) => {
  return (
    <div className='flex flex-column pb-3'>
      <Text bold size={TextSize.H3}>
        {title}
      </Text>
    </div>
  );
};
