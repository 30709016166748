import { Close } from 'icons/svg';
import { toCurrency } from 'common/bspot-shared';
import { BspotModal } from 'components/atoms/modal/modal';
import React from 'react';
import { Button, OutlinedButton } from 'components/atoms/button';

export type WonModalProps = {
  onClose?(): void;
  amount: any;

  show: boolean;
};
export const WonModal = ({ onClose, show, amount }: WonModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'YOU WON!'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>
      <div className={'d-flex flex-column gap-1'}>
        <div style={{ color: '#0aeb88', fontSize: '4rem' }}>
          {toCurrency(amount)}
        </div>
      </div>
      <div className={'d-flex flex-lg-row flex-column gap-2 '}>
        <OutlinedButton text={'Got it!'} onClick={onCloseModal} />
      </div>
    </BspotModal>
  );
};
export default WonModal;
