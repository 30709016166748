import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

const target = document.getElementById('root');
const root = createRoot(target);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

declare global {
  interface Window {
    Bspot: {
      auth?: any;
      onInit?: () => void;
      liveChat?: {
        open?: () => void;
        close?: () => void;
      };
      channel: any;
    };
  }
}

if (!window.Bspot) window.Bspot = {};

type CustomWebComponent<T extends HTMLElement = HTMLElement> =
  React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'bsp-tournaments': CustomWebComponent & {
        islobby?: string;
      };
      'bsp-modals': CustomWebComponent;
      'bsp-toasts': CustomWebComponent;
    }
  }
}
