import { useGetBalance } from 'hooks/use-balance';
import { useGetCredits } from 'hooks/use-credits/use-credits';
import { useEffect, useState } from 'react';

type UseBalanceAndCreditsProps = {
  balanceAndCredits: {
    deposits_count: number;
    credits: number;
    credits_pending: number;
    credits_purchased_count: number;
    credits_last_order: number;
    cash: number;
    shouldConvertCash: boolean;
    shouldDeposit: boolean;
  };
  isLoading: boolean;
};
export const useBalanceAndCredits = (): UseBalanceAndCreditsProps => {
  const { data: latestBalances, isLoading: isLoadingBalances } =
    useGetBalance();
  const { data: latestCredits, isLoading: isLoadingCredits } = useGetCredits();
  const [balanceAndCredits, setBalanceAndCredits] =
    useState<UseBalanceAndCreditsProps['balanceAndCredits']>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoadingBalances || isLoadingCredits) {
      setLoading(true);
      setBalanceAndCredits(null);
    } else {
      setLoading(false);
      setBalanceAndCredits({
        deposits_count: latestCredits?.deposits_count,
        credits: latestCredits?.credits,
        credits_pending: latestCredits?.credits_pending,
        credits_purchased_count: latestCredits?.credits_purchased_count,
        credits_last_order: latestCredits?.credits_last_order,
        cash: latestBalances.playable_balance,
        shouldConvertCash:
          latestCredits?.credits_pending === 0 &&
          latestBalances?.playable_balance >= 10 &&
          latestCredits?.credits === 0 &&
          latestCredits?.deposits_count !== 0,
        shouldDeposit:
          latestBalances?.playable_balance === 0 &&
          latestCredits?.credits === 0 &&
          latestCredits?.credits_pending === 0,
      });
    }
  }, [
    isLoadingBalances,
    isLoadingCredits,
    latestBalances?.playable_balance,
    latestCredits?.credits,
    latestCredits?.credits_last_order,
    latestCredits?.credits_pending,
    latestCredits?.credits_purchased_count,
    latestCredits?.deposits_count,
  ]);

  return { balanceAndCredits, isLoading: loading };
};
