import { useOutlet } from 'react-router-dom';
import React, { Suspense } from 'react';
import './styles.scss';
import { CircularLoader } from 'components/atoms/circular-loader';
export const NoLayout = () => {
  const outlet = useOutlet();

  return (
    <div className={'d-flex flex-column flex-grow-1'}>
      <div className={''}>
        <Suspense
          fallback={
            <div
              className='container d-flex justify-content-center align-items-center'
              style={{ height: '500px' }}
            >
              <CircularLoader color={'white'} size={'2rem'} />
            </div>
          }
        >
          {outlet}
        </Suspense>
      </div>
    </div>
  );
};
