import { useQuery } from '@tanstack/react-query';
import { Api } from 'common/api';

type PlayLimitFields = {
  disabled: boolean;
  id: string;
  title: string;
};

type PlayLimitTextField = PlayLimitFields & {
  type: 'text';
  default: string | null;
  value: string | null;
};

type PlayLimitSelectField = PlayLimitFields & {
  type: 'select';
  value: string | null;
  default: string | null;
  values: { title: string; id: string }[];
};

type PlayLimitPasswordField = PlayLimitFields & {
  type: 'password';
};

type PlayLimitSubmitField = PlayLimitFields & {
  type: 'submit';
};

type PlayLimit<T> = {
  action: string;
  disabled: boolean;
  fields: T;
  id: string;
  note: string;
  title: string;
};

export type DepositLimit = {
  AmountLeft: number;
  Formatting: string;
  FutureLimitAmount: number | null;
  FutureLimitValidFrom: string | null;
  LimitAmount: number;
  LimitType: string;
  ResetTime: string;
  ValidTo: string | null;
};

export type DepositLimitSettings = PlayLimit<
  [
    PlayLimitTextField,
    PlayLimitTextField,
    PlayLimitTextField,
    PlayLimitSubmitField,
  ]
> & {
  data?: DepositLimit[];
  onChangeDepositLimits(): void;
};

export type SelfSuspensionSettings = PlayLimit<
  [PlayLimitSelectField, PlayLimitPasswordField, PlayLimitSubmitField]
>;

export type SelfExclusionSettings = PlayLimit<
  [PlayLimitTextField, PlayLimitPasswordField, PlayLimitSubmitField]
>;

type ResponsibleGamingSettings = {
  limits: [DepositLimitSettings, SelfSuspensionSettings, SelfExclusionSettings];
};

const getPlayLimits = async () => {
  return Api.get<ResponsibleGamingSettings>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/profile/play_limits`,
  ).then(({ data, ok }) => {
    if (!ok) throw new Error('Could not retrieve user personal info');

    return data;
  });
};

export const usePlayLimits = () => {
  return useQuery(['use-play-limits'], {
    queryFn: getPlayLimits,
  });
};
