import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'components/_deprecated/forms-styled';
import { Input } from 'components/atoms/input';
import { getFormattedDateUS } from 'common/bspot-shared';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getStates, State } from 'services/get-states/get-states';
import { Button } from 'components/atoms/button';
import {
  ProfileInfoUpdate,
  updateProfileInfo,
  UserProfile,
} from 'services/profile-info/profile-info.service';
import styled from '@emotion/styled';
import { BspotAlert } from 'components/molecules/alerts/alert';
import { PersonalDetailsForm } from '../form-personal-details/personal-details-form';
import { ResidenceForm } from '../form-residence/residence-form';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';

const StyledForm = styled(Form)`
  background-color: unset;
  padding: 8px 0px;
  gap: 8px;
`;
type EditProfileFormProps = {
  data?: UserProfile;
  editProfile?: boolean;
  onAfterSubmit?: () => void;
};

export function EditProfileForm({
  data,
  editProfile,
  onAfterSubmit,
}: EditProfileFormProps) {
  const {
    register,
    control,
    trigger,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState,
    ...rest
  } = useForm({ mode: 'onBlur' });
  const errors = formState.errors;

  const [states, setStates] = useState<State[]>([]);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isError, setIsError] = useState(null);

  useEffect(() => {
    getStates().then((d) => {
      setStates(d);
      reset(getDefaultValues(d));
    });
  }, []);

  const getDefaultValues = (d) => {
    const [month, day, year] = data.dob.split('-');
    const dob = new Date(year, month - 1, day);
    const stateValue = d.find((it) => it.name === data?.address?.state);
    return {
      firstname: data?.firstName,
      middlename: data?.middleName,
      lastname: data?.lastName,
      dob: dob,
      date_of_birth: dob,
      address: data?.address?.addressLine1,
      city: data?.address?.city,
      province_id: stateValue?.id,
      postal_code: data?.address?.postalCode,
      phone_number: data?.phoneNumber.toString(),
    };
  };

  const onSubmitForm = async (formData) => {
    const isValid = await trigger();

    if (!isValid) {
      return;
    }
    setLoading(true);
    setFeedback(null);
    updateProfileInfo(preparePayload(formData))
      .then((d) => {
        postNativeMessage(
          BspotCashierMessage.EDIT_PERSONAL_INFO_SUCCESS,
          JSON.stringify({ message: d }),
        );
        setFeedback(d);
        setIsError(false);
      })
      .catch((err) => {
        console.log(err);
        postNativeMessage(
          BspotCashierMessage.EDIT_PERSONAL_INFO_FAIL,
          JSON.stringify(err),
        );
        setLoading(false);
        setFeedback(err.message);
        setIsError(true);
      })
      .finally(() => {
        setLoading(false);
        onAfterSubmit();
      });
  };
  const preparePayload = (formData) => {
    const stateValue = states.find((it) => it.id === +formData?.province_id);
    let payload: ProfileInfoUpdate = {
      address: formData?.address,
      city: formData?.city,
      date_of_birth: getFormattedDateUS(formData?.date_of_birth),
      first_name: formData?.firstname,
      last_name: formData?.lastname,
      password: formData?.password,
      phone_number: formData?.phone_number,
      postal_code: formData?.postal_code,
      social_security_number: formData?.ssn || data?.ssn,
      state: stateValue.name,
    };

    if (data.middlename && data.middlename !== '') {
      payload = {
        ...payload,
      };
    }
    return payload;
  };

  const newYorkId = useMemo(
    () => states?.filter((state) => state.label === 'New York')[0]?.id,
    [states],
  );

  const validateLength = useCallback(
    (value) => {
      const province_id = getValues()?.province_id;
      if (
        !value &&
        formState.defaultValues?.province_id != province_id &&
        province_id != newYorkId
      )
        return true;
      if (value || formState.defaultValues?.province_id != province_id) {
        const length = province_id == newYorkId ? 9 : 4;
        const isCorrect =
          value.toString().length === length && /^\d+$/.test(value);
        return isCorrect || `Must contain ${length} digits`;
      }
    },
    [formState.isDirty, newYorkId, formState.defaultValues],
  );

  return (
    <>
      {editProfile && (
        <StyledForm marginTop={'0'} onSubmit={handleSubmit(onSubmitForm)}>
          <FormProvider
            register={register}
            control={control}
            trigger={trigger}
            handleSubmit={handleSubmit}
            reset={reset}
            getValues={getValues}
            setValue={setValue}
            formState={formState}
            {...rest}
          >
            <PersonalDetailsForm />
            <ResidenceForm />
          </FormProvider>
          <div className={'row'}>
            <div className={'col-12 col'}>
              <Input
                label={'Social Security Number'}
                error={errors?.ssn?.message.toString()}
                placeholder={`___-__-${data?.ssn}`}
                {...register('ssn', {
                  validate: {
                    validateLength: (value) => validateLength(value),
                  },
                  deps: ['province_id'],
                })}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12 col'}>
              <Input
                type='password'
                label={'Password'}
                error={errors?.password?.message.toString()}
                {...register('password', {
                  required: 'This field is required',
                })}
              />
            </div>
          </div>

          {feedback && (
            <div className={'row'}>
              <div className={'col-12'}>
                <BspotAlert
                  type={isError ? 'error' : 'success'}
                  message={feedback}
                />
              </div>
            </div>
          )}

          <div className={'row pt-3'}>
            <div className={'col-12'}>
              <div className={'align-self-start'}>
                <Button
                  id='Submit'
                  text={'Save'}
                  disabled={loading}
                  isLoading={loading}
                />
              </div>
            </div>
          </div>
        </StyledForm>
      )}
    </>
  );
}
