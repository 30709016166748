import React from 'react';
import { SvgProps } from './svg';

export function Info({
  size,
  width = '1rem',
  height = '1rem',
  fill = 'none',
  color = '#282828',
  onClick,
}: SvgProps) {
  return (
    <svg
      data-testid='info-svg'
      width={size || width}
      height={size || height}
      fill={fill}
      onClick={onClick}
      viewBox='0 0 16 17'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.25 8.22913C15.25 12.2332 12.0041 15.4791 8 15.4791C3.99594 15.4791 0.75 12.2332 0.75 8.22913C0.75 4.22507 3.99594 0.979126 8 0.979126C12.0041 0.979126 15.25 4.22506 15.25 8.22913ZM6.28571 10.1934C5.47701 10.1934 4.82143 10.849 4.82143 11.6577C4.82143 12.4664 5.47702 13.122 6.28571 13.122H8H9.71429C10.523 13.122 11.1786 12.4664 11.1786 11.6577C11.1786 10.849 10.523 10.1934 9.71429 10.1934H9.46429V7.6577C9.46429 7.17561 9.23132 6.74794 8.87182 6.48112C9.47849 6.16576 9.89286 5.53155 9.89286 4.80055C9.89286 3.75516 9.04539 2.9077 8 2.9077C6.95461 2.9077 6.10714 3.75516 6.10714 4.80055C6.10714 5.35466 6.34524 5.85316 6.7247 6.19932C5.97806 6.26626 5.39286 6.89364 5.39286 7.6577C5.39286 8.35598 5.88163 8.9401 6.53571 9.08659V10.1934H6.28571Z'
        fill={color || '#282828'}
        stroke={color || '#282828'}
        strokeWidth='1.5'
      />
    </svg>
  );
}
