import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from 'contexts/authentication';
import { Trackers } from 'components/molecules/trackers';
import { LiveChat } from 'components/molecules/live-chat';
import { ThemeProvider, createTheme, css } from '@mui/material/styles';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarContextWrapper } from 'contexts/snackbar';
import Modal from 'react-modal';
import { KYCContextProvider } from 'contexts/kyc/kyc-context';
import { ErrorBoundary } from 'components/molecules/error-boundary/error-boundary';
import { BtagContextProvider } from 'contexts/btag/btag-context';
import { ModalTermsManager } from 'components';

import { SdkContextProvider } from 'contexts/sdk/sdk';
import { HelmetProvider } from 'react-helmet-async';

// Undo global css applied by CPIG
const globalStyles = css`
  body {
    margin: 0;
    color: var(--text-light-color);
    background-color: var(--bg-darkest-color);
  }

  header {
    border-image: none;
  }
`;

const queryClient = new QueryClient();
Modal.setAppElement('#root');
export const AppWrapper = ({ children }) => {
  // @ts-ignore
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider
            theme={createTheme({
              palette: {
                mode: 'dark',
                primary: { main: '#0aeb88' },
              },
              typography: {
                fontFamily: ['Poppins'].join(','),
              },
              components: {
                MuiCssBaseline: {
                  styleOverrides: `
                  input:-webkit-autofill,
                  input:-webkit-autofill:hover, 
                  input:-webkit-autofill:focus, 
                  input:-webkit-autofill:active  {
                    -webkit-box-shadow: unset !important;
                  }
                `,
                },
              },
            })}
          >
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            <BrowserRouter>
              <AuthContextProvider>
                <BtagContextProvider>
                  <SdkContextProvider>
                    <SnackbarContextWrapper>
                      <KYCContextProvider>
                        <HelmetProvider>{children}</HelmetProvider>
                        <Trackers />
                        <LiveChat />
                        <ModalTermsManager />
                      </KYCContextProvider>
                    </SnackbarContextWrapper>
                  </SdkContextProvider>
                </BtagContextProvider>
              </AuthContextProvider>
            </BrowserRouter>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
