import {
  RedirectToast,
  RedirectToastProps,
} from 'components/organisms/toasts/redirect-toast';
import { Toast, ToastProps } from 'components/organisms/toasts/toast';
import { MaxWidth, useMediaQuery } from 'hooks/use-media-query';
import {
  useSnackbar as useNotistackSnackbar,
  SnackbarOrigin,
  SnackbarProvider,
  ProviderContext,
  EnqueueSnackbar,
} from 'notistack';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react';

declare module 'notistack' {
  interface VariantOverrides {
    default: ToastProps;
    redirect: RedirectToastProps;
  }
}

export const SnackbarContextWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [isSmall] = useMediaQuery(MaxWidth.Small);

  const anchorOrigin = useMemo<SnackbarOrigin>(() => {
    if (isSmall) {
      return {
        horizontal: 'center',
        vertical: 'bottom',
      };
    } else {
      return {
        horizontal: 'right',
        vertical: 'top',
      };
    }
  }, [isSmall]);

  return (
    <SnackbarProvider
      Components={{
        default: Toast,
        redirect: RedirectToast,
      }}
      anchorOrigin={anchorOrigin}
      classes={{
        containerRoot: 'snackbar-container-root',
        containerAnchorOriginTopRight: 'snackbar-anchor-origin-top-right',
      }}
      maxSnack={5}
    >
      <SnackbarContentProvider>{children}</SnackbarContentProvider>
    </SnackbarProvider>
  );
};

type SnackbarContextValue = {
  push: ProviderContext['enqueueSnackbar'];
  has: (id: string) => boolean;
  pop: (id?: string) => void;
};

const CustomSnackbarContext = createContext<SnackbarContextValue>({
  push: () => 0,
  has: () => true,
  pop: () => {},
});

export const useSnackbar = () => {
  return useContext(CustomSnackbarContext);
};

const SnackbarContentProvider: FC<PropsWithChildren> = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();
  const snackbarIds = useRef(new Set<string>());

  const push: EnqueueSnackbar = (options) => {
    let id = '0';
    if (typeof options === 'object') {
      id = options.id;
      overrideOnClose(options, id);
    }
    if (typeof options[0] === 'string' && typeof options[1] === 'object') {
      id = options[1].id;
      overrideOnClose(options[1], id);
    }

    snackbarIds.current.add(id);
    return enqueueSnackbar(options);
  };

  const overrideOnClose = (options, id) => {
    const onClose = options.onClose;
    options.onClose = () => {
      if (typeof onClose === 'function') onClose();
      snackbarIds.current.delete(id);
    };
  };

  const has = (id: string) => {
    return snackbarIds.current.has(id);
  };

  const pop: ProviderContext['closeSnackbar'] = (id?: string) => {
    if (id === undefined) snackbarIds.current.clear();
    else snackbarIds.current.delete(id);

    closeSnackbar(id);
  };

  return (
    <CustomSnackbarContext.Provider value={{ push, has, pop }}>
      {children}
    </CustomSnackbarContext.Provider>
  );
};
