import { useQuery } from '@tanstack/react-query';
import { getProfileInfo } from 'services/profile-info/profile-info.service';
import { useAuth } from 'contexts/authentication';
import { useLocation } from 'react-router-dom';
import { Routes } from 'common/routes';

export const useProfileInfo = () => {
  const { auth } = useAuth();
  const location = useLocation();
  return useQuery({
    queryKey: ['profile-info', auth.session?.playerId],
    queryFn: getProfileInfo,
    keepPreviousData: false,
    enabled: !!auth.session && location.pathname === Routes.PlayerInfo.path,
  });
};
