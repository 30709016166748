import styled from '@emotion/styled';
import confetti from 'canvas-confetti';
import { Text } from 'components/atoms/text';
import { useSnackbar } from 'contexts/snackbar';
import { WSMessage } from 'hooks/use-webpush-listener';

const ToastTitle = styled(Text)`
  font-size: 18px;
  font-weight: 700;
`;

export const useShowFreeplayAward = () => {
  const { push, has } = useSnackbar();

  const showFreeplayAwardToast = (
    firstName: string,
    message: WSMessage,
    onClose: () => void = () => {},
  ) => {
    if (has(message.id)) return;

    const amount = message?.data?.parameters?.amount / 100;
    push({
      id: message.id,
      title: <ToastTitle>Congratulations {firstName}!</ToastTitle>,
      message: `You have been awarded $${amount} in Freeplay! The Credits will be added to
      your balance shortly. Happy spinning!`,
      persist: true,
      icon: (
        <img
          src='/assets/images/gift-animation.gif'
          style={{ width: '30px', height: '30px' }}
        />
      ),
      onClose,
    });

    confetti({
      particleCount: 450,
      spread: 80,
      startVelocity: 130,
      origin: { x: 0, y: 1.3 },
    });
    confetti({
      particleCount: 450,
      spread: 80,
      startVelocity: 130,
      origin: { x: 1, y: 1.3 },
    });
  };

  return showFreeplayAwardToast;
};
