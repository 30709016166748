import { format } from 'date-fns';

export enum Colors {
  'PrimaryLighter' = '--primary-lighter-color',
  'PrimaryLight' = '--primary-light-color',
  'Primary' = '--primary-color',
  'PrimaryDark' = '--primary-dark-color',
  'PrimaryDarker' = '--primary-darker-color',
  'SecondaryLighter' = '--secondary-lighter-color',
  'SecondaryLight' = '--secondary-light-color',
  'Secondary' = '--secondary-color',
  'SecondaryDark' = '--secondary-dark-color',
  'SecondaryDarker' = '--secondary-darker-color',
  'Secondary2Lighter' = '--secondary-2-lighter-color',
  'Secondary2Light' = '--secondary-2-light-color',
  'Secondary2' = '--secondary-2-color',
  'Secondary2Dark' = '--secondary-2-dark-color',
  'Secondary2Darker' = '--secondary-2-darker-color',
  'Secondary3Lighter' = '--secondary-3-lighter-color',
  'Secondary3Light' = '--secondary-3-light-color',
  'Secondary3' = '--secondary-3-color',
  'Secondary3Dark' = '--secondary-3-dark-color',
  'Secondary3Darker' = '--secondary-3-darker-color',
  'TextDefault' = '--text-default-color',
  'TextGrey' = '--text-grey-color',
  'TextLight' = '--text-light-color',
  'SuccessLigher' = '--success-ligher-color',
  'SuccessLight' = '--success-light-color',
  'Success' = '--success-color',
  'SuccessDark' = '--success-dark-color',
  'SuccessDarker' = '--success-darker-color',
  'ErrorLighter' = '--error-lighter-color',
  'ErrorLight' = '--error-light-color',
  'Error' = '--error-color',
  'ErrorDark' = '--error-dark-color',
  'ErrorDarker' = '--error-darker-color',
  'WarningLighter' = '--warning-lighter-color',
  'WarningLight' = '--warning-light-color',
  'Warning' = '--warning-color',
  'WarningDark' = '--warning-dark-color',
  'WarningDarker' = '--warning-darker-color',
  'BgDark' = '--bg-dark-color',
  'BgDarkest' = '--bg-darkest-color',
  'BgDarkish' = '--bg-darkish-color',
  'BgGrey' = '--bg-grey-color',
  'BgLight' = '--bg-light-color',
  'BgPure' = '--bg-pure-color',
}

export type ICustomerAccount = {
  fullName: string;
  firstName: string;
  lastName: string;
  balance: string;
  availableBalance: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateId: string;
  postalCode: string;
  email: string;
  bspotNumber: string;
};

declare global {
  interface Window {
    customerAccount: ICustomerAccount;
  }
}

export const getCustomerAccount = (): ICustomerAccount => {
  // Customer Account defaults
  const customerAccount: ICustomerAccount = {
    fullName: '',
    firstName: '',
    lastName: '',
    balance: '',
    availableBalance: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateId: '',
    postalCode: '',
    email: '',
    bspotNumber: '',
  };

  // Override any defaults with what we get from window.
  // This is to ensure that we have, at bare minimum, all
  // the fields from the ICustomerAccount. In later
  // iterations we can consider validating the types.
  return {
    ...customerAccount,
    ...(window.customerAccount || {}),
  };
};

export const customerAccountMock: ICustomerAccount = {
  ...getCustomerAccount(),
  firstName: 'John this is a very very long name',
  lastName: 'Smith',
  balance: '5',
  availableBalance: '10',
  phoneNumber: '333-333-3333',
};

export const goTo = (link: string): void => {
  window.location.href = link;
};

export const Regexes = {
  Email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/,
  Phone: /^\d{3}-\d{3}-\d{4}$/,
  Password:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&^])[^\s\-/\\|[\]{}<>()]{8,}$/,
  NumberLength: (len: number) => new RegExp(`^\\d{${len}}$`),
};

export const getFormattedDate = (date: Date) => {
  return format(date, 'dd/MM/yyyy');
};

export const getFormattedDateUS = (date: Date) => {
  return format(date, 'MM-dd-yyyy');
};

export const getUSFormattedDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const getUiDate = (d: string) => {
  const date = new Date(d);

  return format(date, 'MMMM dd, yyyy');
};

export const getUiTime = (d: string) => {
  const date = new Date(d);
  return format(date, 'hh:mm:ss a');
};

export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  const m = today.getMonth() - birthDate.getMonth();
  let age = today.getFullYear() - birthDate.getFullYear();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const getDisplayNumber = (number: any, decimals: number): string => {
  if (parseInt(number) < 0) {
    return '0.00';
  }

  if (isNaN(parseInt(number))) {
    return '0.00';
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
};

export const toCurrency = (
  amount: any,
  options = { symbol: '$', decimals: 2 },
): string => {
  return `${options.symbol}${getDisplayNumber(amount, options.decimals)}`;
};

export const formatDate = (date: string): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dateElement = date.split('-');
  const year = dateElement[0];
  const month = monthNames[Number(dateElement[1]) - 1];
  const day = dateElement[2].split('T')[0];
  return `${month} ${day}, ${year}`;
};

export const TribalZipCodes = [
  '97720',
  '97058',
  '97420',
  '97439',
  '97459',
  '97476',
  '97411',
  '97420',
  '97458',
  '97459',
  '97466',
  '97417',
  '97457',
  '97469',
  '97470',
  '97910',
  '97347',
  '97396',
  '97601',
  '97624',
  '97639',
  '97326',
  '97343',
  '97357',
  '97380',
  '97391',
  '97801',
  '97810',
  '97850',
  '97859',
  '97868',
  '97886',
  '00070',
  '97031',
  '97037',
  '97039',
  '97058',
  '97065',
  '97342',
  '97350',
  '97730',
  '97741',
  '97761',
  '97812',
  '97823',
];
