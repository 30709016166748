import React from 'react';
import { SvgProps } from './svg';

export function Spinner({
  size,
  width = '12',
  stroke = 'black',
  fill = 'black',
  color,
  className = '',
}: SvgProps) {
  return (
    <svg
      data-testid='spinner'
      className={`spinner ${className}`}
      width={size || width}
      height={size || width}
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='16.5'
        cy='16.5'
        r='14.5'
        stroke={color || stroke}
        strokeWidth='4'
      />
      <circle cx='16.5' cy='22.5' r='6.5' fill={color || fill} />
    </svg>
  );
}
