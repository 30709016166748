import 'common/theme/main.scss';
import { ReactNode, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Text } from 'components/atoms/text';
import { Button } from 'components/atoms/button';
import { useProfileInfo } from 'hooks/use-profile-info/use-profile-info';
import { useAuth } from 'contexts/authentication';
import { Spinner } from 'icons/svg';
import { EditProfileForm } from 'components/molecules/form-edit-profile/edit-profile-form';
import { RegistrationFail } from 'components/organisms/registration/registration-failed';
import { SectionTitle } from 'components/atoms/section-title/section-title';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { InCenter } from 'pages/account-history/styled';
import { BspotAlert, ModalWelcome } from 'components';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'common/utils';

export const Card = styled.div`
  display: flex;
  background-color: var(--bg-dark-color);
  flex-direction: column;
  padding: 16px;
  border: 3px solid var(--bg-dark-color);
  border-radius: 4px;
  .header {
    border-bottom: solid 1px var(--bg-darkish-color);
  }
`;

const InfoCard = ({
  title,
  subtitle,
  children,
}: {
  title: ReactNode;
  subtitle: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <div className={`col col-12 ${children ? 'mt-2' : 'mt-4'}`}>
      <Card>
        <Text bold className='pb-2 mb-2 header'>
          {title}
        </Text>
        <Text color={'#707070'} bold>
          {subtitle}
        </Text>
        {children}
      </Card>
    </div>
  );
};

const feedback_status_5 =
  'Personal details verification failed. Please review the information you entered and make certain everything is correct. Once we verify your age and state of residence, you will be playing in no time.';
const feedback_status_6 =
  'We are unable to verify your identity with the information provided. This can happen occasionally for a variety of reasons. But all is not lost. You can easily upload documentation to help us verify your age and state of residence. Please open the Documents Center in the menu to get started. This only takes a minute or two.';

export default function PlayerInfo() {
  const { data: profileData, isLoading } = useProfileInfo();

  const { profile, auth } = useAuth();
  const { data: status } = usePlayerStatus();
  const [toggleEditProfile, setToggleEditProfile] = useState(false);

  const [message, setMessage] = useState(
    status?.validator_status === 5 ? feedback_status_5 : feedback_status_6,
  );
  const [isEditProfile, setIsEditProfile] = useState(
    status?.validator_status === 5,
  );

  const [isValidProfile, setIsValidProfile] = useState(true);

  const [displayError, setDisplayError] = useState(null);
  const [displaySuccess, setDisplaySuccess] = useState(null);

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setIsEditProfile(status?.validator_status === 5);
    setIsValidProfile(status?.validator_status === 1);
    setMessage(
      status?.validator_status === 5 ? feedback_status_5 : feedback_status_6,
    );
    if (submitted) setDisplayError(status?.validator_status === 5);
  }, [submitted, status]);

  const onAfterSubmit = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // strive ws will refetch =>  status, kyc, profile
    setTimeout(() => {
      setSubmitted(true);
    }, 1000);
  };

  const pageTitle = 'Personal details -' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      {isLoading ? (
        <>
          <InCenter isLoading={true} className='mx-auto'>
            <Spinner size='4em' color='white' />
          </InCenter>
        </>
      ) : (
        <>
          <div className='col col-12'>
            <SectionTitle title={'Personal Details'} />
          </div>

          {status?.validator_status && !isValidProfile && (
            <div className='col col-12'>
              <div className={'pb-3'}>
                <BspotAlert type={'error'} message={message} />
              </div>
            </div>
          )}

          <InfoCard
            title={
              <div className='d-flex align-items-center justify-content-between'>
                Account Info{' '}
                {isEditProfile && (
                  <span className='float-end '>
                    <Button
                      className={'align-self-end'}
                      text={toggleEditProfile ? 'Cancel' : 'Edit'}
                      onClick={() => setToggleEditProfile(!toggleEditProfile)}
                    ></Button>
                  </span>
                )}
              </div>
            }
            subtitle={profileData?.fullName}
          >
            <Text>Date Of Birth: {profileData?.dob}</Text>
            <Text>Address: {profileData?.address?.addressLine1}</Text>
            <Text>City: {profileData?.address?.city}</Text>
            <Text>State: {profileData?.address?.state}</Text>
            <Text>Postal Code: {profileData?.address?.postalCode}</Text>
            <Text>Phone Number: {profileData?.phoneNumber}</Text>
            <Text>Social Security Number: {profileData?.ssn}</Text>

            {status?.validator_status === 5 && profileData && (
              <EditProfileForm
                data={profileData}
                editProfile={toggleEditProfile}
                onAfterSubmit={onAfterSubmit}
              />
            )}
          </InfoCard>
          <InfoCard title='Email' subtitle={profile?.email} />
          <InfoCard title='Account Number' subtitle={profile?.playerId} />
        </>
      )}

      {displayError && (
        <RegistrationFail
          show={displayError}
          onclose={() => setDisplayError(false)}
          status={status?.validator_status}
        />
      )}

      {displaySuccess && (
        <ModalWelcome
          playerId={auth.session?.playerId.toString()}
          firstName={auth.session?.firstName}
          status={status?.validator_status}
          show={displaySuccess}
          onclose={() => setDisplaySuccess(false)}
        />
      )}
    </>
  );
}
