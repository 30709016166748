import { Controller } from 'react-hook-form';
import { DateField } from '@mui/x-date-pickers';

type PropsSelect = {
  control: any;
  rules: any;
  label?: string;
  name: string;
  error?: any;
};

export const DatePicker = ({ control, name, rules, label }: PropsSelect) => {
  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      defaultValue={null}
      render={({ field, fieldState: { error } }) => {
        return (
          <DateField
            {...field}
            fullWidth
            label={label}
            format='MM-dd-yyyy'
            error={!!error}
            helperText={error && error?.message}
          />
        );
      }}
    />
  );
};
