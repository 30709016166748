import React from 'react';
import { WithdrawalMethods } from 'services/withdraw/withdraw';
import styled from '@emotion/styled';
export const CancelButtonItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  opacity: 0.8;
  border: 2px solid var(--error-color);
  background-color: var(--text-default-color);
  color: #d47300;
  font-weight: 600;
  width: 90px;
  padding: 6px 16px;
  max-height: 28px;

  :hover {
    opacity: 1;
  }
  :disabled {
    color: #b3b3b3;
    background-color: #f2f2f2;
    border-color: #b3b3b3;
    cursor: not-allowed;
    pointer-events: none;
  }

  @media only screen and (max-width: 620px) {
    width: 60px;
    padding: 3px 8px;
    font-size: 10px;
  }
`;
const Card = styled.div`
  display: flex;
  background-color: rgb(34, 34, 34);
  flex-direction: column;
  gap: 1px;
  padding: 25px 30px;
  border: rgb(34, 34, 34);
  border-radius: 32px;
  .header-rq {
    color: #999999;
    font-size: 12px;
  }
  .bordered-top {
    border-top: 1px solid var(--bg-darkish-color);
  }
  .row-req {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 620px) {
    padding: 12px 15px;
    .row-req {
      font-size: 10px;
    }
  }
`;

export type WithdrawRequestsProps = {
  withdrawMethods: WithdrawalMethods;
  cancelWithdrawRequest?(e: number): void;
};

export function WithdrawRequests({
  withdrawMethods,
  cancelWithdrawRequest,
}: WithdrawRequestsProps) {
  const withRequests = [];
  if (withdrawMethods?.requests) {
    for (const rq of withdrawMethods.requests) {
      withRequests.push(
        <div className={'row p-2 bordered-top my-auto'} key={rq.id}>
          <div
            className={
              'fw-semibold col-2 row-req justify-content-start row-req'
            }
          >
            {rq.id}
          </div>
          <div className={'fw-semibold text-center row-req col-4 row-req'}>
            {rq.account}
          </div>
          <div className={'fw-semibold row-req  col-3 row-req'}>
            {rq.amount}
          </div>
          <div className={'fw-semibold  row-req col-3 justify-content-end '}>
            {rq.cancel_requested ? (
              'Requested'
            ) : (
              <CancelButtonItem onClick={() => cancelWithdrawRequest(rq.id)}>
                {'Cancel'}
              </CancelButtonItem>
            )}
          </div>
        </div>,
      );
    }
  }
  return (
    <div>
      {withRequests.length > 0 ? (
        <div className='col-12 py-5'>
          <Card color={'#b3b3b3'}>
            <div className={' row p-2'}>
              <div className={'fw-semibold  header-rq col-2'}>{'ID'}</div>
              <div className={'fw-semibold  header-rq text-center col-4'}>
                {'BANK ACCOUNT'}
              </div>
              <div className={'fw-semibold  header-rq text-center col-3'}>
                {'AMOUNT'}
              </div>
              <div
                className={
                  'fw-semibold  header-rq d-flex float-end justify-content-end col-3'
                }
              >
                {'CANCEL'}
              </div>
            </div>
            {withRequests}
          </Card>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
