import { SvgProps } from 'icons/svg/svg';

export function ChipBlack({
  size,
  width = '120px',
  height = '120px',
  fill = 'none',
  onClick,
}: SvgProps) {
  return (
    <svg
      viewBox='0 0 51 50'
      width={size || width}
      height={size || height}
      fill={fill}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.6574 46.7259C25.6718 53.5483 10.4188 49.3496 3.58822 37.3513C-3.24238 25.3529 0.93679 10.098 12.9224 3.27402C24.9079 -3.54997 40.1609 0.645653 46.99 12.6486C53.8191 24.6516 49.6414 39.9049 37.6574 46.7259Z'
        fill='url(#paint0_linear_10621_388047)'
      />
      <path
        d='M43.1429 16.5014C47.8328 26.3456 43.6384 38.1702 33.7935 42.8597C23.9486 47.5493 12.1246 43.3491 7.43471 33.505C2.74477 23.6608 6.93918 11.8362 16.7841 7.14815C26.629 2.46009 38.453 6.65267 43.1429 16.5014ZM10.0495 32.2579C11.4847 35.2713 13.7812 37.7914 16.6487 39.4997C19.5162 41.208 22.826 42.0277 26.1594 41.8552C29.4928 41.6826 32.7002 40.5255 35.3759 38.5302C38.0517 36.535 40.0757 33.7911 41.1919 30.6456C42.3081 27.5002 42.4665 24.0944 41.647 20.8589C40.8274 17.6234 39.0668 14.7036 36.5878 12.4687C34.1087 10.2338 31.0225 8.7841 27.7195 8.303C24.4165 7.82189 21.045 8.33097 18.0313 9.76585C13.9933 11.6938 10.8852 15.1447 9.3888 19.3614C7.89242 23.5781 8.13005 28.2161 10.0495 32.2579V32.2579Z'
        fill='url(#paint1_linear_10621_388047)'
      />
      <path
        d='M38.8497 25.0008C38.8494 27.6829 38.0536 30.3046 36.563 32.5344C35.0725 34.7643 32.954 36.502 30.4757 37.5278C27.9973 38.5536 25.2704 38.8215 22.6398 38.2975C20.0092 37.7735 17.5931 36.4812 15.6971 34.5841C13.8011 32.6869 12.5103 30.2701 11.988 27.6393C11.4658 25.0086 11.7355 22.2821 12.7631 19.8046C13.7907 17.3271 15.53 15.21 17.761 13.7211C19.9919 12.2321 22.6144 11.4382 25.2967 11.4397C28.8922 11.4417 32.3396 12.8714 34.8811 15.4144C37.4226 17.9575 38.8501 21.4056 38.8497 25.0008V25.0008Z'
        fill='white'
      />
      <path
        d='M25.2964 38.8438C22.5579 38.8441 19.8808 38.0324 17.6036 36.5112C15.3265 34.9901 13.5516 32.8279 12.5035 30.298C11.4553 27.7682 11.181 24.9844 11.7152 22.2986C12.2494 19.6129 13.5681 17.1459 15.5045 15.2096C17.441 13.2733 19.9082 11.9546 22.5941 11.4205C25.2801 10.8863 28.0641 11.1606 30.5941 12.2087C33.1242 13.2568 35.2866 15.0315 36.8078 17.3085C38.3291 19.5854 39.1409 22.2624 39.1406 25.0007C39.1366 28.6709 37.6767 32.1896 35.0813 34.7848C32.4859 37.38 28.9669 38.8398 25.2964 38.8438V38.8438ZM25.2964 11.7232C22.6696 11.7205 20.101 12.4969 17.9156 13.9542C15.7301 15.4115 14.026 17.4842 13.0189 19.9101C12.0118 22.336 11.7469 25.0061 12.2577 27.5826C12.7685 30.1591 14.0321 32.5262 15.8885 34.3845C17.745 36.2427 20.111 37.5087 22.6872 38.0221C25.2633 38.5355 27.9339 38.2734 30.361 37.2688C32.7881 36.2643 34.8628 34.5625 36.3224 32.3787C37.7821 30.195 38.5612 27.6273 38.5612 25.0007C38.5568 21.483 37.1583 18.1105 34.672 15.6218C32.1857 13.1332 28.8144 11.7313 25.2964 11.7232V11.7232Z'
        fill='url(#paint2_linear_10621_388047)'
      />
      <path
        d='M25.2964 38.7432C22.5778 38.7435 19.9201 37.9376 17.6595 36.4275C15.3989 34.9174 13.6369 32.7709 12.5964 30.2595C11.5559 27.748 11.2835 24.9844 11.8138 22.3182C12.3442 19.652 13.6533 17.2029 15.5756 15.2807C17.498 13.3584 19.9473 12.0494 22.6137 11.5191C25.2801 10.9889 28.0439 11.2612 30.5556 12.3016C33.0672 13.3421 35.2139 15.1039 36.7241 17.3643C38.2343 19.6248 39.0402 22.2822 39.0399 25.0007C39.0355 28.6441 37.5861 32.1369 35.0096 34.7132C32.4332 37.2894 28.94 38.7387 25.2964 38.7432V38.7432ZM25.2964 11.6226C22.6503 11.6226 20.0635 12.4072 17.8633 13.8772C15.6632 15.3472 13.9483 17.4366 12.9357 19.8811C11.9231 22.3256 11.6581 25.0155 12.1743 27.6106C12.6906 30.2057 13.9648 32.5895 15.8359 34.4604C17.707 36.3314 20.091 37.6055 22.6863 38.1217C25.2816 38.6379 27.9717 38.373 30.4164 37.3605C32.8611 36.3479 34.9506 34.6332 36.4207 32.4332C37.8909 30.2331 38.6755 27.6466 38.6755 25.0007C38.6715 21.4538 37.2606 18.0534 34.7524 15.5454C32.2442 13.0374 28.8435 11.6266 25.2964 11.6226V11.6226Z'
        fill='white'
      />
      <path
        d='M25.2967 39.0268C22.5224 39.0268 19.8104 38.2041 17.5036 36.6629C15.1969 35.1217 13.399 32.9312 12.3373 30.3682C11.2756 27.8053 10.9978 24.9851 11.5391 22.2643C12.0803 19.5435 13.4163 17.0443 15.378 15.0828C17.3397 13.1212 19.8391 11.7853 22.5601 11.2441C25.2811 10.7029 28.1015 10.9807 30.6646 12.0423C33.2277 13.1039 35.4185 14.9016 36.9598 17.2082C38.5011 19.5148 39.3238 22.2266 39.3238 25.0007C39.3198 28.7194 37.8406 32.2846 35.2109 34.9142C32.5812 37.5437 29.0157 39.0227 25.2967 39.0268V39.0268ZM25.2967 11.9061C22.7066 11.9061 20.1747 12.6741 18.0212 14.113C15.8676 15.5518 14.1891 17.5969 13.198 19.9896C12.2068 22.3823 11.9475 25.0152 12.4527 27.5553C12.958 30.0954 14.2053 32.4286 16.0367 34.2599C17.8682 36.0912 20.2016 37.3384 22.7419 37.8436C25.2821 38.3489 27.9152 38.0896 30.3081 37.0985C32.701 36.1074 34.7463 34.429 36.1852 32.2756C37.6242 30.1222 38.3922 27.5905 38.3922 25.0007C38.3878 21.5292 37.0066 18.2011 34.5517 15.7463C32.0968 13.2916 28.7685 11.9106 25.2967 11.9061V11.9061Z'
        fill='url(#paint3_linear_10621_388047)'
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.3'>
        <path
          d='M35.1873 34.3098C33.346 36.2602 30.9676 37.6208 28.353 38.2196C25.7383 38.8184 23.0048 38.6284 20.4981 37.6738C17.9914 36.7191 15.8242 35.0426 14.2703 32.8563C12.7165 30.67 11.846 28.0721 11.7688 25.391C11.6916 22.71 12.4112 20.0663 13.8367 17.7942C15.2621 15.5221 17.3294 13.7237 19.777 12.6265C22.2246 11.5292 24.9426 11.1823 27.5874 11.6297C30.2322 12.0771 32.6849 13.2986 34.6354 15.1398C35.9306 16.3622 36.9723 17.8278 37.701 19.4528C38.4297 21.0777 38.8311 22.8304 38.8824 24.6105C38.9337 26.3907 38.6337 28.1635 37.9997 29.8277C37.3657 31.4919 36.41 33.0149 35.1873 34.3098Z'
          fill='url(#paint4_linear_10621_388047)'
        />
        <path
          d='M15.8908 34.9928C13.9143 33.1269 12.5355 30.7169 11.9288 28.0676C11.3221 25.4182 11.5146 22.6484 12.4822 20.1084C13.4497 17.5685 15.1487 15.3725 17.3644 13.798C19.5801 12.2236 22.2129 11.3415 24.9299 11.2633C27.647 11.185 30.3262 11.9142 32.6288 13.3585C34.9314 14.8028 36.754 16.8974 37.8661 19.3774C38.9783 21.8575 39.3299 24.6116 38.8767 27.2915C38.4235 29.9714 37.1857 32.4568 35.3199 34.4333C32.8154 37.0798 29.3634 38.625 25.721 38.7299C22.0787 38.8348 18.5434 37.4908 15.8908 34.9928ZM34.5087 15.2724C32.5844 13.457 30.165 12.2529 27.5564 11.8124C24.9478 11.3718 22.2671 11.7145 19.8532 12.7972C17.4394 13.8799 15.4007 15.6539 13.9951 17.8951C12.5895 20.1362 11.88 22.7437 11.9563 25.388C12.0326 28.0323 12.8913 30.5945 14.4239 32.7509C15.9564 34.9072 18.0939 36.5608 20.5662 37.5025C23.0385 38.4442 25.7345 38.6318 28.3133 38.0415C30.8922 37.4512 33.2382 36.1096 35.0546 34.1863C37.4871 31.6041 38.7958 28.1624 38.6935 24.6165C38.5912 21.0706 37.0862 17.7101 34.5087 15.2724Z'
          fill='url(#paint5_linear_10621_388047)'
        />
      </g>
      <path
        d='M19.2881 1.03129V4.8107C19.2881 4.8107 24.2357 5.92669 30.9321 4.8107V1.03129C30.9321 1.03129 26.5166 -1.28911 19.2881 1.03129Z'
        fill='white'
      />
      <path
        d='M19.5679 49.025V45.4545C19.5679 45.4545 24.5155 44.3995 31.2104 45.4545V49.025C31.2104 49.025 26.7888 51.2189 19.5679 49.025Z'
        fill='white'
      />
      <path
        d='M1.21582 31.0699L4.17067 29.4797C4.17067 29.4797 7.38928 33.3674 9.69156 39.7294L6.73671 41.3211C6.73671 41.3211 2.82589 38.4031 1.21582 31.0699Z'
        fill='white'
      />
      <path
        d='M6.85842 8.4104L10.2127 10.182C10.2127 10.182 8.89236 15.0789 4.7757 20.4774L1.42139 18.7074C1.42139 18.7074 1.42901 13.7083 6.85842 8.4104Z'
        fill='white'
      />
      <path
        d='M49.3609 30.9983L46.3024 29.3335C46.3024 29.3335 42.9923 33.3584 40.6382 39.9643L43.6998 41.6292C43.6998 41.6292 47.7295 38.6075 49.3609 30.9983Z'
        fill='white'
      />
      <path
        d='M43.4997 8.28052L40.3726 9.94535C40.3726 9.94535 41.8759 15.0496 46.0856 20.8049L49.2142 19.1477C49.2142 19.1477 48.9642 13.9961 43.4997 8.28052Z'
        fill='white'
      />
      <path
        d='M25.2971 43.1811C21.7 43.1826 18.1832 42.1174 15.1917 40.1201C12.2001 38.1229 9.8681 35.2833 8.49064 31.9607C7.11319 28.638 6.75216 24.9815 7.45321 21.4537C8.15427 17.9258 9.88592 14.6851 12.4291 12.1414C14.9723 9.59778 18.2129 7.86544 21.7408 7.16355C25.2688 6.46166 28.9256 6.82175 32.2489 8.19826C35.5721 9.57478 38.4125 11.9059 40.4106 14.8967C42.4088 17.8876 43.475 21.4038 43.4744 25.0006C43.468 29.8196 41.5509 34.4394 38.1435 37.8474C34.7362 41.2554 30.1164 43.1735 25.2971 43.1811V43.1811ZM25.2971 7.35367C21.8062 7.35186 18.3932 8.3853 15.4897 10.3233C12.5863 12.2612 10.3228 15.0167 8.98569 18.2411C7.64853 21.4655 7.29773 25.014 7.97766 28.4377C8.65759 31.8615 10.3377 35.0067 12.8055 37.4756C15.2733 39.9445 18.4179 41.6261 21.8416 42.3078C25.2652 42.9894 28.8142 42.6405 32.0395 41.3051C35.2648 39.9697 38.0217 37.7079 39.9613 34.8057C41.9009 31.9034 42.9362 28.4912 42.9362 25.0006C42.931 20.3235 41.0712 15.8394 37.7646 12.5314C34.458 9.22331 29.9745 7.36134 25.2971 7.35367V7.35367Z'
        fill='url(#paint6_linear_10621_388047)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.2968 3.8335C30.9808 3.8335 35.5975 5.31538 35.5975 7.14639C35.5975 8.9774 30.9777 10.4593 25.2968 10.4593C19.6158 10.4593 14.9868 8.9774 14.9868 7.14639C14.9868 5.31538 19.599 3.8335 25.2968 3.8335Z'
        fill='url(#paint7_radial_10621_388047)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.2962 38.5894C32.3738 38.5894 38.1219 40.4356 38.1219 42.7133C38.1219 44.991 32.3707 46.8403 25.2962 46.8403C18.2216 46.8403 12.4629 44.9956 12.4629 42.7148C12.4629 40.4341 18.2033 38.5894 25.2962 38.5894Z'
        fill='url(#paint8_radial_10621_388047)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M46.3876 24.9336C46.3876 30.6187 44.9056 35.2352 43.076 35.2352C41.2463 35.2352 39.7598 30.6187 39.7598 24.9336C39.7598 19.2485 41.2418 14.626 43.076 14.626C44.9102 14.626 46.3876 19.2454 46.3876 24.9336Z'
        fill='url(#paint9_radial_10621_388047)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M42.1644 24.7522C42.1644 30.4358 40.6824 35.0538 38.8512 35.0538C37.0201 35.0538 35.5381 30.4358 35.5381 24.7522C35.5381 19.0686 37.0186 14.4446 38.8512 14.4446C40.6839 14.4446 42.1644 19.0579 42.1644 24.7522Z'
        fill='url(#paint10_radial_10621_388047)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M15.0419 25.0035C15.0419 30.6871 13.5599 35.305 11.7287 35.305C9.89758 35.305 8.41406 30.6871 8.41406 25.0035C8.41406 19.3199 9.89758 14.6943 11.7272 14.6943C13.5568 14.6943 15.0419 19.3092 15.0419 25.0035Z'
        fill='url(#paint11_radial_10621_388047)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M11.701 24.9352C11.701 32.1327 9.82261 37.9795 7.50508 37.9795C5.18756 37.9795 3.30762 32.1327 3.30762 24.9352C3.30762 17.7377 5.18451 11.8818 7.50508 11.8818C9.82566 11.8818 11.701 17.721 11.701 24.9352Z'
        fill='url(#paint12_radial_10621_388047)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.0167 20.7165C19.3311 20.7165 14.7144 17.6064 14.7144 13.766C14.7144 9.92562 19.3311 6.81396 25.0167 6.81396C30.7022 6.81396 35.3251 9.92257 35.3251 13.766C35.3251 17.6095 30.7114 20.7165 25.0167 20.7165Z'
        fill='url(#paint13_radial_10621_388047)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.4747 45.7287C19.7891 45.7287 15.1724 42.6186 15.1724 38.7797C15.1724 34.9409 19.7891 31.8262 25.4747 31.8262C31.1602 31.8262 35.7831 34.9363 35.7831 38.7797C35.7831 42.6232 31.1694 45.7287 25.4747 45.7287Z'
        fill='url(#paint14_radial_10621_388047)'
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity='0.1'
        d='M37.6935 46.7256C25.7079 53.5481 10.455 49.3494 3.62435 37.351C-3.20625 25.3526 0.972922 10.0978 12.9585 3.27377C24.9441 -3.55021 40.197 0.645409 47.0261 12.6484C53.8552 24.6513 49.6776 39.9047 37.6935 46.7256Z'
        fill='url(#paint15_linear_10621_388047)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_10621_388047'
          x1='12.9252'
          y1='3.27209'
          x2='37.6577'
          y2='46.7241'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#424143' />
          <stop offset='1' stop-color='#010101' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_10621_388047'
          x1='33.7992'
          y1='42.8505'
          x2='16.7925'
          y2='7.14427'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#242020' />
          <stop offset='0.7' stop-color='#0D0B0B' />
          <stop offset='1' stop-color='#010101' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_10621_388047'
          x1='11.4447'
          y1='25.0007'
          x2='39.133'
          y2='25.0007'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#553319' />
          <stop offset='0.1' stop-color='#6A4623' />
          <stop offset='0.29' stop-color='#A27B3C' />
          <stop offset='0.39' stop-color='#EED072' />
          <stop offset='0.52' stop-color='#FFF5D8' />
          <stop offset='0.61' stop-color='#EED072' />
          <stop offset='0.81' stop-color='#A27B3C' />
          <stop offset='1' stop-color='#553319' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_10621_388047'
          x1='11.2619'
          y1='25.0007'
          x2='39.3162'
          y2='25.0007'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#DDDAD6' />
          <stop offset='0.06' stop-color='#CECBC9' />
          <stop offset='0.18' stop-color='#A7A5A8' />
          <stop offset='0.26' stop-color='#8A888E' />
          <stop offset='0.52' stop-color='#F3F4F4' />
          <stop offset='0.59' stop-color='#E5E5E6' />
          <stop offset='0.71' stop-color='#BFBEC0' />
          <stop offset='0.87' stop-color='#838083' />
          <stop offset='0.88' stop-color='#807D80' />
          <stop offset='1' stop-color='#A8AAAC' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_10621_388047'
          x1='15.4671'
          y1='15.6867'
          x2='35.1886'
          y2='34.3053'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stop-color='white' />
          <stop offset='1' stop-color='#858A90' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_10621_388047'
          x1='15.334'
          y1='15.5611'
          x2='35.3205'
          y2='34.4298'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stop-color='white' />
          <stop offset='1' stop-color='#553319' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_10621_388047'
          x1='7.1045'
          y1='25.0006'
          x2='43.4744'
          y2='25.0006'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#DDDAD6' />
          <stop offset='0.06' stop-color='#CECBC9' />
          <stop offset='0.18' stop-color='#A7A5A8' />
          <stop offset='0.26' stop-color='#8A888E' />
          <stop offset='0.52' stop-color='#F3F4F4' />
          <stop offset='0.59' stop-color='#E5E5E6' />
          <stop offset='0.71' stop-color='#BFBEC0' />
          <stop offset='0.87' stop-color='#838083' />
          <stop offset='0.88' stop-color='#807D80' />
          <stop offset='1' stop-color='#A8AAAC' />
        </linearGradient>
        <radialGradient
          id='paint7_radial_10621_388047'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.3399 7.27019) rotate(-90) scale(3.30668 10.3053)'
        >
          <stop stop-color='white' />
          <stop offset='0.01' stop-color='#F9F9F9' />
          <stop offset='0.05' stop-color='#CBCBCB' />
          <stop offset='0.09' stop-color='#A0A0A0' />
          <stop offset='0.14' stop-color='#7A7A7A' />
          <stop offset='0.19' stop-color='#5A5A5A' />
          <stop offset='0.25' stop-color='#3E3E3E' />
          <stop offset='0.32' stop-color='#282828' />
          <stop offset='0.4' stop-color='#171717' />
          <stop offset='0.49' stop-color='#0C0C0C' />
          <stop offset='0.63' stop-color='#050505' />
          <stop offset='1' stop-color='#030303' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_10621_388047'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.3393 42.7732) rotate(-90) scale(4.11696 12.8306)'
        >
          <stop stop-color='white' />
          <stop offset='0.01' stop-color='#F9F9F9' />
          <stop offset='0.05' stop-color='#CBCBCB' />
          <stop offset='0.09' stop-color='#A0A0A0' />
          <stop offset='0.14' stop-color='#7A7A7A' />
          <stop offset='0.19' stop-color='#5A5A5A' />
          <stop offset='0.25' stop-color='#3E3E3E' />
          <stop offset='0.32' stop-color='#282828' />
          <stop offset='0.4' stop-color='#171717' />
          <stop offset='0.49' stop-color='#0C0C0C' />
          <stop offset='0.63' stop-color='#050505' />
          <stop offset='1' stop-color='#030303' />
        </radialGradient>
        <radialGradient
          id='paint9_radial_10621_388047'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(43.0325 25.003) scale(3.30693 10.3045)'
        >
          <stop stop-color='white' />
          <stop offset='0.01' stop-color='#F9F9F9' />
          <stop offset='0.05' stop-color='#CBCBCB' />
          <stop offset='0.09' stop-color='#A0A0A0' />
          <stop offset='0.14' stop-color='#7A7A7A' />
          <stop offset='0.19' stop-color='#5A5A5A' />
          <stop offset='0.25' stop-color='#3E3E3E' />
          <stop offset='0.32' stop-color='#282828' />
          <stop offset='0.4' stop-color='#171717' />
          <stop offset='0.49' stop-color='#0C0C0C' />
          <stop offset='0.63' stop-color='#050505' />
          <stop offset='1' stop-color='#030303' />
        </radialGradient>
        <radialGradient
          id='paint10_radial_10621_388047'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(38.8184 24.8233) scale(3.30693 10.3045)'
        >
          <stop stop-color='white' />
          <stop offset='0.01' stop-color='#F9F9F9' />
          <stop offset='0.05' stop-color='#CBCBCB' />
          <stop offset='0.09' stop-color='#A0A0A0' />
          <stop offset='0.14' stop-color='#7A7A7A' />
          <stop offset='0.19' stop-color='#5A5A5A' />
          <stop offset='0.25' stop-color='#3E3E3E' />
          <stop offset='0.32' stop-color='#282828' />
          <stop offset='0.4' stop-color='#171717' />
          <stop offset='0.49' stop-color='#0C0C0C' />
          <stop offset='0.63' stop-color='#050505' />
          <stop offset='1' stop-color='#030303' />
        </radialGradient>
        <radialGradient
          id='paint11_radial_10621_388047'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(11.7443 25.0763) scale(3.30693 10.3045)'
        >
          <stop stop-color='white' />
          <stop offset='0.01' stop-color='#F9F9F9' />
          <stop offset='0.05' stop-color='#CBCBCB' />
          <stop offset='0.09' stop-color='#A0A0A0' />
          <stop offset='0.14' stop-color='#7A7A7A' />
          <stop offset='0.19' stop-color='#5A5A5A' />
          <stop offset='0.25' stop-color='#3E3E3E' />
          <stop offset='0.32' stop-color='#282828' />
          <stop offset='0.4' stop-color='#171717' />
          <stop offset='0.49' stop-color='#0C0C0C' />
          <stop offset='0.63' stop-color='#050505' />
          <stop offset='1' stop-color='#030303' />
        </radialGradient>
        <radialGradient
          id='paint12_radial_10621_388047'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(7.52972 25.0113) scale(4.18807 13.0502)'
        >
          <stop stop-color='white' />
          <stop offset='0.01' stop-color='#F9F9F9' />
          <stop offset='0.05' stop-color='#CBCBCB' />
          <stop offset='0.09' stop-color='#A0A0A0' />
          <stop offset='0.14' stop-color='#7A7A7A' />
          <stop offset='0.19' stop-color='#5A5A5A' />
          <stop offset='0.25' stop-color='#3E3E3E' />
          <stop offset='0.32' stop-color='#282828' />
          <stop offset='0.4' stop-color='#171717' />
          <stop offset='0.49' stop-color='#0C0C0C' />
          <stop offset='0.63' stop-color='#050505' />
          <stop offset='1' stop-color='#030303' />
        </radialGradient>
        <radialGradient
          id='paint13_radial_10621_388047'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(24.926 13.9378) rotate(90) scale(6.9594 10.3053)'
        >
          <stop stop-color='white' />
          <stop offset='0.01' stop-color='#F9F9F9' />
          <stop offset='0.05' stop-color='#CBCBCB' />
          <stop offset='0.09' stop-color='#A0A0A0' />
          <stop offset='0.14' stop-color='#7A7A7A' />
          <stop offset='0.19' stop-color='#5A5A5A' />
          <stop offset='0.25' stop-color='#3E3E3E' />
          <stop offset='0.32' stop-color='#282828' />
          <stop offset='0.4' stop-color='#171717' />
          <stop offset='0.49' stop-color='#0C0C0C' />
          <stop offset='0.63' stop-color='#050505' />
          <stop offset='1' stop-color='#030303' />
        </radialGradient>
        <radialGradient
          id='paint14_radial_10621_388047'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.3827 38.9829) rotate(90) scale(6.9594 10.3053)'
        >
          <stop stop-color='white' />
          <stop offset='0.01' stop-color='#F9F9F9' />
          <stop offset='0.05' stop-color='#CBCBCB' />
          <stop offset='0.09' stop-color='#A0A0A0' />
          <stop offset='0.14' stop-color='#7A7A7A' />
          <stop offset='0.19' stop-color='#5A5A5A' />
          <stop offset='0.25' stop-color='#3E3E3E' />
          <stop offset='0.32' stop-color='#282828' />
          <stop offset='0.4' stop-color='#171717' />
          <stop offset='0.49' stop-color='#0C0C0C' />
          <stop offset='0.63' stop-color='#050505' />
          <stop offset='1' stop-color='#030303' />
        </radialGradient>
        <linearGradient
          id='paint15_linear_10621_388047'
          x1='12.9616'
          y1='3.27191'
          x2='37.6941'
          y2='46.7239'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.61' stop-color='white' />
          <stop offset='0.8' stop-color='#7A7A7A' />
          <stop offset='0.94' stop-color='#232323' />
          <stop offset='1' stop-color='#010101' />
        </linearGradient>
      </defs>
    </svg>
  );
}
