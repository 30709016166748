import { SvgProps } from './svg';

export function LogoCompact({
  size,
  width = '40',
  height = '35',
  fill = '#0AEB88',
  onClick,
  className = '',
}: SvgProps) {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width={size || width}
      height={size || height}
      viewBox={`0 0 ${size || width} ${size || height}`}
      fill='none'
      className={className}
    >
      <g clipPath='url(#clip0_6601_77556)'>
        <path
          d='M12 10.9932C10.2774 10.9932 8.63955 11.3609 7.15691 12.0177L7.3887 0H0V22.9966C0 29.6152 5.38325 35 12 35C18.6167 35 24 29.6152 24 22.9966C24 16.378 18.6167 10.9932 12 10.9932ZM12 28.4631C8.98673 28.4631 6.53506 26.0107 6.53506 22.9966C6.53506 19.9825 8.98673 17.5301 12 17.5301C15.0133 17.5301 17.4649 19.9825 17.4649 22.9966C17.4649 26.0107 15.0133 28.4631 12 28.4631Z'
          fill={fill}
        />
      </g>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M34.4744 28C31.4559 28 29 25.5494 29 22.5374C29 19.5255 31.4559 17.0749 34.4744 17.0749C37.4929 17.0749 39.9488 19.5255 39.9488 22.5374C39.9488 25.5494 37.4929 28 34.4744 28Z'
        fill='#0AEB88'
      />
      <defs>
        <clipPath id='clip0_6601_77556'>
          <rect width='24' height='35' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
