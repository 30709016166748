import React from 'react';
import { Close } from 'icons/svg';
import { Button } from 'components/atoms/button';
import { BspotModal } from 'components/atoms/modal/modal';

type SeeWagerDetailsModalProps = {
  onClose?(): void;
  transactions: any;
  show: boolean;
};
export const WagerDetailsNotAvailableModal = ({
  onClose,
  show,
}: SeeWagerDetailsModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Wager Details'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>

      <div className={'d-flex flex-column gap-1'}>
        <div>We're sorry this data is not available yet.</div>
        <div>Come back later to see your wager details</div>
      </div>
      <div className={'d-flex flex-lg-row flex-column gap-2 '}>
        <Button text={'Ok, got it!'} onClick={onCloseModal} />
      </div>
    </BspotModal>
  );
};
export default WagerDetailsNotAvailableModal;
