import React from 'react';
import { Close } from 'icons/svg';
import { Button, OutlinedButton } from 'components/atoms/button';
import { BspotModal } from 'components/atoms/modal/modal';

type SeeWagerDetailsSpoilerModalProps = {
  onClose?(): void;
  onGoBack?(): void;
  show: boolean;
};
export const WagerDetailsSpoilerModal = ({
  onClose,
  onGoBack,
  show,
}: SeeWagerDetailsSpoilerModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Spoiler Alert'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onGoBack} />
      </div>

      <div className={'d-flex flex-column gap-2'}>
        <div>
          Reviewing Wager details will reveal the race results - including any
          winnings you may have coming.
        </div>
        <div>
          If you don't want to spoil the surprise, head back to the games and
          get spinning! That's the best way to find out what you've won.
        </div>
      </div>
      <div className={'d-flex flex-lg-row flex-column gap-2 '}>
        <OutlinedButton text='Go Back' onClick={onGoBack} />
        <Button text='Show Me The Results' onClick={onCloseModal} />
      </div>
    </BspotModal>
  );
};
export default WagerDetailsSpoilerModal;
