import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import './search-games.scss';
import { GameGroup, GameInfo } from '../../../hooks/use-games-list';
import { GhostSearchBar, SearchBar } from './search-bar/search-bar';
import { GameTileMk2 } from '../game-list/game-tile/game-tile';
import { GhostGames } from '../game-list/game-list';
import { sendGTMEvent } from 'services/_gtm-analytics/gtm-analytics';
import { useAuth } from 'contexts/authentication';

type SearchGamesProps = {
  gamesList: GameGroup[];
  children: ReactNode;
};

export const SearchGames = ({ gamesList, children }: SearchGamesProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedGames, setSearchedGames] = useState([]);
  const [activeCategory, setActiveCategory] = useState('All Games');
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const flattenedGames = gamesList?.flatMap((games) => games.games);
  const gameCategories = gamesList?.flatMap((games) => games.name);
  const gamesByCategory = gamesList?.find(
    (list) => list?.name === activeCategory,
  );

  const onCategoryChanged = (category: string) => {
    setActiveCategory(category);
    sendGTMEvent({
      event: 'game-category-changed',
      user_id: auth?.session?.playerId,
      value: category,
    });
  };

  useEffect(() => {
    const searchGames = () => {
      if (searchTerm.length > 0) {
        const foundGames =
          flattenedGames.filter((game) =>
            game.name.toLowerCase().includes(searchTerm.toLowerCase()),
          ) || [];

        const uniqueIds = new Set();
        const uniqueGames = foundGames.filter((obj) => {
          if (!uniqueIds.has(obj.id)) {
            uniqueIds.add(obj.id);
            return true;
          }
          return false;
        });

        setSearchedGames(uniqueGames);
      }
      setLoading(false);
    };

    const debounceTimeout = setTimeout(searchGames, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [flattenedGames, searchTerm]);

  const showSearchResults = searchTerm.length > 0 && searchedGames.length > 0;

  const showNoSearchResults =
    !loading && searchTerm.length > 0 && searchedGames.length === 0;

  const showAllGames =
    searchTerm.length === 0 && activeCategory === 'All Games';

  const showGamesByCategory =
    searchTerm.length === 0 && gamesByCategory && !showAllGames;

  return (
    <>
      {!gamesList && (
        <div className='app-container p-0'>
          <GhostSearchBar />
          <GhostGames isXl />
          <GhostGames />
          <GhostGames />
          <GhostGames />
          <GhostGames />
          <GhostGames />
          <GhostGames />
          <GhostGames />
        </div>
      )}
      {gamesList && (
        <>
          <section className='app-container p-0'>
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              onSearchTermChange={(e) => {
                setSearchTerm(e.target.value);
                setLoading(true);
              }}
              categories={gameCategories}
              activeCategory={activeCategory}
              onCategoryChange={onCategoryChanged}
            />
          </section>

          {showSearchResults && (
            <section className='app-container'>
              <div className='search-games-grid'>
                {searchedGames?.map((game, index) => (
                  <GameTileMk2
                    key={index + game.game_id + '_' + game.icon_url}
                    isFull
                    gameInfo={game}
                  />
                ))}
              </div>
            </section>
          )}

          {showNoSearchResults && (
            <section className='app-container'>
              <h3 className='fw-bold my-0 text-black'>No results to display</h3>
            </section>
          )}

          {showAllGames && children}

          {showGamesByCategory && (
            <section className='app-container'>
              <h3 className='fw-bold mt-0 text-black'>
                {gamesByCategory.name}
              </h3>
              <div className='search-games-grid mb-3 mb-lg-5'>
                {gamesByCategory.games.map((game, index) => (
                  <GameTileMk2
                    key={index + game.game_id + '_' + game.icon_url}
                    isFull
                    gameInfo={game}
                  />
                ))}
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};
