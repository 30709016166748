import { lazy } from 'react';
import {
  PixelActions,
  sendPixelEvent,
} from '../services/_fb-pixel/pixel.service';
import { sendAppsFlyerEvent } from '../services/_af-events/appsflyer';
import { sendGTMEvent } from '../services/_gtm-analytics/gtm-analytics';
import { GameProduct } from '../services/get-game-products/get-game-products';

export function setPersistentCookie(name, value, days) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);

  const cookieString = `${name}=${encodeURIComponent(
    value,
  )};expires=${expirationDate.toUTCString()};path=/;`;

  document.cookie = cookieString;
}

export function getCookie(name) {
  let cookieValue = null;
  let cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    // Check if the cookie name matches
    if (cookie.startsWith(name + '=')) {
      // Extract the cookie value
      cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
      break;
    }
  }

  return cookieValue;
}

export function setExpireCookie(name) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
}

export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      throw error;
    }
  });

export const CONFIG = {
  BRAND: 'Horseplay ',
};

export const addCreditsToCartAnalyticsEvent = ({
  product,
  playerId,
}: {
  product: GameProduct;
  playerId: number;
}) => {
  sendPixelEvent(PixelActions.ADD_TO_CART, {
    currency: 'USD',
    value: product.cost,
  });
  sendGTMEvent({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'USD',
      product: product,
      items: [
        {
          item_id: product.id,
          item_name: product.cost,
        },
      ],
      value: product.cost,
      user_id: playerId,
    },
  });

  // sendAppsFlyerEvent('add_to_cart', {
  //   currency: 'USD',
  //   productId: product.id.toString(),
  //   value: product.cost.toString(),
  // });
};

export const creditPurchaseAnalyticsEvent = ({
  selectedPackage,
  playerId,
  isQuickCredits = 'false',
}: {
  selectedPackage: GameProduct;
  playerId: number;
  isQuickCredits?: 'true' | 'false';
}) => {
  const d = new Date();
  // sendPixelEvent(PixelActions.PURCHASE, {
  //   currency: 'USD',
  //   value: selectedPackage.cost,
  // });

  // sendAppsFlyerEvent('purchase', {
  //   currency: 'USD',
  //   productId: selectedPackage.id.toString(),
  //   value: selectedPackage.cost.toString(),
  //   transaction_id: playerId.toString() + '-' + d.toISOString(),
  // });
  //
  // sendAppsFlyerEvent('package_revenue', {
  //   currency: 'USD',
  //   af_revenue: JSON.stringify(selectedPackage.cost * (6 / 100)),
  //   eventRevenue: selectedPackage.cost * (6 / 100),
  // });

  sendGTMEvent({
    event: 'purchase',
    ecommerce: {
      quickDeposit: isQuickCredits,
      status: 'success',
      currency: 'USD',
      product: selectedPackage,
      transaction_id: playerId.toString() + '-' + d.toISOString(),
      items: [
        {
          item_id: selectedPackage.id,
          item_name: selectedPackage.cost,
        },
      ],
      value: selectedPackage.cost,
      user_id: playerId,
    },
  });
};

export const sendDepositSuccessAnalyticsEvent = (
  itemAmount,
  playerId,
  paramsAsObject,
  payment_method,
  clientUniqueID,
) => {
  const gdpr = localStorage.getItem('hp-gdpr');
  if (!gdpr || gdpr === 'false') {
    return;
  }

  sendPixelEvent(PixelActions.DEPOSIT_SUCCESS, {
    currency: 'USD',
    value: itemAmount || 'not found',
  });
  sendGTMEvent({
    event: 'deposit',
    status: 'success',
    user_id: playerId,
    value: itemAmount || 'not found',
    params: paramsAsObject,
  });
  const date = new Date().toISOString();
  sendAppsFlyerEvent('deposit', {
    currency: 'USD',
    value: itemAmount.toString() || 'not found',
    payment_method: payment_method,
    clientUniqueId: clientUniqueID.toString(),
    transaction_id: playerId.toString() + '-' + date,
  });
};
