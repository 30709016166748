import { useState } from 'react';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { TermsModal } from 'components/organisms/terms-and-conditions/terms-modal/terms-modal';
import { AcceptModal } from 'components/organisms/terms-and-conditions/terms-modal/accept-modal';
import { useTerms } from 'hooks/use-content';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';

export const ModalTermsManager = () => {
  const { data: status } = usePlayerStatus();
  const { data: terms } = useTerms();
  const [showTerms, setShowTerms] = useState(false);
  const [showAcceptTerms, setShowAcceptTerms] = useState(true);

  const showTermsModal = () => {
    setShowTerms(true);
  };
  const onAccept = () => {
    setShowAcceptTerms(false);
    postNativeMessage(
      BspotCashierMessage.TNC_REQUIRED_ACCEPTED,
      JSON.stringify({}),
    );
  };
  const onDecline = () => {
    setShowAcceptTerms(false);
    postNativeMessage(
      BspotCashierMessage.TNC_REQUIRED_DECLINED,
      JSON.stringify({}),
    );
  };

  if (showTerms) {
    return (
      <TermsModal
        terms={terms?.content}
        show={showTerms}
        tncRequired={status.tnc_required}
        onCloseModal={() => setShowTerms(false)}
      />
    );
  }

  if (status?.tnc_required)
    return (
      <AcceptModal
        tncRequired={status.tnc_required}
        show={showAcceptTerms}
        onDeclineTermsModal={onDecline}
        onShowTermsModal={showTermsModal}
        onAcceptTermsModal={onAccept}
      />
    );
};
export default ModalTermsManager;
