import { ReactNode } from 'react';
import { CheckCircle, Info } from 'icons/svg';
import { WalletCheck } from 'icons/svg/wallet-check';

export type AlertProps = {
  type: 'error' | 'success' | 'grey';
  message?: string | ReactNode;
};

export const BspotAlert = ({ type, message }: AlertProps) => {
  const clazz = `d-flex justify-items-center gap-3 text-white px-3 py-3 ${type}`;

  const bg = {
    error: '#f57c00',
    success: '#10846f',
    grey: '#222222',
  };

  return (
    <div
      className={clazz}
      style={{
        backgroundColor: bg[type],
        borderRadius: '8px',
      }}
    >
      <div className='d-flex  align-items-center '>
        {type === 'error' && (
          <Info color='white' size={'24px'} width={'24px'} />
        )}
        {type === 'success' && (
          <CheckCircle size={'24px'} width={'24px'} color={'#ffffff'} />
        )}
        {type === 'grey' && <WalletCheck />}
      </div>
      {message && <div className=' d-flex align-items-center '>{message}</div>}
    </div>
  );
};
