import { Routes } from '../../../../../../common/routes';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { bp } from '../../../../../../common/theme';
import { MaxWidth, useMediaQuery } from 'hooks/use-media-query';

const StyledLink = styled.a`
  @media ${bp.lg} {
    background: unset;
  }
  padding: 9px 12px;
  cursor: pointer;
  color: #ffffff;
  background: #353535;
  &.active {
    @media ${bp.lg} {
      color: var(--primary-color);
      background: unset;
    }
    color: #000000;
    background: var(--primary-color);
  }
`;

export const NavLinks = ({ className }) => {
  const location = useLocation();
  const [isSmall] = useMediaQuery(MaxWidth.Small);
  return (
    <nav
      className={`${className} text-nowrap`}
      style={{
        fontSize: isSmall ? '12px' : '16px',
        padding: '12px',
        fontWeight: 700,
        gridTemplateColumns: 'repeat(3, 1fr)',
        alignItems: 'center',
        textAlign: 'center',
        gap: '8px',
      }}
    >
      <StyledLink
        className={[
          'rounded-pill w-100 text-decoration-none',
          location.pathname === Routes.Home.path ? 'active' : '',
        ].join(' ')}
        href={Routes.Home.path}
      >
        Games
      </StyledLink>
      <StyledLink
        className={[
          'rounded-pill w-100 text-decoration-none',
          location.pathname === Routes.Tournaments.path ? 'active' : '',
        ].join(' ')}
        href={Routes.Tournaments.path}
      >
        Tournaments
      </StyledLink>
      <StyledLink
        className={[
          'rounded-pill w-100 text-decoration-none',
          location.pathname === Routes.Promotions.path ? 'active' : '',
        ].join(' ')}
        href={Routes.Promotions.path}
      >
        Promotions
      </StyledLink>
    </nav>
  );
};
