import React from 'react';
import { SvgProps } from './svg';

export function Promotion({
  width = 22,
  height = 55,
  fill = '#0AEB88',
}: {
  width?: number;
  height?: number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1083_4014)'>
        <path
          d='M16.9 19.9C16.9 19.9 16.8 19.9 16.8 19.8C16.7 19.8 16.7 19.7 16.6 19.7C16.6 19.6 16.6 19.5 16.7 19.4C17.0333 18.8667 17.2 18.2333 17.2 17.5C17.2 16.5667 16.8667 15.7333 16.2 15C15.5333 14.3333 14.7 14 13.7 14C12.6333 14 11.7333 14.4 11 15.2C10.9 15.3 10.9 15.3 10.8 15.3C10.7 15.3 10.6 15.3 10.6 15.2C9.86667 14.4 8.96667 14 7.9 14C5.56667 14 4.4 15.1667 4.4 17.5C4.4 18.2333 4.56667 18.8667 4.9 19.4C5 19.5 5 19.5 5 19.6C5 19.7 4.8 19.9 4.7 19.9H0.6C0.4 19.9 0.266667 19.9667 0.2 20.1C0.0666667 20.1667 0 20.3 0 20.5V26H1C1.2 26 1.3 26.1 1.3 26.3V35.8C1.3 36.2 1.5 36.4 1.9 36.4H19.7C20.1 36.4 20.3 36.2 20.3 35.8V26.3C20.3 26.1 20.4 26 20.6 26H21.6V20.5C21.6 20.3 21.5667 20.1667 21.5 20.1C21.3667 19.9667 21.2 19.9 21 19.9H16.9ZM10.2 17.5V19.5C10.2 19.6 10 19.8 9.9 19.8H7.9C6.43333 19.8 5.7 19.0333 5.7 17.5C5.7 16.0333 6.43333 15.3 7.9 15.3C8.56667 15.3 9.1 15.5 9.5 15.9C9.96667 16.3667 10.2 16.9 10.2 17.5ZM13.7 15.3C14.3 15.3 14.8333 15.5 15.3 15.9C15.7 16.3667 15.9 16.9 15.9 17.5C15.9 18.1667 15.7 18.7 15.3 19.1C14.8333 19.5667 14.3 19.8 13.7 19.8H11.8C11.6 19.8 11.5 19.6 11.5 19.5V17.5C11.5 16.7667 11.6667 16.2 12 15.8C12.4 15.4667 12.9667 15.3 13.7 15.3ZM1.3 21.5C1.3 21.3 1.4 21.2 1.6 21.2H9.9C10 21.2 10.2 21.3 10.2 21.5V24.4C10.2 24.5 10 24.7 9.9 24.7H1.6C1.4 24.7 1.3 24.5 1.3 24.4V21.5ZM11.8 24.7C11.6 24.7 11.5 24.5 11.5 24.4V21.5C11.5 21.3 11.6 21.2 11.8 21.2H20C20.2 21.2 20.3 21.3 20.3 21.5V24.4C20.3 24.5 20.2 24.7 20 24.7H11.8ZM9.9 26C10 26 10.2 26.1 10.2 26.3V34.8C10.2 35 10 35.1 9.9 35.1H2.9C2.8 35.1 2.6 35 2.6 34.8V26.3C2.6 26.1 2.8 26 2.9 26H9.9ZM11.8 35.1C11.6 35.1 11.5 35 11.5 34.8V26.3C11.5 26.1 11.6 26 11.8 26H18.7C18.9 26 19 26.1 19 26.3V34.8C19 35 18.9 35.1 18.7 35.1H11.8Z'
          fill={fill}
        />
        <path
          d='M11 15.2C11.7333 14.4 12.6333 14 13.7 14C14.7 14 15.5333 14.3333 16.2 15C16.8667 15.7333 17.2 16.5667 17.2 17.5C17.2 18.2333 17.0333 18.8667 16.7 19.4C16.6 19.5 16.6 19.6 16.6 19.7C16.7 19.7 16.7 19.8 16.8 19.8C16.8 19.9 16.9 19.9 16.9 19.9H21C21.2 19.9 21.3667 19.9667 21.5 20.1C21.5667 20.1667 21.6 20.3 21.6 20.5V26H20.6C20.4 26 20.3 26.1 20.3 26.3V35.8C20.3 36.2 20.1 36.4 19.7 36.4H1.9C1.5 36.4 1.3 36.2 1.3 35.8V26.3C1.3 26.1 1.2 26 1 26H0V20.5C0 20.3 0.0666667 20.1667 0.2 20.1C0.266667 19.9667 0.4 19.9 0.6 19.9H4.7C4.8 19.9 5 19.7 5 19.6C5 19.5 5 19.5 4.9 19.4C4.56667 18.8667 4.4 18.2333 4.4 17.5C4.4 15.1667 5.56667 14 7.9 14C8.96667 14 9.86667 14.4 10.6 15.2C10.6 15.3 10.7 15.3 10.8 15.3C10.9 15.3 10.9 15.3 11 15.2ZM11 15.2V15.2ZM10.2 17.5V19.5C10.2 19.6 10 19.8 9.9 19.8H7.9C6.43333 19.8 5.7 19.0333 5.7 17.5C5.7 16.0333 6.43333 15.3 7.9 15.3C8.56667 15.3 9.1 15.5 9.5 15.9C9.96667 16.3667 10.2 16.9 10.2 17.5ZM13.7 15.3C14.3 15.3 14.8333 15.5 15.3 15.9C15.7 16.3667 15.9 16.9 15.9 17.5C15.9 18.1667 15.7 18.7 15.3 19.1C14.8333 19.5667 14.3 19.8 13.7 19.8H11.8C11.6 19.8 11.5 19.6 11.5 19.5V17.5C11.5 16.7667 11.6667 16.2 12 15.8C12.4 15.4667 12.9667 15.3 13.7 15.3ZM1.3 21.5C1.3 21.3 1.4 21.2 1.6 21.2H9.9C10 21.2 10.2 21.3 10.2 21.5V24.4C10.2 24.5 10 24.7 9.9 24.7H1.6C1.4 24.7 1.3 24.5 1.3 24.4V21.5ZM11.8 24.7C11.6 24.7 11.5 24.5 11.5 24.4V21.5C11.5 21.3 11.6 21.2 11.8 21.2H20C20.2 21.2 20.3 21.3 20.3 21.5V24.4C20.3 24.5 20.2 24.7 20 24.7H11.8ZM9.9 26C10 26 10.2 26.1 10.2 26.3V34.8C10.2 35 10 35.1 9.9 35.1H2.9C2.8 35.1 2.6 35 2.6 34.8V26.3C2.6 26.1 2.8 26 2.9 26H9.9ZM11.8 35.1C11.6 35.1 11.5 35 11.5 34.8V26.3C11.5 26.1 11.6 26 11.8 26H18.7C18.9 26 19 26.1 19 26.3V34.8C19 35 18.9 35.1 18.7 35.1H11.8Z'
          stroke={fill}
          strokeWidth='0.2'
        />
      </g>
      <defs>
        <clipPath id='clip0_1083_4014'>
          <rect width='22' height='55' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function PromotionFill({
  width = 22,
  height = 55,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.29855 35.5343C1.29855 36.0131 1.68541 36.4 2.16425 36.4H9.90145V26.228H1.29855V35.5343ZM11.7411 36.4H19.4783C19.9571 36.4 20.344 36.0131 20.344 35.5343V26.228H11.7411V36.4ZM20.7768 19.7353H16.7838C17.1517 19.1563 17.3681 18.4692 17.3681 17.7333C17.3681 15.6746 15.6935 14 13.6348 14C12.5148 14 11.5057 14.4978 10.8213 15.2823C10.1368 14.4978 9.12773 14 8.00773 14C5.94899 14 4.2744 15.6746 4.2744 17.7333C4.2744 18.4692 4.48812 19.1563 4.85874 19.7353H0.8657C0.38686 19.7353 0 20.1221 0 20.601V24.3884H9.90145V19.7353H11.7411V24.3884H21.6425V20.601C21.6425 20.1221 21.2557 19.7353 20.7768 19.7353ZM9.90145 19.6271H8.00773C6.96348 19.6271 6.11401 18.7776 6.11401 17.7333C6.11401 16.6891 6.96348 15.8396 8.00773 15.8396C9.05198 15.8396 9.90145 16.6891 9.90145 17.7333V19.6271ZM13.6348 19.6271H11.7411V17.7333C11.7411 16.6891 12.5905 15.8396 13.6348 15.8396C14.679 15.8396 15.5285 16.6891 15.5285 17.7333C15.5285 18.7776 14.679 19.6271 13.6348 19.6271Z'
        fill='#0AEB88'
      />
    </svg>
  );
}
