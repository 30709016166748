import { Api } from 'common/api';
import { Env } from 'common/web-env';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';

export type EmailObject = {
  email: string;
};

export type NewUserRegistration = {
  firstname?: string;
  lastname?: string;
  email?: string;
  password?: string;
  date_of_birth?: string;
  address_line_1?: string;
  city?: string;
  zip_code?: string;
  phone_number?: string;
  social_security_number?: string;
  newsletter?: boolean;
};

export const postPartialRegistration = async (payload: EmailObject) => {
  const { data, ok } = await Api.post(`${API_HOST}/api/v1/pre-register`, {
    data: payload,
  });

  if (!ok) throw new Error('Pre register request failed');

  return data;
};

export const register = async (payload: NewUserRegistration) => {
  const { data, status } = await Api.post<any>(`${API_HOST}/api/v1/register`, {
    data: payload,
  });

  if (status !== 200) {
    postNativeMessage(
      BspotCashierMessage.REGISTER_ERROR,
      'Unexpected error on register',
    );
    // @ts-ignore
    throw new Error(data?.message || 'Unexpected error');
  }
  // @ts-ignore
  const jwtDataPart = data.token.split('.')[1];
  const jwtData = JSON.parse(atob(jwtDataPart));
  const auth = {
    token: data.token,
    ...jwtData,
    kyc: data.kyc,
    status: data.status,
    leaderboardName: data?.leaderboardName || null,
  };
  return auth;
};
