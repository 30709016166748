import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Text } from 'components/atoms/text';
import { ChevronDown } from 'icons/svg/chevron/chevron-down';
import { Badge } from 'components/atoms/button';

export const ListItem = styled.a`
  color: inherit;
  text-decoration: none;
  display: flex;
  width: 100%;
  gap: 1em;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: var(--bg-dark-color);
  }
`;

export const BorderContainer = styled.div`
  display: flex;
  flex: 1 0 0;
  justify-content: space-between;
  border-bottom: 1px solid var(--bg-darkish-color);
  align-items: center;
  padding-right: 20px;
  position: relative;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
`;

export type NavItemProps = {
  text?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  href?: string;
  target?: string;
  icon?: React.ReactNode;
  withChildren?: any;
  notifications?: boolean;
  path?: string;
  display?: boolean;
};

export const NavItem = ({
  text,
  onClick,
  href,
  target,
  icon,
  notifications,
  withChildren,
}: NavItemProps) => {
  const [collapsed, setCollapsed] = useState(true);

  if (withChildren?.length > 0)
    return (
      <>
        <ListItem>
          <IconContainer>{icon}</IconContainer>
          <BorderContainer onClick={() => setCollapsed(!collapsed)}>
            <Text>{text}</Text>
            <ChevronDown size='20px' fill='var(--secondary-color)' />
          </BorderContainer>
        </ListItem>
        {!collapsed && (
          <div className={'d-flex flex-column overflow-hidden'}>
            {withChildren?.map((it) => (
              <ListItem
                key={it.text}
                href={it.onClick ? undefined : it.href ? it.href : ''}
                target='_blank'
                rel='noreferrer noopener'
                onClick={it.onClick}
              >
                <BorderContainer className='px-5 py-3'>
                  <Text className='px-5'>{it.text}</Text>
                </BorderContainer>
              </ListItem>
            ))}
          </div>
        )}
      </>
    );

  return (
    <>
      <ListItem
        href={onClick ? undefined : href ? href : ''}
        target={target ? target : ''}
        rel='noreferrer noopener'
        onClick={onClick}
      >
        <IconContainer>{icon}</IconContainer>

        <BorderContainer>
          <div style={{ position: 'relative' }}>
            {notifications ? <Badge isMenu={true} /> : <></>}
            <Text>{text}</Text>
          </div>
        </BorderContainer>
      </ListItem>
    </>
  );
};
