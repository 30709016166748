import React from 'react';
import { SvgProps } from './svg';

export function WalletCheck({
  size,
  width = '48',
  height = '45',
  fill = 'none',
}: SvgProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size || width}
      height={size || height}
      viewBox='0 0 48 45'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.0802 27.098C29.0802 31.7715 25.1994 35.5598 20.419 35.5598C15.6386 35.5598 11.7578 31.7715 11.7578 27.098C11.7578 22.4246 15.6386 18.6362 20.419 18.6362C25.1994 18.6362 29.0802 22.4246 29.0802 27.098ZM19.4853 31.6547L19.4512 33.4492H21.1679L21.1339 31.5867C22.8019 31.2657 23.6432 30.1326 23.6432 28.9022C23.6432 27.5941 22.9138 27.0251 21.3235 26.3102L20.6378 26.0038C19.7527 25.6148 19.2373 25.3084 19.2373 24.7443C19.2373 24.2337 19.6166 23.8689 20.3363 23.8689C21.1144 23.8689 21.7417 24.2531 22.1502 24.6519L23.3709 23.2416C23.2639 23.0908 22.6317 22.41 21.2943 22.1863L21.3284 20.4356H19.6214L19.6749 22.1863C18.2111 22.4489 17.1024 23.4361 17.1024 24.9145C17.1024 26.0087 17.6957 26.8597 19.1497 27.4919L19.991 27.8567C21.0658 28.3332 21.5083 28.5715 21.5083 29.1162C21.5083 29.6074 21.1193 29.9672 20.3266 29.9672C19.1983 29.9672 18.2938 29.3399 17.8707 28.9557L16.7084 30.4925C17.1072 30.8426 17.9728 31.504 19.4901 31.6547H19.4853Z'
        fill='#0AEB88'
      />
      <path
        d='M41.8077 33.3034L38.3598 36.7708L37.2802 35.6814C36.8474 35.2486 36.1471 35.2438 35.7143 35.6814C35.2814 36.1143 35.2814 36.8145 35.7143 37.2522L37.5574 39.1099C37.7665 39.3239 38.0631 39.4455 38.3646 39.4455L38.8461 39.4357L43.3834 34.8742C43.8162 34.4413 43.8162 33.7362 43.3834 33.3034C42.9505 32.8754 42.2454 32.8754 41.8174 33.3034H41.8077Z'
        fill='#0AEB88'
      />
      <path
        d='M31.6686 14.8336C31.5373 15.0475 31.3282 15.1983 31.0947 15.2615H35.8362C36.2642 15.2615 36.8283 15.2956 37.3681 15.5533C38.3942 16.0542 38.9584 16.9052 38.9924 18.0383C39.0167 18.9477 39.0167 19.8571 39.0021 20.7714V28.3238C39.1869 28.3141 39.3669 28.2946 39.5468 28.2946C40.0136 28.2946 40.4708 28.3432 40.9133 28.421V17.7368L40.8793 17.5131C40.8452 17.304 40.8063 17.1046 40.748 16.8955C40.1887 14.819 38.3359 13.4184 36.1475 13.4038H31.4643L31.7075 13.8804C31.8582 14.1819 31.8436 14.5418 31.6686 14.8287V14.8336Z'
        fill='#0AEB88'
        stroke='#0AEB88'
        strokeWidth='0.45'
      />
      <path
        d='M5.94758 38.9203C4.4157 38.9203 3.27287 38.0449 2.97136 36.6443C2.92273 36.4352 2.913 36.2018 2.913 35.9781V14.527L3.38959 14.702C3.52089 14.7507 3.64247 14.7944 3.77377 14.8431C4.01206 14.9355 4.24063 15.0279 4.47892 15.0765C4.92146 15.1689 5.39805 15.2613 5.8649 15.2613H6.47279C6.06429 15.2418 5.70928 14.9744 5.58771 14.5805C5.46127 14.172 5.61689 13.7294 5.96703 13.4911L6.09347 13.4036H5.93299C4.4157 13.4036 3.2826 12.5526 2.97136 11.1617C2.84978 10.6268 2.8741 10.0772 3.05403 9.53743C3.20965 9.0803 3.48199 8.71071 3.85645 8.42865C4.33303 8.06878 4.87283 7.72836 5.52935 7.62137C5.65093 7.59706 5.79196 7.59706 5.91353 7.59706H14.599L17.2835 5.76367H6.13724C4.94092 5.76367 3.85645 6.13813 2.81574 6.9065C1.55134 7.82562 0.962903 9.09976 1.00181 10.7678V35.9684C1.00181 37.4759 1.56106 38.7209 2.68444 39.6546C3.55493 40.3889 4.59564 40.7731 5.76764 40.7731H33.108C32.7043 40.2041 32.3736 39.5816 32.1305 38.9154H5.94758V38.9203Z'
        fill='#0AEB88'
        stroke='#0AEB88'
        strokeWidth='0.45'
      />
      <path
        d='M30.7548 7.60194C32.272 7.60194 33.3954 8.46757 33.7067 9.85356C33.7553 10.0627 33.765 10.2961 33.7796 10.5052C33.8039 11.0207 34.2076 11.4292 34.7474 11.4292C35.2872 11.4292 35.6811 11.011 35.6811 10.4858C35.6811 9.21163 35.1899 8.03476 34.2951 7.13508C33.41 6.25973 32.2137 5.76855 30.925 5.76855H27.5938L28.5226 7.60194H30.7596H30.7548Z'
        fill='#0AEB88'
        stroke='#0AEB88'
        strokeWidth='0.45'
      />
      <path
        d='M39.0072 30.5311V28.3232C34.8979 28.6004 31.6348 32.0241 31.6348 36.2015C31.6348 37.1595 31.8147 38.0689 32.1259 38.9199H34.5332C34.0906 38.1127 33.8426 37.1838 33.8426 36.2015C33.8426 33.2398 36.1137 30.7986 39.0072 30.5262V30.5311Z'
        fill='#0AEB88'
      />
      <path
        d='M40.9127 28.4204V30.672C43.3977 31.2848 45.2457 33.5267 45.2457 36.2014C45.2457 39.3429 42.6877 41.9009 39.5462 41.9009C38.272 41.9009 37.0952 41.473 36.1469 40.7678C36.0934 40.7678 36.0447 40.7775 35.9912 40.7775H33.1074C34.542 42.7909 36.8909 44.1088 39.5462 44.1088C43.9035 44.1088 47.4536 40.5587 47.4536 36.2014C47.4536 32.3109 44.6232 29.0721 40.9127 28.4204Z'
        fill='#0AEB88'
      />
      <path
        d='M39.0049 28.3236V30.5315C39.1848 30.5169 39.3599 30.5023 39.5447 30.5023C40.0164 30.5023 40.4735 30.5655 40.9112 30.6725V28.4209C40.4638 28.3431 40.0115 28.2944 39.5447 28.2944C39.3648 28.2944 39.1848 28.309 39.0049 28.3236Z'
        fill='#0AEB88'
      />
      <path
        d='M36.1429 40.7684C35.4815 40.2772 34.9271 39.6499 34.5332 38.9204H32.126C32.3691 39.5867 32.6998 40.2091 33.1035 40.7781H35.9873C36.0408 40.7781 36.0894 40.7684 36.1429 40.7684Z'
        fill='#0AEB88'
      />
      <path
        d='M29.2518 13.3503L9.66326 13.3308L18.0472 7.60693H14.5944L6.09375 13.4086H31.4597L28.5175 7.60693H26.334L29.247 13.3503H29.2518Z'
        fill='#0AEB88'
        stroke='#0AEB88'
        strokeWidth='0.45'
      />
      <path
        d='M24.2044 3.40037L25.4055 5.7687H27.5891L25.4396 1.53295C25.3132 1.28493 25.0846 1.10013 24.8123 1.03205C24.5399 0.9591 24.253 1.01259 24.0244 1.17308L17.2842 5.77356H20.737L24.2092 3.40524L24.2044 3.40037Z'
        fill='#0AEB88'
        stroke='#0AEB88'
        strokeWidth='0.45'
      />
      <path
        d='M30.8377 15.2948C30.9253 15.2948 31.0079 15.2851 31.0906 15.2607H6.47363C6.47363 15.2607 6.50281 15.2705 6.5174 15.2705L30.8377 15.2948Z'
        fill='#0AEB88'
        stroke='#0AEB88'
        strokeWidth='0.45'
      />
      <path
        d='M18.0466 7.60196L20.731 5.77344H17.2831L14.5986 7.60196H18.0466Z'
        fill='#0AEB88'
        stroke='#0AEB88'
        strokeWidth='0.45'
      />
      <path
        d='M26.339 7.60194H28.5225L27.5937 5.76855H25.4102L26.339 7.60194Z'
        fill='#0AEB88'
        stroke='#0AEB88'
        strokeWidth='0.45'
      />
      <path
        d='M6.09716 13.4038L5.97072 13.4913C5.62058 13.7296 5.46496 14.1722 5.5914 14.5807C5.71298 14.9746 6.06799 15.2372 6.47649 15.2615H31.0983C31.3366 15.1983 31.5409 15.0475 31.6722 14.8336C31.8473 14.5466 31.8618 14.1868 31.7111 13.8853L31.4679 13.4087H6.09716V13.4038Z'
        fill='#0AEB88'
        stroke='#0AEB88'
        strokeWidth='0.45'
      />
    </svg>
  );
}
