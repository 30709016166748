import { GameInfo } from '../../../../hooks/use-games-list';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SyntheticEvent } from 'react';
import './game-tile.scss';
import { Link } from 'react-router-dom';
import { Routes } from 'common/routes';
import { postNativeMessage } from 'services/native-ios/native-ios';

export type GameTileProps = {
  gameInfo: GameInfo;
  withMarginTop?: boolean;
  isXl?: boolean;
  isFull?: boolean;
  meta?: any;
};

export const GameTileMk2 = ({
  gameInfo = {
    available: true,
    icon_url: '',
    id: 0,
    game_id: '',
    slug: '',
  },
  withMarginTop,
  isXl = false,
  isFull = false,
  meta,
}: GameTileProps) => {
  const handleClick = (actions) => {
    if (actions?.app_opt_in_action) {
      const url = actions?.app_opt_in_action;
      const urlObj = new URL(url);
      const path = urlObj.pathname;
      const modifiedPath = path.replace(/((\/[^/]*){4}).*/, '$1');
      postNativeMessage(`Game:${modifiedPath}`);
    }
  };

  return (
    <div
      onClick={() => handleClick(meta)}
      className={[
        withMarginTop ? 'margin-top' : '',
        isXl ? 'xl-tile' : '',
        isFull ? 'full-tile' : '',
        'game-tile',
      ].join(' ')}
      data-testid={`game-tile-${gameInfo?.game_id}`}
    >
      <LazyLoadImage
        src={gameInfo?.icon_url || '/assets/images/banner-placeholder.webp'}
        alt='game tile'
        loading={'lazy'}
        width={isXl ? 194 : 126}
        height={isXl ? 143 : 126}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '16px',
          backgroundColor: '#121212',
          cursor: 'pointer',
        }}
      />
      {gameInfo?.available && (
        <>
          {gameInfo?.bottom_ribbon && (
            <span className='badge'>{gameInfo?.bottom_ribbon}</span>
          )}
        </>
      )}
    </div>
  );
};

export const GhostGameTile = ({ isXl }) => (
  <div className={`${isXl ? 'xl-tile' : ''} game-tile-ghost`}>
    <LazyLoadImage
      src={'/assets/images/banner-placeholder.webp'}
      alt='game tile'
      loading={'lazy'}
      width={isXl ? 194 : 126}
      height={isXl ? 143 : 126}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '16px',
        backgroundColor: '#121212',
        cursor: 'pointer',
      }}
    />
  </div>
);
