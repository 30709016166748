import React, { useEffect, useState } from 'react';
import { Text, TextSize } from 'components/atoms/text';
import { InputWrapDiv } from 'components/molecules/input-amount/styled';
import { NumericFormat } from 'react-number-format';

export type InputAmountProps = {
  amount: string;
  onChange?(e: React.SyntheticEvent): void;
};
export function InputAmount({ amount, onChange }: InputAmountProps) {
  const [value, setValue] = useState({ cash: '0.00' });
  function updateValue(e) {
    setValue({ cash: e });
  }
  const handleOnChange = (event) => {
    updateValue(event.split('$')[1]);
    onChange(event.split('$')[1]);
  };

  useEffect(() => {
    updateValue(amount ? amount : 0);
  }, [amount]);
  return (
    <InputWrapDiv>
      <Text bold size={TextSize.Body} className={'text-center'}>
        Amount
      </Text>
      <NumericFormat
        inputMode='decimal'
        value={value.cash}
        prefix={'$'}
        thousandsGroupStyle={'thousand'}
        thousandSeparator={','}
        decimalSeparator={'.'}
        decimalScale={2}
        placeholder={'amount'}
        fixedDecimalScale
        className={'bspot-input-amount'}
        onChange={(e) => handleOnChange(e.target.value)}
        onClick={(e) => {
          setValue({ cash: '' });
        }}
      />
    </InputWrapDiv>
  );
}
