import { SvgProps } from './svg';

export function Check({ size, width = '12', fill = '#02BD9C' }: SvgProps) {
  return (
    <svg
      width={size || width}
      height={size || width}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7264_1132)'>
        <path
          d='M11.6851 0.145035C11.6055 0.0871119 11.5152 0.0455407 11.4194 0.0227247C11.3236 -9.13767e-05 11.2243 -0.00369978 11.1271 0.012108C11.03 0.0279158 10.9369 0.0628264 10.8533 0.114822C10.7697 0.166817 10.6972 0.234866 10.6401 0.315035L3.62509 10.1L1.28009 7.75504C1.13792 7.62256 0.949869 7.55043 0.755568 7.55386C0.561267 7.55729 0.375882 7.636 0.238469 7.77341C0.101056 7.91083 0.0223439 8.09621 0.0189157 8.29051C0.0154875 8.48481 0.0876107 8.67286 0.220091 8.81503L3.18509 11.785C3.26333 11.8596 3.35648 11.9168 3.45841 11.9527C3.56033 11.9887 3.66872 12.0026 3.77643 11.9936C3.88414 11.9847 3.98873 11.953 4.0833 11.9006C4.17787 11.8483 4.26027 11.7765 4.32509 11.69L11.8601 1.19004C11.9178 1.11013 11.959 1.01957 11.9814 0.92361C12.0039 0.82765 12.007 0.728194 11.9908 0.631001C11.9745 0.533808 11.9391 0.440809 11.8866 0.357392C11.8341 0.273975 11.7656 0.201796 11.6851 0.145035Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_7264_1132'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
