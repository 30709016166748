import { formatDate, getUiDate } from 'common/bspot-shared';
import { Profile } from 'services/auth/login';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { getCredits } from '../get-credits/get-credits';

export type GameProductsPayload = {
  latitude: number | string;
  longitude: number | string;
};

type GameProductMetadata = {
  badgeColor: string;
  text: string;
  color: string;
};

type GameProductsResponse = {
  instant_play_eligible: boolean;
  game_products: GameProductResponse[];
};

type GameProductResponse = {
  id: number;
  cost: number;
  expected_wait: string;
  created_at: string;
  updated_at: string;
};

export type GameProducts = {
  instantPlayEligible: boolean;
  gameProducts: GameProduct[];
};

export type GameProduct = GameProductResponse & GameProductMetadata;

export const GameProductInfo: Record<number, GameProductMetadata> = {
  10: {
    badgeColor: 'rgba(215, 215, 215, 1)',
    text: 'Silver Bonus',
    color: 'silver',
  },
  20: {
    badgeColor: 'rgba(253, 235, 169, 1)',
    text: 'Gold Bonus',
    color: 'gold',
  },
  40: {
    badgeColor: 'rgba(230, 230, 230, 1)',
    text: 'Platinum Bonus',
    color: 'platinum',
  },
  100: {
    badgeColor: 'rgba(199, 255, 186, 1)',
    text: 'Emerald Bonus',
    color: 'emerald',
  },
  250: {
    badgeColor: 'rgba(188, 112, 117, 1)',
    text: 'Ruby Bonus',
    color: 'ruby',
  },
};

const resolveRGSHeaders = (session: Profile) => {
  return {
    Accept: '*/*',
    'x-player-id': session.playerId,
    'x-gpn-pam-player-id': session.playerId,
    'x-session-id': session.sessionId,
    'x-gpn-pam-session-token': session.playerSessionToken,
  };
};

export const fetchGameProducts = async (
  session: Profile,
): Promise<GameProducts> => {
  let headers: any = resolveRGSHeaders(session);
  const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/game_products`;
  const request = {
    method: 'GET',
    headers: headers,
  };

  const response = await fetch(url, request);
  if (!response.ok) throw new Error('Failed to retrieve game_products');
  const data = await response.json();

  const gp = data.game_products.map((gameProduct) => ({
    ...gameProduct,
    ...GameProductInfo[gameProduct.cost],
  }));

  return {
    instantPlayEligible: data.instant_play_eligible,
    gameProducts: gp,
  };
};
//https://rgs.stage.bspot.com/rgs/api/v1/game_products

export type WagerDetailsResponse = {
  wagers: ({
    total_wager_amount: {
      table: {
        value: number;
      };
    };
    race_id: string;
    track_name: string;
    event_date: string;
    race: string;
  } & Wager)[];
  wager_package_id: string;
};

export type WagerDetails = {
  id: string;
  wagers: Wager[];
  eventDate: string;
  raceId: string;
  trackName: string;
  race: string;
};

export type Wager = {
  chances: string;
  selections: string;
  pool_type_id: string;
  total_wager_amount: number;
  total_wager_amount_formatted: string;
};

export const getRgsWagerDetails = async (gameProductId, session) => {
  if (!gameProductId) {
    return Promise.reject(
      'A valid gameProductId is required to make the request.',
    );
  }

  let headers: any = resolveRGSHeaders(session);
  const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/wager_details?game_product_id=${gameProductId}`;
  const request = {
    method: 'GET',
    headers: headers,
  };

  const response = await fetch(url, request);
  if (!response.ok) throw new Error('Failed to retrieve game_products');
  const data = await response.json();

  let breakdownTotal = 0;

  const formattedWagers = data.wagers.map((w): Wager => {
    breakdownTotal += w.total_wager_amount.table.value;
    return {
      chances: '1',
      selections: w.selections,
      pool_type_id: w.pool_type_id,
      total_wager_amount: w.total_wager_amount.table.value,
      total_wager_amount_formatted: `$
            ${w.total_wager_amount?.table?.value.toFixed(2)}
        `,
    };
  });

  const wager = data.wagers[0];

  return Promise.resolve({
    id: data.wager_package_id,
    raceId: wager.race_id,
    eventDate: formatDate(wager.event_date.split('T')[0]),
    race: wager.race,
    trackName: wager.track_name,
    wagers: formattedWagers,
    breakdownTotal: breakdownTotal,
  });
};

export type RaceOddsEntry = {
  display_order: number;
  horse_name: string;
  jockey_name: string;
  morning_line_odds_value: number;
  odds_value: string;
  program_number: number;
};

export type RaceOdds = {
  entries: RaceOddsEntry[];
  odds_as_of: string;
  race_id: string;

  event_date: string;
  race: string;
  track_name: string;
  odds_value: string;
  morning_line_odds_value: number;
};

export const getRGSRaceOdds = async (raceId, session): Promise<RaceOdds> => {
  if (!raceId) {
    return Promise.reject('A valid raceId is required to make the request.');
  }

  let headers: any = resolveRGSHeaders(session);
  const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/race_odds/${raceId}`;
  const request = {
    method: 'GET',
    headers: headers,
  };

  const response = await fetch(url, request);
  if (!response.ok) throw new Error('Failed to retrieve game_products');
  const data = await response.json();

  return Promise.resolve({
    ...data,
    event_date: getUiDate(data.event_date.split('T')[0]),
  });
};

export type GameProductDetails = {
  wagerDetails: WagerDetails;
  raceOdds: RaceOdds;
};

export async function getGameProductDetails(
  gameProductId,
  session,
): Promise<GameProductDetails> {
  if (!gameProductId) {
    return Promise.reject(
      'A valid gameProductId is required to make the request.',
    );
  }

  const resWagers = await getRgsWagerDetails(gameProductId, session);
  const resOdds = await getRGSRaceOdds(resWagers.raceId, session);

  return Promise.resolve({
    wagerDetails: resWagers,
    raceOdds: resOdds,
  });
}
