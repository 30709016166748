import './product-square.scss';
import { FC } from 'react';
import { Info } from '../game-product/info';

const squares = {
  silver: '/assets/images/sweepstakes/square_silver.png',
  gold: '/assets/images/sweepstakes/square_gold.png',
  platinum: '/assets/images/sweepstakes/square_platinum.png',
  emerald: '/assets/images/sweepstakes/square_emerald.png',
  ruby: '/assets/images/sweepstakes/square_ruby.png',
};

const backgroundTextColors = {
  silver: '#0C0C0C',
  gold: '#6A4623',
  platinum: '#2B4678',
  emerald: '#28674C',
  ruby: '#420d0d',
};

const textGradients = {
  silver: '180deg, #7A7A7A 0%, #F9F9F9 100%',
  gold: '180deg, #EED072 0%, #F1A422 100%',
  platinum: '180deg, #F5F9FF 0%, #8CCBFB 100%',
  emerald: '180deg, #E0FFF5 0%, #BCE2D7 100%',
  ruby: '180deg, #fbcdcd 0%, #f17878 100%',
};

export const ProductSquare: FC<{
  color: string;
  text: string;
  onClickBonusInfo: (e: React.SyntheticEvent<Element, Event>) => void;
}> = ({ color, text, onClickBonusInfo }) => {
  return (
    <div className='bonus-square'>
      <img src={squares[color]} alt='' />
      <span
        className='tilted-text shadow-text'
        style={{
          color: backgroundTextColors[color],
        }}
      >
        {text}
      </span>
      <span
        className='tilted-text'
        style={{
          background: `linear-gradient(${textGradients[color]})`,
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          transform: 'rotate(-11.27deg)',
        }}
      >
        {color} Bonus
      </span>
      <div className='info-wrapper'>
        <Info fill='white' size='12px' onClick={onClickBonusInfo} />
      </div>
    </div>
  );
};
