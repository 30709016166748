import { Button, OutlinedButton } from 'components/atoms/button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'common/routes';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='row justify-content-center align-items-center flex-grow-1 m-1 mt-5'>
        <div className='col col-12 col-md-10 text-center'>
          <h1>
            Hmmm…
            <br />
            Something went wrong.
          </h1>
          <p>
            Follow your instinct or one of the links below to find what you are
            looking for.
          </p>
          <div
            className={
              'd-flex flex-column flex-md-row flex-sm-row gap-4 justify-content-center'
            }
          >
            <OutlinedButton
              text={'Home'}
              onClick={() => {
                navigate(Routes.Lobby.path);
              }}
            />
            <OutlinedButton
              text={'Help'}
              onClick={() => {
                window.open('https://support.bspot.com/hc/en-us', '_blank');
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
