import './privacy-policy.css';
import { usePrivacyPolicy } from 'hooks/use-content/use-privacy-policy';
import { CmsContent } from 'components/organisms/cms/strive-content-page/cms-content';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'common/utils';

export type PrivacyPolicyProps = Record<string, unknown>;

export default function PrivacyPolicy() {
  const { data: pp } = usePrivacyPolicy();

  const pageTitle = 'Privacy Policy - ' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <CmsContent content={pp?.content} title={pp?.title} />
    </>
  );
}
