// @ts-nocheck
import 'common/theme/main.scss';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Text } from 'components/atoms/text';
import { Button } from 'components/atoms/button';
import { Regexes } from 'common/bspot-shared';
import { Input } from 'components/atoms/input';
import { requestResetPassword } from 'services/password/password';
import {
  changePasswordHelp,
  changePasswordText,
  forgotPasswordText,
} from 'components/molecules/form-password/constants';
import { SectionTitle } from 'components/atoms/section-title/section-title';
import styled from '@emotion/styled';
import { BspotAlert } from 'components/molecules/alerts/alert';

interface formProps {
  forgot?: boolean;
}

export const FormChangePassword = styled.form<formProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  background-color: rgb(34, 34, 34);
  padding: 25px 30px;
  border-radius: ${({ forgot }) => (forgot ? `4px` : `32px`)};
`;

export enum FORM_TYPE {
  CHANGE = 'CHANGE',
  FORGOT = 'FORGOT',
}

export type PasswordFormProps = {
  type: FORM_TYPE;
};

export function PasswordForm({ type }: PasswordFormProps) {
  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { email: '' },
  });
  const passwordText =
    type === FORM_TYPE.FORGOT ? forgotPasswordText : changePasswordText;

  const [feedback, setFeedback] = useState(null);
  const [isError, setIsError] = useState(null);
  const handleForgotPassword = async (formData) => {
    setFeedback(null);
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    requestResetPassword(formData).then((r) => {
      setFeedback(r);
      setIsError(false);
    });
  };

  return (
    <>
      <div className='col col-12'>
        {type === FORM_TYPE.CHANGE && (
          <>
            <SectionTitle title={'Change Password'} />
            <Text text={passwordText} />
          </>
        )}
      </div>

      <div className='col col-12 pt-2'>
        <FormChangePassword
          forgot={type === FORM_TYPE.FORGOT}
          onSubmit={handleSubmit(handleForgotPassword)}
        >
          {feedback && (
            <BspotAlert
              type={isError ? 'error' : 'success'}
              message={feedback}
            />
          )}

          {type === FORM_TYPE.FORGOT && (
            <>
              <SectionTitle title={'Forgot your Password'} />
              <Text text={passwordText} />
            </>
          )}

          {type === FORM_TYPE.CHANGE && <Text text={changePasswordHelp} />}

          <div>
            <Input
              label={'Email'}
              error={errors?.email?.message.toString()}
              placeholder={'Enter a valid email'}
              {...register('email', {
                required: 'This field is required',
                pattern: {
                  value: Regexes.Email,
                  message: 'Must be valid email',
                },
              })}
            />
          </div>
          <div className={'align-self-start mt-3'}>
            <Button text={'Submit'} isLoading={isSubmitting} />
          </div>
        </FormChangePassword>
      </div>
    </>
  );
}
