import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from 'components/atoms/button';
import { Text, TextSize } from 'components/atoms/text';
import { Size } from 'common/theme';

export const FileInputWrapper = styled.div`
  .input-group,
  select,
  input {
    cursor: pointer;
  }
  .input-group > input[type='file'] {
    position: fixed;
    top: -1000px;
    display: none;
  }
`;

function FileInput({ onFileChange, uploadedData, placeholder }) {
  const [fileNameFront, setFileNameFront] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setFileNameFront(file.name);
      onFileChange(fileNameFront, event.target.files[0]);
    };
    reader.readAsText(file);
  };

  return (
    <FileInputWrapper className={'d-flex'}>
      {!uploadedData && (
        <>
          {!fileNameFront && (
            <div className='input-group mb-1'>
              <input
                id={placeholder + -'input'}
                type='text'
                onClick={() => {
                  document.getElementById(placeholder).click();
                }}
                className='form-control bg-inputs'
                placeholder={placeholder}
                aria-label='file upload'
                aria-describedby='basic-addon2'
              />
              <input
                type='file'
                id={placeholder}
                onChange={handleFileChange}
                className='bg-inputs'
              />
              <span
                onClick={() => {
                  document.getElementById(placeholder).click();
                }}
                className='input-group-text fw-semibold text-white addon-cta '
              >
                Upload
              </span>
            </div>
          )}

          {fileNameFront && (
            <div
              className={'d-flex flex-column mt-1 py-2 justify-content-center'}
            >
              <Text size={TextSize.Body}> {placeholder}</Text>
              <div
                className={'d-flex flex-row mt-1 py-2 justify-content-center'}
              >
                <div className={'pr-4'}>{fileNameFront}</div>
                <div className={'px-3'}>
                  <Button
                    size={Size.Small}
                    id='Delete'
                    text={'Remove'}
                    onClick={() => {
                      setFileNameFront(null);
                      onFileChange(null, null);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {uploadedData && (
        <div className={'d-flex flex-column mt-1 py-2'}>
          <Text size={TextSize.Body} bold>
            {placeholder}
          </Text>
          <Text size={TextSize.Body} bold>
            Document Uploaded
          </Text>
          <Text bold>{uploadedData.date}</Text>
        </div>
      )}
    </FileInputWrapper>
  );
}

export default FileInput;
