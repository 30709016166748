import React from 'react';
import { SvgProps } from 'icons/svg/index';

export function Compass({
  size,
  width = '1rem',
  height = '1rem',
  fill = '#282828',
  onClick,
}: SvgProps) {
  return (
    <div
      style={{
        opacity: '.8',
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <g opacity='0.8' clipPath='url(#clip0_848_11397)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 2.50016C5.8579 2.50016 2.50004 5.85803 2.50004 10.0002C2.50004 14.1423 5.8579 17.5002 10 17.5002C14.1422 17.5002 17.5 14.1423 17.5 10.0002C17.5 5.85803 14.1422 2.50016 10 2.50016ZM0.833374 10.0002C0.833374 4.93755 4.93743 0.833496 10 0.833496C15.0626 0.833496 19.1667 4.93755 19.1667 10.0002C19.1667 15.0628 15.0626 19.1668 10 19.1668C4.93743 19.1668 0.833374 15.0628 0.833374 10.0002ZM12.2158 7.78444L8.89218 8.8923L7.78432 12.2159L11.1079 11.108L12.2158 7.78444ZM12.5528 5.93059C12.705 5.89483 12.9897 5.83959 13.2944 5.94826C13.6478 6.07428 13.9259 6.35238 14.0519 6.70579C14.1606 7.01053 14.1054 7.29524 14.0696 7.44743C14.0328 7.60393 13.9713 7.78841 13.9115 7.96765C13.9084 7.97687 13.9054 7.98608 13.9023 7.99526L12.6627 11.7141C12.6605 11.7207 12.658 11.7283 12.6553 11.7368C12.6283 11.8199 12.5726 11.9917 12.469 12.1432C12.3815 12.2711 12.271 12.3816 12.143 12.4691C11.9916 12.5727 11.8197 12.6285 11.7367 12.6554C11.7282 12.6582 11.7205 12.6606 11.714 12.6628L7.96753 13.9116C7.78828 13.9714 7.60381 14.033 7.44731 14.0697C7.29512 14.1055 7.01041 14.1607 6.70567 14.0521C6.35226 13.926 6.07416 13.6479 5.94813 13.2945C5.83946 12.9898 5.89471 12.7051 5.93047 12.5529C5.96725 12.3964 6.02878 12.2119 6.08857 12.0327C6.09164 12.0235 6.09471 12.0142 6.09777 12.0051L7.33739 8.2862C7.33958 8.27965 7.34205 8.27204 7.34482 8.26349C7.37174 8.18046 7.42745 8.00863 7.53106 7.85716C7.61857 7.72923 7.72911 7.61869 7.85704 7.53119C8.0085 7.42758 8.18034 7.37186 8.26337 7.34494C8.27192 7.34217 8.27953 7.3397 8.28608 7.33752L12.0049 6.0979C12.0141 6.09484 12.0233 6.09177 12.0325 6.0887C12.2118 6.02891 12.3963 5.96737 12.5528 5.93059Z'
            fill='#04C468'
          />
        </g>
        <defs>
          <clipPath id='clip0_848_11397'>
            <rect width='20' height='20' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
