import React from 'react';

export const SkeletonLoader = ({ bgColor }) => {
  return (
    <div
      className='skeleton'
      style={{
        height: '72px',
        borderRadius: '2px',
        backgroundColor: bgColor,
      }}
    ></div>
  );
};

export default SkeletonLoader;
