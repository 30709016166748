import { SelfExclusionSettings } from 'hooks/use-play-limits/use-play-limits';
import { ExpandableCard } from 'components/molecules/card-expandable/expandable-card';
import styled from '@emotion/styled';
import { CONFIG } from 'common/utils';

const AssetLink = styled.a`
  text-decoration: none;
  color: var(--text-light-color);
`;

type SelfExclusionProps = SelfExclusionSettings;
export const SelfExclusion: React.FC<SelfExclusionProps> = () => {
  return (
    <ExpandableCard
      title='Self Exclusion'
      description={`You can permanently exclude yourself from ${CONFIG.BRAND}. This action is permanent and means you will no longer be able to wager with us.`}
      openContent={
        <>
          <AssetLink
            target='_blank'
            rel='noreferrer noopener'
            href='https://i.cms.uat.gpn.strivegaming.dev/gpn-cms/100/image_folder_item/image/17/Self_Exclusion_Form_Revised_1_31_23.pdf'
          >
            Download Self Exclusion Form
          </AssetLink>
          <AssetLink
            target='_blank'
            rel='noreferrer noopener'
            href='https://i.cms.uat.gpn.strivegaming.dev/gpn-cms/100/image_folder_item/image/10/VSEFormWQRCode12072021.pdf'
          >
            Download Self Exclusion Form by New York State Gaming Commission
          </AssetLink>
        </>
      }
      emptyMessage=''
    />
  );
};
