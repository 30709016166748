import Marquee from 'react-fast-marquee';
import styled from '@emotion/styled';
import { useHeadlines } from '../../../hooks/use-headlines/use-headlines';
import { ContentfulIcon } from '../../atoms/contentful-icon/contentful-icon';
import { BackgroundImageContainer } from '../../atoms/background-image-container/background-image-container';
import { bp } from '../../../common/theme';
import React from 'react';

const Container = styled.div`
  @media ${bp.lg} {
    font-size: 14px;
  }
  font-size: 12px;
  user-select: none;
  font-weight: 500;
  height: 36px;

  .fade-in {
    animation: fadeIn 2s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export function Headlines() {
  const { data: headlineData, isLoading } = useHeadlines();

  return (
    <BackgroundImageContainer
      width={'100%'}
      height={'36px'}
      theme={headlineData?.theme || 'light-green'}
    >
      <Container className='d-flex gap-4 overflow-hidden align-items-center h-100 position-relative'>
        <Marquee speed={30} autoFill>
          {!isLoading &&
            headlineData?.headlines &&
            headlineData.headlines.map(({ key, icon, headline }) => (
              <div
                key={key}
                className='d-flex gap-4 align-items-center justify-content-center fade-in'
                style={{ color: '#000000' }}
              >
                <ContentfulIcon iconType={icon} isDark={false} />
                <span style={{ marginRight: '24px' }}>{headline}</span>
              </div>
            ))}
        </Marquee>
      </Container>
    </BackgroundImageContainer>
  );
}
