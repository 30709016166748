import { formatDate, toCurrency } from 'common/bspot-shared';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export type WagerTransaction = {
  raceId: string;
  date: string;
  raceName: string;
  wagerAmount: string;
  winnings: string;
  runners: number;
  tsn: string;
  wagerId: string;
};

export type GameProductTransaction = {
  data: WagerTransaction[];
  wagerId: string;
  totalWagered: string;
  totalCredits: string;
  totalBonus: string;
};

export type WagerTransactionResponse = {
  wagered_races: {
    date: string;
    wager_amount: number;
    runners: number;
    race_id: string;
    race_name: string;
    winnings: number;
    tsn: string;
    wager_id: string;
  }[];
  wagers?: {
    date: string;
    wager_amount: number;
    runners: number;
    race_id: string;
    race_name: string;
    winnings: number;
    tsn: string;
    wager_id: string;
  }[];
  wager_id: string;
  total_wagered: string;
  total_credits: string;
  total_bonus: string;
};

export const getGameProductTransactions = async (
  wagerId,
  session,
): Promise<GameProductTransaction> => {
  if (!wagerId) {
    return Promise.reject('WagerId does not exist');
  }

  let headers: any = {
    Accept: '*/*',
    'x-player-id': session.playerId,
    'x-gpn-pam-player-id': session.playerId,
    'x-session-id': session.sessionId,
    'x-gpn-pam-session-token': session.playerSessionToken,
  };

  const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/wager_transactions/${wagerId}`;
  const request = {
    method: 'GET',
    headers: headers,
  };

  const response = await fetch(url, request);
  if (!response.ok)
    throw new Error('Failed to retrieve game product transactions');
  const data = await response.json();

  const formattedWagers = data.wagers.map((w): WagerTransaction => {
    return {
      raceId: w.race_id,
      date: new Date(w.date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
      }),
      raceName: w.race_name,
      wagerAmount: `${toCurrency(w.wager_amount)}`,
      winnings: `${toCurrency(w.winnings)}`,
      runners: w.runners,
      tsn: w.tsn,
      wagerId: w.wager_id,
    };
  });

  return {
    data: formattedWagers,
    wagerId: data.wager_id,
    totalWagered: data.total_credits,
    totalCredits: data.total_wagered,
    totalBonus: data.total_bonus,
  };
};

export type RaceResult = {
  display_order: string;
  horse_number: string;
  horse: string;
  pool_results: PoolResult[];
};

export type PoolResult = {
  paid_amount: number;
};

export type RaceResultsResponse = {
  date: string;
  race_id: string;
  runner_results: RaceResult[];
  race_name: string;
  pool_names: string[];
  scratches: string[];
};

export type RaceResults = {
  id: string;
  runner_results: RaceResult[];
  date: string;
  race_name: string;
  scratches: string[];
  pool_names: string[];
  prices: string[];
};
export const getRaceResults = async (raceId, session): Promise<RaceResults> => {
  let headers: any = {
    Accept: '*/*',
    'x-player-id': session.playerId,
    'x-gpn-pam-player-id': session.playerId,
    'x-session-id': session.sessionId,
    'x-gpn-pam-session-token': session.playerSessionToken,
  };
  const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/race_results/${raceId}`;
  const request = {
    method: 'GET',
    headers: headers,
  };
  const response = await fetch(url, request);
  if (!response.ok)
    throw new Error('Failed to retrieve game product race results');
  const data = await response.json();
  return {
    id: data.race_id,
    runner_results: data.runner_results,
    race_name: data.race_name,
    pool_names: data.pool_names,
    date: formatDate(data.date),
    scratches: data.scratches,
    prices: data.prices,
  };
};

export const getRaceResultsNPP = async (
  wagerId,
  transactionId,
  session,
): Promise<any> => {
  let headers: any = {
    Accept: '*/*',
    'x-player-id': session.playerId,
    'x-gpn-pam-player-id': session.playerId,
    'x-session-id': session.sessionId,
    'x-gpn-pam-session-token': session.playerSessionToken,
  };
  //453debdb-ab77-4699-a9d8-7a7749b6fb51
  const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/wager_pool_result/${wagerId}?transaction_id=${transactionId}`;
  const request = {
    method: 'GET',
    headers: headers,
  };
  const response = await fetch(url, request);
  if (!response.ok)
    throw new Error('Failed to retrieve game product race results');
  const data = await response.json();
  return {
    wagerId: data.wager_id,
    finishers: data.finishers,
    race_number: data.race_number,
    pool_name: data.pool_name,
    poolId: data.pool_identifier,
    date: data.event_date,
    scratches: data.scratches,
    payouts: data.payouts,
    track_name: data.track_name,
  };
};
