import React from 'react';
import { AmountContainer } from 'pages/make-deposit/styled';
import styled from '@emotion/styled';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export const GroupAmounts = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0 0.7rem;
  & > * {
    flex: 1 0 0;
  }

  @media screen and (max-width: 975px) {
    gap: 16px;
  }
`;

export type AvailableAmountsProps = {
  amount: string;
  onSelect?(e: string): void;
};

export function AvailableAmounts({ amount, onSelect }: AvailableAmountsProps) {
  const availableAmounts =
    FeatureFlagsService?.featureFlags?.depositAmounts.split(',');
  const paymentAmounts = [];
  for (const am of availableAmounts) {
    paymentAmounts.push(
      <AmountContainer
        key={am}
        onClick={() => onSelect(am)}
        className={+amount === +am ? 'selected' : ''}
      >
        {'$' + (+am).toFixed(2)}
      </AmountContainer>,
    );
  }
  return <GroupAmounts className={'pt-4'}> {paymentAmounts}</GroupAmounts>;
}
