import { Close } from 'icons/svg';
import { ModalContainer } from 'components/organisms/terms-and-conditions/terms-modal/styled';
import { Button, OutlinedButton } from 'components/atoms/button';
import { BspotModal } from 'components/atoms/modal/modal';
import React from 'react';

export type NoCreditsModalProps = {
  onClose?(): void;
  onPlayAll?(): void;
  show: boolean;
};
export const PlayModal = ({
  onClose,
  show,
  onPlayAll,
}: NoCreditsModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  const onClickPlayAll = () => {
    onClose();
    onPlayAll();
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Are you sure?'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>
      <div className={'d-flex flex-column gap-2'}>
        <div>Heads up.</div>
        <div>You're about to reveal all your wager results.</div>
        <div>
          Your Credit Balance will go to $0, and any winnings will go into your
          Cash Account.
        </div>
        <div>You sure about this?</div>
      </div>

      <div className={'d-flex flex-lg-row flex-column gap-2 '}>
        <OutlinedButton text='Cancel' onClick={onCloseModal} />
        <Button text={"Yes, let's do this"} onClick={onClickPlayAll} />
      </div>
    </BspotModal>
  );
};
export default PlayModal;
