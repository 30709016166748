import React from 'react';

export function LogOut() {
  return (
    <svg
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='44' height='44' rx='22' fill='#191919' />
      <path
        d='M23.5 15V11.5C23.5 11.2348 23.3946 10.9804 23.2071 10.7929C23.0196 10.6054 22.7652 10.5 22.5 10.5H11.5C11.2348 10.5 10.9804 10.6054 10.7929 10.7929C10.6054 10.9804 10.5 11.2348 10.5 11.5V32.5C10.5 32.7652 10.6054 33.0196 10.7929 33.2071C10.9804 33.3946 11.2348 33.5 11.5 33.5H22.5C22.7652 33.5 23.0196 33.3946 23.2071 33.2071C23.3946 33.0196 23.5 32.7652 23.5 32.5V29'
        stroke='#0AEB88'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33.5 22H14.5'
        stroke='#0AEB88'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.5 27L33.5 22L28.5 17'
        stroke='#0AEB88'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
