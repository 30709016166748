import React from 'react';
import { Account } from 'services/withdraw/withdraw';
import { PaymentItem } from 'pages/make-deposit/styled';
import { PaymentsContainer } from 'pages/make-deposit/styled';
import { AvailableBanks } from 'hooks/use-available-banks';
import {
  Skeleton,
  SkeletonWrapper,
} from 'components/molecules/available-payment-methods/styled';

type AvailableAmountsProps = {
  items: Account[] | AvailableBanks[];
  account: number;

  isDeposit: boolean;
  onSelect?(e: Account | AvailableBanks): void;
};

export function AvailablePaymentMethods({
  items,
  account,
  isDeposit,
  onSelect,
}: AvailableAmountsProps) {
  const handleSelected = (acc) => {
    if (isDeposit) {
      return account === acc.bank_id ? 'selected' : '';
    } else {
      return account === acc.uniq_id ? 'selected' : '';
    }
  };

  const paymentMethods = [];
  if (items) {
    for (const pm of items) {
      paymentMethods.push(
        <div
          className={'flex flex-column'}
          key={isDeposit ? pm.bank_id : pm.uniq_id}
        >
          <PaymentItem
            className={handleSelected(pm)}
            onClick={() => onSelect(pm)}
          >
            <img className='' src={pm.icon} alt={pm.name} />
          </PaymentItem>
          <div
            className={'pt-1 fw-semibold text-center'}
            style={{ lineHeight: '1' }}
          >
            <small> {isDeposit ? pm.name : pm.account}</small>
          </div>
        </div>,
      );
    }
  }
  return (
    <>
      {!items ? (
        <SkeletonWrapper>
          {[1, 2, 3, 4].map((it) => (
            <Skeleton key={it}></Skeleton>
          ))}
        </SkeletonWrapper>
      ) : (
        <PaymentsContainer>{paymentMethods}</PaymentsContainer>
      )}
    </>
  );
}
