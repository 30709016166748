import styled from '@emotion/styled';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1 0 0;
  border-radius: 999px 50px 50px 999px;
  gap: 0;
  min-width: 250px;
  font-family: 'Poppins';
  position: relative;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  border-radius: 999px 50px 50px 999px;
  gap: 16px;
`;

export const ContentWrap = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  margin-left: -32px;
  flex: 1 0;
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    gap: 0;
    border: var(--border);
    justify-content: flex-start;
    padding: 10px 0px 10px 100px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 60px;

    &.with-fade {
      opacity: 0.75;
    }

    .no-instant {
      position: absolute;
      padding: 3.5px 4px 3px;
      background-color: white;

      border-radius: 99px;
      top: -10px;
      right: -10px;
      display: flex;
      align-items: center;
    }

    .no-instant-message {
      color: black;
      font-size: 9px;
      font-weight: 700;
      padding: 0 4px;
      line-height: 100%;
    }
  }
  .credits {
    cursor: pointer;
    display: flex;
    align-self: flex-start;
    font-size: 28px;
    font-weight: 700;
  }
  .badge {
    cursor: pointer;
    width: auto;
    align-self: flex-start;
  }
  .badge-container {
    display: flex;
    width: auto;
    align-items: center;
    border-radius: 4px;
    padding: 3px 5px;
    font-size: var(--bsp-badge-font-size, 12px);
    color: var(--bsp-badge-color, white);
    gap: 8px;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

interface StyledChip {
  $position?: boolean;
}
export const ChipWrap = styled.div<StyledChip>`
  cursor: pointer;
  position: ${(props) => (props.$position === true ? 'relative' : `absolute`)};

  left: ${(props) => (props.$position === true ? 'unset' : `-16px`)};
  top: ${(props) => (props.$position === true ? 'unset' : `-5px`)};
`;

interface ShineWrapProp {
  size: string;
}

export const ShineWrap = styled.div<ShineWrapProp>`
  --bsp-chip-size: ${(props) => props.size};

  .image-wrapper {
    width: var(--bsp-chip-size, 120px);
    height: var(--bsp-chip-size, 120px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 1.6;
  }
  .image-wrapper img {
    width: var(--bsp-chip-size, 50px);
    height: var(--bsp-chip-size, 50px);
    border-radius: 50%;
  }
  .shine {
    position: relative;
    overflow: hidden;
  }
  .shine::before {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: -75%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 50%;
    -webkit-animation: shine 0.85s;
    animation: shine 3s ease infinite;
  }

  @-webkit-keyframes shine {
    100% {
      left: 125%;
    }
  }
  @keyframes shine {
    100% {
      left: 125%;
    }
  }

  @keyframes shine {
    100% {
      left: 125%;
    }
  }
  .chip-amount {
    color: var(--bsp-chip-text, black);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 25px;
    @media screen and (max-width: 820px) {
      font-size: 19px;
    }
    @media screen and (max-width: 1180px) {
      font-size: 20px;
    }
  }
`;

export const GamesProductContainer = styled.div`
  .parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    padding-top: 1rem;
  }

  .gameProduct {
    display: flex;
    flex-direction: column;
    flex-basis: calc(90% - 25px);
  }

  @media screen and (max-width: 902px) {
    .gameProduct {
      flex-basis: calc(100% - 50px);
      width: 100%;
      flex: 1;
    }
  }

  @media screen and (max-width: 512px) {
    .parent {
      gap: 30px;
    }
  }
`;

export const InstaPlayContainer = styled.div`
  display: flex;
  flex: 1 0;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  background-color: #53b1fb;
  color: #020202;
  margin-bottom: 50px;
`;
