import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

type AppFlyerEv = {
  eventType: string;
  eventValue: string;
  eventName: string;
  eventRevenue?: string;
};
export const sendAppsFlyerEvent = (event: string, data: any) => {
  let ev: AppFlyerEv = {
    eventType: 'EVENT',
    eventValue: data,
    eventName: event,
  };

  if (event === 'package_revenue') {
    ev = {
      ...ev,
      eventRevenue: data.eventRevenue,
    };
  }

  window['AF'] && window['AF']('pba', 'event', ev);
};

export const setAppsFlyerUserEvent = (playerId: string) => {
  window['AF'] && window['AF']('pba', 'setCustomerUserId', playerId);
};

export const injectAppsFlyer = () => {
  if (document.getElementById('hp-appsflyer-script')) {
    return;
  }
  const _id =
    FeatureFlagsService.featureFlags.environment !== 'prod'
      ? 'a5f199d7-4e25-431b-8a40-14cd403db53f'
      : '0f43158a-3367-4342-9270-21ed45585e92';
  const scriptTag = document.createElement('script');
  scriptTag.id = 'hp-appsflyer-script';
  scriptTag.innerHTML = `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject = a,t.AF=t.AF||function(){
  (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
  t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
  o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
  p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","pba",{pba: {webAppId: "${_id}"}})`;
  document.head.appendChild(scriptTag);
};

export const deleteAppsFlyer = () => {
  const scriptTag = document.getElementById('hp-appsflyer-script');
  window['AF'] && window['AF']('pba', 'setCustomerUserId', '');
  scriptTag && scriptTag.remove();
  setTimeout(() => {
    deleteAppsFlyerCookies();
  }, 500);
};

export function deleteAppsFlyerCookies() {
  document.cookie = 'af_id=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'afUserId=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'AF_SYNC=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie =
    'af_id=;domain=.appsflyer.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie =
    'af_id=;domain=.onelink.me; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
