export const Bell = ({
  width = 24,
  height = 24,
}: {
  width?: number;
  height?: number;
}) => (
  <div className='d-flex align-items-center p-2'>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='-0.5 -0.5 24 24'
      height={height}
      width={width}
    >
      <path
        stroke='#0aeb88'
        strokeWidth='2'
        d='M4.792 8.625v4.792l-1.917 3.833h17.25l-1.917 -3.833V8.625A6.708 6.708 0 1 0 4.792 8.625Z'
      />
      <path stroke='#0aeb88' strokeWidth='2' d='M9.583 21.083h3.833' />
    </svg>
  </div>
);
