import { Api } from 'common/api';

///restapi/v1/user/limits_history?page_size=10&page_number=1
// Request Method:
// GET
export const getUserLimitsHistory = (): Promise<any> => {
  return Api.get<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/limits_history?page_size=30&page_number=1`,
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to get user limits_history');
    }
    return res.data.Data;
  });
};
