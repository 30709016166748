import { InCenter } from 'pages/account-history/styled';
import { FadeInContainer } from 'pages/make-deposit/styled';
import React, { useEffect } from 'react';
import { Spinner } from 'icons/svg';
import { useSearchParams } from 'react-router-dom';
import MakeDepositPage from 'pages/make-deposit/make-deposit';
import { Routes } from 'common/routes';
import { BspotAlert } from 'components/molecules/alerts/alert';
import { CircularLoader, Logo } from 'components';
import { CONFIG } from 'common/utils';

import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
//   0 -> DepositStatus.NOT_FOUND
//   1 -> DepositStatus.PENDING
//   2 -> DepositStatus.CONFIRMED
//   3 -> DepositStatus.REJECTED
//   4 -> DepositStatus.RETURN
//   5 -> DepositStatus.UNVERIFIED
//   11 -> DepositStatus.PENDING_ACTION
// else -> DepositStatus.UNKNOWN

export default function MakeDepositError() {
  const [searchParams] = useSearchParams();
  const nameOnCard = searchParams.get('nameOnCard');
  const paymentStatus = searchParams.get('status');
  const isEmbedded = searchParams.get('embedded');
  const NameOnCardNotMatching =
    'Deposit On Hold : This deposit requires manual review since the name used on your payment method does not match the name on your ' +
    CONFIG.BRAND +
    ' account. Please contact the Player Experience Team.';
  const Rejected =
    'Deposit Rejected : This deposit has been rejected. Please contact the Player Experience Team.';
  const PendingOrUnverified =
    'Deposit On Hold : This deposit requires manual review. Please contact the Player Experience Team.';
  const Unknown = 'There was a problem making the deposit.';

  useEffect(() => {
    if (window.self !== window.top) {
      postNativeMessage(BspotCashierMessage.DEPOSIT_FAIL, setErrorMessage());
      let url =
        window.origin + Routes.MakeDepositErrorPage.path + '?fromIframe=true';
      if (isEmbedded) {
        url = url + '&embedded=true';
      }
      top.location.href = url;
    }
  }, []);

  const setErrorMessage = () => {
    if (paymentStatus === '5' && nameOnCard) {
      // on hold
      return NameOnCardNotMatching;
    }
    if (paymentStatus === '3') {
      //rejected
      return Rejected;
    }
    if (paymentStatus === '5') {
      //unverified
      return PendingOrUnverified;
    }
    if (paymentStatus === '11' || paymentStatus === '1') {
      //pending
      return PendingOrUnverified;
    }
    if (paymentStatus === 'play_error_or_ibt') {
      // play_error
      return Unknown;
    }
    if (![0, 1, 2, 3, 4, 5, 11].includes(+paymentStatus)) {
      //unknown
      return Unknown;
    }
  };

  const message =
    window.self === window.top ? (
      <div>
        {setErrorMessage()}
        {nameOnCard && (
          <>
            <br />
            <a
              style={{ color: 'white' }}
              className='deposit-success-a'
              href={Routes.MakeDepositPage.path}
            >
              Try again
            </a>
          </>
        )}
      </div>
    ) : (
      <></>
    );

  const spinner = (
    <FadeInContainer className='d-flex col-12 flex-column mx-auto pb-3 bg-black'>
      <div
        style={{
          display: 'flex',
          padding: '50px 0',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Logo />
        <CircularLoader size='2em' color='white' />
      </div>
    </FadeInContainer>
  );

  const component =
    window.self === window.top ? (
      <FadeInContainer className='d-flex col-12 flex-column mx-auto pb-3 bg-black'>
        <BspotAlert type={'error'} message={message} />
      </FadeInContainer>
    ) : (
      spinner
    );

  return window.self === window.top ? (
    <MakeDepositPage isModal={false}>{component}</MakeDepositPage>
  ) : (
    component
  );
}
