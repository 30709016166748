import React from 'react';
import { SvgProps } from '../svg';

export function ChipSilver({
  size,
  width = '120px',
  height = '120px',
  fill = 'none',
  onClick,
}: SvgProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      fill={fill}
      onClick={onClick}
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.6227 46.7073C25.6353 53.5271 10.3799 49.33 3.54821 37.3364C-3.28347 25.3427 0.896362 10.0923 12.8838 3.27403C24.8713 -3.54423 40.1251 0.657369 46.9568 12.6434C53.7885 24.6295 49.6087 39.889 37.6227 46.7073Z'
        fill='url(#paint0_linear_10621_388134)'
      />
      <path
        d='M43.1078 16.4901C47.7969 26.3304 43.6049 38.1473 33.7584 42.8365C23.912 47.5258 12.0861 43.3288 7.39546 33.4915C2.70478 23.6543 6.89986 11.8328 16.7463 7.14509C21.4829 4.89661 26.9186 4.61775 31.8607 6.3697C36.8028 8.12165 40.8477 11.7613 43.1078 16.4901V16.4901ZM10.0168 32.2449C11.4528 35.257 13.7503 37.7758 16.6188 39.4829C19.4873 41.19 22.7979 42.0086 26.132 41.8353C29.466 41.662 32.6737 40.5044 35.3495 38.5091C38.0253 36.5138 40.0488 33.7703 41.1644 30.6256C42.2799 27.4809 42.4372 24.0762 41.6164 20.8421C40.7957 17.6081 39.0337 14.6899 36.5533 12.4566C34.073 10.2233 30.9856 8.77528 27.6818 8.2956C24.3779 7.81592 21.0059 8.32614 17.9922 9.76176C13.9544 11.6897 10.8468 15.1397 9.35104 19.3546C7.85531 23.5696 8.09365 28.2054 10.0138 32.2449H10.0168Z'
        fill='#4E5152'
      />
      <path
        d='M38.815 24.9908C38.815 27.6719 38.0194 30.2929 36.5289 32.5221C35.0384 34.7514 32.9198 36.4889 30.4412 37.5148C27.9626 38.5408 25.2352 38.8091 22.604 38.286C19.9727 37.7628 17.5558 36.4716 15.6589 34.5756C13.7619 32.6797 12.4702 30.2641 11.947 27.6345C11.4238 25.0048 11.6926 22.2792 12.7195 19.8022C13.7464 17.3252 15.4852 15.2082 17.716 13.7189C19.9468 12.2295 22.5695 11.4348 25.2523 11.4351C27.0335 11.4351 28.7973 11.7857 30.4429 12.467C32.0885 13.1482 33.5837 14.1467 34.8431 15.4055C36.1025 16.6643 37.1015 18.1587 37.783 19.8034C38.4645 21.448 38.8152 23.2107 38.815 24.9908Z'
        fill='white'
      />
      <path
        d='M25.2527 38.8283C22.5137 38.8286 19.8362 38.0172 17.5587 36.4966C15.2812 34.9761 13.506 32.8147 12.4577 30.2859C11.4094 27.757 11.1351 24.9743 11.6693 22.2896C12.2036 19.6049 13.5225 17.1389 15.4593 15.2033C17.396 13.2678 19.8636 11.9497 22.55 11.4158C25.2364 10.8818 28.0208 11.156 30.5513 12.2036C33.0817 13.2513 35.2444 15.0253 36.7659 17.3014C38.2874 19.5775 39.0994 22.2534 39.0991 24.9906C39.0946 28.6593 37.6344 32.1763 35.0387 34.7705C32.4429 37.3646 28.9236 38.8239 25.2527 38.8283V38.8283ZM25.2527 11.7184C22.626 11.718 20.0582 12.4962 17.874 13.9544C15.6898 15.4126 13.9874 17.4853 12.982 19.9105C11.9766 22.3357 11.7133 25.0043 12.2256 27.579C12.7379 30.1537 14.0026 32.5187 15.8599 34.375C17.7171 36.2313 20.0835 37.4955 22.6597 38.0078C25.2359 38.52 27.9063 38.2573 30.3331 37.2528C32.7599 36.2483 34.8341 34.5471 36.2935 32.3645C37.7529 30.1818 38.5318 27.6157 38.5318 24.9906C38.5278 21.4721 37.1275 18.0988 34.6381 15.6107C32.1487 13.1226 28.7735 11.7228 25.2527 11.7184V11.7184Z'
        fill='url(#paint1_linear_10621_388134)'
      />
      <path
        d='M25.2525 38.7279C22.5339 38.7279 19.8763 37.9222 17.6158 36.4127C15.3554 34.9033 13.5936 32.7579 12.5532 30.2477C11.5128 27.7376 11.2406 24.9755 11.771 22.3108C12.3013 19.6461 13.6105 17.1983 15.5329 15.2772C17.4552 13.356 19.9045 12.0477 22.5709 11.5176C25.2373 10.9876 28.0011 11.2596 30.5128 12.2993C33.0245 13.3391 35.1713 15.0998 36.6817 17.3588C38.1921 19.6179 38.9982 22.2738 38.9982 24.9908C38.9938 28.6327 37.5442 32.1242 34.9673 34.6995C32.3905 37.2747 28.8968 38.7234 25.2525 38.7279V38.7279ZM25.2525 11.6179C22.606 11.6179 20.0189 12.4022 17.8183 13.8716C15.6178 15.3411 13.9027 17.4296 12.8899 19.8732C11.8771 22.3168 11.6121 25.0056 12.1284 27.5997C12.6447 30.1938 13.9192 32.5766 15.7906 34.4468C17.662 36.317 20.0463 37.5907 22.642 38.1067C25.2377 38.6227 27.9282 38.3578 30.3733 37.3457C32.8184 36.3335 34.9083 34.6195 36.3786 32.4203C37.849 30.2212 38.6338 27.6357 38.6338 24.9908C38.6294 21.4454 37.2181 18.0466 34.7096 15.5396C32.2011 13.0327 28.8001 11.6223 25.2525 11.6179V11.6179Z'
        fill='url(#paint2_linear_10621_388134)'
      />
      <path
        d='M25.252 39.0113C22.4773 39.0113 19.7648 38.189 17.4577 36.6484C15.1506 35.1078 13.3524 32.9181 12.2906 30.3562C11.2287 27.7943 10.9509 24.9752 11.4922 22.2555C12.0336 19.5358 13.3697 17.0376 15.3318 15.0767C17.2938 13.1159 19.7936 11.7806 22.515 11.2396C25.2364 10.6986 28.0573 10.9763 30.6208 12.0375C33.1843 13.0987 35.3754 14.8957 36.917 17.2014C38.4585 19.507 39.2813 22.2178 39.2813 24.9908C39.2773 28.708 37.7979 32.2719 35.1678 34.9004C32.5377 37.5288 28.9716 39.0073 25.252 39.0113V39.0113ZM25.252 11.9014C22.6615 11.9014 20.1293 12.6691 17.9754 14.1073C15.8215 15.5456 14.1427 17.5899 13.1514 19.9817C12.1601 22.3735 11.9007 25.0053 12.4061 27.5444C12.9114 30.0835 14.1589 32.4158 15.9906 34.2464C17.8223 36.077 20.1561 37.3236 22.6968 37.8287C25.2375 38.3337 27.871 38.0745 30.2642 37.0838C32.6575 36.0931 34.7031 34.4154 36.1423 32.2629C37.5814 30.1103 38.3496 27.5796 38.3496 24.9908C38.3452 21.5206 36.9638 18.1938 34.5085 15.7401C32.0532 13.2863 28.7243 11.9058 25.252 11.9014V11.9014Z'
        fill='url(#paint3_linear_10621_388134)'
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.3'>
        <path
          d='M35.1519 34.3191C33.3103 36.2688 30.9316 37.6288 28.3165 38.2274C25.7015 38.8259 22.9675 38.6361 20.4604 37.6818C17.9534 36.7275 15.7857 35.0517 14.2317 32.8662C12.6776 30.6808 11.8069 28.0838 11.7297 25.4039C11.6525 22.7239 12.3723 20.0812 13.7979 17.81C15.2236 15.5388 17.2912 13.7412 19.7392 12.6443C22.1872 11.5475 24.9057 11.2007 27.5508 11.6479C30.196 12.0951 32.6491 13.3162 34.5999 15.1567C37.2139 17.6257 38.7403 21.0307 38.8438 24.6238C38.9473 28.2168 37.6195 31.7039 35.1519 34.3191V34.3191Z'
          fill='url(#paint4_linear_10621_388134)'
        />
        <path
          d='M15.8528 35.0033C13.877 33.1377 12.4991 30.7286 11.8932 28.0805C11.2873 25.4325 11.4806 22.6643 12.4486 20.126C13.4167 17.5877 15.116 15.3932 17.3319 13.8199C19.5477 12.2466 22.1805 11.3651 24.8975 11.2869C27.6145 11.2088 30.2937 11.9374 32.5964 13.3807C34.8992 14.824 36.722 16.9172 37.8346 19.3956C38.9471 21.8741 39.2995 24.6265 38.847 27.3051C38.3945 29.9836 37.1576 32.4679 35.2925 34.444C32.7874 37.0919 29.3332 38.638 25.6884 38.7428C22.0436 38.8477 18.5062 37.5028 15.8528 35.0033V35.0033ZM34.4737 15.2877C32.549 13.4723 30.1289 12.268 27.5194 11.8271C24.9099 11.3863 22.2282 11.7286 19.8134 12.8109C17.3986 13.8931 15.3592 15.6667 13.9529 17.9073C12.5467 20.1479 11.8369 22.7549 11.9132 25.3987C11.9896 28.0424 12.8487 30.6042 14.3818 32.76C15.915 34.9159 18.0534 36.5689 20.5267 37.5102C22.9999 38.4515 25.6969 38.6387 28.2766 38.0482C30.8563 37.4576 33.2029 36.1159 35.0196 34.1926C37.4524 31.6117 38.7613 28.1716 38.6589 24.6273C38.5566 21.083 37.0514 17.7241 34.4737 15.2877Z'
          fill='url(#paint5_linear_10621_388134)'
        />
      </g>
      <path
        d='M19.25 1.03088V4.80881C19.25 4.80881 24.1984 5.92436 30.8944 4.80881V1.03088C30.8944 1.03088 26.4721 -1.2886 19.25 1.03088Z'
        fill='white'
      />
      <path
        d='M19.5308 49.0072V45.4365C19.5308 45.4365 24.4792 44.3819 31.1751 45.4365V49.0072C31.1751 49.0072 26.7528 51.1986 19.5308 49.0072Z'
        fill='white'
      />
      <path
        d='M1.17529 31.059L4.1306 29.468C4.1306 29.468 7.34973 33.3542 9.65237 39.7152L6.69706 41.3047C6.69706 41.3047 2.78562 38.3894 1.17529 31.059Z'
        fill='white'
      />
      <path
        d='M6.81875 8.40674L10.1736 10.1776C10.1736 10.1776 8.85301 15.0726 4.7357 20.469L1.38086 18.6997C1.38086 18.6997 1.38848 13.7025 6.81875 8.40674Z'
        fill='white'
      />
      <path
        d='M49.3214 30.9847L46.2716 29.322C46.2716 29.322 42.9609 33.3453 40.6064 39.9487L43.6685 41.6129C43.6685 41.6129 47.6958 38.6045 49.3214 30.9847Z'
        fill='white'
      />
      <path
        d='M43.466 8.27734L40.3384 9.9339C40.3384 9.9339 41.8404 15.0362 46.0523 20.7892L49.1814 19.1326C49.1814 19.1326 48.9314 13.9907 43.466 8.27734Z'
        fill='white'
      />
      <path
        d='M25.2523 43.1673C21.6551 43.1673 18.1386 42.1013 15.1477 40.104C12.1567 38.1068 9.82552 35.268 8.44893 31.9467C7.07233 28.6254 6.71215 24.9707 7.41394 21.4448C8.11572 17.919 9.84794 14.6802 12.3915 12.1382C14.9352 9.5962 18.1759 7.86506 21.704 7.16372C25.2321 6.46237 28.8891 6.82233 32.2125 8.19806C35.5358 9.57379 38.3764 11.9035 40.3749 14.8926C42.3734 17.8817 43.4401 21.3959 43.4401 24.9909C43.4348 29.81 41.517 34.4302 38.1072 37.8378C34.6975 41.2454 30.0744 43.1621 25.2523 43.1673V43.1673ZM25.2523 7.35395C21.761 7.35365 18.3481 8.38804 15.4452 10.3263C12.5422 12.2646 10.2796 15.0196 8.94346 18.2431C7.60735 21.4665 7.25776 25.0135 7.93891 28.4355C8.62005 31.8575 10.3013 35.0008 12.7701 37.4678C15.2389 39.9349 18.3843 41.6148 21.8085 42.2952C25.2327 42.9757 28.7819 42.626 32.0073 41.2904C35.2326 39.9549 37.9892 37.6935 39.9284 34.7921C41.8677 31.8908 42.9024 28.4799 42.9018 24.9909C42.8965 20.3143 41.0354 15.8308 37.7267 12.5239C34.4179 9.2169 29.9318 7.35655 25.2523 7.3509V7.35395Z'
        fill='url(#paint6_linear_10621_388134)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.2569 3.83203C30.9418 3.83203 35.5593 5.31333 35.5593 7.14363C35.5593 8.97392 30.9418 10.4552 25.2569 10.4552C19.5719 10.4552 14.9453 8.97392 14.9453 7.14363C14.9453 5.31333 19.5613 3.83203 25.2569 3.83203Z'
        fill='url(#paint7_radial_10621_388134)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.2568 38.574C32.3355 38.574 38.0845 40.4195 38.0845 42.6963C38.0845 44.9731 32.3355 46.8217 25.2568 46.8217C18.1781 46.8217 12.4185 44.9762 12.4185 42.6963C12.4185 40.4165 18.1659 38.574 25.2568 38.574Z'
        fill='url(#paint8_radial_10621_388134)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M46.3544 24.925C46.3544 30.6064 44.8722 35.2209 43.0423 35.2209C41.2124 35.2209 39.7256 30.6064 39.7256 24.925C39.7256 19.2436 41.2078 14.6199 43.0423 14.6199C44.8768 14.6199 46.3544 19.2329 46.3544 24.925Z'
        fill='url(#paint9_radial_10621_388134)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M42.1303 24.7364C42.1303 30.4193 40.648 35.0339 38.8166 35.0339C36.9852 35.0339 35.5029 30.4254 35.5029 24.7364C35.5029 19.0475 36.9836 14.4329 38.8166 14.4329C40.6496 14.4329 42.1303 19.0505 42.1303 24.7364Z'
        fill='url(#paint10_radial_10621_388134)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M15.011 24.9938C15.011 30.6751 13.5288 35.2928 11.6973 35.2928C9.86589 35.2928 8.37451 30.6797 8.37451 24.9938C8.37451 19.3078 9.85674 14.6902 11.6897 14.6902C13.5227 14.6902 15.011 19.3017 15.011 24.9938Z'
        fill='url(#paint11_radial_10621_388134)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M11.6561 24.9255C11.6561 32.1201 9.77744 37.9646 7.45955 37.9646C5.14166 37.9646 3.26904 32.1201 3.26904 24.9255C3.26904 17.7308 5.14623 11.8772 7.46717 11.8772C9.78811 11.8772 11.6561 17.7262 11.6561 24.9255Z'
        fill='url(#paint12_radial_10621_388134)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M24.9792 20.7086C19.2928 20.7086 14.6753 17.6013 14.6753 13.7624C14.6753 9.92348 19.2928 6.81152 24.9792 6.81152C30.6657 6.81152 35.2923 9.92043 35.2923 13.7639C35.2923 17.6074 30.6748 20.7086 24.9792 20.7086Z'
        fill='url(#paint13_radial_10621_388134)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.4382 45.7121C19.7518 45.7121 15.1343 42.6032 15.1343 38.7643C15.1343 34.9254 19.7518 31.8135 25.4382 31.8135C31.1247 31.8135 35.7498 34.9224 35.7498 38.7643C35.7498 42.6063 31.1338 45.7121 25.4382 45.7121Z'
        fill='url(#paint14_radial_10621_388134)'
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity='0.1'
        d='M37.5951 46.7318C25.6076 53.5501 10.3538 49.3546 3.52057 37.3609C-3.31263 25.3672 0.868718 10.1168 12.8562 3.29704C24.8436 -3.52274 40.099 0.672755 46.9292 12.6679C53.7593 24.6631 49.581 39.912 37.5951 46.7318Z'
        fill='url(#paint15_linear_10621_388134)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_10621_388134'
          x1='12.8868'
          y1='3.27148'
          x2='37.6019'
          y2='46.7168'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#90969C' />
          <stop offset='1' stopColor='#555B60' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_10621_388134'
          x1='11.4049'
          y1='24.9906'
          x2='39.0991'
          y2='24.9906'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#553319' />
          <stop offset='0.1' stopColor='#6A4623' />
          <stop offset='0.29' stopColor='#A27B3C' />
          <stop offset='0.39' stopColor='#EED072' />
          <stop offset='0.52' stopColor='#FFF5D8' />
          <stop offset='0.61' stopColor='#EED072' />
          <stop offset='0.81' stopColor='#A27B3C' />
          <stop offset='1' stopColor='#553319' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_10621_388134'
          x1='11.5068'
          y1='24.9908'
          x2='38.9982'
          y2='24.9908'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DDDAD6' />
          <stop offset='0.06' stopColor='#CECBC9' />
          <stop offset='0.18' stopColor='#A7A5A8' />
          <stop offset='0.26' stopColor='#8A888E' />
          <stop offset='0.52' stopColor='#F3F4F4' />
          <stop offset='0.59' stopColor='#E5E5E6' />
          <stop offset='0.71' stopColor='#BFBEC0' />
          <stop offset='0.87' stopColor='#838083' />
          <stop offset='0.88' stopColor='#807D80' />
          <stop offset='1' stopColor='#A8AAAC' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_10621_388134'
          x1='11.2227'
          y1='24.9908'
          x2='39.2813'
          y2='24.9908'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DDDAD6' />
          <stop offset='0.06' stopColor='#CECBC9' />
          <stop offset='0.18' stopColor='#A7A5A8' />
          <stop offset='0.26' stopColor='#8A888E' />
          <stop offset='0.52' stopColor='#F3F4F4' />
          <stop offset='0.59' stopColor='#E5E5E6' />
          <stop offset='0.71' stopColor='#BFBEC0' />
          <stop offset='0.87' stopColor='#838083' />
          <stop offset='0.88' stopColor='#807D80' />
          <stop offset='1' stopColor='#A8AAAC' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_10621_388134'
          x1='15.2398'
          y1='15.528'
          x2='34.953'
          y2='34.149'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stopColor='white' />
          <stop offset='0.9' stopColor='#2D3233' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_10621_388134'
          x1='15.2955'
          y1='15.5802'
          x2='35.2748'
          y2='34.4525'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stopColor='white' />
          <stop offset='1' stopColor='#553319' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_10621_388134'
          x1='7.06446'
          y1='24.9909'
          x2='43.4401'
          y2='24.9909'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DDDAD6' />
          <stop offset='0.06' stopColor='#CECBC9' />
          <stop offset='0.18' stopColor='#A7A5A8' />
          <stop offset='0.26' stopColor='#8A888E' />
          <stop offset='0.52' stopColor='#F3F4F4' />
          <stop offset='0.59' stopColor='#E5E5E6' />
          <stop offset='0.71' stopColor='#BFBEC0' />
          <stop offset='0.87' stopColor='#838083' />
          <stop offset='0.88' stopColor='#807D80' />
          <stop offset='1' stopColor='#A8AAAC' />
        </linearGradient>
        <radialGradient
          id='paint7_radial_10621_388134'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.3037 7.26908) rotate(-90) scale(3.30538 10.3069)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_10621_388134'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.3037 42.7579) rotate(-90) scale(4.11534 12.8326)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint9_radial_10621_388134'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(42.9983 24.9969) scale(3.30745 10.3005)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint10_radial_10621_388134'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(38.7832 24.8176) scale(3.30745 10.3005)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint11_radial_10621_388134'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(11.7073 25.0706) scale(3.30745 10.3005)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint12_radial_10621_388134'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(7.48974 24.9973) scale(4.18873 13.0451)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint13_radial_10621_388134'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(24.8892 13.9337) rotate(90) scale(6.95667 10.3069)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint14_radial_10621_388134'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.3469 38.9685) rotate(90) scale(6.95667 10.3069)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <linearGradient
          id='paint15_linear_10621_388134'
          x1='12.8599'
          y1='3.29559'
          x2='37.575'
          y2='46.7409'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.61' stopColor='white' />
          <stop offset='0.71' stopColor='#F7E8CE' />
          <stop offset='0.91' stopColor='#EAC078' />
          <stop offset='1' stopColor='#E5B056' />
        </linearGradient>
      </defs>
    </svg>
  );
}
