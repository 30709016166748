import { SvgProps } from 'icons/svg/svg';

export function ChipRuby({
  size,
  width = '120px',
  height = '120px',
  fill = 'none',
  onClick,
}: SvgProps) {
  return (
    <svg
      viewBox='0 0 50 50'
      width={size || width}
      height={size || height}
      fill={fill}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill='url(#a)'
        d='M37.539 46.708c-11.99 6.819-27.247 2.623-34.09-9.37-6.845-11.995-2.654-27.245 9.34-34.065s27.254-2.624 34.089 9.37c6.834 11.996 2.653 27.245-9.339 34.065'
      />
      <path
        fill='#7a2222'
        d='M43.028 16.489c4.693 9.84.496 21.659-9.355 26.348a19.83 19.83 0 0 1-15.122.774 19.8 19.8 0 0 1-11.253-10.12c-4.692-9.84-.496-21.66 9.355-26.346s21.684-.497 26.375 9.344M9.922 32.245a16.88 16.88 0 0 0 6.605 7.238 16.9 16.9 0 0 0 18.74-.973 16.852 16.852 0 0 0 1.204-26.053A16.895 16.895 0 0 0 17.9 9.762a16.9 16.9 0 0 0-8.646 9.593 16.87 16.87 0 0 0 .662 12.89z'
      />
      <path
        fill='#fff'
        d='M38.734 24.99c0 2.68-.796 5.302-2.287 7.531a13.57 13.57 0 0 1-6.09 4.994 13.58 13.58 0 0 1-14.79-2.939 13.55 13.55 0 0 1-2.941-14.773 13.56 13.56 0 0 1 4.998-6.084 13.6 13.6 0 0 1 7.54-2.284 13.59 13.59 0 0 1 9.593 3.973 13.56 13.56 0 0 1 3.977 9.581'
      />
      <path
        fill='url(#b)'
        d='M25.165 38.829c-2.74 0-5.42-.811-7.698-2.332a13.826 13.826 0 0 1-2.101-21.293 13.86 13.86 0 0 1 15.099-3 13.85 13.85 0 0 1 6.217 5.097 13.83 13.83 0 0 1 2.335 7.689 13.85 13.85 0 0 1-4.06 9.78 13.88 13.88 0 0 1-9.792 4.059m0-27.11c-2.628 0-5.198.777-7.383 2.236a13.259 13.259 0 0 0-2.014 20.421 13.29 13.29 0 0 0 14.48 2.877 13.3 13.3 0 0 0 5.963-4.89 13.26 13.26 0 0 0 2.239-7.373 13.28 13.28 0 0 0-3.896-9.38 13.3 13.3 0 0 0-9.39-3.891'
      />
      <path
        fill='url(#c)'
        d='M25.165 38.731c-2.72 0-5.38-.805-7.642-2.314a13.74 13.74 0 0 1-5.066-6.166 13.72 13.72 0 0 1 2.98-14.973A13.76 13.76 0 0 1 30.426 12.3a13.75 13.75 0 0 1 6.173 5.06 13.73 13.73 0 0 1 2.318 7.633 13.75 13.75 0 0 1-4.033 9.71 13.78 13.78 0 0 1-9.72 4.028m0-27.11c-2.648 0-5.237.784-7.438 2.254a13.36 13.36 0 0 0-2.028 20.576 13.39 13.39 0 0 0 14.59 2.898 13.4 13.4 0 0 0 6.008-4.926 13.36 13.36 0 0 0 2.255-7.43 13.38 13.38 0 0 0-3.925-9.453 13.4 13.4 0 0 0-9.463-3.922z'
      />
      <path
        fill='url(#d)'
        d='M25.164 39.036c-2.776 0-5.49-.822-7.798-2.363a14.02 14.02 0 0 1-5.17-6.292A14.01 14.01 0 0 1 15.24 15.1a14.04 14.04 0 0 1 15.296-3.04 14.03 14.03 0 0 1 6.299 5.165 14 14 0 0 1 2.365 7.79 14.03 14.03 0 0 1-4.116 9.909 14.06 14.06 0 0 1-9.92 4.111m0-27.11c-2.591 0-5.125.768-7.28 2.206a13.076 13.076 0 0 0-1.986 20.14 13.11 13.11 0 0 0 14.281 2.836 13.1 13.1 0 0 0 5.88-4.82 13.1 13.1 0 0 0 2.21-7.273 13.1 13.1 0 0 0-3.843-9.251 13.13 13.13 0 0 0-9.262-3.84z'
      />
      <g
        opacity={0.3}
        style={{
          mixBlendMode: 'multiply',
        }}
      >
        <path
          fill='url(#e)'
          d='M35.131 34.226a13.57 13.57 0 0 1-14.698 3.363 13.57 13.57 0 0 1-6.232-4.816 13.546 13.546 0 0 1 5.51-20.222 13.58 13.58 0 0 1 14.868 2.513 13.56 13.56 0 0 1 4.25 9.467 13.54 13.54 0 0 1-3.698 9.695'
        />
        <path
          fill='url(#f)'
          d='M15.724 35.004a13.722 13.722 0 0 1-3.414-14.88 13.74 13.74 0 0 1 4.885-6.309 13.762 13.762 0 0 1 20.516 5.578 13.72 13.72 0 0 1-2.55 15.051 13.776 13.776 0 0 1-19.437.56m18.63-19.715a13.39 13.39 0 0 0-14.668-2.478 13.4 13.4 0 0 0-5.863 5.096 13.36 13.36 0 0 0 .428 14.853 13.4 13.4 0 0 0 20.647 1.434 13.38 13.38 0 0 0-.545-18.905'
        />
      </g>
      <path
        fill='#fff'
        d='M19.16 1.03V4.81s4.95 1.115 11.649 0V1.03s-4.426-2.32-11.65 0M19.446 49.006v-3.569s4.951-1.053 11.652 0v3.57s-4.434 2.19-11.652 0M1.076 31.058l2.961-1.591s3.221 3.886 5.525 10.247l-2.963 1.591S2.686 38.39 1.076 31.058M6.723 8.404l3.356 1.77S8.758 15.07 4.637 20.465l-3.356-1.77s.01-4.993 5.442-10.292M49.252 30.987l-3.062-1.665s-3.312 4.024-5.668 10.627l3.064 1.664s4.032-3.014 5.666-10.626M43.386 8.277l-3.13 1.65s1.505 5.102 5.717 10.856l3.132-1.658s-.25-5.134-5.72-10.848'
      />
      <path
        fill='url(#g)'
        d='M25.164 43.166a18.2 18.2 0 0 1-10.11-3.062 18.2 18.2 0 0 1-6.702-8.157 18.16 18.16 0 0 1 3.943-19.81 18.215 18.215 0 0 1 19.83-3.94 18.2 18.2 0 0 1 8.167 6.694c2 2.99 3.067 6.504 3.067 10.099a18.2 18.2 0 0 1-5.335 12.846 18.23 18.23 0 0 1-12.86 5.33m0-35.814a17.67 17.67 0 0 0-9.812 2.973 17.64 17.64 0 0 0-6.504 7.917 17.62 17.62 0 0 0 3.828 19.225 17.67 17.67 0 0 0 19.246 3.822 17.66 17.66 0 0 0 7.925-6.498 17.63 17.63 0 0 0 2.975-9.801 17.65 17.65 0 0 0-5.178-12.467 17.7 17.7 0 0 0-12.48-5.172z'
      />
      <path
        fill='url(#h)'
        d='M25.168 3.832c5.687 0 10.307 1.481 10.307 3.312s-4.62 3.311-10.308 3.311-10.316-1.481-10.316-3.311 4.618-3.312 10.317-3.312'
        style={{
          mixBlendMode: 'color-dodge',
        }}
      />
      <path
        fill='url(#i)'
        d='M25.17 38.58C32.25 38.58 38 40.422 38 42.693s-5.751 4.124-12.832 4.124c-7.08 0-12.843-1.838-12.843-4.124s5.747-4.115 12.843-4.115'
        style={{
          mixBlendMode: 'color-dodge',
        }}
      />
      <path
        fill='url(#j)'
        d='M46.277 24.924c0 5.683-1.483 10.298-3.316 10.298s-3.316-4.615-3.316-10.298 1.48-10.305 3.316-10.305 3.316 4.614 3.316 10.305'
        style={{
          mixBlendMode: 'color-dodge',
        }}
      />
      <path
        fill='url(#k)'
        d='M42.048 24.743c0 5.681-1.483 10.296-3.315 10.296s-3.315-4.615-3.315-10.296c0-5.682 1.481-10.305 3.315-10.305s3.315 4.613 3.315 10.305'
        style={{
          mixBlendMode: 'color-dodge',
        }}
      />
      <path
        fill='url(#l)'
        d='M14.909 24.994c0 5.682-1.483 10.298-3.315 10.298-1.833 0-3.316-4.616-3.316-10.298 0-5.681 1.483-10.305 3.316-10.305s3.315 4.613 3.315 10.305'
        style={{
          mixBlendMode: 'color-dodge',
        }}
      />
      <path
        fill='url(#m)'
        d='M11.566 24.924c0 7.196-1.884 13.045-4.198 13.045S3.169 32.12 3.169 24.924s1.876-13.047 4.199-13.047 4.198 5.84 4.198 13.047'
        style={{
          mixBlendMode: 'color-dodge',
        }}
      />
      <path
        fill='url(#n)'
        d='M24.89 20.709c-5.688 0-10.309-3.11-10.309-6.948s4.621-6.95 10.309-6.95 10.315 3.108 10.315 6.95-4.621 6.948-10.315 6.948'
        style={{
          mixBlendMode: 'color-dodge',
        }}
      />
      <path
        fill='url(#o)'
        d='M25.349 45.711c-5.688 0-10.309-3.109-10.309-6.946s4.621-6.951 10.309-6.951 10.316 3.109 10.316 6.95c0 3.843-4.624 6.947-10.316 6.947'
        style={{
          mixBlendMode: 'color-dodge',
        }}
      />
      <path
        fill='url(#p)'
        d='M37.54 46.73c-11.991 6.82-27.248 2.625-34.09-9.37C-3.393 25.364.797 10.115 12.79 3.297S40.042.673 46.877 12.671s2.655 27.241-9.337 34.06'
        opacity={0.1}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <defs fill='#000'>
        <linearGradient
          id='a'
          x1={12.7914}
          x2={37.5009}
          y1={3.2723}
          y2={46.7279}
          fill='#000'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#d64141' />
          <stop offset={1} stopColor='#932121' />
        </linearGradient>
        <linearGradient
          id='b'
          x1={11.3101}
          x2={39.0175}
          y1={24.9895}
          y2={24.9895}
          fill='#000'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#82402d' />
          <stop offset={0.1} stopColor='#b5281a' />
          <stop offset={0.29} stopColor='#947e5b' />
          <stop offset={0.39} stopColor='#b09439' />
          <stop offset={0.52} stopColor='#FFF5D8' />
          <stop offset={0.61} stopColor='#b09439' />
          <stop offset={0.81} stopColor='#947e5b' />
          <stop offset={1} stopColor='#82402d' />
        </linearGradient>
        <linearGradient
          id='c'
          x1={11.4107}
          x2={38.9167}
          y1={24.9912}
          y2={24.9912}
          fill='#000'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#c73535' />
          <stop offset={0.07} stopColor='#c74040' />
          <stop offset={0.5} stopColor='#cf3232' />
          <stop offset={0.62} stopColor='#b80f0f' />
          <stop offset={0.67} stopColor='#e63535' />
          <stop offset={0.72} stopColor='#fc0000' />
          <stop offset={0.77} stopColor='#f00b0b' />
          <stop offset={0.81} stopColor='#f00303' />
          <stop offset={1} stopColor='#bf0f0f' />
        </linearGradient>
        <linearGradient
          id='d'
          x1={11.1269}
          x2={39.2004}
          y1={25.0138}
          y2={25.0138}
          fill='#000'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#c73535' />
          <stop offset={0.07} stopColor='#c74040' />
          <stop offset={0.5} stopColor='#cf3232' />
          <stop offset={0.62} stopColor='#b80f0f' />
          <stop offset={0.67} stopColor='#e63535' />
          <stop offset={0.72} stopColor='#fc0000' />
          <stop offset={0.77} stopColor='#f00b0b' />
          <stop offset={0.81} stopColor='#f00303' />
          <stop offset={1} stopColor='#bf0f0f' />
        </linearGradient>
        <linearGradient
          id='e'
          x1={15.21}
          x2={34.9251}
          y1={15.4336}
          y2={34.0649}
          fill='#000'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.39} stopColor='#fff' />
          <stop offset={0.9} stopColor='#420d0d' />
        </linearGradient>
        <linearGradient
          id='f'
          x1={15.1646}
          x2={35.1446}
          y1={15.5793}
          y2={34.461}
          fill='#000'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.39} stopColor='#fff' />
          <stop offset={1} stopColor='#420d0d' />
        </linearGradient>
        <linearGradient
          id='g'
          x1={6.96632}
          x2={43.3592}
          y1={24.9896}
          y2={24.9896}
          fill='#000'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#c73535' />
          <stop offset={0.07} stopColor='#c74040' />
          <stop offset={0.5} stopColor='#cf3232' />
          <stop offset={0.62} stopColor='#b80f0f' />
          <stop offset={0.67} stopColor='#e63535' />
          <stop offset={0.72} stopColor='#fc0000' />
          <stop offset={0.77} stopColor='#f00b0b' />
          <stop offset={0.81} stopColor='#f00303' />
          <stop offset={1} stopColor='#bf0f0f' />
        </linearGradient>
        <radialGradient
          id='h'
          cx={0}
          cy={0}
          r={1}
          fill='#000'
          gradientTransform='matrix(0 -3.30541 10.3118 0 25.221 7.27)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={0.01} stopColor='#F9F9F9' />
          <stop offset={0.05} stopColor='#CBCBCB' />
          <stop offset={0.09} stopColor='#A0A0A0' />
          <stop offset={0.14} stopColor='#7A7A7A' />
          <stop offset={0.19} stopColor='#5A5A5A' />
          <stop offset={0.25} stopColor='#3e3e3e' />
          <stop offset={0.32} stopColor='#282828' />
          <stop offset={0.4} stopColor='#171717' />
          <stop offset={0.49} stopColor='#0c0c0c' />
          <stop offset={0.63} stopColor='#050505' />
          <stop offset={1} stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='i'
          cx={0}
          cy={0}
          r={1}
          fill='#000'
          gradientTransform='matrix(0 -4.11538 12.8387 0 25.222 42.757)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={0.01} stopColor='#F9F9F9' />
          <stop offset={0.05} stopColor='#CBCBCB' />
          <stop offset={0.09} stopColor='#A0A0A0' />
          <stop offset={0.14} stopColor='#7A7A7A' />
          <stop offset={0.19} stopColor='#5A5A5A' />
          <stop offset={0.25} stopColor='#3e3e3e' />
          <stop offset={0.32} stopColor='#282828' />
          <stop offset={0.4} stopColor='#171717' />
          <stop offset={0.49} stopColor='#0c0c0c' />
          <stop offset={0.63} stopColor='#050505' />
          <stop offset={1} stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='j'
          cx={0}
          cy={0}
          r={1}
          fill='#000'
          gradientTransform='matrix(3.30901 0 0 10.3006 42.92 24.993)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={0.01} stopColor='#F9F9F9' />
          <stop offset={0.05} stopColor='#CBCBCB' />
          <stop offset={0.09} stopColor='#A0A0A0' />
          <stop offset={0.14} stopColor='#7A7A7A' />
          <stop offset={0.19} stopColor='#5A5A5A' />
          <stop offset={0.25} stopColor='#3e3e3e' />
          <stop offset={0.32} stopColor='#282828' />
          <stop offset={0.4} stopColor='#171717' />
          <stop offset={0.49} stopColor='#0c0c0c' />
          <stop offset={0.63} stopColor='#050505' />
          <stop offset={1} stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='k'
          cx={0}
          cy={0}
          r={1}
          fill='#000'
          gradientTransform='matrix(3.30901 0 0 10.3006 38.7 24.814)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={0.01} stopColor='#F9F9F9' />
          <stop offset={0.05} stopColor='#CBCBCB' />
          <stop offset={0.09} stopColor='#A0A0A0' />
          <stop offset={0.14} stopColor='#7A7A7A' />
          <stop offset={0.19} stopColor='#5A5A5A' />
          <stop offset={0.25} stopColor='#3e3e3e' />
          <stop offset={0.32} stopColor='#282828' />
          <stop offset={0.4} stopColor='#171717' />
          <stop offset={0.49} stopColor='#0c0c0c' />
          <stop offset={0.63} stopColor='#050505' />
          <stop offset={1} stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='l'
          cx={0}
          cy={0}
          r={1}
          fill='#000'
          gradientTransform='matrix(3.30901 0 0 10.3006 11.611 25.067)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={0.01} stopColor='#F9F9F9' />
          <stop offset={0.05} stopColor='#CBCBCB' />
          <stop offset={0.09} stopColor='#A0A0A0' />
          <stop offset={0.14} stopColor='#7A7A7A' />
          <stop offset={0.19} stopColor='#5A5A5A' />
          <stop offset={0.25} stopColor='#3e3e3e' />
          <stop offset={0.32} stopColor='#282828' />
          <stop offset={0.4} stopColor='#171717' />
          <stop offset={0.49} stopColor='#0c0c0c' />
          <stop offset={0.63} stopColor='#050505' />
          <stop offset={1} stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='m'
          cx={0}
          cy={0}
          r={1}
          fill='#000'
          gradientTransform='matrix(4.19072 0 0 13.0452 7.392 24.993)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={0.01} stopColor='#F9F9F9' />
          <stop offset={0.05} stopColor='#CBCBCB' />
          <stop offset={0.09} stopColor='#A0A0A0' />
          <stop offset={0.14} stopColor='#7A7A7A' />
          <stop offset={0.19} stopColor='#5A5A5A' />
          <stop offset={0.25} stopColor='#3e3e3e' />
          <stop offset={0.32} stopColor='#282828' />
          <stop offset={0.4} stopColor='#171717' />
          <stop offset={0.49} stopColor='#0c0c0c' />
          <stop offset={0.63} stopColor='#050505' />
          <stop offset={1} stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='n'
          cx={0}
          cy={0}
          r={1}
          fill='#000'
          gradientTransform='matrix(0 6.95674 -10.3118 0 24.799 13.933)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={0.01} stopColor='#F9F9F9' />
          <stop offset={0.05} stopColor='#CBCBCB' />
          <stop offset={0.09} stopColor='#A0A0A0' />
          <stop offset={0.14} stopColor='#7A7A7A' />
          <stop offset={0.19} stopColor='#5A5A5A' />
          <stop offset={0.25} stopColor='#3e3e3e' />
          <stop offset={0.32} stopColor='#282828' />
          <stop offset={0.4} stopColor='#171717' />
          <stop offset={0.49} stopColor='#0c0c0c' />
          <stop offset={0.63} stopColor='#050505' />
          <stop offset={1} stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='o'
          cx={0}
          cy={0}
          r={1}
          fill='#000'
          gradientTransform='matrix(0 6.95674 -10.3118 0 25.256 38.969)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={0.01} stopColor='#F9F9F9' />
          <stop offset={0.05} stopColor='#CBCBCB' />
          <stop offset={0.09} stopColor='#A0A0A0' />
          <stop offset={0.14} stopColor='#7A7A7A' />
          <stop offset={0.19} stopColor='#5A5A5A' />
          <stop offset={0.25} stopColor='#3e3e3e' />
          <stop offset={0.32} stopColor='#282828' />
          <stop offset={0.4} stopColor='#171717' />
          <stop offset={0.49} stopColor='#0c0c0c' />
          <stop offset={0.63} stopColor='#050505' />
          <stop offset={1} stopColor='#030303' />
        </radialGradient>
        <linearGradient
          id='p'
          x1={12.7917}
          x2={37.502}
          y1={3.29454}
          y2={46.7515}
          fill='#000'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.61} stopColor='#fff' />
          <stop offset={0.8} stopColor='#b12424' />
          <stop offset={0.94} stopColor='#7a2222' />
          <stop offset={1} stopColor='#420d0d' />
        </linearGradient>
      </defs>
    </svg>
  );
}
