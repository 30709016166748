export enum FreeplayEligibility {
  RegistrationComplete = 'registrationComplete',
  FirstCreditsBought = 'firstCreditsBought',
}

export const FREEPLAY_V3_THRESHOLD = 20;

export const endFreeplayToastEligibility = (playerId?: number) => {
  localStorage.removeItem(`freeplay-eligible-${playerId}`);
  localStorage.removeItem(`credits-bought-${playerId}`);
};

export const setFreeplayEligibility = (playerId?: number) => {
  localStorage.setItem(
    `freeplay-eligible-${playerId}`,
    FreeplayEligibility.RegistrationComplete,
  );
};

export const setCreditsBought = (credits: number, playerId: number) => {
  const eligibility = localStorage.getItem(`freeplay-eligible-${playerId}`);
  if (!eligibility) return;
  if (
    eligibility === FreeplayEligibility.FirstCreditsBought &&
    credits < FREEPLAY_V3_THRESHOLD
  ) {
    endFreeplayToastEligibility(playerId);
    return;
  }

  localStorage.setItem(`credits-bought-${playerId}`, String(credits));
};
