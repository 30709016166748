import React from 'react';
import { Info } from 'components/organisms/buy-credits/game-product/info';
import { GameProduct } from 'services/get-game-products/get-game-products';

export type BadgeProps = {
  onClickBadge(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    product: GameProduct,
  ): void;
  product: GameProduct;
};

const colorMap = {
  silver: '#CBCBCB',
  gold: '#CCAA40',
  platinum: '#85A6D4',
  emerald: '#35AD87',
  ruby: '#c62a2a',
};

const Badge = ({ product, onClickBadge }: BadgeProps) => {
  return (
    <div className={'badge'} onClick={(event) => onClickBadge(event, product)}>
      <div className='badge-container' style={{ fontSize: '11px' }}>
        <span style={{ color: colorMap[product.color] }}>
          {product.text}&nbsp;
        </span>
        <Info fill={colorMap[product.color]} size={'10px'} />
      </div>
    </div>
  );
};
export default Badge;
