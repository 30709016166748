import { SvgProps } from './svg';

export function Cross({
  size,
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'var(--text-light-color)',
  strokeWidth = '2',
  className,
  onClick,
}: SvgProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      className={className}
      onClick={onClick}
      viewBox='0 0 18 17'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
      data-testid='error-svg'
    >
      <line
        x1='2.41421'
        y1='1.88892'
        x2='16.0849'
        y2='15.5596'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        fill={fill}
      />
      <line
        x1='1.88867'
        y1='15.5858'
        x2='15.5594'
        y2='1.91506'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        fill={fill}
      />
    </svg>
  );
}
