import styled from '@emotion/styled';
import { Routes } from 'common/routes';
import { Button } from 'components/atoms/button';
import { Link } from 'components/atoms/link';
import { NavLink } from 'react-router-dom';
import { BspotAlert } from 'components/molecules/alerts/alert';

const StyledCardContent = styled.div`
  text-align: left;
  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
`;
type creationBlockedProps = {
  onclick(): void;
};
export const CreationBlocked = ({ onclick }: creationBlockedProps) => {
  return (
    <StyledCardContent
      style={{
        borderRadius: '8px',
        maxHeight: '90vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: '#121212',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)',
      }}
    >
      <div className={'p-4 d-flex flex-column gap-3'}>
        <BspotAlert
          type={'error'}
          message={
            <strong>
              {' '}
              Sorry, we’ve been unable to verify your information and open your
              account. Please contact our Player Experience Team for assistance.
            </strong>
          }
        />

        <div>
          <p>We have been unable to create you an account.</p>

          <b>
            This can be the result of a combination of one or more of the
            following:
          </b>

          <ul style={{ marginBottom: '0px' }}>
            <li>
              You’ve moved recently and we have been unable to verify your
              address
            </li>
            <li>
              You already have an account with us and are trying to create a new
              one. Have you tried a{' '}
              <NavLink
                to={Routes.ForgotPassword.path}
                style={{ color: 'var(--primary-color)' }}
              >
                Password Reset
              </NavLink>
              ?
            </li>
            <li>
              You may have previously self-excluded. Please see our b safe
              Responsible Gaming page for more information
            </li>
            <li>
              Or perhaps there was a technical issue during the registration
              process
            </li>
          </ul>
        </div>

        <div
          className={'p-3'}
          style={{
            borderRadius: '4px',
            boxShadow:
              '0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)',
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.13))',
          }}
        >
          <span>Still struggling?</span>
          <br />
          Please contact our{' '}
          <Link href='https://support.bspot.com/hc/en-us'>
            Player Experience Team
          </Link>{' '}
          for assistance. We take your security and responsible gaming
          seriously, and will do everything we can to resolve the issue
          promptly.
        </div>

        <div className={'d-flex justify-content-end '}>
          <Button text='Got it' onClick={onclick} />
        </div>
      </div>
    </StyledCardContent>
  );
};
