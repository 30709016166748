import { useAuth } from 'contexts/authentication';
import { useQuery } from '@tanstack/react-query';
import { getWelcomeContent } from 'services/welcome-content/welcome-content';

export const useWelcomeContent = () => {
  const { auth } = useAuth();
  return useQuery({
    queryKey: ['welcome-' + auth.session?.playerId],
    queryFn: () => getWelcomeContent(),
    keepPreviousData: false,
    enabled: !!auth.session?.playerId,
  });
};
