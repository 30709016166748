// @ts-nocheck
import React from 'react';
import { Card } from './styled';
import { SectionTitle } from 'components/atoms/section-title/section-title';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'common/utils';

export const AccountHistoryLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const pageTitle = 'Account History - ' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <div>
        <SectionTitle title={'Account History'} />
        <Card>{children}</Card>
      </div>
    </>
  );
};
