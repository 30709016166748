import React from 'react';
import { SvgProps } from 'icons/svg/svg';

export function HowItWorks({ color }: SvgProps) {
  return (
    <svg
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='44' height='44' rx='22' fill='none' />
      <path
        d='M25.5001 33.5V30.5H26.5001C27.2957 30.5 28.0588 30.1839 28.6214 29.6213C29.184 29.0587 29.5 28.2956 29.5 27.5V24.5H32.313C32.3914 24.4995 32.4686 24.4805 32.5384 24.4447C32.6081 24.4088 32.6684 24.3571 32.7144 24.2936C32.7605 24.2302 32.7909 24.1568 32.8034 24.0793C32.8158 24.0019 32.8099 23.9227 32.786 23.848C30.912 18.048 29.5551 11.813 23.3661 10.681C22.0155 10.4193 20.6253 10.4393 19.2829 10.7399C17.9404 11.0404 16.6744 11.6151 15.5644 12.4277C14.4543 13.2403 13.524 14.2735 12.8319 15.4624C12.1398 16.6514 11.7006 17.9704 11.5421 19.337C11.3837 21.0173 11.6653 22.71 12.3593 24.2485C13.0533 25.787 14.1356 27.1185 15.5001 28.112V33.5'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5 18C18.4996 17.4392 18.6563 16.8894 18.9525 16.4132C19.2486 15.937 19.6724 15.5533 20.1756 15.3057C20.6788 15.0582 21.2413 14.9566 21.7993 15.0125C22.3573 15.0685 22.8885 15.2797 23.3325 15.6223C23.7765 15.9648 24.1156 16.425 24.3114 16.9505C24.5071 17.4761 24.5516 18.046 24.4398 18.5955C24.328 19.1451 24.0644 19.6523 23.679 20.0596C23.2935 20.4669 22.8016 20.7581 22.259 20.9C22.0478 20.9462 21.8582 21.062 21.7206 21.2288C21.583 21.3956 21.5053 21.6038 21.5 21.82V23.5'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.5 25.5C21.5494 25.5 21.5978 25.5147 21.6389 25.5421C21.68 25.5696 21.712 25.6086 21.731 25.6543C21.7499 25.7 21.7548 25.7503 21.7452 25.7988C21.7355 25.8473 21.7117 25.8918 21.6768 25.9268C21.6418 25.9617 21.5973 25.9855 21.5488 25.9952C21.5003 26.0048 21.45 25.9999 21.4043 25.981C21.3586 25.962 21.3196 25.93 21.2921 25.8889C21.2647 25.8478 21.25 25.7994 21.25 25.75C21.25 25.6837 21.2763 25.6201 21.3232 25.5732C21.3701 25.5263 21.4337 25.5 21.5 25.5Z'
        fill='#0AEB88'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
