// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../public/assets/images/bspot_background.webp";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lobby-container{background-size:100% auto;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-y;position:relative}.lobby-tournaments{grid-template-columns:1fr;grid-template-rows:auto;gap:24px;align-items:center;padding:24px 0;min-height:700px}@media screen and (min-width: 768px){.lobby-tournaments{grid-template-columns:repeat(2, 1fr);grid-template-rows:1fr;padding:24px 16px}}.lobby-tournaments .card{margin:0 auto}", "",{"version":3,"sources":["webpack://./src/pages/tournaments-lobby/styles.scss"],"names":[],"mappings":"AAAA,iBACE,yBAAA,CACA,wDAAA,CACA,0BAAA,CACA,iBAAA,CAGF,mBAOE,yBAAA,CACA,uBAAA,CACA,QAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CAXA,qCADF,mBAEI,oCAAA,CACA,sBAAA,CACA,iBAAA,CAAA,CASF,yBACE,aAAA","sourcesContent":[".lobby-container {\n  background-size: 100% auto;\n  background-image: url('/public/assets/images/bspot_background.webp');\n  background-repeat: repeat-y;\n  position: relative;\n}\n\n.lobby-tournaments {\n  @media screen and (min-width: 768px) {\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: 1fr;\n    padding: 24px 16px;\n  }\n  //display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto;\n  gap: 24px;\n  align-items: center;\n  padding: 24px 0;\n  min-height: 700px;\n  & .card {\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
