import { Button } from 'components/atoms/button';
import { ModalContentFixed } from 'components/organisms/terms-and-conditions/terms-modal/styled';
import { BspotModal } from 'components/atoms/modal/modal';

type TermsModalProps = {
  onCloseModal?(): void;
  show: boolean;
  tncRequired: boolean;

  terms?: any;
};

export const TermsModal = ({
  onCloseModal,
  show,
  tncRequired,
  terms,
}: TermsModalProps) => {
  const closeModal = () => {
    onCloseModal();
  };

  return (
    tncRequired && (
      <BspotModal show={show}>
        <h2 className={'my-0'}>{"We've updated our T&Cs"}</h2>
        <ModalContentFixed>
          <div
            className={'text-left'}
            dangerouslySetInnerHTML={{ __html: terms }}
          />
        </ModalContentFixed>
        <div className={'d-flex flex-row gap-2 '}>
          <Button text='Close' onClick={closeModal} />
        </div>
      </BspotModal>
    )
  );
};
