import React from 'react';
import SkeletonTable from 'components/organisms/buy-credits/modals/wager-details/details-table/tables/skeleton';
import { toCurrency } from 'common/bspot-shared';

import 'components/organisms/see-wager-details/modals/styles.scss';
const Scroll = (props) => {
  return (
    <div id='scroll' style={{ overflowY: 'scroll' }}>
      {props.children}
    </div>
  );
};
type WagerBonusTableProps = {
  bonusAmount: any;
};
export const WagerBonusTable = ({ bonusAmount }: WagerBonusTableProps) => {
  return (
    <Scroll
      className={'py-2 pt-4'}
      style={{ height: '100px', overflowY: 'hidden' }}
    >
      {bonusAmount ? (
        <table>
          <thead className='table-header'>
            <tr>
              <th className={'fixed-width text-start'}>DETAIL</th>
              <th className={'fixed-width text-center'}>WAGER</th>
              <th className={'fixed-width text-end'}>WON/CR</th>
            </tr>
          </thead>
          <tbody className={''}>
            <tr className='table-row'>
              <td className='detail fixed-width'>
                <span data-testid={`bonus-payout`} className={''}>
                  Bonus Payouts
                </span>
              </td>
              <td className={'fixed-width text-center'}>--</td>
              <td className='fixed-width winnings text-end'>
                {toCurrency(bonusAmount)}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <SkeletonTable />
      )}
    </Scroll>
  );
};

export default WagerBonusTable;
