import { getUserStatus, Profile } from 'services/auth/login';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { Balances } from 'hooks/use-credits/use-credits';

const getRgsCredits = async (session: Profile) => {
  let headers: any = {
    Accept: '*/*',
    'x-player-id': session.playerId,
    'x-gpn-pam-player-id': session.playerId,
    'x-session-id': session.sessionId,
    'x-gpn-pam-session-token': session.playerSessionToken,
  };
  const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/players/${session.playerId}`;
  // TODO: wait for the RGS api call to be fixed, need to get all fields
  // const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/balances`;
  const request = {
    method: 'GET',
    headers: headers,
  };
  const response = await fetch(url, request);
  if (!response.ok) throw new Error('Failed to retrieve balances');
  const data = await response.json();
  return data;
};

export const getCredits = async (session: Profile): Promise<Balances> => {
  const rgsCredits = await getRgsCredits(session);
  const userStatus = await getUserStatus();

  localStorage.setItem(
    'deposit_count',
    JSON.stringify(
      FeatureFlagsService?.featureFlags?.depositCountRgs
        ? rgsCredits.deposits_count
        : userStatus.total_deposit_count,
    ),
  );
  localStorage.setItem(
    'credits_purchased_count',
    JSON.stringify(rgsCredits.credits_purchased_count),
  );

  return {
    deposits_count: FeatureFlagsService?.featureFlags?.depositCountRgs
      ? rgsCredits.deposits_count
      : userStatus.total_deposit_count,
    credits: rgsCredits.credits,
    credits_pending: rgsCredits.credits_pending,
    credits_purchased_count: rgsCredits.credits_purchased_count,
    credits_last_order: rgsCredits.credits_last_order,
  };
};
