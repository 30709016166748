import styled from '@emotion/styled';
import React from 'react';

export const DivFlex = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: left;
  a {
    color: var(--primary-color);
  }

  .img-responsive {
    max-width: 100%;
    height: auto;
  }
`;

export type CmsContentProps = {
  title: string;
  content: string;
};

export function CmsContent({ title, content }: CmsContentProps) {
  return (
    <DivFlex>
      <div className={'container pb-3 pb-lg-5'}>
        <div className='row justify-content-center align-items-center flex-grow-1'>
          <div className='col col-12 col-md-10 col-lg-12'>
            <h1 className={'text-center'}>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      </div>
    </DivFlex>
  );
}
