import './index.scss';
import { ReactNode } from 'react';
import { FadeInContainer } from '../../../../../pages/make-deposit/styled';

type FlipBoxProps = {
  front: ReactNode;
  back: ReactNode;
  flip: boolean;
};
export const FlipBox = ({ front, back, flip }: FlipBoxProps) => {
  return (
    <div className={`switch-view`}>
      {!flip && <FadeInContainer>{front}</FadeInContainer>}
      {flip && <FadeInContainer>{back}</FadeInContainer>}
    </div>
  );
};
