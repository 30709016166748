import React from 'react';
import { SvgProps } from './svg';

export function ArrowRight({
  size,
  width = '1rem',
  height = '1rem',
  fill = 'none',
  onClick,
  className,
}: SvgProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      fill={fill}
      onClick={onClick}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_3_5664)'>
        <path
          d='M13 8C13 8.46667 12.8 8.86667 12.4667 9.13333L5.00003 15.7333C4.46669 16.2 3.66669 16.1333 3.26669 15.6C2.86669 15.0667 2.86669 14.3333 3.40003 13.8667L9.93336 8.13333C10 8.06667 10 8 9.93336 7.86667L3.40003 2.13334C2.86669 1.66667 2.86669 0.866668 3.33336 0.400002C3.80003 -0.0666651 4.53336 -0.133332 5.06669 0.266668L12.5334 6.8C12.8 7.13333 13 7.53333 13 8Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_3_5664'>
          <rect width='16' height='16' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}
