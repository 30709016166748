import React from 'react';
import { ModalContentTableFlex } from 'components/organisms/buy-credits/modals/wager-details/details-table/tables/styled';
import SkeletonTable from 'components/organisms/buy-credits/modals/wager-details/details-table/tables/skeleton';
import { RaceOdds } from 'services/get-game-products/get-game-products';

type OddsTableProps = { raceOdds: RaceOdds };

export const OddsTable = ({ raceOdds }: OddsTableProps) => {
  return (
    <ModalContentTableFlex style={{ overflowY: 'scroll' }}>
      {raceOdds ? (
        <table>
          <thead className='table-header'>
            <tr>
              <th>{raceOdds.track_name + ' - RACE ' + raceOdds?.race}</th>
              <th></th>
              <th>{raceOdds.event_date}</th>
            </tr>
          </thead>
          <tbody>
            {raceOdds?.entries?.map((row, i) => (
              <tr className='table-row' key={i}>
                <td className='td-flex'>
                  <div className='position'>{i + 1}</div>
                  <div className='names'>
                    <p>{row.horse_name}</p>
                    <p className='jockey-name'>{row.jockey_name}</p>
                  </div>
                </td>
                <td />
                <td className='td-flex'>
                  <div className='chance'>{row.odds_value}</div>
                  <div className='ml'>
                    <p>M/L</p>
                    <p>{row.morning_line_odds_value}</p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <SkeletonTable />
      )}
    </ModalContentTableFlex>
  );
};

export default OddsTable;
