import { useState } from 'react';
import styled from '@emotion/styled';
import WagerTable from 'components/organisms/buy-credits/modals/wager-details/details-table/tables/wager-table';
import OddsTable from 'components/organisms/buy-credits/modals/wager-details/details-table/tables/odds-table';
import {
  RaceOdds,
  WagerDetails,
} from 'services/get-game-products/get-game-products';

export const ButtonFilterRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ButtonFilter = styled.div`
  background-color: #ffffff;
  padding: 4px 16px;
  border: 1px solid black;
  cursor: pointer;
  color: black;
  &.selected {
    background-color: #0aeb88;
  }
  &.btn_first {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  &.btn_last {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  @media screen and (max-width: 400px) {
    font-size: 10px;
    padding: 4px 12px;
  }
`;

export type DetailsTableProps = {
  product: any;
  all: {
    wagerDetails: WagerDetails;
    raceOdds: RaceOdds;
  };
};

const DetailsTable = ({ product, all }: DetailsTableProps) => {
  const [table, setTable] = useState<'wagers' | 'odds'>('wagers');

  const toggleOdds = () => {
    setTable('odds');
  };
  const toggleWagers = () => {
    setTable('wagers');
  };

  return (
    <>
      <ButtonFilterRow className={'flex-grow-1 mx-auto pb-3'}>
        <ButtonFilter
          className={table === 'wagers' ? 'btn_first selected' : 'btn_first'}
          onClick={() => toggleWagers()}
        >
          {'Your $' + product.cost + ' breakdown'}
        </ButtonFilter>
        <ButtonFilter
          className={table === 'odds' ? 'btn_last selected' : 'btn_last'}
          onClick={() => toggleOdds()}
        >
          The Race Odds
        </ButtonFilter>
      </ButtonFilterRow>
      {table === 'wagers' ? (
        <WagerTable wagerDetails={all?.wagerDetails} />
      ) : (
        <OddsTable raceOdds={all?.raceOdds} />
      )}
    </>
  );
};

export default DetailsTable;
