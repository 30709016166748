import { QueryClient, useMutation } from '@tanstack/react-query';
import { Api } from 'common/api';

const postDepositLimit = async (body) => {
  const { ok, data } = await Api.post<any>(
    `${API_HOST}/api/strive-proxy/railsapi/v1/user/settings/set_deposit_limit`,
    {
      data: body,
    },
  );

  if (!ok && data.Message) throw new Error(data.Message);

  return data;
};

export const usePostDepositLimit = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: postDepositLimit,
    onSuccess: () => {
      queryClient.invalidateQueries(['use-play-limits']);
    },
  });
};
