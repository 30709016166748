import { Close, LocationPin } from 'icons/svg';
import { Button, OutlinedButton } from 'components/atoms/button';
import { BspotModal } from 'components/atoms/modal/modal';
import React from 'react';

type NoLocationAvailableModalProps = {
  onClose?(): void;
  show: boolean;
};
export const NoLocationAvailableModal = ({
  onClose,
  show,
}: NoLocationAvailableModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Location unavailable'}</h2>
      </div>
      <div className={'d-flex flex-column gap-1'}>
        <div
          className={
            'd-flex flex-row justify-content-center py-2 gap-2 align-items-center'
          }
        >
          <LocationPin size={'56'} />
        </div>
        <div>
          {' '}
          The acquisition of your geolocation failed. Please make sure you
          accepted to share your location and try again.
        </div>
      </div>
      <div className={'d-flex flex-row gap-2'}>
        <Button text='Ok, got it!' onClick={onCloseModal} />
      </div>
    </BspotModal>
  );
};
export default NoLocationAvailableModal;
