import { ReactNode } from 'react';
import { CircularLoader } from 'components/atoms/circular-loader';
import { LogoHorseplayDesktop } from 'icons/svg/logo-horseplayDesktop';

export const AppLoader = ({
  children,
  fullHeight = true,
}: {
  children?: ReactNode;
  fullHeight?: boolean;
}) => {
  return (
    <div
      className='container'
      style={{
        display: 'flex',
        height: fullHeight ? '100vh' : 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LogoHorseplayDesktop />
      <CircularLoader size='2em' color='white' />
      {children && children}
    </div>
  );
};
