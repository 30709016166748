import { useSearchParams } from 'react-router-dom';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuth } from '../authentication';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags';
import { useGetCredits } from 'hooks/use-credits/use-credits';

export const GameSdkContext = createContext(null);

export enum SdkActions {
  IDLE = 'idle',
  START_PLAY = 'start-play',
  TOKEN_REVEALED = 'token-revealed',
  PURCHASE_CREDITS = 'purchase-credits',
  DEPOSIT_CASH = 'deposit-cash',
}
export const useGameSdk = () => useContext(GameSdkContext);

// const channel = new MessageChannel();
// const port1 = channel.port1;

export const GameSdkProvider = ({ children }) => {
  const { auth } = useAuth();
  const { data: balances, refetch, isLoading } = useGetCredits();
  const [prevValueCredits, setPrevValueCredits] = useState<number>();
  const [sdkCredits, setSdkCredits] = useState<number>();
  const [sdkWager, setSdkWager] = useState<number>();
  const [sdkAction, setSdkAction] = useState<SdkActions>(SdkActions.IDLE);
  const [sdkSpinsRemaining, setSdkSpinsRemaining] = useState(null);
  const [gameId, setGameId] = useState(null);
  const [sdkStatus, setSdkStatus] = useState<'enabled' | 'disabled'>(
    'disabled',
  );
  const isLoadingCreditsButton = isLoading || balances?.credits_pending > 0;
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    return () => {
      window.Bspot.channel = null;
      localStorage.removeItem(`cred-${auth?.session?.playerId}`);
    };
  }, []);

  useEffect(() => {
    localStorage.removeItem(`gc-channel`);
  }, []);

  useEffect(() => {
    if (searchParams.has('gameId')) {
      setGameId(searchParams.get('gameId'));
    }
  }, [searchParams]);

  const logger = (data) => {
    console.log('[bspot SDK - Game Container] ', JSON.stringify(data));
  };

  useEffect(() => {
    if (balances?.credits) {
      setSdkCredits(balances?.credits);
      // console.log('[Game Container] setting credits ==>', balances.credits);
    }
  }, [balances?.credits]);

  const messageHandler = useCallback(
    (event) => {
      const json = JSON.parse(event.data);
      logger(`Message ${json.action}`);
      setSdkAction(json.action);
      if (json.action === SdkActions.START_PLAY) {
        // get credits
        const oldCreditsStorage =
          JSON.parse(localStorage.getItem(`cred-${auth?.session?.playerId}`)) ||
          balances?.credits;

        refetch().then((res) => {
          let oldCredits;
          if (oldCreditsStorage) {
            oldCredits = oldCreditsStorage;
          } else {
            oldCredits = sdkCredits;
          }
          const wager = oldCredits - res.data.credits;
          setSdkWager(wager);
          setSdkSpinsRemaining(Math.floor(res.data.credits / wager));
          setSdkCredits(res.data.credits);
          localStorage.setItem(
            `cred-${auth.session.playerId}`,
            res.data?.credits.toString(),
          );
        });
      }
    },
    [sdkCredits, refetch, auth.session?.playerId, balances?.credits],
  );

  useEffect(() => {
    if (localStorage.getItem('gc-channel')) {
      return;
    }
    const initializeSdk = (event) => {
      const { data } = event;
      if (typeof data !== 'object') {
        try {
          const json = JSON.parse(data);
          if (!json?.jwt) return;
          const { jwt } = json;
          const token = jwt?.split('.')[1];
          const jwtData = token ? JSON.parse(atob(token)) : null;
          const game = jwtData?.app?.uid;
          const playerId = jwtData?.user?.auth_user_id;
          // Check if game and playerId match

          if (
            game === gameId &&
            +playerId === +auth.session.playerId &&
            !localStorage.getItem('gc-channel')
          ) {
            // Set up message channel
            window.Bspot.channel = new MessageChannel();
            window.Bspot.channel.port1.onmessage = messageHandler;
            setSdkStatus('enabled');
            // Send initialization message to iframe
            const frame = document.getElementById('iframe-game');
            frame['contentWindow'].postMessage(jwt, '*', [
              window.Bspot.channel.port2,
            ]);
            localStorage.setItem('gc-channel', 'true');
            logger(
              '===========================================================',
            );
            logger('LIBERTY <----> GAME-SDK : MESSAGE CHANNEL SUCCESS!!');
            logger(
              '===========================================================',
            );
          }
        } catch (error) {
          logger('initializer PostMessage error ' + JSON.stringify(error));
        }
      }
    };

    // Add event listener to init sdk
    if (
      FeatureFlagsService?.featureFlags?.enableSdkComms &&
      !localStorage.getItem('gc-channel')
    ) {
      if (window.Bspot.channel) {
        return;
      }
      window.addEventListener('message', initializeSdk);
    }
    // Remove event listener and close message channel on unmount
    return () => {
      window.removeEventListener('message', initializeSdk);
      localStorage.removeItem(`gc-channel`);
    };
  }, [auth.session?.playerId, gameId, messageHandler]);

  // Check if credits have been added to account
  useEffect(() => {
    if (
      FeatureFlagsService?.featureFlags?.enableSdkComms &&
      balances?.credits
    ) {
      if (balances?.credits > prevValueCredits || !prevValueCredits) {
        setSdkCredits(balances.credits);
        setSdkSpinsRemaining(null);
        localStorage.setItem(
          `cred-${auth.session.playerId}`,
          balances.credits.toString(),
        );
        setSdkAction(SdkActions.IDLE);
        const action = JSON.stringify({ action: 'order-completed' });

        window.Bspot?.channel?.port1.postMessage(action);
        window.Bspot?.channel && logger('Send postMessage port1 ==> ' + action);
      }
      setPrevValueCredits(balances?.credits);
    }

    return () => {};
  }, [balances?.credits, prevValueCredits, auth.session?.playerId]);

  return (
    <GameSdkContext.Provider
      value={{
        sdkAction,
        sdkCredits,
        sdkStatus,
        sdkWager,
        setSdkAction,
        sdkSpinsRemaining,
        isLoadingCreditsButton,
      }}
    >
      {children}
    </GameSdkContext.Provider>
  );
};
