import './terms-of-use.css';
import { useTerms } from 'hooks/use-content';
import { CmsContent } from 'components/organisms/cms/strive-content-page/cms-content';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'common/utils';

export default function TermsOfUse() {
  const { data: terms } = useTerms();

  const pageTitle = 'Terms of Use - ' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <CmsContent content={terms?.content} title={terms?.title} />
    </>
  );
}
