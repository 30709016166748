import { Group } from 'pages/account-history/styled';
import React from 'react';
import { Button, OutlinedButton } from 'components/atoms/button';
import PlayAllButton from 'components/organisms/play-all/play-all-button';
import { MaxWidth, useMediaQuery } from 'hooks/use-media-query';

export type PaginatorProps = {
  page: number;
  totalPages: any;
  onClickPrevious?(): void;
  onClickNext?(): void;
};

export function Paginator({
  page,
  totalPages,
  onClickPrevious,
  onClickNext,
}: PaginatorProps) {
  return (
    <div className={'d-flex gap-2 justify-content-start py-4'}>
      {totalPages > 1 && (
        <OutlinedButton
          onClick={onClickPrevious}
          disabled={page === 1}
          text={'<<'}
        ></OutlinedButton>
      )}
      {totalPages > page && (
        <Button
          text={'Load More'}
          onClick={onClickNext}
          disabled={page === totalPages}
        />
      )}
    </div>
  );
}
