import React from 'react';
import { useStateRef } from 'hooks/use-state-ref';

export enum MaxWidth {
  Small = `(max-width: 576px)`,
  Medium = `(max-width: 768px)`,
  Large = `(max-width: 992px)`,
}

export enum Orientation {
  Landscape = '(orientation: landscape)',
  Portrait = '(orientation: portrait)',
}

export const useMediaQuery = (
  mediaQuery: string,
  onChange?: (e: MediaQueryListEvent) => void,
): [boolean, React.MutableRefObject<boolean>] => {
  const [matched, setMatched, matchedRef] = useStateRef<boolean>(false);

  const onMatchChange = React.useCallback(
    (e: MediaQueryListEvent) => {
      typeof onChange === 'function' && onChange(e);
      setMatched(e.matches);
    },
    [setMatched, onChange],
  );

  React.useEffect(() => {
    const mql = window.matchMedia(`only screen and ${mediaQuery}`);
    setMatched(mql.matches);
    mql.addEventListener('change', onMatchChange);

    return () => {
      mql.removeEventListener('change', onMatchChange);
    };
  }, [onMatchChange]);

  return [matched, matchedRef];
};
