import { useQuery } from '@tanstack/react-query';
import {
  getTournamentLeaderboard,
  getTournamentLeaderboardV2,
} from 'services/tournaments/tournaments';

export const useLeaderBoard = (tournamentId, playerId, leaderboardName) => {
  return useQuery({
    queryKey: [`leaderboard-${tournamentId}`, playerId],
    queryFn: async () =>
      getTournamentLeaderboard(tournamentId, playerId, leaderboardName),
    refetchInterval: 15000,
  });
};

export const useNewLeaderBoard = (
  tournamentId,
  rewards,
  playerId,
  isOptedIn,
  leaderboardName,
) => {
  return useQuery({
    queryKey: [`leaderboard-${tournamentId}`, playerId],
    queryFn: async () =>
      getTournamentLeaderboardV2(
        rewards,
        tournamentId,
        playerId,
        isOptedIn,
        leaderboardName,
      ),
    refetchInterval: 15000,
  });
};
