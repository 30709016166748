import { Group } from 'pages/account-history/styled';
import { useState } from 'react';
import { Paper } from '@mui/material';
import { Button } from 'components/atoms/button';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DatePicker,
  DatePicker as XDatePicker,
} from '@mui/x-date-pickers/DatePicker/DatePicker';
import { MaxWidth, useMediaQuery } from 'hooks/use-media-query';

export type SearchFilterItemProps = {
  onClickSearch?(dateTo: string, dateFrom: string): void;
  dateFrom: string;
  dateTo: string;
};
export const GroupFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > * {
    flex: 1 0 0;
  }
`;
export function SearchFilterItem({
  onClickSearch,
  dateFrom: initialDateFrom,
  dateTo: initialDateTo,
}: SearchFilterItemProps) {
  const [dateFrom, setDateFrom] = useState(initialDateFrom);
  const [dateTo, setDateTo] = useState(initialDateTo);

  const handleOnChangeFrom = (e: string) => {
    setDateFrom(e);
  };

  const handleOnChangeTo = (e: string) => {
    setDateTo(e);
  };

  const onClickSearched = () => {
    // e.preventDefault();

    if (dateTo && dateFrom) {
      onClickSearch(dateTo, dateFrom);
    }
  };
  const { handleSubmit, control, register } = useForm({
    defaultValues: { dateFrom: '', dateTo: '' },
  });
  const [isLarge] = useMediaQuery(MaxWidth.Large);
  register('dateFrom');
  register('dateTo');

  return (
    <form>
      <div className={'d-flex flex-lg-row flex-column gap-2'}>
        <div className={'col-lg-4 col-12 '}>
          <DatePicker
            sx={{
              backgroundColor: '#383838',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#383838',
                },
              },
              borderRadius: '4px',
            }}
            className={''}
            slotProps={{ textField: { size: 'small', fullWidth: true } }}
            label='From'
            defaultValue={null}
            onChange={(newValue) => handleOnChangeFrom(newValue)}
          />
        </div>
        <div className={'d-none d-sm-block clearfix'}></div>
        <div className={'col-lg-4 col-12'}>
          <DatePicker
            slotProps={{ textField: { size: 'small', fullWidth: true } }}
            sx={{
              backgroundColor: '#383838',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#383838',
                },
              },
              borderRadius: '4px',
            }}
            label='To'
            defaultValue={null}
            onChange={(newValue) => handleOnChangeTo(newValue)}
          />
        </div>

        <div className={'col-lg-4 col-12'}>
          <Button text='Search' onClick={handleSubmit(onClickSearched)} />
        </div>
      </div>
    </form>
  );
}
