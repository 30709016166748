import React from 'react';
import { SvgProps } from './svg';

export function Warning({ size, width = '12', fill = '#D57300' }: SvgProps) {
  return (
    <svg
      data-testid='warning-svg'
      width={size || width}
      height={size || width}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7264_1200)'>
        <path
          d='M11.9399 10.8849L6.43991 0.619935C6.39706 0.540559 6.33355 0.474251 6.25609 0.428037C6.17863 0.381822 6.09011 0.357422 5.99991 0.357422C5.90971 0.357422 5.82119 0.381822 5.74373 0.428037C5.66627 0.474251 5.60275 0.540559 5.55991 0.619935L0.0599072 10.8849C0.0160231 10.9609 -0.00708008 11.0472 -0.00708008 11.1349C-0.00708008 11.2227 0.0160231 11.3089 0.0599072 11.3849C0.104161 11.4596 0.167077 11.5214 0.242469 11.5644C0.317862 11.6073 0.403136 11.6299 0.489907 11.6299H11.4999C11.5867 11.6299 11.672 11.6073 11.7473 11.5644C11.8227 11.5214 11.8857 11.4596 11.9299 11.3849C11.9753 11.3098 12.0002 11.224 12.0019 11.1363C12.0037 11.0485 11.9823 10.9618 11.9399 10.8849ZM10.5649 10.5599C10.5537 10.5793 10.5377 10.5955 10.5184 10.6069C10.4992 10.6183 10.4773 10.6245 10.4549 10.6249H1.54491C1.52253 10.6245 1.50064 10.6183 1.48137 10.6069C1.4621 10.5955 1.44609 10.5793 1.43491 10.5599C1.42484 10.5415 1.41957 10.5209 1.41957 10.4999C1.41957 10.479 1.42484 10.4583 1.43491 10.4399L5.88991 2.11993C5.90062 2.10009 5.9165 2.08351 5.93586 2.07196C5.95523 2.06041 5.97736 2.05431 5.99991 2.05431C6.02246 2.05431 6.04459 2.06041 6.06395 2.07196C6.08332 2.08351 6.0992 2.10009 6.10991 2.11993L10.5649 10.4399C10.575 10.4583 10.5802 10.479 10.5802 10.4999C10.5802 10.5209 10.575 10.5415 10.5649 10.5599Z'
          fill={fill}
        />
        <path
          d='M6.125 7.85485C6.25761 7.85485 6.38479 7.80218 6.47855 7.70841C6.57232 7.61464 6.625 7.48746 6.625 7.35485V4.72485C6.625 4.59225 6.57232 4.46507 6.47855 4.3713C6.38479 4.27753 6.25761 4.22485 6.125 4.22485C5.99239 4.22485 5.86521 4.27753 5.77145 4.3713C5.67768 4.46507 5.625 4.59225 5.625 4.72485V7.35485C5.625 7.42051 5.63793 7.48553 5.66306 7.5462C5.68819 7.60686 5.72502 7.66198 5.77145 7.70841C5.81788 7.75484 5.873 7.79167 5.93366 7.81679C5.99432 7.84192 6.05934 7.85485 6.125 7.85485Z'
          fill={fill}
        />
        <path
          d='M6.125 9.72998C6.53921 9.72998 6.875 9.39419 6.875 8.97998C6.875 8.56577 6.53921 8.22998 6.125 8.22998C5.71079 8.22998 5.375 8.56577 5.375 8.97998C5.375 9.39419 5.71079 9.72998 6.125 9.72998Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_7264_1200'>
          <rect width={size || width} height={size || width} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
