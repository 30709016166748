import styled from '@emotion/styled';

export const SkeletonContainer = styled.div`
  .skeleton-table {
    box-sizing: border-box;
    border-collapse: collapse;
    color: white;
    width: 100%;
  }

  .table-header {
    padding: 8px;
    border-radius: 10px 10px 0 0;
    background-color: rgba(68, 68, 68, 1);
    display: table-caption;
    top: -10px;
  }
  .skeleton-table th,
  .skeleton-table td {
    padding: 8px;
    flex: 1;
  }
  .table-row {
    border-bottom: 1px solid rgba(68, 68, 68, 1);
    display: flex;
    justify-content: space-between;
  }
  /* Define the skeleton animation */
  @keyframes skeleton-loading {
    0% {
      background-color: rgba(255, 255, 255, 0);
    }
    50% {
      background-color: rgba(255, 255, 255, 0.2);
    }
    100% {
      background-color: rgba(255, 255, 255, 0);
    }
  }
  /* Apply the skeleton animation to the table cells */
  .skeleton-table th,
  .skeleton-table td {
    animation: skeleton-loading 3s infinite;
  }
`;
export const SkeletonTable = () => {
  return (
    <SkeletonContainer style={{ height: '300px' }}>
      <table className='skeleton-table'>
        <thead className='table-header'>
          <tr>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <tr className='table-row' key={item}>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          ))}
        </tbody>
      </table>
    </SkeletonContainer>
  );
};

export default SkeletonTable;
