import { FadeInContainer } from 'pages/make-deposit/styled';
import React from 'react';
import { AppLoader } from 'components/molecules/app-loader/app-loader';

export default function MakeDepositPending() {
  return (
    <FadeInContainer className='d-flex col-12 flex-column mx-auto pb-3'>
      <div
        className=''
        style={{
          display: 'flex',
          padding: '150px 0',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AppLoader fullHeight={false} />
      </div>
    </FadeInContainer>
  );
}
