import React from 'react';
import { Close, Spinner } from 'icons/svg';
import { BspotModal } from 'components/atoms/modal/modal';
import { InCenter } from 'pages/account-history/styled';

type SeeWagerDetailsModalProps = {
  onClose?(): void;
  transactions: any;
  show: boolean;
};
export const WagerDetailsLoadingModal = ({
  onClose,
  show,
}: SeeWagerDetailsModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Wager Details'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>
      <div className={'d-flex flex-column gap-1'}>
        <div>
          <InCenter isLoading={true} className='mx-auto py-2'>
            <Spinner size='4em' color='white' />
          </InCenter>
        </div>
      </div>
    </BspotModal>
  );
};
export default WagerDetailsLoadingModal;
