import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Routes } from 'common/routes';
import { CreationBlocked } from 'components/organisms/registration/creation-blocked';
import { useAuth } from 'contexts/authentication';
import { NewUserRegistration } from 'services/auth/register';
import { BreakPoints, bp } from 'common/theme';
import { HelpSection } from 'components/molecules/help-section';
import { SingleCreationForm } from './single-creation-form';
import { useNavigate } from 'react-router-dom';
import { PixelActions, sendPixelEvent } from 'services/_fb-pixel/pixel.service';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
import { useSdk } from 'contexts/sdk/sdk';

import { Helmet } from 'react-helmet-async';
import { GA_EVENTS, sendGTMEvent } from 'services/_gtm-analytics/gtm-analytics';
import { CONFIG, getCookie, setExpireCookie } from 'common/utils';
import {
  sendAppsFlyerEvent,
  setAppsFlyerUserEvent,
} from 'services/_af-events/appsflyer';

const FormContainer = styled.div`
  flex-grow: 1;
  padding: 40px 0;
  max-width: ${BreakPoints.sm};
  width: 100%;
  position: relative;
  overflow: hidden;
  @media ${bp.xl} {
    margin-left: 100px;
  }
`;

const StyledHelpSection = styled(HelpSection)`
  width: 300px;
  @media ${bp.lg} {
    padding-top: 80px;
  }
`;

const defaultValues = {
  newsletter: true,
  insta_play: true,
};

export default function CreateAccount() {
  const [showBlockedNotification, setShowBlockedNotification] = useState(false);
  const navigate = useNavigate();
  const { getEmbedded } = useSdk();
  const { signUp, auth } = useAuth();
  const [loading, setLoading] = useState(false);

  const scrollToTop = () => {
    focusRef.current?.scrollIntoView(true);
  };

  const submitRegistration = (form: NewUserRegistration) => {
    setLoading(true);

    const dob = new Date(form.date_of_birth);
    const date_of_birth = `${dob.getFullYear()}-${(
      '0' +
      (dob.getMonth() + 1)
    ).slice(-2)}-${('0' + dob.getDate()).slice(-2)}`;

    const formWithTag = {
      ...form,
      date_of_birth,
      insta_play: true,
      bTag: getCookie('btag'),
      trackingTag: getCookie('trackingTag'),
    };

    signUp(formWithTag).catch((e) => {
      setLoading(false);
      setShowBlockedNotification(true);
      scrollToTop();
      postNativeMessage(
        BspotCashierMessage.REGISTER_ERROR,
        'Error register user ' + e.message,
      );
      sendGTMEvent({
        event: GA_EVENTS.SIGN_UP,
        status: 'failure',
        method: 'Liberty - BFF',
        appId: 'Liberty - webview',
      });
    });
  };
  const handleSignInSuccess = () => {
    const isKycFail = auth.status.validator_status !== 1;
    setLoading(false);
    setExpireCookie('btag');
    setExpireCookie('trackingTag');
    sendPixelEvent(PixelActions.REGISTER, {
      status: 'APPROVED',
    });
    sendGTMEvent({
      event: GA_EVENTS.SIGN_UP,
      status: isKycFail ? 'kyc-failed' : 'success',
      user_id: auth.session.playerId,
      method: 'Liberty - BFF',
      appId: 'Liberty - webview',
    });
    sendAppsFlyerEvent('sign_up', {
      timestamp: new Date().toISOString(),
      user_id: auth.session.playerId,
      status: 'APPROVED',
    });
    setAppsFlyerUserEvent(auth?.session?.playerId.toString());
    if (!getEmbedded) {
      navigate(
        isKycFail
          ? Routes.CreateAccountFail.path
          : Routes.CreateAccountSuccess.path,
      );
    }
  };

  useEffect(() => {
    if (!auth.session) return;

    if (auth.session) handleSignInSuccess();
  }, [auth.session]);

  const focusRef = useRef(null);

  const onclickGotIt = () => {
    setShowBlockedNotification(false);
    scrollToTop();
  };

  const pageTitle = 'Register - ' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <div className={'container'}>
        <div className='d-flex justify-content-center gap-4 flex-wrap'>
          <FormContainer ref={focusRef}>
            {!showBlockedNotification && (
              <SingleCreationForm
                loading={loading}
                onSubmit={submitRegistration}
                defaultValues={defaultValues}
              />
            )}
            {showBlockedNotification && (
              <CreationBlocked onclick={onclickGotIt} />
            )}
          </FormContainer>
          <StyledHelpSection />
        </div>
      </div>
    </>
  );
}
