import { Close } from 'icons/svg';
import MakeDepositPage from 'pages/make-deposit/make-deposit';
import { BspotModal } from 'components/atoms/modal/modal';
import React, { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRemovePaymentQueryParams } from 'hooks/use-remove-payment-query-params/use-remove-payment-query-params';
export type PlayLaterModalProps = {
  onClose?(): void;
  onDepositSuccess?(): void;
  depositResult?: string;
  show: boolean;
  children?: ReactNode;
};
const DepositModal = ({
  onClose,
  show,
  children,
  depositResult,
  onDepositSuccess,
}: PlayLaterModalProps) => {
  const { removeSearchParams } = useRemovePaymentQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const onCloseModal = () => {
    removeSearchParams();
    onClose();
  };

  const onSuccessDeposit = (deposit) => {
    searchParams.set('deposit', deposit);
    setSearchParams(searchParams);
    onDepositSuccess();
  };

  return (
    <BspotModal show={show} smallLg={false}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>
          <span>{'Deposit'}</span>
        </h2>
        <span className={''}>
          <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
        </span>
      </div>
      <div className='d-flex flex-column justify-content-center'>
        <MakeDepositPage
          depositResult={depositResult}
          isModal={true}
          onSuccessDeposit={onSuccessDeposit}
        >
          {children}
        </MakeDepositPage>
      </div>
    </BspotModal>
  );
};
export default DepositModal;
