import { motion } from 'framer-motion';
import './index.scss';
import { GameTileMk2 } from 'components/organisms/game-list/game-tile/game-tile';

export const GameSelect = ({ products }) => {
  return (
    <div className={'game-select container'}>
      <div className={'row p-4 '}>
        {products?.map((product, index) => {
          return (
            <motion.div
              layout
              key={product.id}
              className={'game-select-sub-column col-md-4 col-6 pb-4'}
            >
              <GameTileMk2
                meta={product.meta}
                gameInfo={{
                  available: false,
                  icon_url: product.meta.icon_url,
                  id: product.meta.id,
                  game_id: product.meta.game_id,
                  slug: product.slug,
                }}
                isFull={true}
              />
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};
