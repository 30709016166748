import { Api } from 'common/api';
import { StriveContent } from 'services/content/types';

type ContentTermsAndConditions = {
  name: string;
  structure: {
    'full area': [
      {
        standart: {
          page_title: {
            value: string;
          };
        };
      },
      {
        section: {
          section_content: {
            value: string;
          };
        };
      },
    ];
  };
};

export const getTermsAndConditions = (): Promise<StriveContent> => {
  return Api.get<StriveResponse<ContentTermsAndConditions>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/content/page/terms-and-conditions`,
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Error get terms and conditions');
    }
    return {
      page: res.data.Data.name,
      title: res.data.Data.structure['full area'][0].standart.page_title.value,
      content:
        res.data.Data.structure['full area'][1].section.section_content.value,
    };
  });
};

export const acceptTermsAndConditions = (): Promise<any> => {
  return Api.get<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/accept_tnc`,
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Error accept terms and conditions');
    }
    return res.data.Message;
  });
};
