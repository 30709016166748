import { Close } from 'icons/svg';
import './modal-welcome.scss';
import { useWelcomeContent } from 'hooks/use-welcome-content/use-welcome-content';
import { Routes } from 'common/routes';
import { setExpireCookie } from 'common/utils';
import { useShowFreeplayAward } from 'hooks/use-show-freeplay-award';
import { PERSIST_FREEPLAY_MESSAGE } from 'hooks/use-webpush-listener';
import { setFreeplayEligibility } from 'hooks/use-freeplay-tracker';
import { useEffect } from 'react';
import { Button } from '../../atoms/button';
import { useNavigate } from 'react-router-dom';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { useAuth } from 'contexts/authentication';
import { useWelcomeContentByState } from '../../../hooks/use-welcome-content-by-state/use-welcome-content-by-state';

type ModalWelcomeProps = {
  onclose?(): void;
  show: boolean;
  status: number;
  firstName: string;
  playerId: string;
};
export const ModalWelcome = ({
  onclose,
  show,
  firstName,
  playerId,
}: ModalWelcomeProps) => {
  const { data: welcomeContent = null } = useWelcomeContentByState();

  const showFreeplayAward = useShowFreeplayAward();

  const setCookie = () => {
    setExpireCookie(`${playerId}isRegistered`);
    setFreeplayEligibility(Number(playerId));
    localStorage.setItem(`kyc-success-${playerId}`, 'success');
  };

  // checks if freeplay socket message has been received while the modal was displayed
  const checkFreeplayMessage = () => {
    const message = localStorage.getItem(
      `${PERSIST_FREEPLAY_MESSAGE}-${playerId}`,
    );

    if (!message) return;

    showFreeplayAward(firstName, JSON.parse(message));
    localStorage.removeItem(`${PERSIST_FREEPLAY_MESSAGE}-${playerId}`);
  };

  const onCloseModal = () => {
    setCookie();
    checkFreeplayMessage();
    onclose();
  };

  return (
    <>
      {show && welcomeContent && (
        <WelcomeUI
          welcomeContent={welcomeContent}
          firstName={firstName}
          onclose={onCloseModal}
        />
      )}
    </>
  );
};
export default ModalWelcome;

type ModalWelcomePropsUI = {
  onclose?(): void;
  firstName: string;
  welcomeContent: any;
};

export const WelcomeUI = ({
  onclose,
  firstName,
  welcomeContent,
}: ModalWelcomePropsUI) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const onClose = () => {
    document.body.style.overflow = 'auto';
    onclose();
  };

  return (
    <div className='modal-welcome-overlay'>
      <div className='modal-welcome d-flex align-items-center justify-content-center py-4'>
        <div className='d-flex flex-column gap-2'>
          <div className='close' onClick={onClose}>
            <Close size={'16px'} color={'#ffffff'} />
          </div>
          <h2>
            {welcomeContent?.title} {firstName}!
          </h2>
          <p className='subtitle free-play-amount'>
            <span>{welcomeContent?.subtitle}</span>
          </p>
          <img
            src={welcomeContent?.imageDesktop}
            alt='welcome desktop'
            className='image-desktop'
          />
          <img
            src={welcomeContent?.imageMobile}
            alt='welcome mobile'
            className='image-mobile'
          />
          {welcomeContent?.paragraph && (
            <p className='align-self-center paragraph'>
              {welcomeContent.paragraph}
            </p>
          )}
          {welcomeContent?.paragraph2 && (
            <p className='align-self-center paragraph-2'>
              {welcomeContent.paragraph2}
            </p>
          )}
          <div className='d-flex justify-content-center'>
            <Button
              onClick={() => {
                onclose();
                navigate(
                  welcomeContent?.buttonLink || Routes.MakeDepositPage.path,
                );
              }}
            >
              <span className='mx-3'>{welcomeContent?.buttonText}</span>
            </Button>
          </div>
        </div>
        {FeatureFlagsService?.featureFlags?.enableIncomeAccess && (
          <iframe
            style={{ display: 'none' }}
            src={`https://tracking.bspot.com/Processing/Pixels/Registration.ashx?PlayerID=${auth?.session.playerId}`}
          ></iframe>
        )}
      </div>
    </div>
  );
};
