import { useSearchParams } from 'react-router-dom';

export const useRemovePaymentQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const removeSearchParams = () => {
    const inGamePaymentSuccess = searchParams.get('inGamePaymentSuccess');
    const deposit = searchParams.get('deposit');
    const fromIframe = searchParams.get('fromIframe');
    const nameOnCard = searchParams.get('nameOnCard');
    const inGamePaymentError = searchParams.get('inGamePaymentError');

    if (nameOnCard) {
      searchParams.delete('nameOnCard');
    }
    if (inGamePaymentError) {
      searchParams.delete('inGamePaymentError');
    }
    if (inGamePaymentSuccess) {
      searchParams.delete('inGamePaymentSuccess');
    }
    if (deposit) {
      searchParams.delete('deposit');
    }
    if (fromIframe) {
      searchParams.delete('fromIframe');
    }
    setSearchParams(searchParams);
  };

  return { removeSearchParams };
};
