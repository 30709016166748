import { useQuery } from '@tanstack/react-query';
import { fetchGameProducts } from 'services/get-game-products/get-game-products';
import { useAuth } from 'contexts/authentication';

export const useGameProducts = () => {
  const { auth } = useAuth();
  return useQuery({
    queryKey: ['get-game-products'],
    queryFn: () => fetchGameProducts(auth.session),
    keepPreviousData: true,
    staleTime: 30000,
    placeholderData: { gameProducts: [], instantPlayEligible: false },
    enabled: !!auth?.session?.playerId,
  });
};
