export const Notification = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_9069_6461)'>
      <path
        d='M11.7998 13.6H11.1998C10.8816 13.6 10.5763 13.4736 10.3512 13.2485C10.1262 13.0235 9.9998 12.7182 9.9998 12.4V9.39999C9.9998 9.24087 9.9366 9.08823 9.82404 8.97575C9.71156 8.86319 9.55892 8.79999 9.3998 8.79999H8.7998'
        stroke='#0384DF'
        strokeWidth='2'
        stroke-linecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.6999 6.39999C9.53422 6.39999 9.3999 6.26568 9.3999 6.09999C9.3999 5.9343 9.53422 5.79999 9.6999 5.79999'
        stroke='#0384DF'
        strokeWidth='2'
      />
      <path
        d='M9.7002 6.39999C9.86588 6.39999 10.0002 6.26568 10.0002 6.09999C10.0002 5.9343 9.86588 5.79999 9.7002 5.79999'
        stroke='#0384DF'
        strokeWidth='2'
      />
      <path
        d='M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z'
        stroke='#0384DF'
        strokeWidth='2'
        stroke-linecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_9069_6461'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
