import { Api } from 'common/api';

export const logout = async () => {
  const { data, ok } = await Api.get<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/logout`,
  );

  if (!ok) throw new Error('Unexpected error');

  return data;
};
