import { useAuth } from 'contexts/authentication';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Routes } from 'common/routes';
import { getUserLimitsHistory } from 'services/limits-history/limits-hitory';

export const useLimitsHistory = () => {
  const { auth } = useAuth();
  const location = useLocation();
  return useQuery({
    queryKey: ['profile-info', auth.session?.playerId],
    queryFn: getUserLimitsHistory,
    keepPreviousData: false,
    enabled:
      !!auth.session &&
      location.pathname === Routes.ResponsibleGamingSettings.path,
  });
};
