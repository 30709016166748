import { createContext, PropsWithChildren, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setPersistentCookie } from 'common/utils';
import { useAuth } from 'contexts/authentication';

const BtagContext = createContext(null);

export const BtagContextProvider = ({ children }: PropsWithChildren) => {
  const { auth } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    if (auth.session?.playerId) {
      return;
    }
    const bTag = searchParams.get('btag');
    const trackingTag = searchParams.get('trackingTag');
    if (bTag) {
      setPersistentCookie('btag', bTag, 365);
    }
    if (trackingTag) {
      setPersistentCookie('trackingTag', trackingTag, 365);
    }
    if (searchParams.has('btag')) {
      searchParams.delete('btag');
    }
    if (searchParams.has('trackingTag')) {
      searchParams.delete('trackingTag');
    }
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams, auth.session?.playerId]);

  return (
    <BtagContext.Provider value={'set-bTag-cookie'}>
      {children}
    </BtagContext.Provider>
  );
};
