import { Api } from 'common/api';

export type RequestResetPassword = {
  email: string;
};

export type ResetPassword = {
  new_password: string;
  reset_code: string;
};

export const requestResetPassword = (
  payload: RequestResetPassword,
): Promise<string> => {
  return Api.post<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/login/forgot_password`,
    { data: payload },
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to request reset password');
    }
    return res.data.Message;
  });
};

export const resetPassword = (payload: ResetPassword): Promise<string> => {
  return Api.post<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/login/set_password`,
    { data: payload },
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.data.Message);
    }
    return res.data.Message;
  });
};
