import React from 'react';
import { SvgProps } from './svg';

export function FbSocial({
  size,
  width = '1rem',
  height = '1rem',
  fill = '#282828',
  onClick,
}: SvgProps) {
  return (
    <a
      href='https://www.facebook.com/bspot.horseplay'
      aria-label='Facebook link'
      style={{
        display: 'flex',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: '28px',
        border: '1px solid #4D4D4D',
      }}
    >
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M14.6046 7.49989H11.6671V5.83322C11.6671 4.97322 11.7371 4.43156 12.9696 4.43156H14.5262V1.78156C13.7687 1.70322 13.0071 1.66489 12.2446 1.66656C9.98374 1.66656 8.33374 3.04739 8.33374 5.58239V7.49989H5.83374V10.8332L8.33374 10.8324V18.3332H11.6671V10.8307L14.2221 10.8299L14.6046 7.49989Z'
          fill='white'
        />
      </svg>
    </a>
  );
}
