import React from 'react';
import { SvgProps } from './svg';

export function CrossCircle({
  size,
  width = '1rem',
  height = '1rem',
  fill = 'none',
  color = '#282828',
  onClick,
}: SvgProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      fill={fill}
      onClick={onClick}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.25 8C15.25 12.0041 12.0041 15.25 8 15.25C3.99594 15.25 0.75 12.0041 0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C12.0041 0.75 15.25 3.99594 15.25 8ZM6.85071 4.57786C6.22308 3.95024 5.20549 3.95024 4.57786 4.57786C3.95024 5.20549 3.95024 6.22308 4.57786 6.85071L5.72716 8L4.57786 9.14929C3.95024 9.77692 3.95024 10.7945 4.57786 11.4221C5.20549 12.0498 6.22308 12.0498 6.85071 11.4221L8 10.2728L9.14929 11.4221C9.77692 12.0498 10.7945 12.0498 11.4221 11.4221C12.0498 10.7945 12.0498 9.77692 11.4221 9.14929L10.2728 8L11.4221 6.85071C12.0498 6.22308 12.0498 5.20549 11.4221 4.57786C10.7945 3.95024 9.77692 3.95024 9.14929 4.57786L8 5.72716L6.85071 4.57786Z'
        fill={color}
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
}
