import React from 'react';
import styled from '@emotion/styled';
import 'common/theme/main.scss';
import { bp } from 'common/theme';
import { useAuth } from 'contexts/authentication';
import { LogoCompact, LogoFull } from 'icons/svg';

export enum LogoType {
  Full = 'full',
  Compact = 'compact',
}

const Container = styled.div<{ $type?: LogoType }>`
  display: flex;
  position: relative;
  gap: 10px;
  padding-right: 16px;
  cursor: pointer;

  @media ${bp.md} {
    svg:last-child {
      ${(props) =>
        (props.$type !== LogoType.Compact && 'display: block') || ''};
    }
  }
`;
interface SVG {
  $width?: string;
  $height?: string;
}

const SVG = styled.svg<SVG>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  cursor: pointer;
`;

export type LogoProps = {
  id?: string;
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
  strokeWidth?: string;
  type?: LogoType;
  onClick?(e: React.SyntheticEvent): void;
  navIsOpen?: boolean;
};

export function Logo({
  id,
  width = '24',
  height = '45',
  type,
  onClick,
}: LogoProps) {
  const { auth } = useAuth();

  return (
    <Container id={id} $type={type} onClick={onClick}>
      {type == LogoType.Compact && auth?.session ? (
        <LogoCompact height={height} />
      ) : (
        <LogoFull height={height} />
      )}
    </Container>
  );
}
