import './background-gradient.scss';
import { FC } from 'react';

const dropShadows = {
  silver: '0px 0px 26px rgba(255, 255, 255, 0.20)',
  gold: '0px 0px 26px #EED072',
  platinum: '0px 0px 26px #82A4D3',
  emerald: '0px 0px 26px #35AD87',
  ruby: '0px 0px 26px #F17878',
};

const opaqueGradients = {
  silver: '180deg, #030303 0%, #282828 100%',
  gold: '180deg, #EED072 0%, #E5B056 52.6%, #A27B3C 61.46%, #6A4623 100%',
  platinum:
    '180deg, rgba(3, 3, 3, 1) 0%, rgba(6, 19, 44, 1) 52.6%, rgba(5, 17, 38, 1) 61.46%, rgba(8, 30, 70, 1) 100%',
  emerald: '180deg, #296956 0%, #35AD87 52.6%, #28674C 61.46%, #284D69 100%',
  ruby: '180deg, rgba(184, 34, 43, 0.7) 0%, rgba(149, 39, 39, 0.7) 50.52%, rgba(118, 13, 19, 0.7) 52.6%, rgba(132, 10, 17, 0.7) 100%',
};

const translucentGradients = {
  silver:
    '180deg, rgba(3, 3, 3, 0.1) 0%, rgba(203, 203, 203, 0.1) 50.52%, rgba(90, 90, 90, 0.1) 50.53%, rgba(23, 23, 23, 0.1) 100%',
  gold: '180deg, rgba(238, 208, 114, 0.70) 0%, rgba(106, 70, 35, 0.70) 50.52%, rgba(162, 123, 60, 0.70) 52.6%, rgba(229, 176, 86, 0.70) 100%',
  platinum:
    '180deg, rgba(60, 82, 122, 0.10) 0%, rgba(203, 203, 203, 0.10) 50.52%, rgba(60, 82, 122, 0.10) 50.53%, rgba(130, 164, 211, 0.10) 100%',
  emerald:
    '180deg, rgba(41, 105, 86, 0.70) 0%, rgba(70, 154, 118, 0.70) 50.52%, rgba(40, 103, 76, 0.70) 52.6%, rgba(12, 49, 33, 0.70) 100%',
  ruby: '180deg, rgba(184, 34, 43, 0.7) 0%, rgba(149, 39, 39, 0.7) 50.52%, rgba(118, 13, 19, 0.7) 52.6%, rgba(132, 10, 17, 0.7) 100%',
};

export const BackgroundGradient: FC<{ color: string }> = ({ color }) => {
  return (
    <div className='gradients-wrapper'>
      <div
        className={`${
          color !== 'ruby' ? 'blue-background' : 'ruby-background'
        }`}
        style={{
          filter: `drop-shadow(${dropShadows[color]})`,
        }}
      />
      <div
        style={{
          background: `linear-gradient(${opaqueGradients[color]})`,
        }}
      />
      <div
        className='blurred'
        style={{
          background: `linear-gradient(${translucentGradients[color]})`,
        }}
      />
      <div className='blend' />
    </div>
  );
};
