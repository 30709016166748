import './product-text.scss';
import { FC } from 'react';

const texts = {
  silver: '',
  gold: 'WIN UP TO',
  platinum: 'WIN UP TO',
  emerald: 'WIN UP TO',
  ruby: 'WIN UP TO',
};

const subTexts = {
  silver: "LET'S PLAY",
  gold: '$100,000',
  platinum: '$250,000',
  emerald: '$1,000,000',
  ruby: '$1,000,000',
};

export const ProductText: FC<{
  color: string;
}> = ({ color }) => {
  return (
    <div className='text-wrapper'>
      <span className='top-text'>{texts[color]}</span>
      <span className='bottom-text'>{subTexts[color]}</span>
    </div>
  );
};
