import { Group, LineItem } from 'pages/account-history/styled';
import { Text, TextSize } from 'components/atoms/text';
import React from 'react';

export type PageInfoProps = {
  page: number;
  totalPages: any;
};

export function PageInfo({ page, totalPages }: PageInfoProps) {
  return (
    <Group className={'pt-3'}>
      <LineItem>
        <Text size={TextSize.Body} bold>
          {'Page ' + page}
        </Text>
        <Text size={TextSize.Body} bold>
          {'Total pages '} {totalPages ? totalPages : 0}
        </Text>
      </LineItem>
    </Group>
  );
}
