import { Route } from 'common/routes';
import React from 'react';
import {
  NavigateOptions,
  useNavigate as useRouterNavigate,
} from 'react-router-dom';

interface NavOptions extends NavigateOptions {
  query?: Record<string, string>;
  path?: Record<string, string>;
}

export const useNavigate = () => {
  const navigate = useRouterNavigate();

  return React.useCallback(
    (route: string | Route, options: NavOptions = {}) => {
      if (!route) {
        console.error(`invalid route: ${route}`);
        return;
      }

      let path = typeof route === 'string' ? route : route.path;

      if (!path) {
        console.error(`invalid route: ${route}`);
        return;
      }

      for (const [key, value] of Object.entries(options?.path ?? {})) {
        path = path.replace(`:${key}`, value);
      }

      const query = [];

      for (const [key, value] of Object.entries(options?.query ?? {})) {
        query.push(`${key}=${value}`);
      }

      const queryString = query.length ? `?${query.join('&')}` : '';

      navigate(path + queryString);
    },
    [navigate],
  );
};
