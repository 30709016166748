import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { GameTileMk2, GhostGameTile } from './game-tile/game-tile';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import './game-list.scss';
import { useEffect, useState } from 'react';
import { GameGroup } from '../../../hooks/use-games-list';

type GameListProps = {
  category: GameGroup;
  isXl?: boolean;
};

export const GameList = ({ category, isXl = false }: GameListProps) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }, []);
  return (
    <section className={`game-list ${isXl ? 'xl' : ''} app-container p-0`}>
      <h3 className='app-container category-name'>
        {category?.name || 'category'}
      </h3>
      {category && (
        <div className='app-container p-0'>
          {isTouchDevice && <MobileGames isXl={isXl} category={category} />}
          {!isTouchDevice && (
            <FullScreenGames isXl={isXl} category={category} />
          )}
        </div>
      )}
    </section>
  );
};

const MobileGames = ({ category, isXl }: GameListProps) => {
  const showAsGrid = category.games.length > 14;
  return (
    <div
      className='d-flex position-relative'
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      {!showAsGrid &&
        category.games.map((game) => (
          <div className='slide' key={game.id}>
            <GameTileMk2 isXl={isXl} gameInfo={game} />
          </div>
        ))}
      {showAsGrid &&
        category.games.map((game, index) => {
          const isEvenIndex = index % 2 === 0;
          const secondItem = category.games[index + 1];
          return (
            isEvenIndex && (
              <div className='slide d-flex flex-column' key={game.id}>
                <GameTileMk2 isXl={isXl} gameInfo={game} />
                {secondItem && (
                  <GameTileMk2
                    isXl={isXl}
                    gameInfo={secondItem}
                    withMarginTop
                  />
                )}
              </div>
            )
          );
        })}
    </div>
  );
};

const FullScreenGames = ({ category, isXl }: GameListProps) => {
  const showAsGrid = category.games.length > 14;
  return (
    <Swiper
      className='d-block'
      navigation={{
        prevEl: '.nav-button.prev',
        nextEl: '.nav-button.next',
      }}
      modules={[Navigation]}
      slidesPerView={'auto'}
      slidesPerGroupAuto
      grabCursor
    >
      {!showAsGrid &&
        category.games.map((game) => (
          <SwiperSlide key={game.id} className='slide flex-column'>
            <GameTileMk2 isXl={isXl} gameInfo={game} />
          </SwiperSlide>
        ))}
      {showAsGrid &&
        category.games.map((game, index) => {
          const isEvenIndex = index % 2 === 0;
          const secondItem = category.games[index + 1];
          return (
            isEvenIndex && (
              <SwiperSlide key={game.id} className='slide flex-column'>
                <GameTileMk2 isXl={isXl} gameInfo={game} />
                {secondItem && (
                  <GameTileMk2
                    isXl={isXl}
                    gameInfo={secondItem}
                    withMarginTop
                  />
                )}
              </SwiperSlide>
            )
          );
        })}
      <>
        <button className='nav-button prev' aria-label='previous item'></button>
        <button className='nav-button next' aria-label='next item'></button>
      </>
    </Swiper>
  );
};

export const GhostGames = ({ isXl = false }) => {
  return (
    <div className={'d-flex flex-column my-4 py-4'}>
      <div
        className={'fw-bold d-flex flex-row justify-content-between pb-0 my-0'}
      >
        <div className={'align-self-start'}>
          <img
            alt='ghost image for game'
            src={'/assets/images/banner-placeholder.webp'}
            style={{
              width: '140px',
              height: '16px',
              borderRadius: '4px',
              backgroundColor: '#121212',
            }}
          />
        </div>
      </div>

      <div className='d-flex flex-row gap-4 overflow-hidden position-relative my-3'>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
        <div className='slide '>
          <GhostGameTile isXl={isXl} />
        </div>
      </div>
    </div>
  );
};
