import { css } from '@emotion/react';
import styled from '@emotion/styled';

export type BlurProps = {
  $isVisible?: boolean;
  $animationTime?: number;
};

export const Blur = styled.div<BlurProps>`
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: ${(props) => props.$animationTime || 500}ms ease;

  ${(props) =>
    props.$isVisible
      ? css`
          background: rgba(var(--bg-darkest-color), 0.8);
          backdrop-filter: blur(10px);
        `
      : css`
          pointer-events: none;
        `}
`;
