import { Api } from 'common/api';

type ProfileInfoField = {
  id: string;
  title: string;
  type: string;
  disabled: boolean;
  default: string;
  value: any;
  values: any;
  optional: boolean;
};

type IdentityBlock = {
  id: string;
  title: string;
  data: [keyof string, { value: string; symbol: string }[]] | string[];
  disabled: boolean;
  action?: string;
  fields?: ProfileInfoField[];
};

type EmailBlock = {
  id: string;
  title: string;
  data: string[];
  disabled: boolean;
};

type ProfileInfoDataBlocks = { blocks: (IdentityBlock | EmailBlock)[] };

type UserProfileAddress = {
  addressLine1: string;
  city: string;
  state: string;
  postalCode: string;
};

export type UserProfile = {
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  email: string;
  address: UserProfileAddress;
  phoneNumber: number;
  dob: string;
  ssn: string;
};

const getEmail = (data: EmailBlock): string => {
  return data.data[0];
};

const getFullName = (data: IdentityBlock): string => {
  const firstName = data.fields.filter((it) => it.id === 'first_name')[0]
    .default;
  const middleName = data.fields.filter((it) => it.id === 'middle_name')[0]
    .default;
  const lastName = data.fields.filter((it) => it.id === 'last_name')[0].default;
  return firstName
    .concat(' ')
    .concat(middleName !== null ? middleName.concat(' ') : '')
    .concat(lastName);
};

const getNameFields = (data: IdentityBlock): string[] => {
  const firstName = data.fields.filter((it) => it.id === 'first_name')[0]
    .default;
  const middleName = data.fields.filter((it) => it.id === 'middle_name')[0]
    .default;
  const lastName = data.fields.filter((it) => it.id === 'last_name')[0].default;
  return [firstName, middleName, lastName];
};

const getAddress = (data: IdentityBlock): UserProfileAddress => {
  return {
    addressLine1: data.fields.filter((it) => it.id === 'address')[0].default,
    city: data.fields.filter((it) => it.id === 'city')[0].default,
    state: data.fields.filter((it) => it.id === 'state')[0].default,
    postalCode: data.fields.filter((it) => it.id === 'postal_code')[0].default,
  };
};

const getPhoneNumber = (data: IdentityBlock): number => {
  return +data.fields.filter((it) => it.id === 'phone_number')[0].default;
};

const getDob = (data: IdentityBlock): string => {
  return data.fields.filter((it) => it.id === 'date_of_birth')[0].default;
};
const getSocialSecurityNumber = (data: IdentityBlock): string => {
  return data.fields.filter((it) => it.id === 'social_security_number')[0]
    .default;
};

export const getProfileInfo = (): Promise<UserProfile> => {
  return Api.get<ProfileInfoDataBlocks>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/profile/personal_info`,
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Could not retrieve user personal info');
    }

    const identity = res.data.blocks.filter(
      (it) => it.id === 'identity',
    )[0] as IdentityBlock;
    const email = res.data.blocks.filter(
      (it) => it.id === 'email',
    )[0] as EmailBlock;
    return {
      firstName: getNameFields(identity)[0],
      lastName: getNameFields(identity)[2],
      middleName: getNameFields(identity)[1],
      fullName: getFullName(identity),
      email: getEmail(email),
      address: getAddress(identity),
      phoneNumber: getPhoneNumber(identity),
      dob: getDob(identity),
      ssn: getSocialSecurityNumber(identity),
    };
  });
};

export type ProfileInfoUpdate = {
  address: string;
  city: string;

  state: string;
  date_of_birth: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  password: string;
  phone_number: string;
  postal_code: string;
  social_security_number: string;
  nickname?: string;
  country_of_residence?: string;
  citizenship?: string;
};

export const updateProfileInfo = async (
  payload: ProfileInfoUpdate,
): Promise<any> => {
  const { status, data } = await Api.post<any>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/settings/update_personal_info`,
    { data: payload },
  );
  if (status !== 200) {
    // @ts-ignore
    throw new Error(data.Message || 'Unexpected error');
  }
  return data.Message;
};
