import { SvgProps } from 'icons/svg/svg';

export function Info({ size, fill = 'none', onClick }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      onClick={onClick}
      viewBox='0 0 10 10'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g transform='matrix(1 0 0 1 -101 -14 )'>
        <path
          d='M 0 4.934509803921569  C 0 7.533490196078432  2.238579166666667 9.640392156862745  5 9.640392156862745  C 7.761416666666667 9.640392156862745  10 7.533490196078432  10 4.934509803921569  C 10 2.335529411764706  7.761416666666667 0.22863058823529475  5 0.22863058823529475  C 2.238579166666667 0.22863058823529475  0 2.335529411764706  0 4.934509803921569  Z M 3.4821416666666662 6.951333333333334  C 3.4821416666666662 6.719254901960784  3.6820125000000004 6.531137254901961  3.9285708333333336 6.531137254901961  L 4.553583333333333 6.531137254901961  L 4.553583333333333 5.018549019607843  L 4.285708333333334 5.018549019607843  C 4.039158333333333 5.018549019607843  3.8392874999999997 4.830431372549021  3.8392874999999997 4.5983921568627455  C 3.8392874999999997 4.366313725490197  4.039158333333333 4.178196078431373  4.285708333333334 4.178196078431373  L 5 4.178196078431373  C 5.246541666666666 4.178196078431373  5.446416666666667 4.366313725490197  5.446416666666667 4.5983921568627455  L 5.446416666666667 6.531137254901961  L 6.071416666666667 6.531137254901961  C 6.318 6.531137254901961  6.517875 6.719254901960784  6.517875 6.951333333333334  C 6.517875 7.183372549019608  6.318 7.371490196078431  6.071416666666667 7.371490196078431  L 5 7.371490196078431  L 3.9285708333333336 7.371490196078431  C 3.6820125000000004 7.371490196078431  3.4821416666666662 7.183372549019608  3.4821416666666662 6.951333333333334  Z M 5 2.2454392156862752  C 5.3945 2.2454392156862752  5.714291666666666 2.5464235294117654  5.714291666666666 2.9177058823529416  C 5.714291666666666 3.2889882352941187  5.3945 3.589976470588235  5 3.589976470588235  C 4.6055 3.589976470588235  4.285708333333334 3.2889882352941187  4.285708333333334 2.9177058823529416  C 4.285708333333334 2.5464235294117654  4.6055 2.2454392156862752  5 2.2454392156862752  Z '
          fillRule='nonzero'
          fill={fill}
          stroke='none'
          transform='matrix(1 0 0 1 101 14 )'
        />
      </g>
    </svg>
  );
}
