import React from 'react';
import { SvgProps } from './svg';

export function Instagram({
  size,
  width = '1rem',
  height = '1rem',
  fill = '#282828',
  onClick,
}: SvgProps) {
  return (
    <svg
      data-testid='instagram-icon'
      width={size || width}
      height={size || height}
      viewBox='0 0 17 16'
      fill={fill}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.8333 0H4.16667C3.19475 0.00176232 2.26314 0.388637 1.57589 1.07589C0.888637 1.76314 0.501762 2.69475 0.5 3.66667L0.5 12.3333C0.501762 13.3053 0.888637 14.2369 1.57589 14.9241C2.26314 15.6114 3.19475 15.9982 4.16667 16H12.8333C13.8053 15.9982 14.7369 15.6114 15.4241 14.9241C16.1114 14.2369 16.4982 13.3053 16.5 12.3333V3.66667C16.4982 2.69475 16.1114 1.76314 15.4241 1.07589C14.7369 0.388637 13.8053 0.00176232 12.8333 0V0ZM13.8333 10.6667C13.8333 11.3739 13.5524 12.0522 13.0523 12.5523C12.5522 13.0524 11.8739 13.3333 11.1667 13.3333H5.83333C5.12609 13.3333 4.44781 13.0524 3.94772 12.5523C3.44762 12.0522 3.16667 11.3739 3.16667 10.6667V5.33333C3.16667 4.62609 3.44762 3.94781 3.94772 3.44772C4.44781 2.94762 5.12609 2.66667 5.83333 2.66667H11.1667C11.8739 2.66667 12.5522 2.94762 13.0523 3.44772C13.5524 3.94781 13.8333 4.62609 13.8333 5.33333V10.6667Z'
        fill={fill}
      />
      <path
        d='M8.49967 10.6667C9.97243 10.6667 11.1663 9.47277 11.1663 8.00001C11.1663 6.52725 9.97243 5.33334 8.49967 5.33334C7.02692 5.33334 5.83301 6.52725 5.83301 8.00001C5.83301 9.47277 7.02692 10.6667 8.49967 10.6667Z'
        fill={fill}
      />
      <path
        d='M11.8337 5.33333C12.2018 5.33333 12.5003 5.03486 12.5003 4.66667C12.5003 4.29848 12.2018 4 11.8337 4C11.4655 4 11.167 4.29848 11.167 4.66667C11.167 5.03486 11.4655 5.33333 11.8337 5.33333Z'
        fill={fill}
      />
    </svg>
  );
}
