import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { RegistrationFail } from 'components/organisms/registration/registration-failed';
import { PlayerDisabled } from 'components/organisms/registration/player-disabled';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/authentication';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { Routes } from 'common/routes';
import { getCookie, setExpireCookie } from 'common/utils';
import { useSdk } from 'contexts/sdk/sdk';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
import { ModalWelcome } from 'components';
import { RegistrationSuccessful } from 'components/organisms/registration/registration-success';

type KycType = {
  setShowDisabled?: Dispatch<SetStateAction<boolean>>;
};

const KYCContext = React.createContext<KycType>({});

export const useKyc = () => {
  return useContext(KYCContext);
};

export const KYCContextProvider = (props) => {
  const { getEmbedded } = useSdk();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();
  const { data: status } = usePlayerStatus();

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (localStorage.getItem('isIOS')) return;
    if (showDisabled) return;
    if (!status?.validator_status || !auth.session?.playerId) return;
    const hasRegistered = getCookie(`${auth.session.playerId}isRegistered`);
    if (status?.validator_status === 1 && hasRegistered) {
      setShowError(false);
      // postNativeMessage(
      //   BspotCashierMessage.WELCOME,
      //   'Welcome Register Success Modal',
      // );
      setShowSuccess(true);
      return;
    }
    if (
      status?.validator_status === 5 &&
      location.pathname !== Routes.PlayerInfo.path
    ) {
      postNativeMessage(
        BspotCashierMessage.KYC_FAIL,
        'KYC verification failed : Edit Personal info',
      );
      setShowError(true);
      return;
    }
    if (
      status?.validator_status === 6 &&
      location.pathname !== Routes.DocumentCenter.path
    ) {
      postNativeMessage(
        BspotCashierMessage.KYC_FAIL,
        'KYC verification failed : Upload Documents',
      );
      setShowError(true);
      return;
    }
  }, [
    status?.validator_status,
    auth.session?.playerId,
    location,
    showDisabled,
  ]);

  const onClickSuccess = () => {
    setShowSuccess(false);
    if (!getEmbedded) {
      navigate(Routes.MakeDepositPage.path);
    } else {
      postNativeMessage(
        BspotCashierMessage.CLOSE,
        'CLOSE Welcome Register Success Modal',
      );
    }
  };

  const close = () => {
    setShowSuccess(false);
    if (getEmbedded) {
      postNativeMessage(
        BspotCashierMessage.CLOSE,
        'CLOSE Welcome Register Success Modal',
      );
    }
  };

  return (
    <KYCContext.Provider
      value={{
        setShowDisabled,
      }}
    >
      {props.children}

      <RegistrationSuccessful
        playerId={auth.session?.playerId.toString()}
        firstName={auth.session?.firstName}
        show={showSuccess}
        onclose={close}
        status={status?.validator_status}
        onplayNow={onClickSuccess}
      />

      <RegistrationFail
        show={showError}
        onclose={() => {
          setShowError(false);
          postNativeMessage(BspotCashierMessage.CLOSE, 'CLOSE');
        }}
        status={status?.validator_status}
      />

      <PlayerDisabled
        show={showDisabled}
        onclose={() => {
          setShowDisabled(false);
        }}
        status={status?.validator_status}
      />
    </KYCContext.Provider>
  );
};
