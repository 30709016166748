import { SvgProps } from 'icons/svg/svg';

export function ChipEmerald({
  size,
  width = '120px',
  height = '120px',
  fill = 'none',
  onClick,
}: SvgProps) {
  return (
    <svg
      viewBox='0 0 50 50'
      width={size || width}
      height={size || height}
      fill={fill}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.539 46.7081C25.5489 53.5265 10.2924 49.3309 3.44836 37.3371C-3.3957 25.3433 0.795258 10.0928 12.7884 3.2729C24.7815 -3.54695 40.0426 0.648592 46.8775 12.6439C53.7125 24.6392 49.5306 39.8883 37.539 46.7081Z'
        fill='url(#paint0_linear_10621_388105)'
      />
      <path
        d='M43.0283 16.4887C47.7212 26.3291 43.5241 38.1476 33.673 42.837C28.9337 45.085 23.4952 45.3635 18.5506 43.6113C13.606 41.8591 9.55907 38.2193 7.29756 33.4903C2.60618 23.6514 6.80172 11.8314 16.6528 7.14512C26.504 2.45885 38.3369 6.6483 43.0283 16.4887ZM9.92168 32.2452C11.3584 35.2573 13.657 37.7762 16.5268 39.4833C19.3967 41.1904 22.7088 42.009 26.0445 41.8357C29.3801 41.6623 32.5893 40.5048 35.2664 38.5095C37.9434 36.5141 39.9679 33.7706 41.084 30.6259C42.2 27.4811 42.3574 24.0764 41.5363 20.8423C40.7151 17.6082 38.9523 14.69 36.4708 12.4567C33.9893 10.2234 30.9005 8.77532 27.595 8.29564C24.2896 7.81595 20.916 8.32619 17.9008 9.76181C13.8612 11.6899 10.752 15.1397 9.25529 19.3546C7.75859 23.5695 7.9966 28.2054 9.9171 32.2452H9.92168Z'
        fill='#3E4C52'
      />
      <path
        d='M38.7336 24.9894C38.7339 27.6707 37.9382 30.2918 36.4472 32.5212C34.9561 34.7507 32.8367 36.4885 30.3569 37.5147C27.8771 38.5409 25.1484 38.8094 22.5158 38.2864C19.8832 37.7634 17.465 36.4723 15.567 34.5764C13.6691 32.6805 12.3766 30.2649 11.853 27.6352C11.3294 25.0055 11.5983 22.2797 12.6256 19.8026C13.6529 17.3255 15.3925 15.2084 17.6244 13.719C19.8564 12.2296 22.4803 11.4348 25.1645 11.4351C28.7625 11.4375 32.2124 12.8663 34.7566 15.4077C37.3008 17.9491 38.7312 21.3953 38.7336 24.9894V24.9894Z'
        fill='white'
      />
      <path
        d='M25.1646 38.8289C22.4243 38.8292 19.7456 38.0177 17.467 36.4972C15.1885 34.9767 13.4125 32.8154 12.3636 30.2866C11.3148 27.7579 11.0402 24.9752 11.5746 22.2905C12.109 19.6058 13.4284 17.1398 15.3659 15.2041C17.3034 13.2685 19.7721 11.9503 22.4596 11.4162C25.1471 10.8821 27.9329 11.156 30.4645 12.2035C32.9962 13.2509 35.1601 15.0247 36.6825 17.3006C38.2049 19.5765 39.0175 22.2523 39.0175 24.9895C39.0138 28.6585 37.5532 32.1763 34.9561 34.7708C32.359 37.3654 28.8376 38.8248 25.1646 38.8289V38.8289ZM25.1646 11.7186C22.5365 11.7183 19.9674 12.4965 17.7822 13.9548C15.5969 15.413 13.8937 17.4859 12.8878 19.9112C11.882 22.3365 11.6187 25.0052 12.1314 27.58C12.644 30.1547 13.9095 32.5198 15.7678 34.376C17.6261 36.2323 19.9937 37.4964 22.5713 38.0085C25.1488 38.5206 27.8205 38.2576 30.2484 37.2529C32.6763 36.2481 34.7514 34.5467 36.2113 32.3638C37.6712 30.181 38.4502 27.6147 38.4499 24.9895C38.4459 21.4711 37.0449 18.0979 34.5543 15.61C32.0637 13.1221 28.6868 11.7226 25.1646 11.7186V11.7186Z'
        fill='url(#paint1_linear_10621_388105)'
      />
      <path
        d='M25.1645 38.7314C22.4442 38.7317 19.7849 37.9262 17.5229 36.4168C15.2609 34.9073 13.4978 32.7617 12.4566 30.2513C11.4153 27.7409 11.1428 24.9785 11.6733 22.3133C12.2038 19.6482 13.5136 17.2 15.437 15.2784C17.3605 13.3569 19.8111 12.0482 22.4791 11.518C25.1472 10.9878 27.9126 11.2598 30.4259 12.2996C32.9392 13.3394 35.0873 15.1003 36.5986 17.3597C38.11 19.619 38.9167 22.2753 38.9167 24.9927C38.9126 28.6349 37.4625 32.1269 34.8844 34.7025C32.3062 37.2781 28.8107 38.727 25.1645 38.7314ZM25.1645 11.6212C22.5166 11.6212 19.9282 12.4055 17.7266 13.8751C15.5249 15.3446 13.809 17.4332 12.7958 19.8769C11.7826 22.3206 11.5175 25.0096 12.0342 27.6037C12.5509 30.1979 13.8261 32.5807 15.6985 34.4509C17.571 36.3211 19.9566 37.5946 22.5536 38.1104C25.1506 38.6263 27.8425 38.3612 30.2887 37.3488C32.735 36.3364 34.8257 34.6221 36.2966 32.4227C37.7674 30.2234 38.5523 27.6377 38.552 24.9927C38.5488 21.4468 37.1373 18.047 34.6274 15.5396C32.1175 13.0321 28.7142 11.6218 25.1645 11.6181V11.6212Z'
        fill='url(#paint2_linear_10621_388105)'
      />
      <path
        d='M25.1644 39.036C22.3884 39.036 19.6746 38.2137 17.3664 36.6731C15.0582 35.1325 13.2592 32.9428 12.1969 30.3808C11.1345 27.8189 10.8565 24.9998 11.3981 22.28C11.9397 19.5603 13.2765 17.062 15.2395 15.1012C17.2024 13.1404 19.7034 11.805 22.4261 11.264C25.1488 10.723 27.971 11.0007 30.5357 12.0619C33.1005 13.1231 35.2926 14.9202 36.8349 17.2259C38.3772 19.5315 39.2004 22.2423 39.2004 25.0153C39.196 28.7325 37.7157 32.2961 35.0844 34.9246C32.4532 37.553 28.8856 39.0316 25.1644 39.036ZM25.1644 11.9258C22.5727 11.9258 20.0392 12.6935 17.8843 14.1318C15.7294 15.5701 14.0499 17.6144 13.0581 20.0062C12.0663 22.398 11.8068 25.0299 12.3124 27.569C12.818 30.1081 14.066 32.4404 15.8986 34.271C17.7312 36.1017 20.0661 37.3483 22.608 37.8534C25.1499 38.3584 27.7846 38.0992 30.179 37.1085C32.5734 36.1178 34.62 34.4401 36.0598 32.2875C37.4997 30.1349 38.2682 27.6042 38.2682 25.0153C38.2642 21.5449 36.8824 18.2177 34.4258 15.7635C31.9693 13.3094 28.6387 11.9287 25.1644 11.9243V11.9258Z'
        fill='url(#paint3_linear_10621_388105)'
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.3'>
        <path
          d='M35.1313 34.2264C33.2888 36.176 30.909 37.5361 28.2927 38.1346C25.6764 38.7332 22.9411 38.5433 20.4328 37.589C17.9246 36.6347 15.7559 34.9589 14.2011 32.7734C12.6463 30.5879 11.7752 27.991 11.698 25.311C11.6208 22.631 12.3408 19.9883 13.7672 17.7171C15.1935 15.4459 17.2621 13.6482 19.7112 12.5513C22.1604 11.4544 24.8802 11.1077 27.5266 11.5549C30.173 12.0021 32.6273 13.2232 34.579 15.0637C35.875 16.2856 36.9174 17.7506 37.6466 19.375C38.3757 20.9994 38.7774 22.7513 38.8287 24.5308C38.88 26.3102 38.5799 28.0823 37.9455 29.7459C37.3111 31.4095 36.3548 32.932 35.1313 34.2264Z'
          fill='url(#paint4_linear_10621_388105)'
        />
        <path
          d='M15.7236 35.0036C13.7452 33.1387 12.3649 30.7297 11.7572 28.0812C11.1495 25.4326 11.3418 22.6636 12.3098 20.1242C13.2777 17.5849 14.9777 15.3893 17.1949 13.8152C19.4121 12.241 22.0469 11.3591 24.7659 11.2808C27.4849 11.2026 30.1661 11.9316 32.4703 13.3756C34.7746 14.8196 36.5983 16.9138 37.711 19.3933C38.8237 21.8728 39.1752 24.6262 38.7212 27.3053C38.2672 29.9843 37.028 32.4687 35.1603 34.4443C32.6547 37.0888 29.2014 38.6328 25.5577 38.7376C21.914 38.8425 18.3775 37.4996 15.7236 35.0036V35.0036ZM34.3533 15.2892C32.4279 13.4737 30.0067 12.2692 27.396 11.8281C24.7854 11.387 22.1023 11.7291 19.6863 12.8112C17.2702 13.8932 15.2297 15.6667 13.8226 17.9072C12.4155 20.1477 11.7052 22.7547 11.7813 25.3985C11.8575 28.0423 12.7168 30.6042 14.2505 32.7601C15.7842 34.9161 17.9235 36.5694 20.3979 37.5109C22.8722 38.4524 25.5705 38.6398 28.1515 38.0494C30.7325 37.4591 33.0802 36.1175 34.8979 34.1943C37.3317 31.6134 38.6412 28.1735 38.5391 24.6293C38.437 21.0851 36.9316 17.7261 34.3533 15.2892V15.2892Z'
          fill='url(#paint5_linear_10621_388105)'
        />
      </g>
      <path
        d='M19.1592 1.03089V4.80886C19.1592 4.80886 24.1099 5.92443 30.809 4.80886V1.03089C30.809 1.03089 26.3831 -1.28862 19.1592 1.03089Z'
        fill='white'
      />
      <path
        d='M19.4463 49.0062V45.437C19.4463 45.437 24.397 44.384 31.0977 45.437V49.0077C31.0977 49.0077 26.6641 51.1977 19.4463 49.0062Z'
        fill='white'
      />
      <path
        d='M1.07617 31.0581L4.03746 29.467C4.03746 29.467 7.25811 33.3532 9.56184 39.7143L6.59903 41.3054C6.59903 41.3054 2.68573 38.3885 1.07617 31.0581Z'
        fill='white'
      />
      <path
        d='M6.72276 8.40381L10.0792 10.1732C10.0792 10.1732 8.75798 15.0697 4.63719 20.4662L1.28076 18.6953C1.28076 18.6953 1.29144 13.7027 6.72276 8.40381Z'
        fill='white'
      />
      <path
        d='M49.2517 30.9865L46.1898 29.3223C46.1898 29.3223 42.8776 33.3456 40.522 39.949L43.5855 41.6132C43.5855 41.6132 47.6178 38.5988 49.2517 30.9865Z'
        fill='white'
      />
      <path
        d='M43.3855 8.27734L40.2563 9.92782C40.2563 9.92782 41.7606 15.0286 45.973 20.7832L49.1051 19.1251C49.1051 19.1251 48.8549 13.9908 43.3855 8.27734Z'
        fill='white'
      />
      <path
        d='M25.1643 43.1662C21.5653 43.1665 18.0471 42.1008 15.0545 40.1037C12.0619 38.1066 9.72936 35.2679 8.35188 31.9466C6.9744 28.6252 6.61383 24.9705 7.31577 21.4445C8.01771 17.9184 9.75063 14.6795 12.2954 12.1373C14.8401 9.59515 18.0824 7.86384 21.6122 7.16237C25.142 6.46089 28.8008 6.82076 32.1259 8.19646C35.4509 9.57216 38.2929 11.9019 40.2924 14.8911C42.2919 17.8802 43.3592 21.3946 43.3592 24.9896C43.3535 29.8084 41.4348 34.4281 38.0239 37.8357C34.6129 41.2432 29.9883 43.1602 25.1643 43.1662V43.1662ZM25.1643 7.35249C21.6714 7.35219 18.2568 8.38659 15.3525 10.3249C12.4482 12.2631 10.1845 15.0182 8.84773 18.2417C7.51099 21.4652 7.16124 25.0122 7.8427 28.4343C8.52417 31.8563 10.2063 34.9996 12.6762 37.4667C15.1462 39.9337 18.2931 41.6137 21.7189 42.2942C25.1447 42.9746 28.6956 42.6249 31.9225 41.2893C35.1493 39.9538 37.9073 37.6923 39.8474 34.791C41.7875 31.8896 42.8228 28.4787 42.8222 24.9896C42.8169 20.3132 40.9548 15.8298 37.6445 12.523C34.3341 9.21625 29.8458 7.35621 25.1643 7.35096V7.35249Z'
        fill='url(#paint6_linear_10621_388105)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.1675 3.83203C30.8551 3.83203 35.4748 5.31335 35.4748 7.14366C35.4748 8.97397 30.8551 10.4553 25.1675 10.4553C19.4799 10.4553 14.8511 8.97397 14.8511 7.14366C14.8511 5.31335 19.4692 3.83203 25.1675 3.83203Z'
        fill='url(#paint7_radial_10621_388105)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.1691 38.5791C32.2496 38.5791 38.0013 40.4231 38.0013 42.6939C38.0013 44.9646 32.2496 46.8178 25.1691 46.8178C18.0886 46.8178 12.3262 44.9799 12.3262 42.6939C12.3262 40.4079 18.0733 38.5791 25.1691 38.5791Z'
        fill='url(#paint8_radial_10621_388105)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M46.2765 24.9239C46.2765 30.6068 44.7936 35.2215 42.9613 35.2215C41.129 35.2215 39.6445 30.6068 39.6445 24.9239C39.6445 19.2409 41.1259 14.6187 42.9613 14.6187C44.7967 14.6187 46.2765 19.2333 46.2765 24.9239Z'
        fill='url(#paint9_radial_10621_388105)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M42.0484 24.7427C42.0484 30.4242 40.5655 35.0388 38.7332 35.0388C36.9009 35.0388 35.418 30.4242 35.418 24.7427C35.418 19.0613 36.8994 14.4375 38.7332 14.4375C40.567 14.4375 42.0484 19.0506 42.0484 24.7427Z'
        fill='url(#paint10_radial_10621_388105)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M14.9088 24.9942C14.9088 30.6756 13.4259 35.2918 11.5936 35.2918C9.76125 35.2918 8.27832 30.6756 8.27832 24.9942C8.27832 19.3127 9.76125 14.689 11.5936 14.689C13.4259 14.689 14.9088 19.3021 14.9088 24.9942Z'
        fill='url(#paint11_radial_10621_388105)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M11.5661 24.9241C11.5661 32.1203 9.68195 37.9694 7.36754 37.9694C5.05312 37.9694 3.16895 32.1203 3.16895 24.9241C3.16895 17.7278 5.0455 11.8772 7.36754 11.8772C9.68958 11.8772 11.5661 17.7171 11.5661 24.9241Z'
        fill='url(#paint12_radial_10621_388105)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M24.8899 20.7088C19.2022 20.7088 14.5811 17.5998 14.5811 13.7609C14.5811 9.92199 19.2022 6.81152 24.8899 6.81152C30.5775 6.81152 35.2048 9.91894 35.2048 13.7609C35.2048 17.6029 30.5836 20.7088 24.8899 20.7088Z'
        fill='url(#paint13_radial_10621_388105)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.3489 45.7112C19.6612 45.7112 15.04 42.6023 15.04 38.7649C15.04 34.9275 19.6612 31.814 25.3489 31.814C31.0365 31.814 35.6653 34.9229 35.6653 38.7649C35.6653 42.6069 31.0411 45.7112 25.3489 45.7112Z'
        fill='url(#paint14_radial_10621_388105)'
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity='0.1'
        d='M37.5403 46.7307C25.5487 53.5506 10.2922 49.355 3.44964 37.3597C-3.3929 25.3644 0.796534 10.1154 12.7897 3.29702C24.7828 -3.52131 40.0424 0.672711 46.8773 12.6711C53.7122 24.6695 49.5319 39.9124 37.5403 46.7307Z'
        fill='url(#paint15_linear_10621_388105)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_10621_388105'
          x1='12.7914'
          y1='3.2723'
          x2='37.5009'
          y2='46.7279'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#779E97' />
          <stop offset='1' stopColor='#1D5E57' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_10621_388105'
          x1='11.3101'
          y1='24.9895'
          x2='39.0175'
          y2='24.9895'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#553319' />
          <stop offset='0.1' stopColor='#6A4623' />
          <stop offset='0.29' stopColor='#A27B3C' />
          <stop offset='0.39' stopColor='#EED072' />
          <stop offset='0.52' stopColor='#FFF5D8' />
          <stop offset='0.61' stopColor='#EED072' />
          <stop offset='0.81' stopColor='#A27B3C' />
          <stop offset='1' stopColor='#553319' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_10621_388105'
          x1='11.4107'
          y1='24.9912'
          x2='38.9167'
          y2='24.9912'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.01' stopColor='#35AD87' />
          <stop offset='0.07' stopColor='#296956' />
          <stop offset='0.5' stopColor='#B6DBD5' />
          <stop offset='0.62' stopColor='#88C6B2' />
          <stop offset='0.67' stopColor='#84C5B0' />
          <stop offset='0.72' stopColor='#78C1A9' />
          <stop offset='0.77' stopColor='#64BA9D' />
          <stop offset='0.81' stopColor='#50B391' />
          <stop offset='1' stopColor='#28674C' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_10621_388105'
          x1='11.1269'
          y1='25.0138'
          x2='39.2004'
          y2='25.0138'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.01' stopColor='#35AD87' />
          <stop offset='0.07' stopColor='#296956' />
          <stop offset='0.5' stopColor='#B6DBD5' />
          <stop offset='0.62' stopColor='#88C6B2' />
          <stop offset='0.67' stopColor='#84C5B0' />
          <stop offset='0.72' stopColor='#78C1A9' />
          <stop offset='0.77' stopColor='#64BA9D' />
          <stop offset='0.81' stopColor='#50B391' />
          <stop offset='1' stopColor='#28674C' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_10621_388105'
          x1='15.21'
          y1='15.4336'
          x2='34.9251'
          y2='34.0649'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stopColor='white' />
          <stop offset='0.9' stopColor='#294841' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_10621_388105'
          x1='15.1646'
          y1='15.5793'
          x2='35.1446'
          y2='34.461'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stopColor='white' />
          <stop offset='1' stopColor='#164A41' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_10621_388105'
          x1='6.96632'
          y1='24.9896'
          x2='43.3592'
          y2='24.9896'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.01' stopColor='#35AD87' />
          <stop offset='0.07' stopColor='#296956' />
          <stop offset='0.5' stopColor='#B6DBD5' />
          <stop offset='0.62' stopColor='#88C6B2' />
          <stop offset='0.67' stopColor='#84C5B0' />
          <stop offset='0.72' stopColor='#78C1A9' />
          <stop offset='0.77' stopColor='#64BA9D' />
          <stop offset='0.81' stopColor='#50B391' />
          <stop offset='1' stopColor='#28674C' />
        </linearGradient>
        <radialGradient
          id='paint7_radial_10621_388105'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.2215 7.26979) rotate(-90) scale(3.30541 10.3118)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_10621_388105'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.2216 42.7565) rotate(-90) scale(4.11538 12.8387)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint9_radial_10621_388105'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(42.9202 24.9931) scale(3.30901 10.3006)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint10_radial_10621_388105'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(38.7001 24.8136) scale(3.30901 10.3006)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint11_radial_10621_388105'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(11.6115 25.0668) scale(3.30901 10.3006)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint12_radial_10621_388105'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(7.39195 24.9933) scale(4.19072 13.0452)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint13_radial_10621_388105'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(24.7988 13.9334) rotate(90) scale(6.95674 10.3118)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint14_radial_10621_388105'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.2565 38.9687) rotate(90) scale(6.95674 10.3118)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <linearGradient
          id='paint15_linear_10621_388105'
          x1='12.7917'
          y1='3.29454'
          x2='37.502'
          y2='46.7515'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.61' stopColor='white' />
          <stop offset='0.8' stopColor='#7A9D9A' />
          <stop offset='0.94' stopColor='#235D58' />
          <stop offset='1' stopColor='#01443E' />
        </linearGradient>
      </defs>
    </svg>
  );
}
