import { useQuery } from '@tanstack/react-query';
import { Api } from 'common/api';
import { useAuth } from 'contexts/authentication';
import { GameProduct } from 'services/get-game-products/get-game-products';

type BonusModalInfo = {
  environment: string;
  premiumStates: string[];
  silverBonus: any;
  silverBonusCta: string;
  silverBonusPremium: any;
  silverBonusPremiumCta: string;
  goldBonus: any;
  goldBonusCta: string;
  goldBonusPremium: any;
  goldBonusPremiumCta: string;
  platinumBonus: any;
  platinumBonusCta: string;
  platinumBonusPremium: any;
  platinumBonusPremiumCta: string;
  emeraldBonus: any;
  emeraldBonusCta: string;
  emeraldBonusPremium: any;
  emeraldBonusPremiumCta: string;
  rubyBonus: any;
  rubyBonusCta: string;
  rubyBonusPremium: any;
  rubyBonusPremiumCta: string;
};

export const useBonusModalInfo = (product: GameProduct) => {
  const { auth } = useAuth();
  const {
    data: content,
    isLoading,
    isError,
  } = useQuery(['bonus-modal', auth?.session?.state], {
    queryFn: getBonusContent,
    refetchOnWindowFocus: false,
  });

  if (isLoading || isError || !content) {
    return { terms: '', buttonUrl: '', isLoading, isError };
  }

  const keyName = getBonusModalKeyName(
    product.cost,
    auth?.session?.state,
    content.premiumStates,
  );
  const terms = content[keyName];
  const buttonUrl = content[keyName + 'Cta'];

  return { terms, buttonUrl, isLoading, isError };
};

const getBonusContent = async () => {
  const { data: content, ok } = await Api.get<BonusModalInfo>(
    `${API_HOST}/api/v1/bonus-modal?env=${BUILD_ENV}`,
  );
  if (!ok)
    throw new Error('An error occurred while fetching the bonus modal info.');

  return content;
};

const getBonusModalKeyName = (
  productCost: number,
  userState: string,
  premiumStates: string[],
) => {
  const bonusNames: Record<
    number,
    Exclude<keyof BonusModalInfo, 'environment' | 'premiumStates'>
  > = {
    10: 'silverBonus',
    20: 'goldBonus',
    40: 'platinumBonus',
    100: 'emeraldBonus',
    250: 'rubyBonus',
  };
  let key = bonusNames[productCost];
  const isPremium = premiumStates.some((state) => state === userState);
  const isNewYork = userState === 'New York';
  const isCalifornia = userState === 'California';
  if (isPremium) {
    return `${key}Premium`;
  } else if (isNewYork) {
    return `${key}Ny`;
  } else if (isCalifornia) {
    return `${key}Ca`;
  }
  return key;
};
