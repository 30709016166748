import styled from '@emotion/styled';

export const ModalContainer = styled.div`
  background-color: #121212;
  border-radius: var(--spacing-1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
`;

export const ModalContentFlex = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: white;
  text-align: left;
  a {
    color: var(--primary-color);
  }
`;

export const ModalContentFixed = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  height: 550px;
  overflow-y: scroll;
  text-align: left;
  a {
    color: var(--primary-color);
  }
  @media screen and (max-width: 400px) {
    height: 350px;
  }
`;

export const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  a {
    cursor: pointer;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
export const ModalRowCentered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: var(--spacing-3);
  a {
    cursor: pointer;
  }
`;
