import React from 'react';
import { SvgProps } from './svg';

export function CheckCircle({ size = '1rem', color = '#02BD9C' }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.2002 11.4794L8.16019 14.261C8.23454 14.372 8.33434 14.4637 8.45127 14.5284C8.56821 14.5931 8.69891 14.629 8.83249 14.6329C8.96607 14.6369 9.09868 14.609 9.21927 14.5514C9.33986 14.4938 9.44495 14.4082 9.52579 14.3018L13.5002 8.00009'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 19.5C14.9706 19.5 19 15.4706 19 10.5C19 5.52944 14.9706 1.5 10 1.5C5.02944 1.5 1 5.52944 1 10.5C1 15.4706 5.02944 19.5 10 19.5Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
