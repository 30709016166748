import React from 'react';
import { SvgProps } from './svg';

export function LocationPin({
  size,
  width = '100',
  height = '100',
  fill = 'none',
  stroke = 'white',
}: SvgProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      viewBox='0 0 100 100'
      fill={fill}
      stroke={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M80.2083 34.375C80.2083 51.9625 67.9875 62.0792 58.8292 71.875C53.3458 77.7417 48.9583 96.875 48.9583 96.875C48.9583 96.875 44.5833 77.7542 39.1125 71.9042C29.9458 62.1125 17.7083 51.9792 17.7083 34.375C17.7083 26.087 21.0007 18.1384 26.8612 12.2779C32.7218 6.4174 40.6703 3.125 48.9583 3.125C57.2463 3.125 65.1949 6.4174 71.0554 12.2779C76.9159 18.1384 80.2083 26.087 80.2083 34.375V34.375Z'
        stroke={stroke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.9083 48.5492L60.0083 26.4492'
        stroke={stroke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.9083 26.4492L60.0083 48.5492'
        stroke={stroke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
