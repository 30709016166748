import { Controller, useFormContext } from 'react-hook-form';
import { Select } from 'components/atoms/select';
import { useStates } from 'hooks/use-states/use-states';
import SmartySDK from 'smartystreets-javascript-sdk';
import { Autocomplete, TextField } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FormInput } from 'components/atoms/input/input';
import { FormPhoneNumberInput } from 'components/atoms/phone-number/phone-number';
import { TribalZipCodes } from 'common/bspot-shared';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { CONFIG } from 'common/utils';

type FormFields = {
  address: string | AutocompleteSuggestion;
  city: string;
  province_id: string;
  postal_code: string;
  phone_number: string;
};

type AutocompleteSuggestion = SmartySDK.usAutocompletePro.Suggestion;

export function ResidenceForm() {
  const { control, setValue } = useFormContext<FormFields>();

  const { data: states } = useStates();
  const [addressOptions, setAddressOptions] = useState<
    AutocompleteSuggestion[]
  >([]);

  const stateIds = useMemo(
    () => states?.map((state) => state.code) ?? [],
    [states],
  );

  const getAddresses = useCallback(
    async (address: string) => {
      const key = FeatureFlagsService?.featureFlags?.smartyEmbeddedKey;
      const SmartyCore = SmartySDK.core;
      const credentials = new SmartyCore.SharedCredentials(key);
      const clientBuilder = new SmartyCore.ClientBuilder(credentials);

      const client = clientBuilder.buildUsAutocompleteProClient();
      const Lookup = SmartySDK.usAutocompletePro.Lookup;
      const lookup = new Lookup(address);
      lookup.includeOnlyStates = stateIds;
      lookup.preferRatio = 100;

      return client.send(lookup);
    },
    [stateIds],
  );

  const onAddressChange = async (e) => {
    if (!e.target.value) return;

    const res = await getAddresses(e.target.value);
    setAddressOptions(res.result);
  };

  const onAddressSelect = (address: FormFields['address']) => {
    if (typeof address === 'string') return;

    const addresState =
      states.find(({ code }) => code === address.state)?.id?.toString() ?? '';

    setValue('province_id', addresState, {
      shouldValidate: true,
    });
    setValue('city', address.city, {
      shouldValidate: true,
    });
    setValue('postal_code', address.zipcode, {
      shouldValidate: true,
    });
  };

  const getOptionLabel = (option: FormFields['address']) => {
    if (!option) return '';

    if (typeof option === 'string') return option;

    const { streetLine, secondary, city, state, zipcode } = option;
    return `${streetLine} ${secondary} ${city} ${state} ${zipcode}`;
  };

  return (
    <>
      <Controller
        name='address'
        control={control}
        rules={{
          required: 'This field is required',
          pattern: {
            value: /^((?!p\.?\s?o\.? box).)*$/i,
            message:
              'We cannot accept PO Boxes as a valid residence. To proceed, please provide a valid residential address',
          },
        }}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <Autocomplete
            fullWidth
            freeSolo
            disablePortal
            inputValue={getOptionLabel(value)}
            options={addressOptions}
            getOptionLabel={getOptionLabel}
            onChange={(_, value, reason) => {
              if (reason !== 'selectOption') return;
              onAddressSelect(value);
              onChange(typeof value === 'string' ? value : value.streetLine);
            }}
            onBlur={onBlur}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                fullWidth
                inputRef={params.InputProps.ref}
                variant='outlined'
                data-cy='address'
                inputProps={{ ...params.inputProps }}
                onChange={(e) => {
                  params.inputProps.onChange(e);
                  onAddressChange(e);
                  onChange(e.target.value);
                }}
                label='Address'
                helperText={error?.message}
                error={!!error}
              />
            )}
          />
        )}
      />

      <FormInput
        label={'City'}
        name='city'
        data-cy='city'
        validation={{
          required: 'This field is required',
        }}
      />

      <div className={'row g-3'}>
        <div className={'col-12 col-lg-7'}>
          <Select
            label='State'
            control={control}
            name='province_id'
            options={states}
            rules={{
              required: 'This field is required',
            }}
          />
        </div>
        <div className={'col-12 col-lg-5'}>
          <FormInput
            fullWidth
            name='postal_code'
            data-cy='postal_code'
            label={'ZIP Code'}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            validation={{
              required: 'This field is required',
              validate: (value) =>
                !TribalZipCodes.includes(value) ||
                CONFIG.BRAND + ' isn’t available in your Zip Code',
              maxLength: {
                value: 5,
                message: '5 digit code required.',
              },
              minLength: {
                value: 5,
                message: '5 digit code required.',
              },
            }}
          />
        </div>
      </div>

      <FormPhoneNumberInput
        label='Phone Number'
        name='phone_number'
        data-cy='phone_number'
        validation={{
          required: 'This field is required',
          maxLength: {
            value: 10,
            message: 'Must have 10 digits',
          },
          minLength: {
            value: 10,
            message: 'Must have 10 digits',
          },
        }}
      />
    </>
  );
}
