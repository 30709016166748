import styled from '@emotion/styled';
import { Text } from 'components/atoms/text';
import { useMemo } from 'react';
import { useAuth } from 'contexts/authentication';

const HelpItem = styled.a`
  display: flex;
  align-items: center;
  gap: 25px;
  padding-bottom: 12px;
  cursor: pointer;
  line-height: 1.2em;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--bg-darkish-color);
  min-width: 40px;
  min-height: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const Section = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledText = styled(Text)`
  padding-bottom: 5px;
`;

export const HelpSection = ({ className }: { className?: string }) => {
  const { auth } = useAuth();

  const items = useMemo(() => {
    const chat = {
      title: 'Live Chat',
      description: 'Our Agents are available 24x7x365 days a year',
      icon: '/assets/icons/live-help.svg',
      href: '',
      onClick: () => {
        window.Bspot.liveChat.open();
      },
    };
    const contact = {
      title: 'Contact Us',
      description: 'Do you have a question or suggestion?',
      icon: '/assets/icons/email.svg',
      onClick: () => {
        window.open(
          'https://support.bspot.com/hc/en-us',
          '_blank',
          'noopener,noreferrer',
        );
      },
    };
    return auth.session ? [contact, chat] : [contact];
  }, [auth.session]);

  return (
    <Section className={className}>
      <Text bold color='var(--text-grey-color)'>
        Help?
      </Text>
      {items.map((item, index) => {
        return (
          <HelpItem key={index} onClick={item.onClick}>
            <Icon>
              <img src={item.icon} height='20px' width='20px' color='white' />
            </Icon>
            <Content>
              <StyledText bold>{item.title}</StyledText>
              <Text color='var(--text-grey-color)'>{item.description}</Text>
            </Content>
          </HelpItem>
        );
      })}
    </Section>
  );
};
