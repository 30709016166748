export enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum Theme {
  Default = 'default',
  Other = 'other',
}

export const BreakPointsNumbers = {
  xxs: 320,
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const BreakPoints = {
  xxs: `${BreakPointsNumbers.xxs}px`,
  xs: `${BreakPointsNumbers.xs}px`,
  sm: `${BreakPointsNumbers.sm}px`,
  md: `${BreakPointsNumbers.md}px`,
  lg: `${BreakPointsNumbers.lg}px`,
  xl: `${BreakPointsNumbers.xl}px`,
  xxl: `${BreakPointsNumbers.xxl}px`,
};

export const bp = {
  xxs: `(min-width: ${BreakPoints.xxs})`,
  xs: `(min-width: ${BreakPoints.xs})`,
  sm: `(min-width: ${BreakPoints.sm})`,
  md: `(min-width: ${BreakPoints.md})`,
  lg: `(min-width: ${BreakPoints.lg})`,
  xl: `(min-width: ${BreakPoints.xl})`,
  xxl: `(min-width: ${BreakPoints.xxl})`,
};

export const switchTheme = (theme: Theme): void => {
  document.documentElement.setAttribute('data-common', theme);
};
