import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export const injectGTM = () => {
  if (document.getElementById('gtm-bspot-script')) {
    return;
  }
  const scriptTag = document.createElement('script');
  scriptTag.id = 'gtm-bspot-script';
  scriptTag.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${FeatureFlagsService?.featureFlags?.gtmKey}');`;
  document.head.appendChild(scriptTag);

  const scriptTagBody = document.createElement('noscript');
  scriptTagBody.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id='${FeatureFlagsService?.featureFlags?.gtmKey}'"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.body.appendChild(scriptTagBody);
};

export const deleteGTM = () => {
  const scriptTag = document.getElementById('gtm-bspot-script');
  scriptTag && scriptTag.remove();
};
