import { useQuery } from '@tanstack/react-query';
import { getTournaments } from 'services/tournaments/tournaments';
import { useAuth } from 'contexts/authentication';

export const useBffTournaments = () => {
  const { auth } = useAuth();
  return useQuery({
    queryKey: ['get-tournaments', auth.session?.playerId || ''],
    queryFn: () => getTournaments(auth.member?.id),
    keepPreviousData: false,
    refetchInterval: 30000,
  });
};
