import { useQuery } from '@tanstack/react-query';
import { Api } from 'common/api';
import { useAuth } from 'contexts/authentication';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export type Balances = {
  cash: number;
  credits: number;
  credits_pending: number;
  credits_purchased_count: number;
  deposits_count: number;
  shouldConvertCash: boolean;
  shouldDeposit: boolean;
};

type StriveBalancesResponse = {
  playable_balance: number;
  bonus_balance: number;
  withdrawable_balance: number;
  locked_balance: number;
};

type StriveBalance = {
  playable_balance: number;
};

const getStriveBalance = async () => {
  const { data, ok } = await Api.get<StriveResponse<StriveBalancesResponse>>(
    `${API_HOST}/api/strive-proxy/railsapi/v1/user/balances`,
  );
  if (!ok) throw new Error('Failed to retrieve balances');
  return data.Data;
};

export const getBalances = async (): Promise<StriveBalance> => {
  const striveBalances = await getStriveBalance();
  const { playable_balance } = striveBalances;
  return {
    playable_balance,
  };
};

export const useGetBalance = () => {
  const { auth } = useAuth();
  if (FeatureFlagsService?.featureFlags?.enableBalancesPoll) {
    return useQuery({
      queryKey: ['use-balance-with-poll', auth.session?.playerId],
      queryFn: () => getBalances(),
      keepPreviousData: false,
      refetchInterval: Number(FeatureFlagsService?.featureFlags?.balancesPool),
      enabled: !!auth.session?.playerId,
    });
  } else {
    return useQuery({
      queryKey: ['use-balance', !!auth.session?.playerId],
      queryFn: () => getBalances(),
      keepPreviousData: false,
      enabled: !!auth.session?.playerId,
    });
  }
};
