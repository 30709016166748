import React, { Suspense, useState } from 'react';
import { useOutlet } from 'react-router-dom';
import { BspotHeader } from 'components/organisms/layout/header';
import { CircularLoader } from 'components/atoms/circular-loader';
import { FooterMk2 } from '../footer-mk2/footer-mk2';
import { BottomNav } from '../bottom-nav/bottom-nav';
import { Position, useDrawer } from '../../../../hooks/use-drawer';
import { lazyWithRetry } from '../../../../common/utils';
import { useBffTournaments } from '../../../../hooks/use-tournaments/use-tournaments';
import { AppLoader } from 'components/molecules/app-loader/app-loader';
const MyAccountNav = lazyWithRetry(
  () => import('components/organisms/layout/my-account-nav/my-account-nav'),
);

export const SuspenseFallback = ({ children }) => {
  return <Suspense fallback={<AppLoader />}>{children}</Suspense>;
};

export function Layout() {
  const outlet = useOutlet();
  // @ts-ignore
  return (
    <div className={'d-flex flex-column min-vh-100'}>
      <BspotHeader />
      <main className={`d-flex flex-column flex-grow-1`}>
        <SuspenseFallback>{outlet}</SuspenseFallback>
      </main>
      <FooterMk2 />
    </div>
  );
}
