import { getUiDate, getUiTime } from 'common/bspot-shared';
import { Api } from 'common/api';

export enum TransactionType {
  DEPOSIT = 'transaction_type_7',
  WITHDRAW = 'transaction_type_9',
  WITHDRAW_REJECTED = 'transaction_type_11',
  WITHDRAW_REJECTED_NEW = 'transaction_type_705',
  WITHDRAW_CORRECTION = 'transaction_type_54',
  FREE_CREDITS = 'transaction_type_26',
  DEPOSIT_CORRECTION = 'transaction_type_55',
  DEPOSIT_CONFIRMED_MANUALLY = 'transaction_type_20',
  DEPOSIT_RETURNED = 'transaction_type_15',
  CREDITS_PURCHASED = 'transaction_type_920',
  PACKAGE_REFUND = 'transaction_type_921',
  GAME_PLAYED = 'transaction_type_922',
  GAME_WIN = 'transaction_type_923',
  FREEPLAY = 'transaction_type_925',
  TYPE_21 = 'transaction_type_21',
  INACTIVE_FEE = 'transaction_type_638',
}

export type TransactionHistoryItem = {
  date: string;
  game_name: string | null;
  transaction_id: number;
  title: TransactionType;
  amount: string;
  account_number: string | null;
  in: boolean;
};

export type TransactionUI = Partial<TransactionHistoryItem> & {
  key: string;
  date: string;
  time: string;
  transactions: TransactionHistoryItem[];
};

const convertToUTC = (d) => {
  let isoDateStr = d.toISOString();
  isoDateStr = isoDateStr.substring(0, isoDateStr.indexOf('T'));
  isoDateStr = isoDateStr.split('-').reverse().join('/');
  return isoDateStr;
};

const prepareForUi = (
  result: TransactionUI[],
  item: TransactionHistoryItem,
) => {
  const existingGroup = result.find((group) => group.key === item.date);
  if (existingGroup) {
    existingGroup.transactions.push({ ...item });
  } else if (item.title !== 'transaction_type_21') {
    const newGroup = {
      key: item.date,
      date: getUiDate(item.date),
      time: getUiTime(item.date),
      transactions: [{ ...item }],
    };
    result.push(newGroup);
  }

  return result;
};

export const getTransactionHistory = async (
  page: number,
  period: number,
  dateTo?: string,
  dateFrom?: string,
) => {
  const req_dateTo = dateTo ? new Date(dateTo) : new Date();
  const req_dateFrom = dateFrom
    ? new Date(dateFrom)
    : new Date(new Date().setDate(req_dateTo.getDate() - period));
  //
  req_dateTo.setDate(req_dateTo.getDate());
  const prodDateFrom = convertToUTC(req_dateFrom);
  const prodDateTo = convertToUTC(req_dateTo);
  return Api.get<any>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/transactions?from=${prodDateFrom}&to=${prodDateTo}&page=${page}&d=${new Date().toString()}`,
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Could not retrieve transactions for the user');
    }

    const data = res.data.transactions.reduce(prepareForUi, []);

    return {
      ...res.data,
      transactions: data,
    };
  });
};
