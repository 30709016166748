// @ts-nocheck
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDelayUnmount } from 'hooks/use-delay-unmount';
import { usePortal } from 'hooks/use-portal';
import { useStateRef } from 'hooks/use-state-ref';
import { Blur } from 'components/atoms/blur';

export enum Position {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

const topTranslate = (props) => css`
  ${props.$isOpen
    ? 'transform: translateY(100%);'
    : 'transform: translateY(0);'}
`;

const rightTranslate = (props) => css`
  ${props.$isOpen
    ? 'transform: translateX(-100%);'
    : 'transform: translateX(0);'}
`;

const bottomTranslate = (props) => css`
  ${props.$isOpen
    ? 'transform: translateY(-100%);'
    : 'transform: translateY(0);'}
`;

const leftTranslate = (props) => css`
  ${props.$isOpen
    ? 'transform: translateX(100%);'
    : 'transform: translateX(0);'}
`;

const PositionStyles = {
  [Position.Top]: (props) => css`
    bottom: 100%;
    width: 100%;
    ${topTranslate(props)}
  `,
  [Position.Right]: (props) => css`
    top: 0;
    left: 100%;
    height: 100%;
    ${rightTranslate(props)}
  `,
  [Position.Bottom]: (props) => css`
    top: 100%;
    width: 100%;
    ${bottomTranslate(props)}
  `,
  [Position.Left]: (props) => css`
    top: 0;
    right: 100%;
    height: 100%;
    ${leftTranslate(props)}
  `,
};

const Drawer = styled.div`
  position: fixed;
  z-index: 101;
  display: block;
  transition: ${(props) => props.$animationTime}ms ease;
  pointer-events: none;
  ${(props) => PositionStyles[props.$position]}

  & > * {
    pointer-events: auto;
  }
`;

export type useDrawerOptions = {
  initialState?: boolean;
  showBlur?: boolean;
  delayTime?: number;
};

export const useDrawer = (
  position: Position = Position.Bottom,
  {
    initialState = false,
    showBlur = true,
    delayTime = 200,
  }: useDrawerOptions = {},
): {
  isOpen: boolean;
  toggle: () => void;
  Drawer({ children: ReactNode }): JSX.Element;
} => {
  const [isOpen, setIsOpen, isOpenRef] = useStateRef(initialState);
  const [, isVisibleRef] = useDelayUnmount(isOpen, delayTime);
  const Portal = usePortal();

  useEffect(() => {
    document.querySelector('body').style.overflow = isVisibleRef.current
      ? 'hidden'
      : 'auto';

    return () => {
      document.querySelector('body').style.overflow = 'auto';
    };
  }, [isVisibleRef.current]);

  return {
    isOpen,
    toggle: () => setIsOpen((prevState) => !prevState),
    Drawer: React.useCallback(
      (props) => {
        return (
          <Portal>
            {showBlur && (
              <Blur
                onClick={() => setIsOpen(false)}
                $isVisible={isOpenRef.current}
                $animationTime={delayTime}
              />
            )}
            <Drawer
              key={1}
              $isOpen={isOpenRef.current}
              $position={position}
              $animationTime={delayTime}
            >
              {isVisibleRef.current && props.children}
            </Drawer>
          </Portal>
        );
      },
      [
        Portal,
        showBlur,
        isOpenRef,
        delayTime,
        position,
        isVisibleRef,
        setIsOpen,
      ],
    ),
  };
};
