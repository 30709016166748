import { Button } from './button';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export type OutlinedButtonProps = {
  shouldConvertCash?: boolean;
  cashIncrease?: boolean;
  quickCredits?: boolean;
};

export const OutlinedButton = styled(Button)<OutlinedButtonProps>`
  background-color: white;
  z-index: 2;

  ${({ shouldConvertCash }) =>
    shouldConvertCash &&
    css`
      background-color: #ffe0e5;
      border: 2px solid #ff6060;
      :hover {
        background-color: #ffa19a;
      }

      transition: 5s ease-in-out;
      animation: colors 8s linear infinite;
      @keyframes colors {
        0%,
        100% {
          background-color: #ffa19a;
        }
        20% {
          background-color: #ffe0e5;
        }
        40% {
          background-color: #ffa19a;
        }
        60% {
          background-color: #ffe0e5;
        }
        80% {
          background-color: #ffa19a;
        }
        90% {
          background-color: #ffe0e5;
        }
      }
    `}

  ${({ cashIncrease }) =>
    cashIncrease &&
    css`
      background-color: #c5f4df;
      border: 2px solid #06c772;
      :hover {
        background-color: #89f9c8;
      }
    `}

  &.cashIncrease::before,
  &.cashIncrease::after {
    content: '';
    position: absolute;
    background: linear-gradient(
      45deg,
      #0aeb88,
      #000000,
      #0aeb88,
      #000000,
      #0aeb88,
      #000000
    );
    z-index: -1;
    border-radius: 30px;
  }

  ${({ quickCredits }) =>
    quickCredits &&
    css`
      height: 45px;
      min-width: 110px;
      background-color: #fff3c1;
      border: 2px solid #e7a000;
      :hover {
        background-color: #ffe57d;
      }
    `}

  &.quickCredits::before,
  &.quickCredits::after {
    content: '';
    filter: blur(5px);
    position: absolute;
    background: linear-gradient(
      45deg,
      #e7a000,
      #000000,
      #e7a000,
      #000000,
      #e7a000,
      #000000
    );
    z-index: -1;
    border-radius: 30px;
    background-size: 400%;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    animation: animate 20s linear infinite;
  }
`;
