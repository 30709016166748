import { Close } from 'icons/svg';
import { Button } from 'components/atoms/button';
import { useGetBalance } from 'hooks/use-balance';
import { toCurrency } from 'common/bspot-shared';
import { BspotModal } from 'components/atoms/modal/modal';

export type DepositFirstModalProps = {
  onClose(): void;
  onGoDeposit(): void;
  show: boolean;
};
export const DepositFirstModal = ({
  onClose,
  show,
  onGoDeposit,
}: DepositFirstModalProps) => {
  const { data: balances } = useGetBalance();

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Deposit required'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onClose} />
      </div>
      <div className={'d-flex flex-column gap-2'}>
        <div>
          {
            "Before you can buy credits, you must first make a successful deposit. If you need any assistance along the way, don't hesitate to reach out! "
          }
        </div>
      </div>
      <div className={''}>
        <Button text='Deposit Now' onClick={onGoDeposit} />
      </div>
    </BspotModal>
  );
};
export default DepositFirstModal;
