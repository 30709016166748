import { useQuery } from '@tanstack/react-query';
import { getTermsAndConditions } from 'services/content/terms-and-conditions/terms-and-conditions';
import { useLocation } from 'react-router-dom';
import { Routes } from 'common/routes';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
export const useTerms = () => {
  const location = useLocation();
  const { data: status } = usePlayerStatus();
  return useQuery({
    queryKey: ['terms-and-conditions'],
    queryFn: getTermsAndConditions,
    staleTime: 1200000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled:
      location.pathname === Routes.TermsOfUse.path || status?.tnc_required,
  });
};
