import { useOutlet } from 'react-router-dom';
import { Close } from 'icons/svg';
import React, { Suspense } from 'react';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
import './styles.scss';
import { CircularLoader } from 'components/atoms/circular-loader';
import styled from '@emotion/styled';
import ModalTermsManager from 'components/organisms/terms-and-conditions/terms-modal/modal-manager';
export const DefaultLayout = () => {
  const outlet = useOutlet();

  return (
    <div className={'d-flex flex-column flex-grow-1'}>
      <div className={'container py-2'}>
        <div
          id={'scrollDiv'}
          className={'d-flex flex-column '}
          style={{ overflowY: 'scroll', minHeight: '800px' }}
        >
          <Suspense
            fallback={
              <div
                className='container d-flex justify-content-center align-items-center'
                style={{ height: '500px' }}
              >
                <CircularLoader color={'white'} size={'2rem'} />
              </div>
            }
          >
            {outlet}
          </Suspense>
          <ModalTermsManager />
        </div>
      </div>
    </div>
  );
};
