import 'common/theme/main.scss';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useGetAvailableBanks } from 'hooks/use-available-banks/use-available-banks';
import { FadeInContainer } from 'pages/make-deposit/styled';
import { useForm } from 'react-hook-form';
import { BANK_ID } from 'services/make-deposit/make-deposit';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { SectionTitle } from 'components/atoms/section-title/section-title';
import { BspotAlert } from 'components/molecules/alerts/alert';
import { Helmet } from 'react-helmet-async';
import { PaymentCardComponent } from './payment-card';
import { PaymentIframe } from './payment-iframe';
import { CONFIG } from 'common/utils';
import { AppLoader } from 'components/molecules/app-loader/app-loader';
import { Routes } from 'common/routes';

type MakeDepositPageProps = {
  depositResult?: string;
  children?: ReactNode;
  onSuccessDeposit?: (amount) => void;
  isModal: boolean;
};
export default function MakeDepositPage({
  children,
  isModal = false,
  onSuccessDeposit,
}: MakeDepositPageProps) {
  const { data: status } = usePlayerStatus();
  const { data: availableBanks, isLoading } = useGetAvailableBanks();
  const {
    register,
    unregister,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { payment_method: null, amount: '' },
  });
  const paymentMethodId = getValues('payment_method');
  const amount = getValues('amount');
  const [checkout, setCheckout] = useState<string | null>(null);
  const [depositRangeHint, setDepositRangeHint] = useState('');
  const [noPaymentResults, setNoPaymentResults] = useState('');
  const [errorPayment, setErrorPayment] = useState(null);

  const userFailedKyc = useMemo(() => {
    if (!status?.validator_status) return;
    return status?.validator_status !== 1;
  }, [status]);

  const registerPaymentMethodControl = () => {
    register('payment_method', {
      required: 'Please select the payment method',
    });
  };

  const registerAmountControl = (options?) => {
    register('amount', { required: 'Please select the amount', ...options });
  };

  registerPaymentMethodControl();
  registerAmountControl();

  const selectDefaultPaymentMethod = () => {
    unregister('amount');
    const paymentMethod = availableBanks.find((it) => it?.bank_id === 209);
    setValue('payment_method', paymentMethod?.bank_id, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setDepositRangeHint(
      `Minimum deposit: $${paymentMethod?.min_deposit} - Maximum deposit: $${paymentMethod?.max_deposit}`,
    );
    registerAmountControl({
      min: {
        message:
          'The amount you entered is outside limits. It must be above the minimum deposit.',
        value: paymentMethod?.min_deposit,
      },
      max: {
        message: 'Exceeded Maximum Deposit Limit',
        value: paymentMethod?.max_deposit,
      },
    });
  };

  const onSelectAmount = (amount) => {
    setErrorPayment(null);
    setValue('amount', parseFloat(amount.replace(/,/g, '')).toFixed(2), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (!userFailedKyc) {
      if (
        availableBanks?.length &&
        availableBanks.find((it) => it.bank_id === 209)
      ) {
        selectDefaultPaymentMethod();
      }
    }
  }, [availableBanks, userFailedKyc]);

  const pageTitle = 'Deposit - ' + CONFIG.BRAND;

  const onErrorDeposit = (data) => {
    setCheckout(null);
    selectDefaultPaymentMethod();
    setErrorPayment(data);
  };

  if (isLoading) {
    return <AppLoader fullHeight={false} />;
  }

  if (availableBanks?.length === 0) {
    return (
      <>
        {!isModal && <SectionTitle title={'Deposit'} />}
        <div className={'py-3'}>
          {' '}
          <BspotAlert
            type={'error'}
            message={'There was an issue. Please contact Support.'}
          />
        </div>
      </>
    );
  }

  return (
    availableBanks?.length > 0 && (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name='og:title' content={pageTitle} />
          <meta name='twitter:title' content={pageTitle} />
        </Helmet>
        <FadeInContainer>
          {!isModal && <SectionTitle title={'Deposit'} />}
          {userFailedKyc && (
            <div className={'pb-3'}>
              {' '}
              <BspotAlert
                type={'error'}
                message={
                  'You need to verify your account to perform a deposit.'
                }
              />
            </div>
          )}
          {children}
          {errorPayment && (
            <MakeDepositErrorMessage
              data={errorPayment}
              onClicked={() => {
                setErrorPayment(null);
                selectDefaultPaymentMethod();
              }}
            />
          )}
          {noPaymentResults && (
            <div className={'pb-3'}>
              {' '}
              <BspotAlert type={'error'} message={noPaymentResults} />
            </div>
          )}
          {(!checkout || (checkout && paymentMethodId !== BANK_ID.TRUSTLY)) && (
            <PaymentIframe
              checkout={checkout}
              onSuccessDeposit={onSuccessDeposit}
              onErrorDeposit={onErrorDeposit}
            />
          )}
          {(!checkout || (checkout && paymentMethodId === BANK_ID.TRUSTLY)) && (
            <PaymentCardComponent
              amount={amount}
              availableBanks={availableBanks}
              depositRangeHint={depositRangeHint}
              errors={errors}
              handleSubmit={handleSubmit}
              isModal={isModal}
              onSelectAmount={onSelectAmount}
              paymentMethodId={paymentMethodId}
              userFailedKyc={userFailedKyc}
              setCheckout={setCheckout}
              setNoPaymentResults={setNoPaymentResults}
            />
          )}
        </FadeInContainer>
        <div id='widget' />
      </>
    )
  );
}

//   0 -> DepositStatus.NOT_FOUND
//   1 -> DepositStatus.PENDING
//   2 -> DepositStatus.CONFIRMED
//   3 -> DepositStatus.REJECTED
//   4 -> DepositStatus.RETURN
//   5 -> DepositStatus.UNVERIFIED
//   11 -> DepositStatus.PENDING_ACTION
// else -> DepositStatus.UNKNOWN

export const MakeDepositErrorMessage = ({ data, onClicked }) => {
  const NameOnCardNotMatching =
    'Deposit On Hold : This deposit requires manual review since the name used on your payment method does not match the name on your ' +
    CONFIG.BRAND +
    ' account. Please contact the Player Experience Team.';
  const Rejected =
    'Deposit Rejected : This deposit has been rejected. Please contact the Player Experience Team.';
  const PendingOrUnverified =
    'Deposit On Hold : This deposit requires manual review. Please contact the Player Experience Team.';
  const Unknown = 'There was a problem making the deposit.';

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (data) {
      const nameOnCard = data.nameOnCard;
      const paymentStatus = data.status;
      const getErrorMessage = () => {
        if (paymentStatus === 5 && nameOnCard) {
          // on hold
          return NameOnCardNotMatching;
        }
        if (paymentStatus === 3) {
          //rejected
          return Rejected;
        }
        if (paymentStatus === 5) {
          //unverified
          return PendingOrUnverified;
        }
        if (paymentStatus === 11 || paymentStatus === 1) {
          //pending
          return PendingOrUnverified;
        }
        if (paymentStatus === 'play_error_or_ibt') {
          // play_error
          return Unknown;
        }
        if (![0, 1, 2, 3, 4, 5, 11].includes(paymentStatus)) {
          //unknown
          return Unknown;
        }
      };

      setErrorMessage(getErrorMessage());
    }
  }, [NameOnCardNotMatching, data]);

  const message = (
    <div>
      {errorMessage}

      <>
        <br />
        <a
          style={{
            color: 'white',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          className='deposit-success-a'
          onClick={onClicked}
        >
          {data?.nameOnCard ? 'Try again' : 'Ok'}
        </a>
      </>
    </div>
  );

  return (
    <FadeInContainer className='d-flex col-12 flex-column mx-auto pb-3'>
      <BspotAlert type={'error'} message={message} />
    </FadeInContainer>
  );
};
