import { Api } from 'common/api';
import { StriveContent } from 'services/content/types';

export const getAboutUs = (): Promise<StriveContent> => {
  return Api.get<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/content/page/about-us`,
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Error get about-us');
    }
    return {
      page: res.data.Data.name,
      title: res.data.Data.structure['full area'][0].section.page_title.value,
      content:
        res.data.Data.structure['full area'][2].section.section_content.value,
    };
  });
};
