import { useQuery } from '@tanstack/react-query';
import { getResponsibleGaming } from 'services/content/responsible-gaming/responsible-gaming';

export const useResponsibleGaming = () => {
  return useQuery({
    queryKey: ['use-responsible-gaming'],
    queryFn: getResponsibleGaming,
    keepPreviousData: false,
  });
};
