export enum BspotCashierMessage {
  DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS',
  DEPOSIT_FAIL = 'DEPOSIT_FAIL',
  WITHDRAW_REQUEST_SUCCESS = 'WITHDRAW_REQUEST_SUCCESS',
  WITHDRAW_REQUEST_FAIL = 'WITHDRAW_REQUEST_FAIL',
  CANCEL_WITHDRAW_REQUEST_SUCCESS = 'CANCEL_WITHDRAW_REQUEST_SUCCESS',
  CANCEL_WITHDRAW_REQUEST_FAIL = 'CANCEL_WITHDRAW_REQUEST_FAIL',
  WELCOME = 'WELCOME',
  PLAYER_DISABLED = 'PLAYER_DISABLED',
  KYC_FAIL = 'KYC_FAIL',
  AUTH = 'AUTH',
  AUTH_ERROR = 'AUTH',
  REGISTER = 'REGISTER',
  REGISTER_ERROR = 'REGISTER_ERROR',
  CLOSE = 'CLOSE',
  BUY_CREDITS_SUCCESS = 'BUY_CREDITS_SUCCESS',
  BUY_CREDITS_FAIL = 'BUY_CREDITS_FAIL',
  EDIT_PERSONAL_INFO_SUCCESS = 'EDIT_PERSONAL_INFO_SUCCESS',
  EDIT_PERSONAL_INFO_FAIL = 'EDIT_PERSONAL_INFO_FAIL',
  UPLOAD_DOCUMENTS_SUCCESS = 'UPLOAD_DOCUMENTS_SUCCESS',
  UPLOAD_DOCUMENTS_FAIL = 'UPLOAD_DOCUMENTS_FAIL',
  TNC_REQUIRED_ACCEPTED = 'TNC_REQUIRED_ACCEPTED',
  TNC_REQUIRED_DECLINED = 'TNC_REQUIRED_DECLINED',
  GO_LOGIN = 'GO_LOGIN',
  NO_TOURNAMENTS = 'NO_TOURNAMENTS',
}

export const postNativeMessage = (payload: any, info?: string) => {
  try {
    // @ts-ignore
    if (window.webkit) {
      // @ts-ignore
      window.webkit.messageHandlers['bspot-liberty'].postMessage({
        message: payload,
        info: info,
      });
    } else {
      console.log('window.webkit is undefined');
    }
  } catch (e) {
    console.error(e);
  }
};
