import { Api } from 'common/api';
import { GameGroup } from 'hooks/use-games-list';

export const getWelcomeContent = () => {
  return Api.get<any>(`${API_HOST}/api/v1/welcome-content`).then((res) => {
    if (!res.ok) {
      throw new Error('Could not retrieve games list');
    }
    return res.data;
  });
};
