import styled from '@emotion/styled';

export const SkeletonContainerDiv = styled.div`
  .skeleton-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1rem;
  }

  .skeleton {
    margin-bottom: 50px;
    flex-basis: calc(90% - 25px);
    display: flex;
    height: 88px;
    border-radius: 2px;
    background-color: #444;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 200% auto;
    animation: shine 1s ease-in-out infinite;
  }

  @media screen and (max-width: 902px) {
    .skeleton {
      flex-basis: calc(100% - 5px);
      width: 100%;
    }
  }

  @keyframes shine {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 400px;
    }
  }
`;
export const SkeletonGameProduct = () => {
  const items = [1, 2, 3, 4];
  const sk = [];
  for (const pm of items) {
    sk.push(
      <div className='skeleton' key={pm}>
        &nbsp;
      </div>,
    );
  }
  return (
    <SkeletonContainerDiv>
      <div className='skeleton-wrapper'>{sk}</div>
    </SkeletonContainerDiv>
  );
};

export default SkeletonGameProduct;
