import { Api } from 'common/api';

export enum BANK_ID {
  SAFECHARGE = 177,
  NUVEI = 176,
  TRUSTLY = 187,

  NUVEI_CASHIER = 209,
}

export type MakeDepositPayload = {
  Amount: number;
  BankId: number;
  ReturnSuccessUrl: string;
  ReturnCancelUrl: string;
  ReturnErrorUrl: string;
  ExtraDetails?: { key: string; value: string }[] | [];
  Locale: string;
  AccountId?: number;
  AccountPrefillRequested?: boolean;
};

export const makeBankDeposit = (payload: MakeDepositPayload): Promise<any> => {
  return Api.post<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/railsapi/v1/deposits/perform`,
    { data: payload },
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to make a deposit');
    }
    return res.data.Data;
  });
};

export const getDepoStatus = (depositRequestId: any): Promise<any> => {
  return Api.post<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/railsapi/v1/deposits/status?d=${new Date().toString()}`,
    {
      data: {
        id: depositRequestId,
      },
    },
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to make a deposit');
    }
    if (res.data.Data.code === 500) {
      return null;
    }
    return res.data.Data;
  });
};
