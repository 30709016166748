import { Api } from 'common/api';
import { Profile } from 'services/auth/login';

export type Reward = {
  entityId: string;
  description: string;
  rewardTypeObject: {
    jsonClass: string;
    name: string;
    key: string;
    description: string;
    accountId: string;
    system: boolean;
    unitOfMeasureType: string;
    metadata: any[];
    created: string;
    id: string;
  };
  rewardRank: number[];
  rewardType: string;
  id: string;
  entityType: string;
  metadata: any[];
  rewardName: string;
  memberAcknowledgmentRequired: boolean;
  value: number;
  period: number;
};

type ProductMeta = {
  background: string;
  image: string;
  opt_in_action: string;
  app_opt_in_action: string;
};

export type Product = {
  id: string;
  productRefId: string;
  name: string;
  description: string;
  meta: ProductMeta;
};

export type Meta = {
  opted_in_label: string;
  logged_in_label: string;
  logged_out_label: string;
  opt_in_action: string;
  logged_out_action: string;
  app_opt_in_action: string;
  background: string;
  competition_type: string;
  image: string;
};

export type TournamentType = {
  backgroundImage: string;
  description: string;
  endTime: string;
  id: string;
  logoImage: string;
  meta: Meta;
  parentId: string;
  products: Product[];
  rewards: Reward[];
  startTime: string;
  status: string;
  subtitle: string;
  title: string;
  tnc: string;
  isOptedIn: boolean;
  totalRewards: string;
};

export type LeaderboardEntry = {
  id: number;
  player: {
    id: number;
    fullName: string;
    score: number;
  };
  isCurrentUser: boolean;
  pointDifference: number | null;
  onBoard: boolean;
  position?: number;
  reward?: string;
};

type OptInResponse = {
  jsonClass: string;
  accountId: string;
  memberId: string;
  participationId: string;
  value: number;
  bestScores: any[]; // You can replace 'any' with a more specific type if needed
  timestamp: number;
  updateCount: number;
  markerTimeStamp: number;
  goalReached: boolean;
  statusCode: number;
};

export const getTournamentLeaderboard = (
  contestId: string,
  playerId: string,
  leaderboardName: string | undefined,
): Promise<LeaderboardEntry[]> => {
  let url = `${API_HOST}/api/v1/leaderboards/${contestId}`;
  if (playerId) {
    url =
      url +
      '?playerId=' +
      playerId +
      (leaderboardName ? `&leaderboardName=${leaderboardName}` : '');
  }
  return Api.get(url).then((res) => {
    if (!res.ok) {
      throw new Error('There was an error fetching the leaderboard');
    }
    return res.data as LeaderboardEntry[];
  });
};

export const getTournamentLeaderboardV2 = (
  rewards: any,
  contestId: string,
  playerId: string,
  isOptedIn: boolean,
  leaderboardName: string | undefined,
): Promise<LeaderboardEntry[]> => {
  let url = `${API_HOST}/api/v1/leaderboards/${contestId}`;
  if (playerId) {
    url =
      url +
      '?playerId=' +
      playerId +
      (leaderboardName ? `&leaderboardName=${leaderboardName}` : '');
  }
  return Api.get(url).then((res) => {
    if (!res.ok) {
      throw new Error('There was an error fetching the leaderboard');
    }
    const d = formatItems(
      res.data,
      rewards,
      playerId,
      isOptedIn,
      leaderboardName,
    );
    return d as LeaderboardEntry[];
  });
};

const formatItems = (data, rewards, playerId, isOptedIn, leaderboardName) => {
  try {
    const lastPositionPlayer = data?.users[rewards.length - 2] || {
      score: '0pts',
    };
    let isCurrentUser = false;
    let onBoard = false;
    let items = rewards
      .join('')
      .split('')
      .map((reward, i) => {
        if (i === 0) return null;
        const player = data?.users[i - 1] || {};
        isCurrentUser = player.id && player.id?.toString() === playerId;
        if (isCurrentUser) onBoard = true;

        return {
          id: player.id || i,
          player,
          isCurrentUser,
          position: i,
          reward: rewards[i],
          onBoard,
          pointDifference: isCurrentUser
            ? lastPositionPlayer.score - player.score
            : undefined,
        };
      });
    items.shift();
    items = items.filter((el) => (el && el?.reward) || el?.isCurrentUser);
    if (isOptedIn && !onBoard) {
      const currentUser = data?.users?.filter(
        (player) => player.id === data?.user?.PlayerId.toString(),
      )[0] ?? {
        id: playerId,
        fullName: leaderboardName,
        score: 0,
      };

      const pointDifference = lastPositionPlayer.score - currentUser.score;
      items.push({
        id: currentUser.id,
        player: currentUser,
        isCurrentUser: true,
        position: currentUser.position,
        pointDifference: pointDifference,
        onBoard: onBoard,
        reward: undefined,
      });
    }
    return items;
  } catch (e) {
    throw new Error('ERROR: formatting leaderboards');
  }
};

export const getTournaments = (userId: string): Promise<TournamentType[]> => {
  let url = `${API_HOST}/api/v1/competitions`;
  if (userId) {
    url = url + '?playerId=' + userId;
  }

  return Api.get(url).then((res) => {
    if (!res.ok) {
      throw new Error('There was an error fetching the tournaments from BFF');
    }
    const t = res.data as TournamentType[];

    const d = [];
    for (const tournamentType of t) {
      const curr = Date.now();
      const tournamentEndTime = new Date(tournamentType.endTime).getTime();
      if (tournamentEndTime > curr) {
        d.push(tournamentType);
      }
    }
    return d;
  });
};

export const getTournament = (
  userId: string,
  contestId: string,
): Promise<TournamentType> => {
  return Api.get(`${API_HOST}/api/v1/tournament/${contestId}/${userId}`).then(
    (res) => {
      if (!res.ok) {
        throw new Error(
          `There was an error fetching the tournament: ${contestId}`,
        );
      }
      return res.data as TournamentType;
    },
  );
};

export const optinTournament = (
  user: Profile,
  tournamentPayload: {
    contestId: string;
    title: string;
    startTime: string;
    endTime: string;
  },
): Promise<OptInResponse> => {
  return Api.post(`${API_HOST}/api/v1/tournament/optin`, {
    data: { user, tournamentPayload },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(
        `There was an error opt in the tournament: ${tournamentPayload.contestId}`,
      );
    }
    return res.data as OptInResponse;
  });
};
