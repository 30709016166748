import React, { useEffect } from 'react';
import { Text, TextSize } from 'components/atoms/text';
import { CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import { useLimitsHistory } from 'hooks/use-limits-history/use-limits-history';
import { getFormattedDateUS, getUiTime, toCurrency } from 'common/bspot-shared';

export const DepositLimitsHistory = ({ shouldRefetch }) => {
  const { data: limitsHistory, refetch } = useLimitsHistory();

  useEffect(() => {
    if (shouldRefetch) refetch();
  }, [refetch, shouldRefetch]);

  if (limitsHistory?.LimitsHistory?.length > 0) {
    return (
      <Card>
        <CardHeader
          title={
            <Text bold size={TextSize.H6}>
              {'Limits History'}
            </Text>
          }
        ></CardHeader>
        <div className={'d-flex flex-column pb-4'}>
          <div
            className={'d-flex px-3 py-2'}
            style={{ borderBottom: '1px solid rgba(119, 140, 166, 0.15)' }}
          >
            <div className={'col-3'}>
              <Text bold size={TextSize.Hint} color={'#999999'}>
                {'DATE'}
              </Text>
            </div>
            <div className={'col-5'}>
              <Text bold size={TextSize.Hint} color={'#999999'}>
                {'SAVE'}
              </Text>
            </div>
            <div className={'col-4 d-flex flex-column align-items-end'}>
              <Text bold size={TextSize.Hint} color={'#999999'}>
                {'AMOUNT'}
              </Text>
            </div>
          </div>

          {limitsHistory?.LimitsHistory?.map((lh, index) => (
            <div
              key={lh.CreatedAt + '-' + index}
              className={'d-flex px-3 py-3'}
              style={{
                borderBottom:
                  limitsHistory?.LimitsHistory.length !== index + 1
                    ? '1px solid rgba(119, 140, 166, 0.15)'
                    : 'none',
              }}
            >
              <div className={'col-3'}>
                <Text size={TextSize.Body}>
                  {getFormattedDateUS(new Date(lh.CreatedAt))}{' '}
                  {getUiTime(lh.CreatedAt)}
                </Text>
              </div>
              <div
                className={'col-5 d-flex flex-column justify-content-center '}
              >
                <Text bold size={TextSize.Body}>
                  {'Funding Limit - ' + lh.LimitDurationToString}
                </Text>
              </div>
              <div
                className={
                  'col-4 d-flex flex-column justify-content-center align-items-end'
                }
              >
                <Text size={TextSize.Body}>{toCurrency(lh.Amount)}</Text>
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  }
  return null;
};
