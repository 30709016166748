import { useEffect, useState } from 'react';
import { animate, useMotionValue, useTransform } from 'framer-motion';
import { toCurrency } from '../../common/bspot-shared';

export const useBalanceIncrease = ({ balance }) => {
  const [start, setStart] = useState(false);
  const count = useMotionValue(balance);
  const rounded = useTransform(count, toCurrency);
  const [prevValue, setPrevValue] = useState(balance);
  useEffect(() => {
    let timer;
    if (balance && balance > prevValue) {
      const controls = animate(count, balance, { duration: 3 });
      setStart(true);
      controls.then(() => {
        timer = setTimeout(() => {
          setStart(false);
        }, 1000);
      });
    } else {
      count.set(balance);
    }
    setPrevValue(balance);
    return () => {
      timer && clearTimeout(timer);
    };
  }, [balance, count, prevValue]);

  return { start, rounded, prevValue };
};
