import styled from '@emotion/styled';
import { Spinner } from 'icons/svg';
import { InCenter } from 'pages/account-history/styled';

const StyledInCenter = styled(InCenter)`
  padding-top: 24px;
`;

export const CircularLoader = ({ color, size }) => (
  <StyledInCenter isLoading={true}>
    <Spinner color={color} size={`${size}`} />
  </StyledInCenter>
);
