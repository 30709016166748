import { Link } from 'components/atoms/link';
import { Text, TextSize } from 'components/atoms/text';
import styled from '@emotion/styled';
import { DepositLimits } from 'components/molecules/form-deposit-limits/deposit-limits';
import { SelfSuspension } from 'components/molecules/form-self-suspension/self-suspension';
import { usePlayLimits } from 'hooks/use-play-limits/use-play-limits';
import { Skeleton } from '@mui/material';
import { SelfExclusion } from 'components/molecules/form-self-exclusion/self-exclusion';
import { SectionTitle } from 'components/atoms/section-title/section-title';
import { useState } from 'react';
import { DepositLimitsHistory } from 'components/organisms/deposit-limits-history/deposit-limits-history';
import { Helmet } from 'react-helmet-async';
import { Routes } from 'common/routes';
import { CONFIG } from 'common/utils';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ResponsibleGamingSettingsPage = () => {
  const { data, isLoading, isError } = usePlayLimits();
  const [shouldRefetchHistory, setShouldRefetchHistory] = useState(false);

  const onChangeDepositLimits = () => {
    setShouldRefetchHistory(true);
  };

  const pageTitle = 'Responsible Gaming - ' + CONFIG.BRAND;

  if (isError) return <></>;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <SectionTitle title={' Responsible Gaming: b safe'} />
      <Column>
        <Text size={TextSize.Body}>
          {CONFIG.BRAND} promotes a responsible wagering environment at all
          times. Our{' '}
          <Link href={Routes.ResponsibleGaming.path}>
            b safe responsible gaming program
          </Link>{' '}
          provides tools and resources if you’re concerned about wagering.
        </Text>
        {isLoading ? (
          <>
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
          </>
        ) : (
          <>
            <DepositLimits
              {...data.limits[0]}
              onChangeDepositLimits={onChangeDepositLimits}
            />
            <SelfSuspension {...data.limits[1]} />
            <SelfExclusion {...data.limits[2]} />
            <DepositLimitsHistory shouldRefetch={shouldRefetchHistory} />
          </>
        )}
      </Column>
    </>
  );
};
export default ResponsibleGamingSettingsPage;
