import { Close } from 'icons/svg';
import { Button, OutlinedButton } from 'components/atoms/button';
import React from 'react';
import { BspotModal } from 'components/atoms/modal/modal';

export type WarningWagerModalProps = {
  onClose?(): void;
  product: any;
  show: boolean;
  onClickPlayLater?(product: any): void;
};
export const WarningModal = ({
  product,
  onClose,
  show,
  onClickPlayLater,
}: WarningWagerModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  const onPlayLater = () => {
    onClickPlayLater(product);
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Play Later?'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>

      <div className={'d-flex flex-column gap-1'}>
        <div>
          The Wager Details screen displays live info for upcoming races.
        </div>
        <div>
          {' '}
          Reviewing these details will allow you to wager on the selected race.
          It may take 15-30 minutes for credits from this wager to be ready to
          play!
        </div>
      </div>

      <div className={'d-flex flex-lg-row flex-column gap-2 '}>
        <OutlinedButton text='Go back & Play Now!' onClick={onCloseModal} />
        <Button text='Proceed & Play Later' onClick={onPlayLater} />
      </div>
    </BspotModal>
  );
};
export default WarningModal;
