import styled from '@emotion/styled';

export const InputWrapDiv = styled.div`
  .bspot-input-amount {
    display: block;
    width: 100%;
    border: 1px solid #383838;
    background-color: #383838;
    &:focus + label {
      font-weight: 600;
    }
    color: white;
    text-align: center;
    font-weight: bold;
    font-family: var(--font-family), sans-serif;
    border-radius: var(--spacing-2);
    padding: var(--spacing-2);
    order: 2;
    font-size: 2rem;
    margin-bottom: var(--spacing-1);
    opacity: 1;
    & disabled {
      opacity: 0.8;
    }
    &:focus-visible {
      outline: none;
    }
  }
`;
