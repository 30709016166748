import { FormProvider, useForm } from 'react-hook-form';
import { Text, TextSize } from 'components/atoms/text';
import { StyledForm } from './styled';
import { SocialSecurityForm } from 'components/molecules/form-social-secirity/social-security-form';
import { CredentialsForm } from 'components/molecules/form-credentials/credentials-form';
import { ResidenceForm } from 'components/molecules/form-residence/residence-form';
import { PersonalDetailsForm } from 'components/molecules/form-personal-details/personal-details-form';
import { Button, ButtonColor } from 'components/atoms/button';
import styled from '@emotion/styled';
import { Link } from 'components/atoms/link';
import { Routes } from 'common/routes';
import { useEffect } from 'react';
import { useNavigate } from 'hooks/use-navigate';

const StyledButton = styled(Button)`
  color: var(--text-default-color);
`;

const StriveStyledForm = styled(StyledForm)`
  text-align: start;
  border-radius: 32px;
  background-color: var(--bg-dark-color);
  padding: 30px 40px 40px;
  max-width: 480px;
  min-width: 350px;
  .credentials-title {
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid rgb(74, 74, 74);
    color: rgb(153, 153, 153);
    font-size: 12px;
    padding-bottom: 8px;
  }
  @media only screen and (max-width: 414.98px) {
    padding: 30px 22px;
  }
`;

export const SingleCreationForm = ({ defaultValues, onSubmit, loading }) => {
  const navigate = useNavigate();
  const methods = useForm({ defaultValues, mode: 'onBlur' });

  const submit = async () => {
    const isValid = await methods.trigger();

    if (!isValid) {
      return;
    }

    if (typeof onSubmit === 'function') {
      onSubmit(methods.getValues());
    }
  };

  useEffect(() => {
    const errors = methods.formState.errors;
    const firstError = Object.keys(errors).reduce((field, a) => {
      return errors[field] ? field : a;
    }, null);

    if (firstError) {
      methods.setFocus(firstError);
    }
  }, [methods.formState.errors, methods.setFocus]);

  return (
    <FormProvider {...methods}>
      <div className={'d-flex flex-column align-items-center'}>
        <StriveStyledForm>
          <Text
            as='h1'
            size={TextSize.H3}
            text="Let's get you signed up!"
            color='rgb(10, 235, 136)'
            className='text-start my-0 fw-bold'
          />
          <Text
            as='h2'
            text='Regulations require we verify your age and state of residence before opening your account. Please fill out the following fields and we’ll have you playing in no time'
            className='text-start my-0'
          />
          <div className={'mt-1 col-12 credentials-title'}>CREDENTIALS</div>
          <CredentialsForm />
          <div className={'mt-1 col-12 credentials-title'}>
            PERSONAL DETAILS
          </div>
          <PersonalDetailsForm />
          <div className={'mt-1 col-12 credentials-title'}>
            PLACE OF RESIDENCE
          </div>
          <ResidenceForm />
          <div className={'mt-1 col-12 credentials-title'}>
            YOUR SOCIAL SECURITY NUMBER
          </div>
          <SocialSecurityForm />
          <div className='d-flex justify-content-center w-100 mt-3 font-weight-bold'>
            <Text text='Already have an account?'>&nbsp;</Text>
            <Link
              href={Routes.Login.path}
              text=' Log in'
              onMouseDown={() => {
                navigate(Routes.Login.path);
              }}
            />
          </div>
          <StyledButton
            color={ButtonColor.Primary}
            text={'Register'}
            isLoading={loading}
            onClick={submit}
          />
          <Text
            className='d-flex justify-content-center text-center'
            text='© 2024 Game Play Network, Inc. All Rights Reserved.'
            color='var(--text-grey-color)'
          />
        </StriveStyledForm>
      </div>
    </FormProvider>
  );
};
