export const sendGTMEvent = (event: any) => {
  window['dataLayer'] && window['dataLayer'].push(event);
};

export enum GA_EVENTS {
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
}

export const sendGAEvent = (event: GA_EVENTS, eventData: any) => {
  window['gtag'] && window['gtag']('event', event, eventData);
};
