import { Profile } from 'services/auth/login';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export type BuyCreditsPayload = {
  game_product_id: string | number;
  wager_package_id?: string | number;
  replace_unavailable_requested_wagers?: boolean;
};

// 36.778259, -119.417931
export const buyCredits = async (
  orderData: BuyCreditsPayload,
  session: Profile,
): Promise<any> => {
  const geolocation = localStorage.getItem('geo');

  if (!geolocation) throw new Error('Geolocation not found');

  let headers: any = {
    Accept: '*/*',
    'Content-type': 'application/json',
    'x-player-id': session.playerId,
    'x-gpn-pam-player-id': session.playerId,
    'x-session-id': session.sessionId,
    'x-gpn-pam-session-token': session.playerSessionToken,
    'x-gpn-latitude': geolocation.split('@')[0],
    'x-gpn-longitude': geolocation.split('@')[1],
  };
  const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/orders`;
  const request = {
    method: 'POST',
    body: JSON.stringify(orderData),
    headers: headers,
  };
  const response: Response = await fetch(url, request);
  if (response.status === 403) return { status: 403 };
  if (!response.ok) throw new Error('Failed to request buy credits');
  //
  if (response.ok) {
    try {
      const data = await response.json();
      return data;
    } catch (err) {
      console.log('expected :');
      return { status: 200 };
    }
  }
};
