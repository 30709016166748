import { FilterItem } from 'pages/account-history/styled';
import React from 'react';

export type PeriodFilterItemProps = {
  changePeriod?(number: number): void;
  period: number;
};

export function PeriodFilterItem({
  period,
  changePeriod,
}: PeriodFilterItemProps) {
  return (
    <div
      className={'py-4 d-flex flex-row '}
      aria-label='outlined primary button group'
    >
      <FilterItem
        disabled={period === 7}
        className={period === 7 ? 'btn_first selected' : 'btn_first'}
        key={7}
        onClick={() => changePeriod(7)}
      >
        {7 + ' days'}
      </FilterItem>
      <FilterItem
        disabled={period === 14}
        className={period === 14 ? 'selected' : ''}
        key={14}
        onClick={() => changePeriod(14)}
      >
        {14 + ' days'}
      </FilterItem>
      <FilterItem
        disabled={period === 30}
        className={period === 30 ? 'btn_last selected' : 'btn_last '}
        key={30}
        onClick={() => changePeriod(30)}
      >
        {30 + ' days'}
      </FilterItem>
    </div>
  );
}
