import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { SnackbarContent, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { Close, Warning, Success, Error, Notification } from 'icons/svg';
import styled from '@emotion/styled';
import { bp } from 'common/theme';

const StyledContent = styled(SnackbarContent)`
  .MuiSnackbarContent-message {
    width: 100%;
    padding: 0;
  }

  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: ${({ square }) => (square ? '32px' : '999px')};
  border: 3px solid #000000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  background: var(--bsp-toast-background, rgba(255, 255, 255, 0.85));
  color: black;
  backdrop-filter: blur(2.5px);
  width: 100%;

  @media only screen and ${bp.md} {
    min-width: 400px;
    max-width: 400px;
  }
`;

export const Icon = (variant: ToastStatus) => {
  switch (variant) {
    case ToastStatus.success:
      return Success();
    case ToastStatus.error:
      return Error();
    case ToastStatus.warning:
      return Warning({});
    default:
      return Notification();
  }
};

export enum ToastStatus {
  success = 'success',
  error = 'error',
  warning = 'warning',
  notification = 'info',
  default = 'default',
}

export type ToastProps = {
  id?: string;
  variant?: ToastStatus;
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  cta?: React.ReactNode;
  status?: ToastStatus;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
};

export const Toast = React.forwardRef<HTMLDivElement, ToastProps>(
  (
    { id, message, title, cta, status = ToastStatus.notification, icon, style },
    ref,
  ) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = React.useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <StyledContent
        data-testid={`${id}-toast-content`}
        square={!!message}
        ref={ref}
        style={style}
        message={
          <>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              gap='8px'
              width='100%'
            >
              <Stack direction='row' gap='8px'>
                <Box
                  data-testid={`${id}-icon`}
                  display='flex'
                  alignItems='center'
                >
                  {icon ?? Icon(status)}
                </Box>
                <Typography
                  component={'span'}
                  fontWeight='bold'
                  data-testid={`${id}-title`}
                >
                  {title}
                </Typography>
              </Stack>
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={handleDismiss}
                data-testid={`${id}-action`}
              >
                <Close fill='black' stroke='black' />
              </IconButton>
            </Stack>
            <Stack>
              <Typography data-testid={`${id}-message`}>{message}</Typography>
              <Box alignSelf={'flex-end'}>{cta}</Box>
            </Stack>
          </>
        }
      />
    );
  },
);
