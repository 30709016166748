import React, { useState } from 'react';
import { Close } from 'icons/svg';
import Badge from 'components/organisms/buy-credits/game-product/badge';
import { Button, OutlinedButton } from 'components/atoms/button';
import { useNavigate } from 'hooks/use-navigate';
import { Routes } from 'common/routes';
import { useWagerDetails } from 'hooks/use-wager-details/use-wager-details';
import DetailsTable from 'components/organisms/buy-credits/modals/wager-details/details-table/details-table';
import Chip from 'components/organisms/buy-credits/game-product/chip';
import { BspotModal } from 'components/atoms/modal/modal';
import { BspotAlert } from 'components/molecules/alerts/alert';
import { Checkbox, FormControlLabel } from '@mui/material';
import { GameProduct } from 'services/get-game-products/get-game-products';
import './details-table/tables/styles.scss';

const Scroll = (props) => {
  return (
    <div id='scroll-no-border' style={{ overflowY: 'scroll' }}>
      {props.children}
    </div>
  );
};

export type WagerDetailsProps = {
  onClose?(): void;
  product: any;
  instantPlayEligible: boolean;
  onClickBadge?(event: any, product: any): void;
  onConfirm: (
    product: GameProduct,
    wagerDetailsId?: string,
    replaceWagers?: boolean,
  ) => Promise<void>;
  show: boolean;
};
export const WagerDetails = ({
  product,
  onClose,
  show,
  instantPlayEligible,
  onConfirm,
  onClickBadge,
}: WagerDetailsProps) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const {
    data: wagerDetails,
    isFetching,
    refetch,
  } = useWagerDetails(product.id);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const onCloseModal = () => {
    onClose();
  };

  const reshuffle = () => {
    refetch(product.id);
  };

  const confirm = async () => {
    //place order
    setLoading(true);
    setFeedback(null);
    const res = await onConfirm(product, wagerDetails.wagerDetails.id, checked);
    setFeedback(res);
    setLoading(false);
  };

  return (
    <BspotModal show={show} maxHeight={'75vh'}>
      <Scroll className={''}>
        <div className={'d-flex flex-row justify-content-between'}>
          <h2 className={'my-0'}>
            <span>{'Your Wager'}</span>
          </h2>
          <span className={''}>
            <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
          </span>
        </div>

        <div className={'text-left d-flex flex-column'}>
          <div className='mx-auto d-flex flex-column justify-content-center align-items-center py-1'>
            <Chip
              relative={true}
              size={'70px'}
              product={product}
              onClickChip={() => {}}
            />
            <div className={'my-1'}>
              <Badge product={product} onClickBadge={onClickBadge} />
            </div>
          </div>

          <DetailsTable
            all={isFetching ? null : wagerDetails}
            product={product}
          ></DetailsTable>

          <div className='py-2 text-left wager-text'>
            By clicking
            <b>
              {' '}
              {product.expected_wait === 'IMMEDIATE'
                ? 'Confirm & Play Now'
                : instantPlayEligible
                ? 'Confirm & Play Later'
                : 'Confirm & Place Final Wager'}{' '}
            </b>
            I am agreeing to deduct {'$' + product.cost} from my Cash Account to
            wager on these horse races. If any of the above cannot be placed, I
            authorize you to place another wager in the same amount and same
            pool type.
          </div>

          <div className={'d-flex flex-row py-1'}>
            <div
              className={
                'd-flex flex-column justify-content-center align-items-center'
              }
              style={{ marginRight: '-16px' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onClick={() => setChecked(!checked)}
                    name={'agreement'}
                    sx={{
                      marginTop: -1,
                      '&.Mui-checked': {
                        color: 'var(--primary-color)',
                      },
                      '.MuiFormControlLabel-label': {
                        lineHeight: '12px!important',
                      },
                    }}
                  />
                }
                label={''}
              />
            </div>
            <span
              className={'wager-text-check p'}
              style={{ lineHeight: '12px!important' }}
            >
              If any of the above wagers cannot be placed, please refund the
              amount to my Cash Account
            </span>
          </div>
        </div>

        {feedback && <BspotAlert type={'success'} message={feedback} />}
        <div className={'d-flex flex-row gap-2 pt-1'}>
          {!feedback && (
            <>
              <OutlinedButton text='Reshuffle' onClick={reshuffle} />
              <Button
                text={
                  product.expected_wait === 'IMMEDIATE'
                    ? 'Confirm & Play Now'
                    : instantPlayEligible
                    ? 'Confirm & Play Later'
                    : 'Confirm & Place Final Wager'
                }
                disabled={loading}
                isLoading={loading}
                onClick={confirm}
              />
            </>
          )}

          {feedback && (
            <OutlinedButton
              text='Play Now!'
              onClick={() => navigate(Routes.Home)}
            />
          )}
        </div>
      </Scroll>
    </BspotModal>
  );
};
export default WagerDetails;
