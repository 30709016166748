import styled from '@emotion/styled';

export const SkeletonWrapper = styled.div`
  display: grid;
  gap: 10px;
  @media (min-width: 1366px) {
    grid-template-columns: repeat(4, minmax(92px, 1fr));
  }

  @media only screen and (max-width: 414.98px) {
    grid-template-columns: repeat(2, minmax(110px, 1fr));
  }
`;

export const Skeleton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 63px;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--text-light-color);
  border: 2px solid var(--text-light-color);
  background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 10%,
          rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% auto;
  animation: shine 0.2s ease-in-out infinite;
}

@media screen and (max-width: 900px) {
  .skeleton {
    flex-basis: calc(100% - 50px);
  }
}

@keyframes shine {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 400px;
  }
`;
