import styled from '@emotion/styled';
import { FadeInContainer } from './styled';
import { FC, useEffect } from 'react';
import { useAuth } from 'contexts/authentication';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'common/routes';
import { sendDepositSuccessAnalyticsEvent } from 'common/utils';

interface IframeProps {
  checkout: boolean;
}
const IframeStyled = styled.iframe<IframeProps>`
  border: none;
  min-height: ${({ checkout }) => (checkout ? `820px` : `0`)};
  * {
    ::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-radius: 10px;
  width: 100%;
`;

export const PaymentIframe: FC<{
  checkout: string | null;
  onSuccessDeposit: (amount) => void;
  onErrorDeposit: (data) => void;
}> = ({ checkout, onSuccessDeposit, onErrorDeposit }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const handlePaymentMessage = (event) => {
    if (event.data.event_id === auth.token) {
      if (event.data.data.inGame) {
        // error
        if (event.data.data.status) {
          onErrorDeposit(event.data.data);
          return;
        }
        // success
        if (!localStorage.getItem('depositEvent')) {
          localStorage.setItem('depositEvent', 'true');
          sendDepositSuccessAnalyticsEvent(
            event.data.data.deposit.toString(),
            auth.session.playerId,
            event.data.data.paramsAsObject,
            event.data.data.payment_method,
            event.data.data.clientUniqueID,
          );
        }
        onSuccessDeposit(event.data.data.deposit);
      } else {
        if (event.data.data.status) {
          onErrorDeposit(event.data.data);
          return;
        }

        if (!localStorage.getItem('depositEvent')) {
          localStorage.setItem('depositEvent', 'true');
          sendDepositSuccessAnalyticsEvent(
            event.data.data.deposit.toString(),
            auth.session.playerId,
            event.data.data.paramsAsObject,
            event.data.data.payment_method,
            event.data.data.clientUniqueID,
          );
        }

        navigate(
          Routes.WagersPage.path +
            '?fromIframe=true&deposit=' +
            event.data.data.deposit,
        );
      }
    }
  };
  return (
    <FadeInContainer className='d-flex col-12'>
      <IframeStyled
        checkout={checkout !== null}
        id='iframe-content'
        name={'iframe-content'}
        height={checkout !== null ? '820px' : '0'}
        src={checkout}
        allow='payment'
        onLoad={() => {
          window.addEventListener('message', handlePaymentMessage);
        }}
      />
    </FadeInContainer>
  );
};
