import { Text, TextSize } from 'components/atoms/text';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  getRequiredDocuments,
  uploadRequiredDocuments,
} from 'services/documents-center/documents-center';
import FileInput from 'pages/document-center/file-input';
import { Button } from 'components/atoms/button';
import { useAuth } from 'contexts/authentication';
import styled from '@emotion/styled';
import { Group } from 'pages/account-history/styled';
import { SectionTitle } from 'components/atoms/section-title/section-title';
import { BspotAlert } from 'components/molecules/alerts/alert';
import { Helmet } from 'react-helmet-async';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
import { useRequiredDocuments } from 'hooks/use-required-documents';
import { CONFIG } from 'common/utils';

export const Form = styled.form`
  .form-control:focus {
    box-shadow: none;
  }

  .bg-inputs {
    border: none;
    background-color: rgb(85, 85, 85);
    color: rgba(256, 256, 256, 0.5);
    font-weight: bold;
    padding: 10px 12px;
  }

  .password {
    background-color: transparent;
    border: 1px solid rgb(74, 74, 74);
  }

  .bg-inputs::placeholder {
    color: rgba(256, 256, 256, 0.5);
    font-weight: bold;
  }

  .addon-cta {
    background-color: rgb(68, 68, 68);
    color: rgba(256, 256, 256, 0.5);
    border: none;
    min-width: 82px;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
  }
`;

export const CardDoc = styled.div`
  display: flex;
  background-color: var(--bg-dark-color);
  flex-direction: column;
  gap: 24px;
  padding: 25px 30px;
  border: 3px solid var(--bg-dark-color);
  border-radius: 32px;
`;

const docText =
  'Regulations require we verify your personal details before we can open your account. If requested during sign up, please submit an image of your official, government-issued ID. This could be a Driver License, State ID Card or Passport. Our agents will contact you if additional information is required. Add your documents here, using the front and back of the same one.';

export default function DocumentCenter() {
  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  register('fileUploadF', { required: 'Please upload front document!' });
  register('fileUploadB', { required: 'Please upload back document!' });

  const [typeIds, setTypeIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const [fileContentF, setFileContentF] = useState(null);
  const [fileContentB, setFileContentB] = useState(null);

  const [fileUploadF, setFileUploadF] = useState(null);
  const [fileUploadB, setFileUploadB] = useState(null);

  const [feedback, setFeedback] = useState(null);
  const [isError, setIsError] = useState(null);

  const { data: requireDocs } = useRequiredDocuments();

  const onChangeFront = (fileName, file) => {
    setFileContentF(file);
    setValue('fileUploadF', file);
  };

  const onChangeBack = (fileName, file) => {
    setFileContentB(file);
    setValue('fileUploadB', file);
  };

  useEffect(() => {
    if (requireDocs?.length > 0) {
      setTypeIds(requireDocs[0]);
      handleUploadedFiles([requireDocs[1], requireDocs[2]]);
    }
  }, [requireDocs]);

  const handleUploadedFiles = (files) => {
    // FRONT
    if (files[0].status === 'upload_image_residence_uploaded') {
      setFileUploadF(files[0]);
    }
    // BACK
    if (files[1].status === 'upload_image_payment_proof_uploaded') {
      setFileUploadB(files[1]);
    }
  };

  const prepareFormData = (form) => {
    const formData = new FormData();
    formData.append('password', form.password);
    formData.append('image_id_sub_type', form.typeId);
    if (fileContentF) {
      formData.append('image_residence', fileContentF);
    }
    if (fileContentB) {
      formData.append('image_payment_proof', fileContentB);
    }
    return formData;
  };

  const onSubmitForm = (form) => {
    setFeedback(null);
    setLoading(true);

    uploadRequiredDocuments(prepareFormData(form), auth)
      .then((d) => {
        postNativeMessage(
          BspotCashierMessage.UPLOAD_DOCUMENTS_SUCCESS,
          JSON.stringify({ message: d[0] }),
        );
        setFeedback(d[0]);
        handleUploadedFiles([d[1][1], d[1][2]]);
        setIsError(false);
      })
      .catch((e) => {
        postNativeMessage(
          BspotCashierMessage.UPLOAD_DOCUMENTS_FAIL,
          JSON.stringify({ message: e }),
        );
        setFeedback('There was a problem uploading the documents');
        setIsError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const pageTitle = 'Documents center - ' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <div className='col col-12'>
        <SectionTitle title={' Documents Center'} />
      </div>
      {feedback && (
        <div className={'pb-4'}>
          <BspotAlert type={isError ? 'error' : 'success'} message={feedback} />
        </div>
      )}
      <div className='col col-12'>
        <CardDoc>
          <span style={{ lineHeight: '1' }}>{docText}</span>

          <Form onSubmit={handleSubmit(onSubmitForm)}>
            <div className={'row'}>
              <div className={'col-12 col  mb-1'}>
                <div className=''>
                  <select
                    name='typeId'
                    className='form-control bg-inputs'
                    {...register('typeId', {
                      required: 'Please select the type of ID',
                    })}
                  >
                    <option value=''>Select ID type</option>
                    {typeIds?.map(({ label, id }) => {
                      const key = `option-key-${id}`;
                      return (
                        <option
                          key={key}
                          data-testid='select-option'
                          value={id}
                        >
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <Group>
                {errors.typeId && (
                  <Text bold size={TextSize.Body} color='#d70022'>
                    {errors.typeId.message.toString()}
                  </Text>
                )}
              </Group>
            </div>

            <div className={'row'}>
              <div className={'col-12 col'}>
                <div className={'d-flex flex-column py-3'}>
                  <FileInput
                    placeholder={'Upload Id (Front)'}
                    onFileChange={onChangeFront}
                    uploadedData={fileUploadF}
                  />
                  {errors.fileUploadF && !fileContentF && (
                    <p style={{ color: '#d70022', fontWeight: 'bold' }}>
                      {errors.fileUploadF.message.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-12 col'}>
                <div className={'d-flex flex-column pb-3'}>
                  <FileInput
                    placeholder={'Upload Id (Back)'}
                    onFileChange={onChangeBack}
                    uploadedData={fileUploadB}
                  />
                  {errors.fileUploadB && !fileContentB && (
                    <p style={{ color: '#d70022', fontWeight: 'bold' }}>
                      {errors.fileUploadB.message.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-12 col'}>
                <input
                  type='password'
                  className='form-control bg-inputs password'
                  {...register('password', {
                    required: 'This field is required',
                  })}
                  placeholder={'Current Password'}
                  aria-label='current password'
                  aria-describedby='Current Password'
                />
              </div>
              <Group>
                {errors.password && (
                  <Text bold size={TextSize.Body} color='#d70022'>
                    {errors.password.message.toString()}
                  </Text>
                )}
              </Group>
            </div>

            <div className={'row pt-3'}>
              <div className={'col-12'}>
                <div className={'align-self-start'}>
                  <Button
                    id='Submit'
                    text={'Submit for review'}
                    disabled={loading}
                    isLoading={loading}
                  />
                </div>
              </div>
            </div>
          </Form>
        </CardDoc>
      </div>
    </>
  );
}
