import { useQuery } from '@tanstack/react-query';
import { getGameProductDetails } from 'services/get-game-products/get-game-products';
import { useAuth } from 'contexts/authentication';

export const useWagerDetails = (id) => {
  const { auth } = useAuth();
  return useQuery({
    queryKey: ['use-wager-details', id],
    queryFn: (): Promise<any> => getGameProductDetails(id, auth.session),
    keepPreviousData: false,
    staleTime: 500,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
