import React from 'react';
import { SvgProps } from './svg';

export function Facebook({
  size,
  width = '1rem',
  height = '1rem',
  fill = '#282828',
  onClick,
}: SvgProps) {
  return (
    <svg
      data-testid='facebook-icon'
      width={size || width}
      height={size || height}
      viewBox='0 0 9 16'
      fill={fill}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.84301 4.78C8.81214 4.74486 8.77423 4.71661 8.73173 4.69709C8.68924 4.67756 8.64311 4.6672 8.59634 4.66667H6.08301V3.72667C6.08301 3.54 6.12301 3.32667 6.42301 3.32667H8.42301C8.46703 3.32956 8.51111 3.32201 8.55166 3.30463C8.59221 3.28725 8.62808 3.26054 8.65634 3.22667C8.68633 3.1971 8.71006 3.16178 8.72609 3.12284C8.74213 3.08389 8.75015 3.04212 8.74967 3V0.333333C8.74967 0.244928 8.71456 0.160143 8.65204 0.0976311C8.58953 0.035119 8.50475 0 8.41634 0L5.52967 0C2.32967 0 2.08301 2.73333 2.08301 3.56667V4.66667H0.416341C0.327936 4.66667 0.243151 4.70179 0.180639 4.7643C0.118127 4.82681 0.0830078 4.91159 0.0830078 5V7.66667C0.0830078 7.75507 0.118127 7.83986 0.180639 7.90237C0.243151 7.96488 0.327936 8 0.416341 8H2.08301V15.6667C2.08301 15.7551 2.11813 15.8399 2.18064 15.9024C2.24315 15.9649 2.32794 16 2.41634 16H5.74967C5.83808 16 5.92286 15.9649 5.98538 15.9024C6.04789 15.8399 6.08301 15.7551 6.08301 15.6667V8H8.31634C8.39926 8.00042 8.47936 7.96991 8.541 7.91444C8.60264 7.85896 8.64138 7.78251 8.64968 7.7L8.92301 5.03333C8.92905 4.98782 8.92501 4.94155 8.91119 4.89777C8.89736 4.85399 8.87409 4.81379 8.84301 4.78Z'
        fill={fill}
      />
    </svg>
  );
}
