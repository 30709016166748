import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import 'common/theme/main.scss';
import { Size } from 'common/theme';
import { Disabled } from 'common/styles';
import { Spinner } from 'icons/svg';

export enum ButtonColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Secondary2 = 'secondary-2',
  Secondary3 = 'secondary-3',
  White = 'bg-pure',
}

interface SpinnerWrapper {
  isLoading?: boolean;
}

const SpinnerWrapper = styled.div<SpinnerWrapper>`
  align-items: center;
  display: flex;
  width: 0;
  overflow: hidden;
  line-height: 1;
  transition: width 0.3s, opacity 0.1s, margin-right 0.1s;
  opacity: 0;

  ${(props) =>
    props.isLoading &&
    css`
      margin-right: var(--spacing-2);
      width: 0.8em;
      height: 0.8rem;
      opacity: 1;
    `}

  svg {
    animation: rotation 0.9s infinite linear;
  }
`;

export const fontSizes: Record<string, string> = {
  [Size.Small]: '0.75rem',
  [Size.Medium]: '1rem',
  [Size.Large]: '1.5rem',
};

const IconWrapper = styled.span`
  font-size: 0.8em;
  vertical-align: middle;
  display: flex;
`;

interface StyledButton {
  hasSubText?: boolean;
  iconOnly?: boolean;
  $size?: string;
  $color?: string;
  truncateText?: boolean;
  isLoading?: boolean;
  shouldConvertCash?: boolean;
  isSmall?: boolean;
  withIcon?: boolean;
  type?: string;
  isALink?: boolean;
}

const StyledLinkButton = styled.a<StyledButton>`
  position: relative;
  color: var(--text-default-color);
  background-color: var(--${({ $color }) => $color}-color);
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family), sans-serif;
  border-radius: 999px;
  outline: none;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
  border: 2px solid var(--bg-dark-color);
  text-decoration: none;
  ${(props) =>
    !props.isSmall &&
    css`
      height: 40px;
    `}

  ${(props) =>
    !props.isSmall &&
    props.isLoading &&
    css`
      height: 40px;
    `}

  ${(props) =>
    props.withIcon ||
    (props.isLoading &&
      css`
        height: 45px;
      `)}


  ${(props) =>
    props.withIcon &&
    css`
      height: 45px;
    `}
  
  
  svg:not(.spinner) {
    width: 1.6em;
    height: 1.6em;
  }

  :disabled {
    ${Disabled()}
  }

  :hover {
    background-color: var(
      --${({ $color }) => ($color === ButtonColor.White ? $color + '-color' : $color + '-light-color')}
    );
  }

  span:first-of-type {
    font-size: ${(props) => (props.isSmall === true ? '0.75rem' : '14px')};
    line-height: ${(props) => (props.hasSubText ? '14px' : '1.2')};
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: ${(props) => (props.truncateText ? '100px' : 'none')};
    white-space: nowrap;
    font-weight: bolder;
    letter-spacing: ${(props) => (props.hasSubText ? '-.5px' : 'normal')};
  }

  span: nth-of-type(2) {
    line-height: ${(props) => (props.hasSubText ? '15px' : '1')};
    font-size: 12px;
    line-height: 12px;
  }

  @media screen and (max-width: 542px) {
    height: 40px;

    ${(props) =>
      props.isSmall &&
      css`
        height: 32px;
      `}

    span:first-of-type {
      font-size: 11px;
      letter-spacing: ${(props) => (props.hasSubText ? '-.5px' : 'normal')};
    }
    span: nth-of-type(2) {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;

const StyledButton = styled.button<StyledButton>`
  position: relative;
  color: var(--text-default-color);
  background-color: var(--${({ $color }) => $color}-color);
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family), sans-serif;
  border-radius: 999px;
  outline: none;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
  border: 2px solid var(--bg-dark-color);

  ${(props) =>
    !props.isSmall &&
    css`
      height: 40px;
    `}

  ${(props) =>
    !props.isSmall &&
    props.isLoading &&
    css`
      height: 40px;
    `}

  ${(props) =>
    props.withIcon ||
    (props.isLoading &&
      css`
        height: 45px;
      `)}


  ${(props) =>
    props.withIcon &&
    css`
      height: 45px;
    `}
  
  
  svg:not(.spinner) {
    width: 1.6em;
    height: 1.6em;
  }

  :disabled {
    ${Disabled()}
  }

  :hover {
    background-color: var(
      --${({ $color }) => ($color === ButtonColor.White ? $color + '-color' : $color + '-light-color')}
    );
  }

  span:first-of-type {
    font-size: ${(props) => (props.isSmall === true ? '0.75rem' : '14px')};
    line-height: ${(props) => (props.hasSubText ? '14px' : '1.2')};
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: ${(props) => (props.truncateText ? '100px' : 'none')};
    white-space: nowrap;
    font-weight: bolder;
    letter-spacing: ${(props) => (props.hasSubText ? '-.5px' : 'normal')};
  }

  span: nth-of-type(2) {
    line-height: ${(props) => (props.hasSubText ? '15px' : '1')};
    font-size: 12px;
    line-height: 12px;
  }

  @media screen and (max-width: 542px) {
    height: 36px;

    ${(props) =>
      props.isSmall &&
      css`
        height: 36px;
      `}

    span:first-of-type {
      font-size: 11px;
      letter-spacing: ${(props) => (props.hasSubText ? '-.5px' : 'normal')};
    }
    span: nth-of-type(2) {
      font-size: 10px;
      line-height: 10px;
    }
  }

  @media screen and (max-width: 999px) and (orientation: landscape) {
    height: 32px;
    span:first-of-type {
      font-size: 11px;
      letter-spacing: ${(props) => (props.hasSubText ? '-.5px' : 'normal')};
    }
    span: nth-of-type(2) {
      font-size: 10px;
      line-height: 10px;
    }

    svg:not(.spinner) {
      width: 0;
      height: 0;
    }

    padding: 4px 8px;
  }
`;
interface NotificationBadge {
  isMenu?: boolean;
}

export const Badge = styled.span<NotificationBadge>`
  position: absolute;
  top: -6px;
  right: ${({ isMenu }) => (isMenu ? `-16px` : `5px`)};
  background-color: red;
  color: white;
  height: 16px;
  width: 16px;
  font-size: 0.65em;
  border-radius: 50%;
`;

export type ButtonProps = {
  id?: string;
  as?: string;
  children?: ReactNode;
  leftIcon?: ReactNode;
  className?: string;
  type?: 'button' | 'submit';
  text?: string | ReactNode;
  subText?: string | ReactNode;
  color?: ButtonColor;
  size?: Size;
  disabled?: boolean;
  onClick?(e: React.SyntheticEvent): void;
  href?: string;
  isLoading?: boolean;
  truncateText?: boolean;
  badge?: boolean;
  isSmall?: boolean;
  withIcon?: boolean;
  isALink?: boolean;
};

export function Button({
  id,
  children,
  className,
  leftIcon,
  type = 'submit',
  truncateText = false,
  text = '',
  subText,
  color = ButtonColor.Primary,
  size = Size.Medium,
  disabled,
  isLoading,
  onClick,
  isSmall,
  badge = false,
  isALink = false,
  href,
}: ButtonProps) {
  if (isALink) {
    return (
      <StyledLinkButton
        id={id}
        className={className}
        $color={color}
        $size={size}
        truncateText={truncateText}
        isSmall={isSmall}
        data-testid='link as button'
        href={href}
      >
        {text && (
          <div className={'d-flex flex-column align-items-center'}>
            <span className={'fw-bold'}>{text}</span>
          </div>
        )}
        {children}
      </StyledLinkButton>
    );
  }

  return (
    <StyledButton
      type={type}
      id={id}
      className={className}
      $color={color}
      $size={size}
      truncateText={truncateText}
      hasSubText={!!subText}
      iconOnly={!subText && !text}
      isLoading={isLoading}
      disabled={disabled ?? isLoading}
      onClick={onClick}
      isSmall={isSmall}
      withIcon={!!leftIcon}
      data-testid='button'
    >
      {badge && <Badge />}
      <>
        {isLoading && (
          <SpinnerWrapper isLoading={isLoading}>
            <Spinner size='0.8em' />
          </SpinnerWrapper>
        )}

        {leftIcon && (
          <IconWrapper className={!subText && !text ? '' : 'me-2'}>
            {leftIcon}
          </IconWrapper>
        )}
      </>
      {(text || subText) && (
        <div className={'d-flex flex-column align-items-center'}>
          <span className={'fw-bold'}>{text}</span>
          {subText && <span>{subText}</span>}
        </div>
      )}
      {children}
    </StyledButton>
  );
}
