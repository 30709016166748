import './sweepstakes-game-product.scss';
import { FC, SyntheticEvent } from 'react';
import Chip from '../game-product/chip';
import { ProductText } from './product-text';
import { ProductSquare } from './product-square';
import { Timer } from '../game-product/timer';
import { BackgroundGradient } from './background-gradient';
import { LightDot } from './light-dot';
import { GameProduct as GameProductType } from '../../../../services/get-game-products/get-game-products';

type GameProductProps = {
  onShowBonus(product: GameProductType): void;
  onShowInfo(product: GameProductType): void;
  product: GameProductType;
  variant?: string;
};
export const SweepstakesGameProduct: FC<GameProductProps> = ({
  onShowBonus,
  onShowInfo,
  product,
  variant,
}) => {
  const handleShowBonus = (event: SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    onShowBonus(product);
  };

  return (
    <div
      key={product.id}
      className='game-product'
      onClick={() => onShowInfo(product)}
    >
      <BackgroundGradient color={product.color} />
      <ProductSquare
        color={product.color}
        text={product.text}
        onClickBonusInfo={handleShowBonus}
      />
      <ProductText color={product.color} />
      <div className='chip-wrapper'>
        <Chip product={product} relative onClickChip={() => {}} size='100%' />
      </div>
      {variant !== 'instant' && (
        <span className='no-instant'>
          <span className='no-instant-message'> WAIT 15-30 MINS TO PLAY </span>
          <Timer className='badge' size={'12px'} />
        </span>
      )}
      <LightDot className='middle-left' />
      <LightDot className='top-middle' />
      <LightDot className='top-right' />
      <LightDot className='bottom-left' />
      <LightDot className='bottom-right' />
    </div>
  );
};
