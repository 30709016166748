import { Api } from 'common/api';

export const playAll = (): Promise<number> => {
  return Api.post<{ revealed_prize_amount: number }>(
    `${API_HOST}/api/rgs-proxy/api/v1/play_all`,
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to play all');
    }

    return res.data.revealed_prize_amount;
  });
};
