import { OutlinedButton } from 'components/atoms/button';
import { BspotModal } from 'components/atoms/modal/modal';
import { FC } from 'react';
import Chip from '../game-product/chip';
import { GameProduct } from 'services/get-game-products/get-game-products';

type DelayedPurchaseModalProps = {
  gameProduct: GameProduct;
  show: boolean;
  onClose: () => void;
};

export const DelayedPurchaseModal: FC<DelayedPurchaseModalProps> = ({
  gameProduct,
  show,
  onClose,
}) => {
  return (
    <BspotModal show={show} smallLg>
      <h1 className='text-uppercase text-center'>All done!</h1>
      <div className='d-flex justify-content-center'>
        <Chip product={gameProduct} relative />
      </div>
      <span>
        Your ${gameProduct.cost} Credits are getting uploaded to your account.
      </span>
      <span>
        This can take up to <b>30 minutes</b>. You will see the credits balance
        updated when the process is complete.
      </span>
      <div className='d-flex justify-content-end'>
        <OutlinedButton text='Ok, got it.' onClick={onClose}></OutlinedButton>
      </div>
    </BspotModal>
  );
};
