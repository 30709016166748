import { Api } from 'common/api';
import { Route, Routes } from 'common/routes';
import { setPersistentCookie } from 'common/utils';
import { useNavigate } from 'hooks/use-navigate';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
} from 'react';
import { interceptHttpRequests } from 'services/auth-interceptor/auth-interceptor';
import {
  Auth,
  KycStatus,
  login,
  LoginCredentials,
  Profile,
  UserStatus,
} from 'services/auth/login';
import { logout } from 'services/auth/logout';
import { NewUserRegistration, register } from 'services/auth/register';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
import { datadogRum } from '@datadog/browser-rum';
import { useSearchParams } from 'react-router-dom';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
const resolveToken = (data) => {
  const jwtDataPart = data.split('.')[1];
  const jwtData: Auth = JSON.parse(atob(jwtDataPart));
  const auth = {
    token: data,
    ...jwtData,
    kyc: data?.kyc,
    status: data?.status,
  };
  return auth;
};

export type AuthContext = {
  signIn?: (credentials: LoginCredentials) => Promise<void>;
  signOut?: (to?: Route, shouldLogout?: boolean) => Promise<void>;
  signUp?: (form: NewUserRegistration) => Promise<void>;
  profile?: Profile;
  auth?: Auth;
  setAuth?: Dispatch<SetStateAction<Auth>>;
  token?: string;
  updateAuthAndToken?(auth: Auth): void;
  updateKyc?: (kyc: KycStatus) => void;
};

const defaultAuthState = {
  status: undefined,
  session: undefined,
  token: undefined,
  auth: undefined,
  kyc: undefined,
};

export const AuthContext = React.createContext<AuthContext>({});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = (props) => {
  // @ts-ignore
  const sessionStorageAuth = sessionStorage.getItem('auth');
  const [auth, setAuth] = React.useState<Auth>(
    sessionStorageAuth ? JSON.parse(sessionStorageAuth) : defaultAuthState,
  );
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!auth.token) {
      Api.defaultHeaders.delete('Authorization');
      return;
    }
    // if (FeatureFlagsService?.featureFlags?.salesforceLiveAgent) {
    //   window.Bspot.auth = auth;
    //   initSalesforceChat(auth);
    // }

    setApiAuthorization(auth.token);
  }, [auth.token]);

  useEffect(() => {
    interceptHttpRequests(() => invalidateUserSession());
  }, []);

  const signOut = React.useCallback(
    async (to = Routes.Lobby, shouldLogout = true) => {
      setAuth(defaultAuthState);
      sessionStorage.removeItem('auth');
      localStorage.removeItem('xtremepush.data');
      window.Bspot.auth = null;
      // if (FeatureFlagsService.featureFlags.salesforceLiveAgent) {
      //   DestroySalesforceChat();
      // }
      //navigate(to);
    },
    [navigate],
  );

  const invalidateUserSession = () => {
    if (!auth.session) return;
    signOut();
  };

  const updateKyc = React.useCallback(
    (newStatus: KycStatus) => {
      const updated = {
        ...auth,
        kyc: {
          ...auth.kyc,
          attempts: newStatus?.attempts,
        },
      };
      setAuth(updated);
      setSessionStorageAuth(updated);
    },
    [auth, setAuth],
  );

  const initSalesforceChat = (auth) => {
    window['embedded_svc'].settings.prepopulatedPrechatFields = {
      FirstName: auth?.session.firstName,
      LastName: auth?.session.lastName,
      Email: auth?.session.email,
      WebEmail: auth?.session.email,
      SuppliedEmail: auth?.session.email,
      Origin: 'Liberty Web',
      Subject:
        'New live chat ' +
        auth?.session.firstName +
        ' ' +
        auth?.session.lastName,
    };
    // window.initESW('https://service.force.com');
  };

  const DestroySalesforceChat = () => {
    window['embedded_svc'].settings.prepopulatedPrechatFields = {
      FirstName: null,
      LastName: null,
      Email: null,
      WebEmail: null,
      SuppliedEmail: null,
      Subject: null,
    };
    window?.initESW('https://service.force.com');
  };

  const updateAuthAndToken = (auth) => {
    setAuth(auth);
    // if (FeatureFlagsService?.featureFlags?.salesforceLiveAgent) {
    //   window.Bspot.auth = auth;
    //   initSalesforceChat(auth);
    // }
    setApiAuthorization(auth.token);
    setSessionStorageAuth(auth);
    initDatadog(auth);
  };

  const signUp = async (form) => {
    const auth = await register(form);
    if (process.env.NODE_ENV === 'development') {
      console.log('AUTH : ' + JSON.stringify(auth));
    }
    postNativeMessage(
      BspotCashierMessage.REGISTER,
      JSON.stringify({
        token: auth.token,
        credentials: { email: form.email, password: form.password },
        tnc_required: auth.status.tnc_required,
      }),
    );
    updateAuthAndToken(auth);
  };

  const signIn = async (credentials: LoginCredentials) => {
    const auth = await login(credentials);
    if (process.env.NODE_ENV === 'development') {
      console.log('AUTH : ' + JSON.stringify(auth));
    }
    postNativeMessage(
      BspotCashierMessage.AUTH,
      JSON.stringify({
        token: auth.token,
        credentials: credentials,
        tnc_required: auth.status.tnc_required,
      }),
    );
    updateAuthAndToken(auth);
  };

  const setApiAuthorization = (token) => {
    Api.defaultHeaders.set('Authorization', `Bearer ${token}`);
  };

  const setSessionStorageAuth = (auth) => {
    sessionStorage.setItem('auth', JSON.stringify(auth));
  };

  const setExtremePushPlayerId = (auth) => {
    if (!window['xtremepush']) return;
    window['xtremepush']('set', 'user_id', auth.session?.playerId || '');
  };

  const initDatadog = (auth) => {
    datadogRum.setUser({
      id: auth.session.playerId.toString(),
      name: auth.session.firstName + ' ' + auth.session.lastName,
      session: auth.session.sessionId,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        signUp,
        auth,
        setAuth,
        updateAuthAndToken,
        updateKyc,
        profile: auth?.session,
        token: auth?.token,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
