import { GamesProductContainer } from 'components/organisms/buy-credits/styled';
import SkeletonGameProduct from 'components/organisms/buy-credits/game-product-list/skeleton-game-products';
import {
  GameProduct as GameProductType,
  GameProducts,
} from 'services/get-game-products/get-game-products';
import { FC } from 'react';
import { AppLoader } from 'components/molecules/app-loader/app-loader';
import { SweepstakesGameProduct } from '../sweepstakes-game-product/sweepstakes-game-product';

export type GameProductListProps = {
  onClickBonus: (product: GameProductType) => void;
  onClickProduct: (product: GameProductType) => void;
  products: GameProducts['gameProducts'];
  inGame: boolean;
  noInstantGamesAvailable: boolean;
};

export const GameProductList: FC<GameProductListProps> = ({
  onClickBonus,
  onClickProduct,
  products,
  inGame,
  noInstantGamesAvailable,
}) => (
  <GamesProductContainer>
    <div className='d-flex flex-column' style={{ gap: '60px' }}>
      {products?.map((gameProduct) => (
        <SweepstakesGameProduct
          key={gameProduct.cost}
          onShowBonus={onClickBonus}
          onShowInfo={onClickProduct}
          product={gameProduct}
          variant={
            noInstantGamesAvailable || gameProduct.expected_wait === 'IMMEDIATE'
              ? 'instant'
              : undefined
          }
        />
      ))}
    </div>
    {!products.length && (inGame ? <AppLoader /> : <SkeletonGameProduct />)}
  </GamesProductContainer>
);

export default GameProductList;
