import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import styled from '@emotion/styled';

const ContentWrapper = styled.div`
  p {
    margin-block-start: 0;

    :last-child {
      margin: 0;
    }
  }

  a {
    color: var(--primary-color);
  }
`;

type ContentfulRichTextProps = {
  content: any;
  className?: string;
};
export const ContentfulRichText: React.FC<ContentfulRichTextProps> = ({
  content,
  className,
}) => {
  return (
    <ContentWrapper
      className={className}
      dangerouslySetInnerHTML={{
        __html: documentToHtmlString(content),
      }}
    />
  );
};
