import React, { useCallback, useEffect, useState } from 'react';

export const Timer = ({
  id,
  isActive,
  endTime = new Date(),
  startTime = new Date(),
  contestId,
  fetchTournament,
  removeTournament,
}) => {
  const [stringDate, setStringDate] = useState('');
  const [label, setLabel] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [clock, setClock] = useState(`00:00:00`);
  const [intervalId, setIntervalId] = useState();

  const options = {
    month: 'short',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    // timeZone: 'America/Los_Angeles',
  };

  const updateTournament = useCallback(() => {
    clearInterval(intervalId);
    if (isActive) {
      removeTournament();
    } else {
      setTimeout(() => {
        fetchTournament();
      }, 20000); //buffer for 20 seconds to allow for competition labs to start the tournament
    }
  }, [intervalId, isActive]);

  useEffect(() => {
    const initiateTimer = () => {
      const date = isActive ? endTime : startTime;
      const formattedDate = date.toLocaleString('en-US', options);

      const is24Hrs =
        Math.abs(
          (Date.now() -
            (isActive ? endTime?.getTime() : startTime?.getTime())) /
            36e5,
        ) > 24;

      const timerLabel = isActive
        ? is24Hrs
          ? 'ends'
          : 'ends in'
        : is24Hrs
        ? 'starts'
        : 'starts in';

      const newIntervalId = setInterval(() => {
        let timeRemaining = Math.round((date?.getTime() - Date.now()) / 1000);
        timeRemaining = timeRemaining - 1;
        setTimeRemaining(timeRemaining);
        if (timeRemaining <= 0) {
          clearInterval(newIntervalId);
          updateTournament();
        } else {
          const remainingSeconds = `${timeRemaining % 60}`.padStart(2, '0');
          const remainingMinutes = `${
            Math.floor(timeRemaining / 60) % 60
          }`.padStart(2, '0');
          const remainingHours = `${Math.floor(timeRemaining / 3600)}`.padStart(
            2,
            '0',
          );
          setClock(`${remainingHours}:${remainingMinutes}:${remainingSeconds}`);
          setStringDate(formattedDate);
          setLabel(timerLabel);
        }
      }, 1000);

      setIntervalId(newIntervalId);
    };

    initiateTimer();
    return () => {
      if (intervalId) clearInterval(intervalId); // Clear the interval on unmount
    };
  }, [endTime, startTime, isActive, contestId]);

  return (
    <div className='timer' data-testid={`${id}-timer`}>
      {timeRemaining > 0 && label ? (
        <>
          {label}: {parseInt(clock.split(':')[0]) > 24 ? stringDate : clock}
        </>
      ) : (
        'loading...'
      )}
    </div>
  );
};
