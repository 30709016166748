import { useQuery } from '@tanstack/react-query';
import { getRequiredDocuments } from 'services/documents-center/documents-center';
import { useAuth } from 'contexts/authentication';

export const useRequiredDocuments = () => {
  const { auth } = useAuth();
  return useQuery({
    queryKey: ['required-docs', auth.session?.playerId],
    queryFn: getRequiredDocuments,
    keepPreviousData: true,
    enabled: !!auth.session?.playerId,
  });
};
