import React from 'react';
import { SvgProps } from '../svg';

export function ChipPlatinum({
  size,
  width = '120px',
  height = '120px',
  fill = 'none',
  onClick,
}: SvgProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      fill={fill}
      onClick={onClick}
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.3867 46.7075C25.4034 53.5259 10.1569 49.3303 3.32844 37.3365C-3.49998 25.3428 0.677851 10.0922 12.6581 3.27241C24.6383 -3.54743 39.8879 0.648102 46.7194 12.6434C53.5508 24.6387 49.3669 39.8877 37.3867 46.7075Z'
        fill='url(#paint0_linear_10621_388076)'
      />
      <path
        d='M42.8692 16.4887C47.5591 26.329 43.3645 38.1476 33.5243 42.8369C23.684 47.5262 11.8608 43.3322 7.17392 33.4903C2.487 23.6484 6.67703 11.8314 16.5203 7.14511C26.3636 2.45885 38.1838 6.64981 42.8692 16.4887ZM9.79402 32.2452C11.2298 35.2571 13.5268 37.7757 16.3943 39.4824C19.2618 41.189 22.5711 42.0071 25.9037 41.8331C29.2362 41.659 32.4423 40.5008 35.1164 38.5048C37.7906 36.5088 39.8127 33.7647 40.9269 30.6196C42.0412 27.4745 42.1976 24.0696 41.3763 20.8356C40.5551 17.6016 38.7931 14.6838 36.3132 12.4511C33.8333 10.2184 30.7469 8.7712 27.4443 8.29251C24.1418 7.81382 20.7714 8.32513 17.7595 9.76179C13.7247 11.6909 10.6199 15.1413 9.12608 19.3562C7.63225 23.5711 7.87136 28.2063 9.79097 32.2452H9.79402Z'
        fill='#3E4C52'
      />
      <path
        d='M38.5789 24.9894C38.5792 27.6706 37.7843 30.2917 36.2946 32.5212C34.805 34.7507 32.6876 36.4884 30.2102 37.5146C27.7328 38.5408 25.0066 38.8094 22.3765 38.2864C19.7464 37.7634 17.3305 36.4723 15.4344 34.5764C13.5382 32.6804 12.2469 30.2649 11.7238 27.6352C11.2007 25.0054 11.4694 22.2797 12.4957 19.8026C13.522 17.3255 15.26 15.2084 17.4898 13.719C19.7196 12.2296 22.3411 11.4348 25.0227 11.4351C26.8029 11.4351 28.5657 11.7857 30.2104 12.4668C31.8551 13.148 33.3496 14.1464 34.6084 15.405C35.8672 16.6637 36.8657 18.1579 37.547 19.8024C38.2283 21.4469 38.5789 23.2094 38.5789 24.9894V24.9894Z'
        fill='white'
      />
      <path
        d='M25.0225 38.8288C22.2849 38.8291 19.6087 38.0177 17.3323 36.4972C15.0559 34.9767 13.2816 32.8154 12.2337 30.2866C11.1859 27.7578 10.9116 24.9752 11.4455 22.2905C11.9794 19.6058 13.2975 17.1398 15.2332 15.2041C17.1688 13.2685 19.6351 11.9503 22.3201 11.4162C25.0051 10.8821 27.7882 11.156 30.3174 12.2035C32.8467 13.2509 35.0085 15.0247 36.5294 17.3006C38.0504 19.5765 38.8622 22.2522 38.8622 24.9895C38.8582 28.6584 37.3988 32.1759 34.8043 34.7704C32.2097 37.3648 28.6919 38.8244 25.0225 38.8288V38.8288ZM25.0225 11.7186C22.3969 11.7183 19.8303 12.4965 17.6471 13.9548C15.4639 15.413 13.7623 17.4859 12.7574 19.9112C11.7525 22.3364 11.4895 25.0052 12.0017 27.5799C12.5138 30.1547 13.7781 32.5197 15.6346 34.376C17.4912 36.2323 19.8566 37.4964 22.4316 38.0084C25.0067 38.5205 27.6759 38.2575 30.1015 37.2528C32.5271 36.2481 34.6003 34.5467 36.0587 32.3638C37.5172 30.1809 38.2955 27.6146 38.2952 24.9895C38.2908 21.4712 36.891 18.0983 34.4028 15.6104C31.9147 13.1226 28.5413 11.723 25.0225 11.7186V11.7186Z'
        fill='url(#paint1_linear_10621_388076)'
      />
      <path
        d='M25.022 38.7314C22.3043 38.7317 19.6475 37.9262 17.3877 36.4167C15.1278 34.9073 13.3664 32.7617 12.3262 30.2513C11.2859 27.7409 11.0136 24.9784 11.5436 22.3133C12.0736 19.6481 13.3822 17.2 15.3038 15.2784C17.2254 13.3569 19.6738 12.0482 22.3392 11.518C25.0047 10.9878 27.7676 11.2598 30.2784 12.2996C32.7893 13.3394 34.9354 15.1003 36.4453 17.3597C37.9552 19.619 38.7611 22.2753 38.7611 24.9926C38.7571 28.6349 37.3083 32.1268 34.7327 34.7024C32.157 37.278 28.6647 38.7269 25.022 38.7314V38.7314ZM25.022 11.6212C22.3766 11.6212 19.7907 12.4055 17.5911 13.8751C15.3916 15.3446 13.6773 17.4332 12.6651 19.8769C11.6528 22.3206 11.388 25.0095 11.9042 27.6037C12.4204 30.1978 13.6944 32.5807 15.5651 34.4508C17.4357 36.321 19.819 37.5946 22.4136 38.1104C25.0082 38.6262 27.6975 38.3612 30.1414 37.3488C32.5853 36.3364 34.674 34.6221 36.1435 32.4227C37.613 30.2233 38.3971 27.6376 38.3968 24.9926C38.3936 21.4468 36.9835 18.047 34.476 15.5395C31.9684 13.0321 28.5684 11.6218 25.022 11.6181V11.6212Z'
        fill='url(#paint2_linear_10621_388076)'
      />
      <path
        d='M25.0226 39.0116C22.2492 39.0116 19.5381 38.1893 17.2321 36.6487C14.9261 35.108 13.1288 32.9183 12.0674 30.3564C11.0061 27.7944 10.7284 24.9753 11.2694 22.2556C11.8105 19.5359 13.146 17.0376 15.1071 15.0768C17.0682 13.116 19.5668 11.7806 22.287 11.2396C25.0071 10.6986 27.8266 10.9763 30.3889 12.0375C32.9512 13.0987 35.1412 14.8957 36.682 17.2014C38.2229 19.5071 39.0453 22.2179 39.0453 24.9909C39.0408 28.708 37.562 32.2717 34.9332 34.9001C32.3045 37.5285 28.7403 39.0071 25.0226 39.0116ZM25.0226 11.8999C22.4334 11.8999 19.9023 12.6675 17.7495 14.1058C15.5966 15.5441 13.9187 17.5884 12.9278 19.9802C11.937 22.372 11.6777 25.0039 12.1828 27.543C12.688 30.0821 13.9348 32.4145 15.7657 34.2451C17.5965 36.0757 19.9292 37.3223 22.4686 37.8274C25.0081 38.3324 27.6404 38.0732 30.0325 37.0825C32.4246 36.0918 34.4692 34.4141 35.9077 32.2615C37.3462 30.109 38.114 27.5782 38.114 24.9894C38.1096 21.5192 36.7289 18.1924 34.2747 15.7386C31.8206 13.2848 28.4933 11.9043 25.0226 11.8999V11.8999Z'
        fill='url(#paint3_linear_10621_388076)'
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.3'>
        <path
          d='M34.8307 34.3191C32.9899 36.2681 30.6124 37.6275 27.9989 38.2256C25.3854 38.8236 22.6533 38.6334 20.1479 37.6789C17.6426 36.7244 15.4765 35.0486 13.9237 32.8633C12.3709 30.678 11.501 28.0814 11.424 25.4018C11.3471 22.7222 12.0666 20.08 13.4914 17.8092C14.9163 15.5385 16.9826 13.7412 19.4291 12.6445C21.8755 11.5479 24.5923 11.2013 27.2358 11.6484C29.8793 12.0955 32.3309 13.3163 34.2804 15.1565C35.5751 16.3785 36.6164 17.8436 37.3447 19.468C38.0731 21.0924 38.4742 22.8444 38.5253 24.6239C38.5764 26.4033 38.2765 28.1754 37.6425 29.8389C37.0086 31.5025 36.0531 33.0248 34.8307 34.3191Z'
          fill='url(#paint4_linear_10621_388076)'
        />
        <path
          d='M15.5388 35.0037C13.563 33.1385 12.1846 30.7295 11.5781 28.0811C10.9716 25.4328 11.1641 22.664 12.1313 20.1251C13.0985 17.5861 14.797 15.3909 17.0119 13.8171C19.2269 12.2433 21.8589 11.3615 24.5751 11.2833C27.2912 11.2051 29.9696 11.9339 32.2715 13.3777C34.5734 14.8214 36.3954 16.9152 37.5071 19.3943C38.6189 21.8734 38.9705 24.6265 38.5174 27.3054C38.0643 29.9842 36.8269 32.4686 34.9617 34.4444C32.4578 37.0896 29.007 38.6339 25.3659 38.7388C21.7248 38.8436 18.1908 37.5004 15.5388 35.0037V35.0037ZM34.1508 15.2894C32.2272 13.4738 29.8084 12.2693 27.2002 11.8282C24.592 11.3871 21.9115 11.7293 19.4978 12.8113C17.084 13.8934 15.0454 15.6668 13.6397 17.9073C12.2339 20.1478 11.5243 22.7548 11.6003 25.3986C11.6764 28.0424 12.5349 30.6043 14.0672 32.7603C15.5994 34.9162 17.7367 36.5695 20.2087 37.511C22.6807 38.4525 25.3764 38.6399 27.9549 38.0495C30.5334 37.4592 32.879 36.1176 34.695 34.1944C37.1264 31.6135 38.4347 28.1736 38.3327 24.6294C38.2307 21.0852 36.7267 17.7262 34.1508 15.2894V15.2894Z'
          fill='url(#paint5_linear_10621_388076)'
        />
      </g>
      <path
        d='M19.0225 1.03089V4.80885C19.0225 4.80885 23.9685 5.92441 30.6613 4.80885V1.03089C30.6613 1.03089 26.2395 -1.28861 19.0225 1.03089Z'
        fill='white'
      />
      <path
        d='M19.3032 49.006V45.4368C19.3032 45.4368 24.2493 44.3837 30.942 45.4368V49.0075C30.942 49.0075 26.5203 51.1975 19.3032 49.006Z'
        fill='white'
      />
      <path
        d='M0.957031 31.0581L3.91093 29.467C3.91093 29.467 7.12852 33.3532 9.42853 39.7143L6.48073 41.3054C6.48073 41.3054 2.56506 38.3885 0.957031 31.0581Z'
        fill='white'
      />
      <path
        d='M6.59789 8.40381L9.95113 10.1747C9.95113 10.1747 8.63118 15.0697 4.51584 20.4662L1.1626 18.6953C1.1626 18.6953 1.17022 13.7027 6.59789 8.40381Z'
        fill='white'
      />
      <path
        d='M49.0862 30.9865L46.0271 29.3223C46.0271 29.3223 42.7181 33.3456 40.3647 39.949L43.4253 41.6132C43.4253 41.6132 47.4538 38.5988 49.0862 30.9865Z'
        fill='white'
      />
      <path
        d='M43.2267 8.27759L40.1006 9.92807C40.1006 9.92807 41.6019 15.0288 45.8102 20.7834L48.9394 19.1253C48.9394 19.1253 48.6895 13.991 43.2267 8.27759Z'
        fill='white'
      />
      <path
        d='M25.022 43.1662C21.4264 43.1665 17.9115 42.1007 14.9218 40.1036C11.932 38.1065 9.60173 35.2678 8.22556 31.9465C6.84939 28.6252 6.48916 24.9704 7.19044 21.4444C7.89171 17.9184 9.62298 14.6795 12.1653 12.1373C14.7077 9.59514 17.9469 7.86384 21.4733 7.16237C24.9998 6.46089 28.6551 6.82076 31.9769 8.19646C35.2988 9.57216 38.1381 11.9019 40.1357 14.8911C42.1334 17.8802 43.1996 21.3945 43.1996 24.9896C43.1939 29.8083 41.277 34.4281 37.8693 37.8356C34.4616 41.2431 29.8414 43.1601 25.022 43.1662ZM25.022 7.35249C21.5324 7.35219 18.1211 8.38658 15.2195 10.3249C12.3179 12.2631 10.0564 15.0182 8.72095 18.2417C7.38547 21.4651 7.03605 25.0122 7.71687 28.4342C8.39769 31.8563 10.0782 34.9996 12.5458 37.4666C15.0134 39.9337 18.1573 41.6136 21.5799 42.2941C25.0024 42.9745 28.5499 42.6248 31.7737 41.2893C34.9975 39.9537 37.7528 37.6922 39.6911 34.7909C41.6294 31.8896 42.6637 28.4787 42.6631 24.9896C42.6574 20.3133 40.797 15.8301 37.4899 12.5234C34.1827 9.21677 29.6989 7.35661 25.022 7.35096V7.35249Z'
        fill='url(#paint6_linear_10621_388076)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.0248 3.83203C30.7086 3.83203 35.3239 5.31335 35.3239 7.14365C35.3239 8.97396 30.7147 10.4553 25.0248 10.4553C19.335 10.4553 14.7197 8.97396 14.7197 7.14365C14.7197 5.31335 19.332 3.83203 25.0248 3.83203Z'
        fill='url(#paint7_radial_10621_388076)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.0265 38.5791C32.1003 38.5791 37.8481 40.4231 37.8481 42.6939C37.8481 44.9646 32.1003 46.8178 25.0265 46.8178C17.9527 46.8178 12.1958 44.9798 12.1958 42.6939C12.1958 40.4079 17.939 38.5791 25.0265 38.5791Z'
        fill='url(#paint8_radial_10621_388076)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M46.1096 24.924C46.1096 30.607 44.6281 35.2216 42.7975 35.2216C40.9669 35.2216 39.4839 30.607 39.4839 24.924C39.4839 19.2411 40.9654 14.6204 42.7975 14.6204C44.6296 14.6204 46.1096 19.2335 46.1096 24.924Z'
        fill='url(#paint9_radial_10621_388076)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M41.8917 24.7427C41.8917 30.4241 40.4087 35.0403 38.5797 35.0403C36.7506 35.0403 35.2676 30.4241 35.2676 24.7427C35.2676 19.0613 36.7476 14.4375 38.5797 14.4375C40.4118 14.4375 41.8917 19.0506 41.8917 24.7427Z'
        fill='url(#paint10_radial_10621_388076)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M14.7763 24.9939C14.7763 30.6753 13.2948 35.2915 11.4643 35.2915C9.63369 35.2915 8.15674 30.6753 8.15674 24.9939C8.15674 19.3125 9.63826 14.6887 11.4688 14.6887C13.2994 14.6887 14.7763 19.3018 14.7763 24.9939Z'
        fill='url(#paint11_radial_10621_388076)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M11.4366 24.9253C11.4366 32.1201 9.56033 37.9691 7.24202 37.9691C4.92371 37.9691 3.0459 32.1246 3.0459 24.9299C3.0459 17.7352 4.92371 11.877 7.24202 11.877C9.56033 11.877 11.4366 17.7169 11.4366 24.9253Z'
        fill='url(#paint12_radial_10621_388076)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M24.7477 20.7088C19.0655 20.7088 14.4487 17.5998 14.4487 13.7609C14.4487 9.92198 19.0655 6.81152 24.7477 6.81152C30.43 6.81152 35.0529 9.91893 35.0529 13.7609C35.0529 17.6029 30.4422 20.7088 24.7477 20.7088Z'
        fill='url(#paint13_radial_10621_388076)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.2067 45.7109C19.5245 45.7109 14.9092 42.602 14.9092 38.7646C14.9092 34.9272 19.5245 31.8137 25.2067 31.8137C30.8889 31.8137 35.5133 34.9227 35.5133 38.7646C35.5133 42.6066 30.8996 45.7109 25.2067 45.7109Z'
        fill='url(#paint14_radial_10621_388076)'
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity='0.1'
        d='M37.3347 46.7309C25.3514 53.5507 10.1049 49.3552 3.2795 37.3599C-3.54587 25.3646 0.62587 10.1156 12.6061 3.29726C24.5863 -3.52105 39.8359 0.672954 46.6628 12.6713C53.4897 24.6696 49.3103 39.9125 37.3347 46.7309Z'
        fill='url(#paint15_linear_10621_388076)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_10621_388076'
          x1='12.6611'
          y1='3.27109'
          x2='37.3833'
          y2='46.7078'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8F9AA1' />
          <stop offset='1' stopColor='#405A65' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_10621_388076'
          x1='11.1812'
          y1='24.9895'
          x2='38.8622'
          y2='24.9895'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#553319' />
          <stop offset='0.1' stopColor='#6A4623' />
          <stop offset='0.29' stopColor='#A27B3C' />
          <stop offset='0.39' stopColor='#EED072' />
          <stop offset='0.52' stopColor='#FFF5D8' />
          <stop offset='0.61' stopColor='#EED072' />
          <stop offset='0.81' stopColor='#A27B3C' />
          <stop offset='1' stopColor='#553319' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_10621_388076'
          x1='11.2813'
          y1='24.9911'
          x2='38.7611'
          y2='24.9911'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.01' stopColor='#85A6D4' />
          <stop offset='0.07' stopColor='#3C527A' />
          <stop offset='0.5' stopColor='#E0E6F3' />
          <stop offset='0.62' stopColor='#BECDE8' />
          <stop offset='0.67' stopColor='#BACAE7' />
          <stop offset='0.72' stopColor='#AEC2E2' />
          <stop offset='0.77' stopColor='#9AB5DB' />
          <stop offset='0.81' stopColor='#82A4D3' />
          <stop offset='1' stopColor='#284D69' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_10621_388076'
          x1='10.9985'
          y1='24.9909'
          x2='39.0453'
          y2='24.9909'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.01' stopColor='#85A6D4' />
          <stop offset='0.07' stopColor='#3C527A' />
          <stop offset='0.5' stopColor='#E0E6F3' />
          <stop offset='0.62' stopColor='#BECDE8' />
          <stop offset='0.67' stopColor='#BACAE7' />
          <stop offset='0.72' stopColor='#AEC2E2' />
          <stop offset='0.77' stopColor='#9AB5DB' />
          <stop offset='0.81' stopColor='#82A4D3' />
          <stop offset='1' stopColor='#284D69' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_10621_388076'
          x1='14.9262'
          y1='15.5265'
          x2='34.639'
          y2='34.1381'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stopColor='white' />
          <stop offset='0.9' stopColor='#2D3233' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_10621_388076'
          x1='14.9815'
          y1='15.5791'
          x2='34.9604'
          y2='34.4418'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stopColor='white' />
          <stop offset='1' stopColor='#343D45' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_10621_388076'
          x1='6.84132'
          y1='24.9896'
          x2='43.1996'
          y2='24.9896'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.01' stopColor='#85A6D4' />
          <stop offset='0.07' stopColor='#3C527A' />
          <stop offset='0.5' stopColor='#E0E6F3' />
          <stop offset='0.62' stopColor='#BECDE8' />
          <stop offset='0.67' stopColor='#BACAE7' />
          <stop offset='0.72' stopColor='#AEC2E2' />
          <stop offset='0.77' stopColor='#9AB5DB' />
          <stop offset='0.81' stopColor='#82A4D3' />
          <stop offset='1' stopColor='#284D69' />
        </linearGradient>
        <radialGradient
          id='paint7_radial_10621_388076'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.0774 7.26978) rotate(-90) scale(3.3054 10.302)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_10621_388076'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.0775 42.7565) rotate(-90) scale(4.11537 12.8264)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint9_radial_10621_388076'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(42.7613 24.9932) scale(3.30587 10.3006)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint10_radial_10621_388076'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(38.5454 24.8135) scale(3.30587 10.3006)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint11_radial_10621_388076'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(11.4825 25.0665) scale(3.30587 10.3006)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint12_radial_10621_388076'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(7.26671 25.0012) scale(4.18673 13.0452)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint13_radial_10621_388076'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(24.6553 13.9334) rotate(90) scale(6.95672 10.302)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint14_radial_10621_388076'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.1211 38.9684) rotate(90) scale(6.95672 10.302)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <linearGradient
          id='paint15_linear_10621_388076'
          x1='12.6097'
          y1='3.29549'
          x2='37.3311'
          y2='46.7309'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.61' stopColor='white' />
          <stop offset='0.75' stopColor='#B8D2DE' />
          <stop offset='0.92' stopColor='#629CB6' />
          <stop offset='1' stopColor='#4087A6' />
        </linearGradient>
      </defs>
    </svg>
  );
}
