import { useAuth } from 'contexts/authentication';
import { useQuery } from '@tanstack/react-query';
import { Api } from '../../common/api';

export const useWelcomeContentByState = () => {
  const { auth } = useAuth();
  return useQuery({
    queryKey: ['welcome-content-by-state'],
    queryFn: async () => await getWelcomeContentByState(auth.session.state),
    keepPreviousData: false,
    enabled: !!auth.session?.playerId,
  });
};

const getWelcomeContentByState = async (state: string) => {
  const res = await Api.get<any>(
    `${API_HOST}/api/v1/welcome-content-by-state?state=${state}`,
  );
  if (!res.ok) {
    throw new Error('Could not retrieve welcome content');
  }
  return res.data;
};
