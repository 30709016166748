import React, { useState } from 'react';
import { Button, OutlinedButton } from 'components/atoms/button';
import { BspotModal } from 'components/atoms/modal/modal';
import { Close } from 'icons/svg';

export type WithdrawConfirmationProps = {
  title: string;
  confirm_info: {
    account: string;
    amount: string;
    name: string;
  };
  show: boolean;
  onConfirmWithdraw?(e: string): void;
  onCancelWithdraw?(e: React.SyntheticEvent): void;
};

export function WithdrawConfirmation({
  title,
  show,
  confirm_info,
  onConfirmWithdraw,
  onCancelWithdraw,
}: WithdrawConfirmationProps) {
  const [confirm, setConfirm] = useState(null);

  const onClickConfirmation = () => {
    onConfirmWithdraw(confirm_info.amount);
    setConfirm(true);
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{title || 'Withdraw'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCancelWithdraw} />
      </div>
      <div>
        <h3 className={'my-0 pb-3'}>{'Please verify and confirm '}</h3>
        <div className='col-12 '>
          <div className={'row'}>
            <div className={'d-flex flex-row gap-2'}>
              <strong style={{ width: '80px' }}> {'Account '}</strong>
              {confirm_info?.account}
            </div>
          </div>
          <div className={'row'}>
            <div className={'d-flex  flex-row gap-2'}>
              <strong style={{ width: '80px' }}> {'Amount '}</strong>
              {confirm_info?.amount}
            </div>
          </div>

          <div className={'row'}>
            <div className={' d-flex  flex-row gap-2'}>
              <strong style={{ width: '80px' }}> {'Name '}</strong>
              {confirm_info?.name}
            </div>
          </div>
        </div>
      </div>

      <div className={'d-flex flex-row gap-2 justify-content-start'}>
        <OutlinedButton text={'Cancel'} onClick={onCancelWithdraw} />
        <Button
          isLoading={confirm}
          disabled={confirm}
          text={'Confirm'}
          onClick={onClickConfirmation}
        />
      </div>
    </BspotModal>
  );
}
