import { Routes } from 'common/routes';
import { Button } from 'components/atoms/button';
import { useNavigate } from 'hooks/use-navigate';
import { Close, Cross } from 'icons/svg';
import { useAuth } from 'contexts/authentication';
import { BspotModal } from 'components/atoms/modal/modal';
import React from 'react';
export type KycProps = {
  onclose?(): void;
  show: boolean;
  status: number;
};
export const PlayerDisabled = ({ show, onclose }: KycProps) => {
  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Account Locked'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onclose} />
      </div>
      <div className={'d-flex flex-column gap-1'}>
        <div>{'Please contact our Player Experience Team for support.'}</div>
      </div>
      <div className={'d-flex flex-row gap-2 justify-content-center'}>
        <Button
          text='Close'
          onClick={() => {
            onclose();
          }}
        />
      </div>
    </BspotModal>
  );
};
