import { Counter } from './counter';
import { LeaderboardEntry } from '../../../../../../services/tournaments/tournaments';

export const PlayerItem = ({
  id,
  player,
  position,
  isCurrentUser,
  pointDifference,
  reward,
}: LeaderboardEntry) => {
  const points = player?.score;

  const Decorations = {
    1: '/assets/images/gold.svg',
    2: '/assets/images/silver.svg',
    3: '/assets/images/bronze.svg',
  };

  return (
    <div
      className={`player-item ${
        position % 2 === 0 ? 'player-item-color' : ''
      } ${isCurrentUser ? 'sticky' : ''}`}
    >
      <div className={`position-circle position-${position}`}>
        {position > 3 && (
          <span className={isCurrentUser ? 'black-text' : 'white-text'}>
            {position ? position : '--'}
          </span>
        )}
        {Decorations[position] && (
          <div className='decoration'>
            <img
              width={32}
              height={32}
              src={Decorations[position]}
              alt='leaderboard position'
            />
          </div>
        )}
      </div>
      <div className='player'>
        <span
          className={isCurrentUser ? 'black-text' : 'white-text'}
          data-testid={`${id}-${player?.id}-name`}
        >
          <b>
            {player?.fullName ? player.fullName : ''}
            {isCurrentUser ? ' (You)' : ''}
          </b>
        </span>
        <span
          className={`prize ${isCurrentUser ? 'black-text' : ''}`}
          data-testid={`${id}-${player?.id}-prize`}
        >
          {reward
            ? reward
            : isCurrentUser && !!pointDifference
            ? `${(pointDifference + 1)?.toLocaleString(
                'en-US',
              )} points to get a prize`
            : ''}
        </span>
      </div>
      <span
        data-testid={`${id}-${player?.id}-score`}
        className={isCurrentUser ? 'black-text' : 'white-text'}
      >
        <b>{points ? <Counter from={0} to={points} /> : '0pts'}</b>
      </span>
    </div>
  );
};

export default PlayerItem;
