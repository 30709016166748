import React from 'react';
import { SvgProps } from 'icons/svg/svg';

export function MakeADeposit({ color }: SvgProps) {
  return (
    <svg
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='44' height='44' rx='22' fill='none' />
      <path
        d='M14.768 19.5L13.868 16.712C13.7868 16.4596 13.8091 16.1854 13.9301 15.9495C14.051 15.7136 14.2607 15.5354 14.513 15.454L29.744 10.548C29.9962 10.4675 30.2701 10.4902 30.5056 10.6113C30.741 10.7324 30.9188 10.942 31 11.194L33.452 18.808C33.4924 18.933 33.5077 19.0648 33.4971 19.1957C33.4865 19.3266 33.4503 19.4542 33.3904 19.5711C33.3305 19.688 33.2481 19.792 33.148 19.8771C33.0479 19.9622 32.932 20.0267 32.807 20.067L27.5 21.776'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.636 19.0999L16.9 14.6899'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.769 13.5681L27.369 11.3101'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32.689 16.4301L30.431 20.8301'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.952 15.24L21.946 16.209C21.7498 16.2717 21.5835 16.404 21.4783 16.581C21.3732 16.7581 21.3365 16.9675 21.3753 17.1697C21.4141 17.3719 21.5256 17.5529 21.6888 17.6784C21.852 17.804 22.0556 17.8653 22.261 17.851L25.061 17.664C25.2663 17.6496 25.4699 17.711 25.6331 17.8366C25.7963 17.9621 25.9078 18.1431 25.9466 18.3453C25.9854 18.5475 25.9487 18.7569 25.8436 18.9339C25.7384 19.111 25.5721 19.2433 25.376 19.306L24.783 19.497'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.049 15.862L22.743 14.91'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M27.5 28.5V31.5C27.5 32.0304 27.2893 32.5391 26.9142 32.9142C26.5391 33.2893 26.0304 33.5 25.5 33.5H12.5C11.9696 33.5 11.4609 33.2893 11.0858 32.9142C10.7107 32.5391 10.5 32.0304 10.5 31.5V21.5C10.5 20.9696 10.7107 20.4609 11.0858 20.0858C11.4609 19.7107 11.9696 19.5 12.5 19.5H25.5C26.0304 19.5 26.5391 19.7107 26.9142 20.0858C27.2893 20.4609 27.5 20.9696 27.5 21.5V24.5'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 19.5V33.5'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24 24.5C23.4696 24.5 22.9609 24.7107 22.5858 25.0858C22.2107 25.4609 22 25.9696 22 26.5C22 27.0304 22.2107 27.5391 22.5858 27.9142C22.9609 28.2893 23.4696 28.5 24 28.5H27.5C27.7652 28.5 28.0196 28.3946 28.2071 28.2071C28.3946 28.0196 28.5 27.7652 28.5 27.5V25.5C28.5 25.2348 28.3946 24.9804 28.2071 24.7929C28.0196 24.6054 27.7652 24.5 27.5 24.5H24Z'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.254 26.25C26.3035 26.25 26.3518 26.2647 26.3929 26.2921C26.434 26.3196 26.4661 26.3586 26.485 26.4043C26.5039 26.45 26.5089 26.5003 26.4992 26.5488C26.4896 26.5973 26.4658 26.6418 26.4308 26.6768C26.3958 26.7117 26.3513 26.7355 26.3028 26.7452C26.2543 26.7548 26.204 26.7499 26.1584 26.731C26.1127 26.712 26.0736 26.68 26.0462 26.6389C26.0187 26.5978 26.004 26.5494 26.004 26.5C26.004 26.4337 26.0304 26.3701 26.0773 26.3232C26.1241 26.2763 26.1877 26.25 26.254 26.25Z'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
