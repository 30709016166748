import React from 'react';
import { SvgProps } from '../svg';

export function ChipGold({
  size,
  width = '120px',
  height = '120px',
  fill = 'none',
  onClick,
}: SvgProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      fill={fill}
      onClick={onClick}
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.3378 46.7249C25.3547 53.5474 10.1083 49.3502 3.28 37.3503C-3.54833 25.3503 0.629451 10.0954 12.611 3.27446C24.5926 -3.5465 39.839 0.649139 46.6658 12.6491C53.4926 24.649 49.3179 39.9039 37.3378 46.7249Z'
        fill='url(#paint0_linear_10621_388163)'
      />
      <path
        d='M42.8202 16.4959C47.5101 26.3401 43.3171 38.1632 33.4755 42.8528C28.741 45.1026 23.3076 45.3819 18.3676 43.6292C13.4276 41.8765 9.38444 38.2351 7.12545 33.5041C2.43707 23.6614 6.63314 11.8368 16.4717 7.14721C26.3103 2.45761 38.1334 6.65172 42.8202 16.4959ZM9.74247 32.257C11.1771 35.2707 13.473 37.7911 16.3398 39.4996C19.2066 41.208 22.5155 42.0277 25.848 41.855C29.1806 41.6822 32.3871 40.5248 35.062 38.5292C37.7369 36.5335 39.7601 33.7892 40.8756 30.6433C41.9911 27.4974 42.1489 24.0913 41.3289 20.8557C40.509 17.6201 38.7482 14.7004 36.2693 12.4659C33.7903 10.2313 30.7046 8.78233 27.4023 8.30209C24.0999 7.82186 20.7294 8.33199 17.717 9.76796C13.6817 11.6964 10.5759 15.1471 9.0809 19.3632C7.58592 23.5792 7.82384 28.2162 9.74247 32.257V32.257Z'
        fill='#B2793A'
      />
      <path
        d='M38.5297 24.9998C38.5309 27.6823 37.7368 30.3048 36.2477 32.5358C34.7587 34.7667 32.6416 36.5059 30.1643 37.5332C27.687 38.5604 24.9607 38.8298 22.3304 38.3071C19.7 37.7844 17.2837 36.4932 15.3871 34.5967C13.4905 32.7002 12.1988 30.2837 11.6753 27.6529C11.1519 25.022 11.4202 22.295 12.4464 19.8167C13.4726 17.3384 15.2106 15.2202 17.4404 13.73C19.6703 12.2398 22.2919 11.4445 24.9737 11.4448C28.568 11.4452 32.0151 12.8733 34.5572 15.4152C37.0992 17.957 38.5281 21.4045 38.5297 24.9998Z'
        fill='white'
      />
      <path
        d='M24.9735 38.8444C22.2359 38.8447 19.5597 38.033 17.2834 36.5119C15.007 34.9908 13.2327 32.8287 12.1849 30.2989C11.1371 27.7691 10.8627 24.9854 11.3966 22.2997C11.9305 19.614 13.2486 17.147 15.1843 15.2106C17.1199 13.2742 19.5862 11.9555 22.2711 11.4212C24.9561 10.8868 27.7391 11.1609 30.2683 12.2088C32.7976 13.2566 34.9593 15.0311 36.4803 17.3079C38.0012 19.5846 38.813 22.2614 38.813 24.9997C38.8086 28.6699 37.3491 32.1886 34.7547 34.7839C32.1603 37.3793 28.6427 38.8395 24.9735 38.8444V38.8444ZM24.9735 11.7222C22.348 11.7219 19.7815 12.5003 17.5984 13.9591C15.4153 15.4179 13.7136 17.4914 12.7087 19.9176C11.7038 22.3437 11.4407 25.0134 11.9527 27.5891C12.4647 30.1648 13.7289 32.5308 15.5852 34.3878C17.4416 36.2449 19.8068 37.5096 22.3817 38.022C24.9567 38.5345 27.6258 38.2716 30.0514 37.2667C32.477 36.2618 34.5502 34.56 36.0088 32.3765C37.4675 30.193 38.246 27.6258 38.246 24.9997C38.242 21.4798 36.8424 18.1052 34.3542 15.6161C31.8661 13.127 28.4925 11.7266 24.9735 11.7222V11.7222Z'
        fill='url(#paint1_linear_10621_388163)'
      />
      <path
        d='M24.9739 38.7437C22.2563 38.744 19.5995 37.9382 17.3397 36.4282C15.0799 34.9181 13.3185 32.7717 12.2783 30.2604C11.2381 27.749 10.9658 24.9855 11.4958 22.3193C12.0258 19.6531 13.3343 17.204 15.2559 15.2817C17.1775 13.3594 19.6258 12.0503 22.2912 11.5198C24.9567 10.9894 27.7195 11.2615 30.2303 12.3017C32.7412 13.3419 34.8872 15.1035 36.3971 17.3637C37.907 19.624 38.7129 22.2813 38.7129 24.9997C38.7085 28.6432 37.2596 32.1362 34.6841 34.7128C32.1085 37.2893 28.6165 38.7389 24.9739 38.7437V38.7437ZM24.9739 11.6215C22.3287 11.6215 19.7428 12.4061 17.5434 13.8761C15.3439 15.3462 13.6297 17.4355 12.6174 19.8801C11.6051 22.3246 11.3402 25.0145 11.8563 27.6096C12.3723 30.2047 13.6461 32.5885 15.5166 34.4595C17.3871 36.3304 19.7702 37.6046 22.3647 38.1208C24.9591 38.637 27.6483 38.372 30.0922 37.3595C32.5361 36.3469 34.625 34.6322 36.0946 32.4322C37.5642 30.2322 38.3486 27.6456 38.3486 24.9997C38.3442 21.4529 36.9337 18.0527 34.4264 15.5448C31.9191 13.0369 28.5198 11.626 24.9739 11.6215V11.6215Z'
        fill='white'
      />
      <path
        d='M24.9736 39.0276C22.2003 39.0276 19.4892 38.2049 17.1832 36.6637C14.8772 35.1225 13.0799 32.9319 12.0186 30.369C10.9572 27.8061 10.6796 24.9859 11.2206 22.2651C11.7617 19.5443 13.0972 17.0451 15.0583 15.0835C17.0193 13.1219 19.5179 11.7861 22.238 11.2449C24.9581 10.7037 27.7775 10.9814 30.3398 12.043C32.9021 13.1046 35.0921 14.9024 36.6329 17.209C38.1737 19.5155 38.9961 22.2273 38.9961 25.0015C38.9917 28.72 37.5129 32.2851 34.8841 34.9145C32.2554 37.5439 28.6913 39.0231 24.9736 39.0276V39.0276ZM24.9736 11.9053C22.3844 11.9053 19.8534 12.6733 17.7006 14.1122C15.5477 15.551 13.8698 17.5961 12.879 19.9888C11.8881 22.3816 11.6289 25.0145 12.134 27.5546C12.6391 30.0947 13.8859 32.4279 15.7168 34.2592C17.5476 36.0905 19.8802 37.3376 22.4197 37.8429C24.9591 38.3482 27.5913 38.0888 29.9834 37.0977C32.3755 36.1067 34.4201 34.4283 35.8586 32.2749C37.297 30.1215 38.0648 27.5898 38.0648 24.9999C38.0612 21.5279 36.6809 18.199 34.2267 15.7436C31.7725 13.2882 28.4448 11.9067 24.9736 11.9023V11.9053Z'
        fill='url(#paint2_linear_10621_388163)'
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.3'>
        <path
          d='M34.8309 34.3135C32.9902 36.2639 30.6126 37.6245 27.9988 38.2233C25.385 38.8221 22.6524 38.6322 20.1466 37.6775C17.6407 36.7228 15.4742 35.0464 13.9209 32.86C12.3676 30.6737 11.4973 28.0758 11.4202 25.3947C11.343 22.7137 12.0624 20.07 13.4874 17.7979C14.9123 15.5258 16.9789 13.7274 19.4257 12.6301C21.8725 11.5329 24.5896 11.186 27.2335 11.6333C29.8774 12.0807 32.3293 13.3023 34.2791 15.1435C36.8918 17.6135 38.4175 21.0199 38.521 24.6143C38.6244 28.2087 37.2972 31.6973 34.8309 34.3135V34.3135Z'
          fill='url(#paint3_linear_10621_388163)'
        />
        <path
          d='M15.5422 34.9936C13.5663 33.1277 12.188 30.7177 11.5815 28.0683C10.975 25.4189 11.1675 22.6491 12.1347 20.1092C13.1019 17.5692 14.8004 15.3732 17.0153 13.7988C19.2302 12.2244 21.8622 11.3422 24.5783 11.264C27.2944 11.1858 29.9728 11.9149 32.2746 13.3592C34.5765 14.8035 36.3985 16.8981 37.5102 19.3782C38.622 21.8583 38.9735 24.6124 38.5204 27.2923C38.0674 29.9722 36.83 32.4575 34.9648 34.4341C32.4612 37.0806 29.0103 38.6258 25.3691 38.7307C21.728 38.8356 18.1939 37.4916 15.5422 34.9936ZM34.1539 15.2717C32.2308 13.4543 29.8119 12.2483 27.2034 11.8062C24.5949 11.3641 21.9139 11.7058 19.4996 12.788C17.0853 13.8702 15.046 15.6444 13.6399 17.886C12.2337 20.1277 11.5238 22.7361 11.6 25.3814C11.6761 28.0266 12.5349 30.5899 14.0676 32.7469C15.6004 34.9039 17.7383 36.5578 20.2109 37.4993C22.6835 38.4407 25.3797 38.6276 27.9584 38.0361C30.5372 37.4446 32.8826 36.1014 34.6981 34.1764C37.1276 31.595 38.4346 28.1553 38.3326 24.6115C38.2306 21.0677 36.7279 17.709 34.1539 15.2717V15.2717Z'
          fill='url(#paint4_linear_10621_388163)'
        />
      </g>
      <path
        d='M18.979 1.03197V4.81139C18.979 4.81139 23.925 5.92738 30.6176 4.81139V1.03197C30.6176 1.03197 26.1929 -1.28996 18.979 1.03197Z'
        fill='white'
      />
      <path
        d='M19.2549 49.0256V45.4535C19.2549 45.4535 24.2008 44.3985 30.8935 45.4535V49.0256C30.8935 49.0256 26.4719 51.2179 19.2549 49.0256Z'
        fill='white'
      />
      <path
        d='M0.908691 31.0707L3.86255 29.479C3.86255 29.479 7.0801 33.3667 9.37704 39.7303L6.42775 41.3265C6.42775 41.3265 2.51823 38.4039 0.908691 31.0707Z'
        fill='white'
      />
      <path
        d='M6.54948 8.41089L9.90268 10.1809C9.90268 10.1809 8.58274 15.0794 4.46745 20.4779L1.11426 18.7064C1.11426 18.7064 1.12188 13.7088 6.54948 8.41089Z'
        fill='white'
      />
      <path
        d='M49.0373 30.9988L45.9783 29.334C45.9783 29.334 42.6708 33.3589 40.3159 39.9633L43.378 41.6312C43.378 41.6312 47.4064 38.6126 49.0373 30.9988Z'
        fill='white'
      />
      <path
        d='M43.1783 8.28125L40.0522 9.93846C40.0522 9.93846 41.5536 15.0412 45.7633 20.798L48.891 19.1393C48.891 19.1393 48.641 13.9969 43.1783 8.28125Z'
        fill='white'
      />
      <path
        d='M24.9734 43.1832C21.3779 43.1835 17.863 42.1173 14.8733 40.1194C11.8836 38.1216 9.55335 35.2818 8.1772 31.9592C6.80105 28.6366 6.44083 24.9804 7.14209 21.453C7.84336 17.9257 9.57461 14.6855 12.1169 12.1423C14.6592 9.59915 17.8984 7.86717 21.4248 7.16543C24.9512 6.46369 28.6064 6.8237 31.9283 8.19993C35.2501 9.57616 38.0894 11.9068 40.087 14.8971C42.0846 17.8874 43.1508 21.4031 43.1508 24.9995C43.1455 29.8203 41.2288 34.4421 37.821 37.851C34.4133 41.2599 29.7929 43.1775 24.9734 43.1832V43.1832ZM24.9734 7.35563C21.4839 7.35532 18.0726 8.39012 15.1711 10.3291C12.2695 12.2682 10.008 15.0243 8.67258 18.249C7.33713 21.4738 6.98771 25.0222 7.66852 28.4455C8.34933 31.8689 10.0298 35.0134 12.4974 37.4814C14.9649 39.9494 18.1088 41.6301 21.5313 42.3107C24.9539 42.9914 28.5013 42.6416 31.7251 41.3055C34.9488 39.9695 37.7041 37.7071 39.6424 34.8047C41.5806 31.9022 42.6149 28.49 42.6143 24.9995C42.6086 20.3214 40.7482 15.8365 37.4411 12.5286C34.1341 9.22063 29.6503 7.35975 24.9734 7.3541V7.35563Z'
        fill='url(#paint5_linear_10621_388163)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M24.9764 3.83398C30.66 3.83398 35.2753 5.31587 35.2753 7.14536C35.2753 8.97486 30.66 10.4598 24.9764 10.4598C19.2927 10.4598 14.6714 8.97791 14.6714 7.14536C14.6714 5.31282 19.2836 3.83398 24.9764 3.83398Z'
        fill='url(#paint6_radial_10621_388163)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M24.9785 38.5898C32.0537 38.5898 37.7999 40.4346 37.7999 42.7138C37.7999 44.9931 32.0537 46.8393 24.9785 46.8393C17.9033 46.8393 12.1479 44.9946 12.1479 42.7138C12.1479 40.4331 17.8911 38.5898 24.9785 38.5898Z'
        fill='url(#paint7_radial_10621_388163)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M46.0651 24.9344C46.0651 30.618 44.5836 35.236 42.7545 35.236C40.9255 35.236 39.4395 30.618 39.4395 24.9344C39.4395 19.2508 40.9271 14.6252 42.7561 14.6252C44.5851 14.6252 46.0651 19.2401 46.0651 24.9344Z'
        fill='url(#paint8_radial_10621_388163)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M41.8419 24.7528C41.8419 30.4364 40.3588 35.0528 38.5298 35.0528C36.7008 35.0528 35.2178 30.4364 35.2178 24.7528C35.2178 19.0692 36.6977 14.4436 38.5298 14.4436C40.3619 14.4436 41.8419 19.0585 41.8419 24.7528Z'
        fill='url(#paint9_radial_10621_388163)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M14.7296 25.003C14.7296 30.6866 13.2481 35.3061 11.4176 35.3061C9.58703 35.3061 8.104 30.6866 8.104 25.003C8.104 19.3193 9.58551 14.6953 11.4176 14.6953C13.2496 14.6953 14.7296 19.3102 14.7296 25.003Z'
        fill='url(#paint10_radial_10621_388163)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M11.3886 24.9342C11.3886 32.1333 9.51233 37.98 7.19558 37.98C4.87883 37.98 2.99951 32.1333 2.99951 24.9342C2.99951 17.7352 4.87578 11.8823 7.19558 11.8823C9.51538 11.8823 11.3886 17.7245 11.3886 24.9342Z'
        fill='url(#paint11_radial_10621_388163)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M24.6997 20.7173C19.0176 20.7173 14.4023 17.6072 14.4023 13.7668C14.4023 9.92636 19.0176 6.8147 24.6997 6.8147C30.3818 6.8147 35.0062 9.92331 35.0062 13.7668C35.0062 17.6102 30.394 20.7173 24.6997 20.7173Z'
        fill='url(#paint12_radial_10621_388163)'
      />
      <path
        style={{ mixBlendMode: 'color-dodge' }}
        d='M25.1582 45.7295C19.4761 45.7295 14.8608 42.6194 14.8608 38.779C14.8608 34.9386 19.4761 31.8269 25.1582 31.8269C30.8403 31.8269 35.4647 34.9355 35.4647 38.779C35.4647 42.6224 30.851 45.7295 25.1582 45.7295Z'
        fill='url(#paint13_radial_10621_388163)'
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity='0.1'
        d='M37.3821 46.7251C25.4005 53.5476 10.1541 49.3504 3.32579 37.3505C-3.50254 25.3506 0.673717 10.0957 12.6553 3.2747C24.6369 -3.54625 39.8848 0.649384 46.7192 12.6493C53.5536 24.6493 49.3621 39.9042 37.3821 46.7251Z'
        fill='url(#paint14_linear_10621_388163)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_10621_388163'
          x1='12.6135'
          y1='3.27241'
          x2='37.3503'
          y2='46.7174'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4C05F' />
          <stop offset='1' stopColor='#C38B3F' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_10621_388163'
          x1='11.1324'
          y1='24.9997'
          x2='38.813'
          y2='24.9997'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#553319' />
          <stop offset='0.1' stopColor='#6A4623' />
          <stop offset='0.29' stopColor='#A27B3C' />
          <stop offset='0.39' stopColor='#EED072' />
          <stop offset='0.52' stopColor='#FFF5D8' />
          <stop offset='0.61' stopColor='#EED072' />
          <stop offset='0.81' stopColor='#A27B3C' />
          <stop offset='1' stopColor='#553319' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_10621_388163'
          x1='10.9512'
          y1='24.9999'
          x2='38.9961'
          y2='24.9999'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#553319' />
          <stop offset='0.1' stopColor='#6A4623' />
          <stop offset='0.29' stopColor='#A27B3C' />
          <stop offset='0.39' stopColor='#EED072' />
          <stop offset='0.52' stopColor='#FFF5D8' />
          <stop offset='0.61' stopColor='#EED072' />
          <stop offset='0.81' stopColor='#A27B3C' />
          <stop offset='1' stopColor='#553319' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_10621_388163'
          x1='15.1179'
          y1='15.6872'
          x2='34.8391'
          y2='34.2992'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stopColor='white' />
          <stop offset='1' stopColor='#553319' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_10621_388163'
          x1='14.9847'
          y1='15.5617'
          x2='34.9708'
          y2='34.4237'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.39' stopColor='white' />
          <stop offset='1' stopColor='#553319' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_10621_388163'
          x1='6.7945'
          y1='24.9995'
          x2='43.1508'
          y2='24.9995'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#553319' />
          <stop offset='0.1' stopColor='#6A4623' />
          <stop offset='0.29' stopColor='#A27B3C' />
          <stop offset='0.39' stopColor='#EED072' />
          <stop offset='0.52' stopColor='#FFF5D8' />
          <stop offset='0.61' stopColor='#EED072' />
          <stop offset='0.81' stopColor='#A27B3C' />
          <stop offset='1' stopColor='#553319' />
        </linearGradient>
        <radialGradient
          id='paint6_radial_10621_388163'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.0287 7.27136) rotate(-90) scale(3.30668 10.3019)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint7_radial_10621_388163'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.0293 42.7744) rotate(-90) scale(4.11697 12.8263)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_10621_388163'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(42.7113 25.0078) scale(3.30582 10.3045)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint9_radial_10621_388163'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(38.4987 24.8278) scale(3.30582 10.3045)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint10_radial_10621_388163'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(11.4332 25.0813) scale(3.30582 10.3045)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint11_radial_10621_388163'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(7.22033 25.0076) scale(4.18668 13.0503)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint12_radial_10621_388163'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(24.607 13.9382) rotate(90) scale(6.95942 10.3019)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <radialGradient
          id='paint13_radial_10621_388163'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.0724 38.9832) rotate(90) scale(6.95942 10.3019)'
        >
          <stop stopColor='white' />
          <stop offset='0.01' stopColor='#F9F9F9' />
          <stop offset='0.05' stopColor='#CBCBCB' />
          <stop offset='0.09' stopColor='#A0A0A0' />
          <stop offset='0.14' stopColor='#7A7A7A' />
          <stop offset='0.19' stopColor='#5A5A5A' />
          <stop offset='0.25' stopColor='#3E3E3E' />
          <stop offset='0.32' stopColor='#282828' />
          <stop offset='0.4' stopColor='#171717' />
          <stop offset='0.49' stopColor='#0C0C0C' />
          <stop offset='0.63' stopColor='#050505' />
          <stop offset='1' stopColor='#030303' />
        </radialGradient>
        <linearGradient
          id='paint14_linear_10621_388163'
          x1='12.6593'
          y1='3.27292'
          x2='37.3953'
          y2='46.7166'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.61' stopColor='white' />
          <stop offset='0.71' stopColor='#F7E8CE' />
          <stop offset='0.91' stopColor='#EAC078' />
          <stop offset='1' stopColor='#E5B056' />
        </linearGradient>
      </defs>
    </svg>
  );
}
