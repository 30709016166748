/**
 * options object for a network request
 */
export type NetworkRequestOptions = {
  url: string;
  method: string;
  data?: any;
  query?: Record<string, string | string[]>;
  path?: string;
  headers?: Record<string, string>;
};

export type NetworkResponse<T> = {
  ok: boolean;
  data: T;
  status: number;
  headers: Record<string, string> | Headers;
};

export class Api {
  static defaultHeaders: Map<string, any> = new Map([
    ['content-type', 'application/json'],
  ]);

  /**
   * @private
   */
  private static getBuiltUrl({
    url,
    path,
    query,
  }: Pick<NetworkRequestOptions, 'url' | 'path' | 'query'>): URL {
    const newUrl = new URL(url);

    newUrl.pathname = path ?? newUrl.pathname;

    for (const [key, val] of Object.entries(query ?? {})) {
      let value: string = Array.isArray(val) ? val.join(',') : val;
      newUrl.searchParams.append(key, value);
    }

    return newUrl;
  }

  /**
   * Method to run a network request
   */
  static async networkRequest<T>({
    method,
    url,
    path,
    query,
    data,
    headers = {},
  }: NetworkRequestOptions) {
    for (const [key, val] of Api.defaultHeaders.entries()) {
      if (headers[key]) continue;

      headers[key] = val;
    }

    const input = this.getBuiltUrl({ url, path, query });
    const isJson = headers['content-type']?.includes('application/json');

    const response = await fetch(input, {
      method,
      headers,
      body: isJson ? JSON.stringify(data) : data,
    });
    const resData: T = await response.json();

    return {
      ok: response.ok,
      status: response.status,
      data: resData,
      headers: response.headers,
    };
  }

  /**
   * Method to run a DELETE request
   */
  static delete<T>(url: string, options: Partial<NetworkRequestOptions> = {}) {
    const requestOptions: NetworkRequestOptions = {
      method: 'DELETE',
      ...options,
      url: url,
    };

    return Api.networkRequest<T>(requestOptions);
  }

  /**
   * Method to run a GET request
   */
  static get<T>(url: string, options: Partial<NetworkRequestOptions> = {}) {
    const requestOptions: NetworkRequestOptions = {
      method: 'GET',
      ...options,
      url,
    };

    return Api.networkRequest<T>(requestOptions);
  }

  /**
   * Method to run a POST request
   */
  static post<T>(url: string, options: Partial<NetworkRequestOptions> = {}) {
    const requestOptions: NetworkRequestOptions = {
      method: 'POST',
      ...options,
      url,
    };

    return Api.networkRequest<T>(requestOptions);
  }

  /**
   * Method to run a PUT request
   */
  static put<T>(url: string, options: Partial<NetworkRequestOptions> = {}) {
    const requestOptions: NetworkRequestOptions = {
      method: 'PUT',
      ...options,
      url,
    };

    return Api.networkRequest<T>(requestOptions);
  }
}
