import { toCurrency } from '../../../../../../common/bspot-shared';
import {
  CashStack,
  Credits,
  Profile,
  Spinner,
} from '../../../../../../icons/svg';
import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import { useBalanceAndCredits } from '../../../../../../hooks/use-balance-and-credits/use-balance-and-credits';
import styled from '@emotion/styled';
import { useXtremeNotifications } from '../../../../../../hooks/use-xtreme-notifications/use-xtreme-notifications';
import { bp } from '../../../../../../common/theme';
import { css } from '@emotion/react';
import { OutlinedButtonProps } from 'components/atoms/button';
import React, { useEffect, useState } from 'react';
import { useBalanceIncrease } from '../../../../../../hooks/use-balance-increase/use-balance-increase';
import { Plus } from 'icons/svg/plus';
import '../../../game-header/buttons-sdk/styles.scss';
import { CreditsMk2 } from 'icons/svg/credits/credits-mk2';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { OneTimeSetupPopover } from './one-time-setup-popover/one-time-setup-popover';

export const StyledAnimatedAccountButton = styled.button<OutlinedButtonProps>`
  @media ${bp.lg} {
    max-height: 42px;
    min-width: 95px;
  }
  font-family: Poppins;
  border: 0;
  max-height: 36px;
  min-width: 65px;
  font-size: 10px;
  font-weight: 400;
  cursor: pointer;
  background: #353535;
  color: #ffffff;
  font-family: Poppins;
  line-height: 14px;
  overflow: hidden;
  position: relative;
  padding: 1rem 0.5rem;

  & .amount {
    @media ${bp.lg} {
      font-size: 14px;
      line-height: 13px;
    }
    font-size: 12px;
    line-height: 11px;
    font-weight: 700;
    color: var(--primary-color);
  }

  & .claim {
    @media ${bp.lg} {
      font-size: 12px;
      line-height: 11px;
    }
    line-height: 9px;
    font-size: 10px;
    font-weight: 700;
    align-items: end;
  }

  & .animate {
    animation: rotation 0.9s infinite linear;
  }

  ${({ shouldConvertCash }) =>
    shouldConvertCash &&
    css`
      background-color: #ff6060;
      border: 0 solid #ff6060;
      :hover {
        background-color: #ffa19a;
      }
      color: #ffa19a;
      & .amount {
        color: #ffa19a;
      }

      svg {
        fill: #ffa19a !important;
      }

      transition: 2s ease-in-out;
      animation: colors 10s linear infinite;
      @keyframes colors {
        0%,
        100% {
          background-color: #ff6060;
        }
        20% {
          background-color: #590d18;
        }
        40% {
          background-color: #ff6060;
        }
        60% {
          background-color: #93031a;
        }
        80% {
          background-color: #ff6060;
        }
        90% {
          background-color: #93031a;
        }
      }
    `}

  ${({ cashIncrease }) =>
    cashIncrease &&
    css`
      background-color: #89f9c8;
      //border: 2px solid #06c772;
      :hover {
        background-color: #06c772;
      }
      color: #000000;
      & .amount,
      .icon-amount-stack {
        color: #000000;
      }
    `}

  &.cashIncrease::before,
  &.cashIncrease::after {
    content: '';
    position: absolute;
    background: linear-gradient(
      45deg,
      #0aeb88,
      #000000,
      #0aeb88,
      #000000,
      #0aeb88,
      #000000
    );
    z-index: -1;
    border-radius: 30px;
  }

  @media screen and (max-width: 999px) and (orientation: landscape) {
    &.p-land {
      //width: 100%;
      //flex-grow: 1;
      padding: 2px 6px;
    }
    .icon-amount-stack {
      width: 0;
      height: 0;
      display: none;
    }
  }
`;

export const StyledProfileButton = styled.button`
  @media ${bp.lg} {
    height: 42px;
    width: 42px;
  }
  height: 36px;
  width: 36px;
  background: #353535;
  border: unset;
  cursor: pointer;
  line-height: 14px;
`;

type AccountButtonsProps = {
  onCashClick(e: React.SyntheticEvent): void;
  onChipsClick(e: React.SyntheticEvent): void;
  onProfileClick(e: React.SyntheticEvent): void;
  showProfileButton?: boolean;
  notifications?: number;
};
export const AccountButtons = ({
  onCashClick,
  onProfileClick,
  onChipsClick,
  showProfileButton = true,
}: AccountButtonsProps) => {
  const { data: notifications } = useXtremeNotifications();
  const { balanceAndCredits: balances, isLoading: isLoadingBalances } =
    useBalanceAndCredits();
  const isLoadingCreditsButton =
    isLoadingBalances || balances?.credits_pending > 0;
  const { start: startCash, rounded: roundedCash } = useBalanceIncrease({
    balance: balances?.cash,
  });
  const { start: startCredits, rounded: roundedCredits } = useBalanceIncrease({
    balance: balances?.credits,
  });

  return (
    <div
      className='d-flex align-items-center gap-2'
      style={{ marginLeft: 'auto' }}
    >
      <button
        id='horseplay-cash-button'
        onClick={onCashClick}
        className={`header-button ${
          balances?.shouldDeposit ? ' immediate' : ' initial'
        }  ${startCash ? ' cashIncrease' : ''}`}
        style={{
          cursor: 'pointer',
          appearance: 'none',
          border: 'unset',
        }}
      >
        {isLoadingBalances && (
          <>
            {startCash && (
              <Spinner className='animate' color={'#353535'} size='1rem' />
            )}
            {!startCash && (
              <Spinner
                className='animate'
                color={'var(--primary-color)'}
                size='1rem'
              />
            )}
          </>
        )}
        {!isLoadingBalances && (
          <>
            {startCash && (
              <Spinner className='animate' color={'#353535'} size='1rem' />
            )}
            {!startCash && (
              <CashStack
                displayState={balances?.shouldDeposit ? 'immediate' : 'initial'}
                width={'25'}
                height={'26'}
              />
            )}
          </>
        )}
        <div className='amount'>
          <span>Cash</span>
          <span
            className={`value ${
              balances?.shouldDeposit ? 'immediate' : 'initial'
            }`}
          >
            {startCash ? (
              <motion.div>{roundedCash}</motion.div>
            ) : (
              toCurrency(balances?.cash)
            )}
          </span>
        </div>
      </button>

      <button
        id='horseplay-credits-button'
        onClick={onChipsClick}
        className={`header-button ${
          balances?.shouldConvertCash ? ' immediate' : ' initial'
        }  ${startCredits ? ' cashIncrease' : ''}`}
        style={{
          cursor: 'pointer',
          appearance: 'none',
          border: 'unset',
        }}
      >
        {isLoadingCreditsButton && (
          <>
            {startCredits && (
              <Spinner className='animate' color={'#353535'} size='1rem' />
            )}
            {!startCredits && (
              <Spinner
                className='animate'
                color={'var(--primary-color)'}
                size='1rem'
              />
            )}
          </>
        )}

        {!isLoadingCreditsButton && (
          <>
            {startCredits && (
              <Spinner className='animate' color={'#353535'} size='1rem' />
            )}
            {!startCredits && (
              <CreditsMk2
                displayState={
                  balances?.shouldConvertCash ? 'immediate' : 'initial'
                }
                width={25}
                height={26}
              />
            )}
          </>
        )}
        <div className='amount'>
          <span>Credits</span>
          <span
            className={`value ${
              balances?.shouldConvertCash ? 'immediate' : 'initial'
            }`}
          >
            {startCredits ? (
              <motion.div>{roundedCredits}</motion.div>
            ) : (
              toCurrency(balances?.credits)
            )}
          </span>
        </div>
      </button>

      <OneTimeSetupPopover />

      {showProfileButton && (
        <StyledProfileButton
          onClick={onProfileClick}
          id='header-profile'
          className='rounded-pill d-flex justify-content-center align-items-center position-relative'
          aria-label='profile button'
        >
          {notifications > 0 && (
            <span
              className='position-absolute rounded-pill'
              style={{
                top: '0px',
                right: '0px',
                backgroundColor: 'red',
                height: '10px',
                width: '10px',
              }}
            ></span>
          )}

          <Profile />
        </StyledProfileButton>
      )}
    </div>
  );
};
