import { useQuery } from '@tanstack/react-query';
import { getTransactionHistory } from 'services/transactions/transactions';
import { useAuth } from 'contexts/authentication';

export const useTransactionHistory = (
  page: number,
  period: number,
  dateTo: string,
  dateFrom: string,
) => {
  const { token, profile } = useAuth();
  return useQuery({
    queryKey: [
      'transactions-history',
      page,
      period,
      dateTo,
      dateFrom,
      profile?.playerId,
    ],
    queryFn: async () => getTransactionHistory(page, period, dateTo, dateFrom),
    keepPreviousData: false,
    enabled: !!token && !!profile,
  });
};
