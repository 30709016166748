import { BspotAlert } from 'components/molecules/alerts/alert';
import React from 'react';
import { CONFIG } from 'common/utils';

const NameOnCardNotMatching = `Deposit On Hold : This deposit requires manual review since the name used on your payment method does not match the name on your ${CONFIG.BRAND} account. Please contact the Player Experience Team.`;
const Rejected =
  'Deposit Rejected : This deposit has been rejected. Please contact the Player Experience Team.';
const PendingOrUnverified =
  'Deposit On Hold : This deposit requires manual review. Please contact the Player Experience Team.';
const Unknown = 'There was a problem making the deposit.';

type DepositErrorProps = {
  paymentStatus: string;
  nameOnCard: boolean;
};
export const AlertDepositError = ({
  paymentStatus,
  nameOnCard,
}: DepositErrorProps) => {
  const setErrorMessage = () => {
    if (paymentStatus === '5' && nameOnCard) {
      // on hold
      return NameOnCardNotMatching;
    }
    if (paymentStatus === '3') {
      //rejected
      return Rejected;
    }
    if (paymentStatus === '5') {
      //unverified
      return PendingOrUnverified;
    }
    if (paymentStatus === '11') {
      //pending
      return PendingOrUnverified;
    }

    if (![0, 1, 2, 3, 4, 5, 11].includes(+paymentStatus)) {
      //unknown
      return Unknown;
    }
  };
  return <BspotAlert type={'error'} message={setErrorMessage()} />;
};
