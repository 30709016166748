import styled from '@emotion/styled';
import { CardContent, CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import { Button, OutlinedButton } from 'components/atoms/button';
import { Text, TextSize } from 'components/atoms/text';
import { useState } from 'react';

const CardContentWrapper = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type ExpandableCardProps = {
  title: string;
  description: string;
  defaultContent?: React.ReactNode;
  emptyMessage: string;
  openContent: React.ReactNode;
};
export const ExpandableCard: React.FC<ExpandableCardProps> = ({
  description,
  title,
  defaultContent,
  emptyMessage = '',
  openContent,
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Text bold size={TextSize.H6}>
            {title}
          </Text>
        }
        subheader={description}
        action={
          <OutlinedButton
            onClick={toggleOpen}
            isSmall={true}
            text={open ? 'Cancel' : 'Edit'}
          />
        }
      ></CardHeader>
      <CardContentWrapper>
        {defaultContent ? (
          defaultContent
        ) : (
          <Text bold size={TextSize.Body}>
            {emptyMessage}
          </Text>
        )}
        {open && openContent}
      </CardContentWrapper>
    </Card>
  );
};
