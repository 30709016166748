import { ChipWrap, ShineWrap } from 'components/organisms/buy-credits/styled';
import { ChipBlack } from 'icons/svg/chips/chip-black';
import { ChipSilver } from 'icons/svg/chips/chip-silver';
import { ChipGold } from 'icons/svg';
import { ChipPlatinum } from 'icons/svg/chips/chip-platinum';
import { ChipEmerald } from 'icons/svg/chips/chip-emerald';
import { ChipRuby } from 'icons/svg/chips/chip-ruby';

type ChipProps = {
  onClickChip?(product: any): void;
  product: any;
  relative: boolean;
  size?: string;
};
export const Chip = ({ product, onClickChip, relative, size }: ChipProps) => {
  const ChipColors = {
    black: <ChipBlack size={size || '120px'} />,
    silver: <ChipSilver size={size || '120px'} />,
    gold: <ChipGold size={size || '120px'} />,
    platinum: <ChipPlatinum size={size || '120px'} />,
    emerald: <ChipEmerald size={size || '120px'} />,
    ruby: <ChipRuby size={size || '120px'} />,
  };

  return (
    <ChipWrap $position={relative} onClick={() => onClickChip(product)}>
      <ShineWrap size={size}>
        <div className='image-wrapper shine'>
          {ChipColors[product.color]}
          <span
            className='chip-amount'
            style={{
              letterSpacing: '-1px',
              fontSize: size === '70px' ? '12px' : 'auto',
            }}
          >
            {'$' + product.cost}
          </span>
        </div>
      </ShineWrap>
    </ChipWrap>
  );
};
export default Chip;
