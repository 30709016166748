import React from 'react';
import { SvgProps } from './svg';

export function EyeClosed({ size, width = '12', fill }: SvgProps) {
  return (
    <svg
      data-testid='eye-closed-svg'
      width={size || width}
      height={size || width}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5948 5.25506C10.963 4.5618 10.2397 3.95791 9.44476 3.46006L11.4448 1.46006C11.4896 1.41573 11.5247 1.36258 11.5479 1.30396C11.5711 1.24535 11.5819 1.18255 11.5795 1.11956C11.5772 1.05657 11.5618 0.99475 11.5343 0.938027C11.5067 0.881305 11.4678 0.830916 11.4198 0.790056C11.3789 0.743351 11.3286 0.705919 11.2721 0.680273C11.2156 0.654626 11.1543 0.641357 11.0923 0.641357C11.0302 0.641357 10.9689 0.654626 10.9124 0.680273C10.8559 0.705919 10.8056 0.743351 10.7648 0.790056L0.999761 10.5351C0.930314 10.5715 0.870405 10.6237 0.824871 10.6875C0.779338 10.7514 0.749457 10.825 0.737642 10.9025C0.725827 10.9801 0.73241 11.0593 0.756858 11.1338C0.781306 11.2083 0.822934 11.276 0.87838 11.3314C0.933826 11.3869 1.00154 11.4285 1.07604 11.453C1.15054 11.4774 1.22975 11.484 1.30727 11.4722C1.38479 11.4604 1.45844 11.4305 1.52228 11.3849C1.58612 11.3394 1.63835 11.2795 1.67476 11.2101L3.74976 9.13506C4.47447 9.45843 5.2613 9.61886 6.05476 9.60505C8.17976 9.60505 10.2998 8.10506 11.5548 6.70506C11.7402 6.51144 11.847 6.25577 11.8544 5.98777C11.8618 5.71977 11.7693 5.4586 11.5948 5.25506ZM8.15476 6.61506C8.04387 6.96732 7.84961 7.28762 7.58847 7.54876C7.32733 7.8099 7.00703 8.00417 6.65476 8.11506C6.1443 8.2776 5.59155 8.24372 5.10476 8.02006C5.08636 8.01185 5.07032 7.99913 5.05813 7.98309C5.04594 7.96705 5.03798 7.9482 5.03499 7.92827C5.032 7.90834 5.03408 7.88799 5.04103 7.86907C5.04798 7.85016 5.05958 7.8333 5.07476 7.82006L7.84976 5.04006C7.86354 5.02494 7.88082 5.01344 7.90008 5.00656C7.91934 4.99968 7.93999 4.99763 7.96023 5.00059C7.98047 5.00355 7.99967 5.01143 8.01615 5.02355C8.03263 5.03566 8.04589 5.05163 8.05476 5.07006C8.2788 5.55477 8.31445 6.10551 8.15476 6.61506Z'
        fill={fill || '#282828'}
      />
      <path
        d='M3.805 6.68C3.8213 6.66514 3.83319 6.64608 3.83936 6.62491C3.84554 6.60374 3.84576 6.58128 3.84 6.56C3.78355 6.37855 3.75325 6.18999 3.75 6C3.74803 5.70303 3.80473 5.40859 3.91686 5.13359C4.02898 4.8586 4.19433 4.60846 4.40339 4.39754C4.61245 4.18661 4.86112 4.01907 5.13511 3.90451C5.4091 3.78995 5.70303 3.73065 6 3.73C6.19671 3.73131 6.39215 3.76164 6.58 3.82C6.60092 3.82536 6.62285 3.82542 6.6438 3.82018C6.66475 3.81495 6.68407 3.80457 6.7 3.79L7.675 2.815C7.68938 2.79899 7.69956 2.77966 7.70463 2.75875C7.7097 2.73784 7.7095 2.716 7.70405 2.69519C7.6986 2.67437 7.68807 2.65524 7.6734 2.63949C7.65873 2.62375 7.64038 2.6119 7.62 2.605C7.09627 2.438 6.54971 2.35365 6 2.355C3.85 2.355 1.7 3.855 0.405004 5.255C0.225113 5.45362 0.125488 5.71202 0.125488 5.98C0.125488 6.24797 0.225113 6.50638 0.405004 6.705C0.921549 7.26472 1.49649 7.76758 2.12 8.205C2.14326 8.22409 2.17242 8.23453 2.2025 8.23453C2.23259 8.23453 2.26175 8.22409 2.285 8.205L3.805 6.68Z'
        fill={fill || '#282828'}
      />
    </svg>
  );
}
