import React, { ReactNode, useCallback, useMemo } from 'react';
import { Button, ButtonColor } from 'components/atoms/button';
import { Link } from 'components/atoms/link';
import { Warning } from 'icons/svg';

type ErrorMessageProps = {
  header?: string;
  body?: ReactNode;
  image?: ReactNode;
};

function ErrorMessage({ header, body, image }: ErrorMessageProps) {
  return (
    <>
      <div className='d-flex justify-content-center'>{image}</div>
      <h2 className='text-center'>{header}</h2>
      {body}
    </>
  );
}

type ErrorProps = {
  type?: string;
};

export default function Error({ type }: ErrorProps) {
  const errorType = useMemo(() => {
    if (type) return type;

    const searchString =
      window.location.search === ''
        ? window.location.hash
        : window.location.search;

    const urlParams = new URLSearchParams(searchString);

    if (urlParams.has('type')) return urlParams.get('type');
    return null;
  }, [window.location.hash, type]);

  return (
    <div className='d-flex flex-column justify-content-center  mx-auto py-5 mt-5'>
      <div className='d-flex justify-content-center'>
        <Warning size={'75px'} stroke={'white'} />
      </div>
      <h2 style={{ color: 'white' }}>There was an unexpected error. </h2>
      <div className='d-flex flex-column justify-content-center'>
        <div className='d-flex justify-content-center'>
          <Button
            text='Return Home'
            onClick={() => {
              window.location.href = '/';
            }}
          />
        </div>
      </div>
    </div>
  );
}
