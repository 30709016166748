import './index.css';
import { useAuth } from 'contexts/authentication';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useBffTournaments } from 'hooks/use-tournaments/use-tournaments';
import { TournamentCard } from '../../components';
import { BackgroundImageContainer } from '../../components/atoms/background-image-container/background-image-container';
import './styles.scss';
import { Routes } from '../../common/routes';
import { AppLoader } from '../../components/molecules/app-loader/app-loader';
import { CONFIG } from 'common/utils';
const Tournaments = () => {
  const { auth } = useAuth();
  const {
    data: tournaments,
    refetch: refetchTournaments,
    isLoading,
  } = useBffTournaments();
  const pageTitle = 'Tournaments - ' + CONFIG.BRAND;

  const showNoTournaments = !tournaments || tournaments.length === 0;
  const showTournaments = tournaments?.length > 0;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>

      <BackgroundImageContainer width='100%' theme='green'>
        {isLoading && <AppLoader />}
        {!isLoading && (
          <div
            className='tournament-page app-container d-flex flex-column gap-4 pb-3 pb-lg-5'
            style={{ minHeight: '192px' }}
          >
            <h1 style={{ maxWidth: '350px' }} className='align-self-center'>
              <img
                width={350}
                height={156}
                className='align-self-center w-100'
                src='/assets/images/tournament-badge-white.webp'
                alt='tournament-badge'
              />
            </h1>

            <section className='d-flex flex-column gap-4'>
              {showNoTournaments && <TournamentNotAvailable />}
              {showTournaments && <TournamentPageContent />}
            </section>

            <section
              className='d-flex justify-content-center gap-4 flex-wrap align-items-start'
              style={{ minHeight: '664px' }}
            >
              {tournaments?.map((tournament, index) => (
                <TournamentCard
                  key={index}
                  tournament={tournament}
                  playerId={auth?.session?.playerId.toString()}
                  refetchTournaments={refetchTournaments}
                />
              ))}
            </section>
          </div>
        )}
      </BackgroundImageContainer>
    </>
  );
};

const TournamentPageContent = () => {
  return (
    <section className='d-flex flex-column gap-4'>
      <p>
        Play and win in our <b>BIG PRIZE</b> tournaments! Earn points, climb the
        leaderboard and score big with
        <b> $1000s in Freeplay</b> prizes up for grabs. Keep an eye out for the
        Weekend Tournaments, Monthly Tournaments, Happy Hour Showdowns, Flash
        Challenges and more.
      </p>
      <p>
        You must tap the <b>Opt In</b> button join each tournament.
      </p>
    </section>
  );
};

const TournamentNotAvailable = () => (
  <p>
    We're sorry but there are no tournaments to take part in at the moment. Why
    not check out our{' '}
    <a className='fw-bold text-white' href={Routes.Promotions.path}>
      Promotions
    </a>{' '}
    page instead?
  </p>
);

export default Tournaments;
