import styled from '@emotion/styled';
interface formProps {
  marginTop?: string;
}
export const Form = styled.form<formProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  background-color: #323232;
  padding: 40px;
  border-radius: 8px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '10%')};
`;
