import { useFormContext } from 'react-hook-form';
import { DatePicker } from 'components/atoms/date';
import { FormInput } from 'components/atoms/input/input';

function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  const m = today.getMonth() - birthDate.getMonth();

  let age = today.getFullYear() - birthDate.getFullYear();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function PersonalDetailsForm() {
  const { control } = useFormContext();

  return (
    <>
      <FormInput
        label='First name'
        name='firstname'
        data-cy='firstname'
        validation={{
          required: 'This field is required',
        }}
      />

      <FormInput label='Middle Name' name='middlename' data-cy='middlename' />
      <FormInput
        label='Last name'
        name='lastname'
        data-cy='lastname'
        validation={{
          required: 'This field is required',
        }}
      />
      <DatePicker
        rules={{
          required: 'This field is required',
          validate: {
            over21: (value) =>
              getAge(value) >= 21 || 'Must be over 21 years old',
            maxAge: (value) => {
              const year = new Date(value).getFullYear();
              return year >= 1900 || 'The DOB entered is not valid';
            },
          },
        }}
        label='Date of Birth'
        control={control}
        name={'date_of_birth'}
      />
    </>
  );
}
