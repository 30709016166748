import { useResponsibleGaming } from 'hooks/use-content';
import { CmsContent } from 'components/organisms/cms/strive-content-page/cms-content';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'common/utils';

export default function ResponsibleGaming() {
  const { data: res } = useResponsibleGaming();

  const pageTitle = 'Responsible gaming - ' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <CmsContent content={res?.content} title={res?.title} />
    </>
  );
}
