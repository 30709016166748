import styled from '@emotion/styled';
import { Route } from 'common/routes';
import { bp } from 'common/theme';
import { useNavigate } from 'hooks/use-navigate';
import { ArrowRight } from 'icons/svg';
import { SnackbarContent } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';

const RedirectToastWrapper = styled(SnackbarContent)`
  padding: 24px 20px 24px 24px;
  border: 3px solid #000;
  border-radius: 32px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  color: black;
  font-family: 'Poppins';
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  cursor: pointer;
  z-index: 1;
  flex-wrap: nowrap;

  @media only screen and ${bp.md} {
    min-width: 400px;
    max-width: 400px;
  }
`;

const ToastTitle = styled.span`
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
`;

export type RedirectToastProps = {
  id: string;
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  redirectTo: Route;
  style?: React.CSSProperties;
};

export const RedirectToast = React.forwardRef<
  HTMLDivElement,
  RedirectToastProps
>((props, ref) => {
  const { id, title, message, redirectTo, style } = props;
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const timeoutId = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    registerCloseTimeout();

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  function registerCloseTimeout() {
    timeoutId.current = setTimeout(() => {
      setOpen(!open);
    }, 3000);
  }

  const onClick = () => {
    if (open) {
      navigate(redirectTo);
    } else {
      setOpen(!open);
      registerCloseTimeout();
    }
  };

  return (
    <RedirectToastWrapper
      id={id}
      ref={ref}
      style={style}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <div className='d-flex flex-1 flex-column justify-content-center'>
        <div className='d-flex align-items-center gap-2'>
          <img src='/assets/images/square_cash_stack.svg' />
          <ToastTitle>{title}</ToastTitle>
        </div>
        {open && (
          <div className='mt-2'>
            <span>{message}</span>
          </div>
        )}
      </div>
      <div className='d-flex align-items-center'>
        <ArrowRight fill='black' />
      </div>
    </RedirectToastWrapper>
  );
});
