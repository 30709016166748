import React from 'react';
import { SvgProps } from 'icons/svg/index';

export function Help({
  size,
  width = '1rem',
  height = '1rem',
  fill = '#282828',
  onClick,
}: SvgProps) {
  return (
    <div
      style={{
        opacity: '.8',
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='20'
        viewBox='0 0 21 20'
        fill='none'
      >
        <g opacity='0.8'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.3334 2.50016C6.19128 2.50016 2.83341 5.85803 2.83341 10.0002C2.83341 14.1423 6.19128 17.5002 10.3334 17.5002C14.4755 17.5002 17.8334 14.1423 17.8334 10.0002C17.8334 5.85803 14.4755 2.50016 10.3334 2.50016ZM1.16675 10.0002C1.16675 4.93755 5.2708 0.833496 10.3334 0.833496C15.396 0.833496 19.5001 4.93755 19.5001 10.0002C19.5001 15.0628 15.396 19.1668 10.3334 19.1668C5.2708 19.1668 1.16675 15.0628 1.16675 10.0002ZM10.5485 6.68709C10.1606 6.62055 9.76161 6.69345 9.42228 6.89288C9.08294 7.09231 8.82514 7.4054 8.69453 7.7767C8.5418 8.21086 8.06604 8.439 7.63188 8.28627C7.19772 8.13355 6.96958 7.65778 7.1223 7.22363C7.38353 6.48103 7.89914 5.85486 8.57781 5.456C9.25648 5.05714 10.0544 4.91134 10.8303 5.04442C11.6061 5.1775 12.3099 5.58088 12.8168 6.1831C13.3237 6.7852 13.6011 7.54723 13.6001 8.33426C13.5997 9.60955 12.6542 10.4517 11.979 10.9019C11.616 11.1439 11.2589 11.3218 10.9958 11.4388C10.8631 11.4977 10.7514 11.5425 10.6708 11.5732C10.6305 11.5885 10.5978 11.6004 10.574 11.6089L10.5451 11.619L10.5358 11.6222L10.5326 11.6233L10.5313 11.6237C10.5311 11.6238 10.5303 11.6241 10.2667 10.8335L10.5303 11.6241C10.0937 11.7696 9.62172 11.5336 9.47618 11.097C9.33069 10.6605 9.56645 10.1888 10.0028 10.0431L10.0156 10.0386C10.0285 10.034 10.0495 10.0264 10.0775 10.0157C10.1337 9.99429 10.2173 9.96092 10.3189 9.91574C10.5246 9.82432 10.7925 9.68977 11.0545 9.51512C11.6292 9.13201 11.9334 8.72444 11.9334 8.3335L11.9334 8.33225C11.934 7.93866 11.7953 7.55755 11.5418 7.25644C11.2883 6.95532 10.9364 6.75363 10.5485 6.68709ZM9.50008 14.1668C9.50008 13.7066 9.87318 13.3335 10.3334 13.3335H10.3417C10.802 13.3335 11.1751 13.7066 11.1751 14.1668C11.1751 14.6271 10.802 15.0002 10.3417 15.0002H10.3334C9.87318 15.0002 9.50008 14.6271 9.50008 14.1668Z'
            fill='#00F07D'
          />
        </g>
      </svg>
    </div>
  );
}
