import styled from '@emotion/styled';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';

export const StyledStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'white',
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

export const StyledStepRoot = styled('div')<{
  ownerState: { active?: boolean };
}>(({ ownerState }) => ({
  color: 'white',
  display: 'flex',
  height: 30,
  width: 30,
  alignItems: 'center',
  justifyContent: 'center',

  '& .StyledStepIcon-circle': {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: '2px white solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    ...(ownerState.active && {
      height: 30,
      width: 30,
      backgroundColor: 'var(--secondary-color)',
      border: 'none',
      fontSize: '16px',
    }),
  },
}));

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  text-align: start;
`;
