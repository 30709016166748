import { Close } from 'icons/svg';
import { OutlinedButton } from 'components/atoms/button';
import { useNavigate } from 'hooks/use-navigate';
import { Routes } from 'common/routes';
import { toCurrency } from 'common/bspot-shared';
import { BspotModal } from 'components/atoms/modal/modal';
import React from 'react';

type WonModalProps = {
  onClose?(): void;
  amount: any;
  show: boolean;
};
export const LostModal = ({ onClose, show, amount }: WonModalProps) => {
  const navigate = useNavigate();

  const onCloseModal = () => {
    onClose();
    navigate(Routes.WagersPage);
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'HARD LUCK'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>
      <div className={'d-flex flex-column gap-1'}>
        <div style={{ color: '#0aeb88', fontSize: '4rem' }}>
          {toCurrency(amount)}
        </div>
        <div>{'Get back in the game!'}</div>
      </div>
      <div className={'d-flex flex-lg-row flex-column gap-2 '}>
        <OutlinedButton text={'Get more Credits!'} onClick={onCloseModal} />
      </div>
    </BspotModal>
  );
};
export default LostModal;
