import { Api } from 'common/api';

export type Account = {
  account: string;
  allow_sepa_payout: boolean;
  bank: string;
  bank_id: number;
  card_number: string | null;
  editable: boolean;
  edited_account: string;
  icon: string;
  max_withdraw_amount: number;
  min_withdraw_amount: number;
  name: string;
  net_balance: number;
  sub_bank: string;
  sub_bank_code: string;
  uniq_id: string;
};

export type WithdrawalMethods = {
  cash: number;
  banks: any[];
  info: string;
  title: string;
  translations: {
    account: string;
    additional_information: string;
    amount: string;
    bank: string;
    cancel: string;
    cancel_withdraw_request: string;
    confirm: string;
    max_amount: string;
    name: string;
    no_accounts: string;
    request_withdrawal: string;
    sepa_bic: string;
    sepa_iban: string;
    withdraw_requests: string;
    withdrawal_confirm: string;
  };
  accounts: Account[];

  default_account?: {
    account: string;
    max_withdraw_amount: number;
    min_withdraw_amount: number;
    uniq_id: string;
  };
  isAllowed?: boolean;

  requests?: {
    account: string;
    amount: string;
    cancel_requested: boolean;
    id: number;
    is_used: boolean;
  }[];
};

export type WithdrawalRequestPayload = {
  id: string;
  amount: string;
};

export type WithdrawalRequestResponse = {
  params: {
    amount: string;
    edited_account: string;
    id: string;
    withdrawal_type: number;
  };
  confirm_info: {
    account: string;
    amount: string;
    name: string;
  };
  title: string;
};

export type ConfirmWithdrawalRequestPayload = {
  id: string;
  amount: string | number;
  edited_account: string;
  withdrawal_type: string;
};

export type ConfirmWithdrawalRequestResponse = {
  RedirectUrl: string | null;
  ExternalResultCode: string | null;
  ExternalErrorCode: string | null;
  ExternalErrorCodeDescription: string | null;
  Success: boolean;
  Code: number;
  Message: string | null;
};

export type CancelWithdrawalRequestPayload = {
  request_id: number;
};

export type CancelWithdrawalRequestResponse = {
  Success: boolean;
  Code: number;
  Message: string | null;
};

export const getWithdrawMethods = (): Promise<WithdrawalMethods> => {
  return Api.get<StriveResponse<WithdrawalMethods>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/withdrawals`,
  ).then((res) => {
    return {
      ...res.data.Data,
      isAllowed: res.status === 200,
    };
  });
};

export const requestWithdrawal = (
  payload: WithdrawalRequestPayload,
): Promise<WithdrawalRequestResponse> => {
  return Api.post<StriveResponse<WithdrawalRequestResponse>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/withdrawals`,
    { data: payload },
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to request withdrawal');
    }
    return res.data.Data;
  });
};

export const confirmWithdrawal = (
  payload: ConfirmWithdrawalRequestPayload,
): Promise<ConfirmWithdrawalRequestResponse> => {
  return Api.post<StriveResponse<ConfirmWithdrawalRequestResponse>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/withdrawals/confirm`,
    { data: payload },
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to confirm withdrawal');
    }
    return {
      ...res.data.Data,
      Message: res.data.Message,
    };
  });
};

export const cancelWithdrawal = (
  payload: CancelWithdrawalRequestPayload,
): Promise<CancelWithdrawalRequestResponse> => {
  return Api.post<StriveResponse<CancelWithdrawalRequestResponse>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/withdrawals/cancel`,
    { data: payload },
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to cancel withdrawal');
    }
    return {
      ...res.data.Data,
      Message: res.data.Message,
    };
  });
};
