export const Success = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_9069_6133)'>
      <path
        d='M6.2002 10.9794L8.16019 13.761C8.23454 13.872 8.33434 13.9637 8.45127 14.0284C8.56821 14.0931 8.69891 14.1289 8.83249 14.1329C8.96607 14.1369 9.09868 14.109 9.21927 14.0514C9.33986 13.9937 9.44495 13.9082 9.52579 13.8018L13.5002 7.50008'
        stroke='#02BD9C'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z'
        stroke='#02BD9C'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_9069_6133'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
