export function Profile({
  width = 22,
  height = 55,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.9 23.5C17.2333 22.3 17.2 21.1 16.8 19.9C16.3333 18.7667 15.6 17.8333 14.6 17.1C13.5333 16.3667 12.4 16 11.2 16C9.93333 16 8.8 16.3667 7.8 17.1C6.73333 17.8333 6 18.7667 5.6 19.9C5.13333 21.1 5.06667 22.3 5.4 23.5C5.73333 24.7 6.4 25.7 7.4 26.5C7.4 26.6 7.5 26.6 7.5 26.7C7.5 26.8 7.6 26.9 7.5 27.1C7.5 27.2 7.4 27.3 7.2 27.4C5.66667 28.1333 4.43333 29.2 3.5 30.6C2.56667 32 2.06667 33.5333 2 35.2C2 35.4 2.06667 35.5667 2.2 35.7C2.33333 35.8333 2.5 35.9 2.7 35.9C2.9 35.9667 3.06667 35.9333 3.2 35.8C3.33333 35.6667 3.4 35.5 3.4 35.3C3.53333 33.2333 4.33333 31.4667 5.8 30C7.33333 28.6 9.13333 27.9 11.2 27.9C13.2667 27.9 15.0333 28.6 16.5 30C18.0333 31.4667 18.8333 33.2333 18.9 35.3C18.9 35.4333 18.9333 35.5333 19 35.6C19 35.6667 19.0333 35.7333 19.1 35.8C19.2333 35.8667 19.3333 35.9333 19.4 36C19.4667 36 19.5667 36 19.7 36C19.7667 36 19.8333 35.9667 19.9 35.9C20.0333 35.9 20.1333 35.8667 20.2 35.8C20.2667 35.7333 20.3 35.6333 20.3 35.5C20.3 35.4333 20.3 35.3333 20.3 35.2C20.2333 33.5333 19.7333 32 18.8 30.6C17.8667 29.2 16.6333 28.1333 15.1 27.4C15 27.3 15 27.3 14.9 27.2C14.8 27 14.8 26.7 15 26.5C15.9333 25.7 16.5667 24.7 16.9 23.5ZM14.4 25.2C13.4667 26.0667 12.4 26.5 11.2 26.5C9.93333 26.5 8.86667 26.0667 8 25.2C7.06667 24.2667 6.6 23.2 6.6 22C6.6 20.7333 7.06667 19.6333 8 18.7C8.86667 17.8333 9.93333 17.4 11.2 17.4C12.4 17.4 13.4667 17.8333 14.4 18.7C15.2667 19.6333 15.7 20.7333 15.7 22C15.7 23.2 15.2667 24.2667 14.4 25.2Z'
        fill='#0AEB88'
      />
      <path
        d='M16.9 23.5C17.2333 22.3 17.2 21.1 16.8 19.9C16.3333 18.7667 15.6 17.8333 14.6 17.1C13.5333 16.3667 12.4 16 11.2 16C9.93333 16 8.8 16.3667 7.8 17.1C6.73333 17.8333 6 18.7667 5.6 19.9C5.13333 21.1 5.06667 22.3 5.4 23.5C5.73333 24.7 6.4 25.7 7.4 26.5C7.4 26.6 7.5 26.6 7.5 26.7C7.5 26.8 7.6 26.9 7.5 27.1C7.5 27.2 7.4 27.3 7.2 27.4C5.66667 28.1333 4.43333 29.2 3.5 30.6C2.56667 32 2.06667 33.5333 2 35.2C2 35.4 2.06667 35.5667 2.2 35.7C2.33333 35.8333 2.5 35.9 2.7 35.9C2.9 35.9667 3.06667 35.9333 3.2 35.8C3.33333 35.6667 3.4 35.5 3.4 35.3C3.53333 33.2333 4.33333 31.4667 5.8 30C7.33333 28.6 9.13333 27.9 11.2 27.9C13.2667 27.9 15.0333 28.6 16.5 30C18.0333 31.4667 18.8333 33.2333 18.9 35.3M16.9 23.5C16.5667 24.7 15.9333 25.7 15 26.5C14.8 26.7 14.8 27 14.9 27.2C15 27.3 15 27.3 15.1 27.4C16.6333 28.1333 17.8667 29.2 18.8 30.6C19.7333 32 20.2333 33.5333 20.3 35.2C20.3 35.3333 20.3 35.4333 20.3 35.5C20.3 35.6333 20.2667 35.7333 20.2 35.8C20.1333 35.8667 20.0333 35.9 19.9 35.9C19.8333 35.9667 19.7667 36 19.7 36C19.5667 36 19.4667 36 19.4 36C19.3333 35.9333 19.2333 35.8667 19.1 35.8C19.0333 35.7333 19 35.6667 19 35.6C18.9333 35.5333 18.9 35.4333 18.9 35.3M16.9 23.5V23.5ZM18.9 35.3V35.3ZM14.4 25.2C13.4667 26.0667 12.4 26.5 11.2 26.5C9.93333 26.5 8.86667 26.0667 8 25.2C7.06667 24.2667 6.6 23.2 6.6 22C6.6 20.7333 7.06667 19.6333 8 18.7C8.86667 17.8333 9.93333 17.4 11.2 17.4C12.4 17.4 13.4667 17.8333 14.4 18.7C15.2667 19.6333 15.7 20.7333 15.7 22C15.7 23.2 15.2667 24.2667 14.4 25.2ZM14.4 25.2V25.2Z'
        stroke='#0AEB88'
        strokeWidth='0.2'
      />
    </svg>
  );
}

export function ProfileFill({
  width = 22,
  height = 55,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.7814 16.0519C9.55361 16.0516 8.36068 16.4601 7.39088 17.213C6.42107 17.9659 5.7296 19.0204 5.42561 20.2099C5.12162 21.3995 5.22241 22.6564 5.71208 23.7823C6.20174 24.9082 7.0524 25.8389 8.1298 26.4277C6.20883 26.9886 4.51465 28.143 3.28993 29.7257C2.06522 31.3083 1.37282 33.238 1.31191 35.2382C1.30868 35.3419 1.32592 35.4452 1.36264 35.5422C1.39936 35.6393 1.45484 35.7281 1.52591 35.8037C1.59698 35.8792 1.68225 35.9401 1.77683 35.9827C1.87142 36.0252 1.97348 36.0488 2.07717 36.0519H19.4866C19.6959 36.0456 19.894 35.9564 20.0375 35.804C20.181 35.6517 20.2581 35.4485 20.2519 35.2393C20.191 33.239 19.4986 31.3094 18.2739 29.7267C17.0492 28.1441 15.355 26.9896 13.434 26.4288C14.512 25.8402 15.3633 24.9093 15.8533 23.7831C16.3433 22.6569 16.4442 21.3995 16.14 20.2095C15.8359 19.0196 15.1439 17.9649 14.1735 17.212C13.2031 16.4591 12.0096 16.0509 10.7814 16.0519Z'
        fill='#0AEB88'
      />
    </svg>
  );
}
