import React from 'react';
import { Close } from 'icons/svg';
import { Button, OutlinedButton } from 'components/atoms/button';
import { useNavigate } from 'hooks/use-navigate';
import { Routes } from 'common/routes';
import { BspotModal } from 'components/atoms/modal/modal';

type NoCreditsModalProps = {
  onClose?(): void;
  show: boolean;
};
export const NoCreditsModal = ({ onClose, show }: NoCreditsModalProps) => {
  const navigate = useNavigate();

  const onCloseModal = () => {
    onClose();
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{"You're out of credits"}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>

      <div className={'d-flex flex-column gap-1'}>
        <div>
          You need to purchase additional credits before you can Play All.
        </div>
      </div>

      <div className={'d-flex flex-lg-row flex-column gap-2 '}>
        <OutlinedButton text='Cancel' onClick={onCloseModal} />
        <Button
          text='Buy Credits'
          onClick={() => navigate(Routes.WagersPage)}
        />
      </div>
    </BspotModal>
  );
};
export default NoCreditsModal;
