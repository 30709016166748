import type { SerializedStyles } from '@emotion/react';
import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import 'common/theme/main.scss';
import { BreakPoints } from 'common/theme';

export enum TextSize {
  H0 = 'h0',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  Body = 'body',
  Hint = 'hint',
}

const SizeStyleRem: Record<string, Record<string, SerializedStyles>> = {
  [TextSize.H0]: {
    large: css`
      font-size: 4rem;
    `,
  },
  [TextSize.H1]: {
    large: css`
      font-size: 3rem;
    `,
    medium: css`
      font-size: 2.5rem;
    `,
    small: css`
      font-size: 2rem;
    `,
  },
  [TextSize.H2]: {
    large: css`
      font-size: 2.5rem;
    `,
    medium: css`
      font-size: 2rem;
    `,
    small: css`
      font-size: 1.75rem;
    `,
  },
  [TextSize.H3]: {
    large: css`
      font-size: 2rem;
    `,
    medium: css`
      font-size: 1.75rem;
    `,
    small: css`
      font-size: 1.5rem;
    `,
  },
  [TextSize.H4]: {
    large: css`
      font-size: 1.75rem;
    `,
    medium: css`
      font-size: 1.5rem;
    `,
    small: css`
      font-size: 1.25rem;
    `,
  },
  [TextSize.H5]: {
    large: css`
      font-size: 1.5rem;
    `,
    medium: css`
      font-size: 1.25rem;
    `,
    small: css`
      font-size: 1.125rem;
    `,
  },
  [TextSize.H6]: {
    large: css`
      font-size: 1.25rem;
    `,
    medium: css`
      font-size: 1rem;
    `,
    small: css`
      font-size: 0.75rem;
    `,
  },
  [TextSize.Body]: {
    large: css`
      font-size: 1rem;
    `,
  },
  [TextSize.Hint]: {
    large: css`
      font-size: 0.85rem;
    `,
  },
};

const SizeStyleEm: Record<string, Record<string, SerializedStyles>> = {
  [TextSize.H0]: {
    large: css`
      font-size: 4em;
    `,
  },
  [TextSize.H1]: {
    large: css`
      font-size: 3em;
    `,
    medium: css`
      font-size: 2.5em;
    `,
    small: css`
      font-size: 2em;
    `,
  },
  [TextSize.H2]: {
    large: css`
      font-size: 2.5em;
    `,
    medium: css`
      font-size: 2em;
    `,
    small: css`
      font-size: 1.75em;
    `,
  },
  [TextSize.H3]: {
    large: css`
      font-size: 2em;
    `,
    medium: css`
      font-size: 1.75em;
    `,
    small: css`
      font-size: 1.5em;
    `,
  },
  [TextSize.H4]: {
    large: css`
      font-size: 1.75em;
    `,
    medium: css`
      font-size: 1.5em;
    `,
    small: css`
      font-size: 1.25em;
    `,
  },
  [TextSize.H5]: {
    large: css`
      font-size: 1.5em;
    `,
    medium: css`
      font-size: 1.25em;
    `,
    small: css`
      font-size: 1.125em;
    `,
  },
  [TextSize.H6]: {
    large: css`
      font-size: 1.25em;
    `,
    medium: css`
      font-size: 1em;
    `,
    small: css`
      font-size: 0.75em;
    `,
  },
  [TextSize.Body]: {
    large: css`
      font-size: 0.9em;
    `,
  },
  [TextSize.Hint]: {
    large: css`
      font-size: 0.75em;
    `,
  },
};

interface StyledText {
  $color?: string;
  $bold?: boolean;
  $useEm?: boolean;
  $size?: string;
  as?: any;
}

export const StyledText = styled.span<StyledText>`
  font-family: var(--font-family), sans-serif;
  font-size: inherit;
  color: ${(props) => props.$color || 'inherit'};
  font-weight: ${(props) => (props.$bold ? 'bold' : 'inherit')};

  ${(props) => {
    const SizeStyle = props.$useEm ? SizeStyleEm : SizeStyleRem;
    return css`
      ${SizeStyle[props.$size]?.large};

      @media only screen and (max-width: ${BreakPoints.lg}) {
        ${SizeStyle[props.$size]?.medium};
      }

      @media only screen and (max-width: ${BreakPoints.sm}) {
        ${SizeStyle[props.$size]?.small};
      }
    `;
  }}
`;

export type TextProps = {
  children?: ReactNode | ReactNode[];
  bold?: boolean;
  size?: TextSize;
  text?: string | ReactNode | number;
  color?: string;
  useEm?: boolean;
  className?: string;
  as?: string;
};

export function Text({
  children,
  bold,
  size = TextSize.Body,
  text = '',
  color = '',
  useEm = false,
  className = '',
  as = 'span',
}: TextProps) {
  return (
    // @ts-ignore
    <StyledText
      className={className}
      as={as}
      $bold={bold}
      $size={size}
      $color={color}
      $useEm={useEm}
    >
      {text}
      {children}
    </StyledText>
  );
}

export const H1 = (props: TextProps) => (
  <Text size={TextSize.H1} as='h1' {...props} />
);
export const H4 = (props: TextProps) => (
  <Text size={TextSize.H4} as='h4' {...props} />
);
export const H5 = (props: TextProps) => (
  <Text size={TextSize.H5} as='h5' {...props} />
);
export const H6 = (props: TextProps) => (
  <Text size={TextSize.H6} as='h6' {...props} />
);
export const Hint = (props: TextProps) => (
  <Text size={TextSize.Hint} as='span' {...props} />
);
