import {
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
  OutlinedInput,
} from '@mui/material';
import { Controller } from 'react-hook-form';

export const Select = ({
  name,
  control,
  label = '',
  options,
  rules,
  errors,
  disabled = false,
}) => {
  return (
    <FormControl fullWidth>
      <Controller
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <InputLabel variant='outlined' htmlFor='select-label'>
              {label}
            </InputLabel>
            <NativeSelect
              variant='outlined'
              inputProps={{
                id: 'select-label',
              }}
              input={<OutlinedInput label={label} />}
              onChange={onChange}
              value={value == undefined ? '' : value}
              disabled={disabled}
              error={!!error}
            >
              <option value={''} disabled>
                {' '}
              </option>
              {options?.map(({ value, label }, index) => {
                const key = `option-key-${index}`;
                return (
                  <option key={key} data-testid='select-option' value={value}>
                    {label}
                  </option>
                );
              })}
            </NativeSelect>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
