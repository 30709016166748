import React, { useEffect, useState } from 'react';
import './styles.scss';
import 'components/organisms/layout/game-header/buttons-sdk/styles.scss';
import { useProductsAndBalances } from 'hooks/use-products-and-balances/use-products-and-balances';
import { useBalanceIncrease } from 'hooks/use-balance-increase/use-balance-increase';
import { QuickCreditsModalManager } from 'components';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';

/**
 * flag  - enabled : used to switch polling interval to 2 seconds in (useGetCredits hook)
 * localStorage.setItem('lc', 'true');
 */

export const WagersQuick = () => {
  const { gameProducts, balances, isLoadingBalances, instantPlayEligible } =
    useProductsAndBalances();
  const { start, rounded } = useBalanceIncrease({
    balance: balances?.credits,
  });
  const [prevValue, setPrevValue] = useState(null);
  const isLoadingCreditsButton =
    isLoadingBalances || balances?.credits_pending > 0;
  const [miniModalCredits, setMiniModalCredits] = useState(0);
  const [wager, setWager] = useState(0);
  const [animationState, setAnimationState] = useState<
    'initial' | 'slow' | 'fast' | 'immediate'
  >('initial');
  const [spinsRemaining, setSpinsRemaining] = useState(null);
  const [prevWager, setPrevWager] = useState(null);

  useEffect(() => {
    if (isLoadingCreditsButton) {
      setAnimationState('initial');
      setMiniModalCredits(0);
      return;
    }

    const hasMaybeWagered = balances?.credits < prevValue;
    const hasMaybeAddedCredits = balances?.credits > prevValue;

    if (hasMaybeWagered) {
      const _wager = prevValue - balances?.credits;
      const _spins = Math.floor(balances?.credits / _wager);
      const _credsNeeded = 20 * _wager;
      console.log('ARF =================> WAGER NOT CHANGED');
      console.log('[ARF Game container -no-sdk-game- ] prev', prevValue);
      console.log('[ARF Game container -no-sdk-game- ] curr', balances.credits);
      console.log('[ARF Game container -no-sdk-game- ] wager', _wager);
      console.log('[ARF Game container -no-sdk-game- ] spins', _spins);
      console.log(
        '[ARF Game container -no-sdk-game- ] _credsNeeded',
        _credsNeeded,
      );

      if (prevWager && prevWager !== _wager) {
        if (animationState === 'fast' || animationState === 'immediate') {
          console.log('ARF =================> WAGER CHANGED');
          setPrevWager(_wager);
          setSpinsRemaining(_spins);
          localStorage.removeItem('lc-');
          return;
        }
      }
      setWager(_wager);
      setSpinsRemaining(_spins);

      if (isLoadingCreditsButton || start) {
        setAnimationState('initial');
        setMiniModalCredits(0);
        return;
      }
      if (_spins === null || _spins > 20) {
        localStorage.removeItem('lc');
        setAnimationState('initial');
        setMiniModalCredits(0);
      }
      if (_spins <= 20 && _spins > 10) {
        localStorage.setItem('lc', 'true');
        setAnimationState('slow');
        setMiniModalCredits(0);
      }
      if (_spins <= 10 && _spins > 1) {
        setAnimationState((prevState) => {
          if (prevState !== 'fast' && miniModalCredits === 0) {
            setMiniModalCredits(_credsNeeded);
          }
          return 'fast';
        });
        localStorage.setItem('lc', 'true');
      }
      if (_spins <= 1) {
        setAnimationState((prevState) => {
          if (prevState !== 'immediate' && miniModalCredits === 0) {
            setMiniModalCredits(_credsNeeded);
          }
          return 'immediate';
        });
        localStorage.setItem('lc', 'true');
      }
    }

    if (hasMaybeAddedCredits) {
      localStorage.removeItem('lc');
      setAnimationState('initial');
      setMiniModalCredits(0);
    }

    setPrevValue(balances?.credits);

    if (!prevWager) {
      setPrevWager(wager);
    }

    return () => {};
  }, [
    balances?.credits,
    start,
    isLoadingCreditsButton,
    prevValue,
    spinsRemaining,
    prevWager,
    wager,
    animationState,
    miniModalCredits,
  ]);

  return (
    <>
      <QuickCreditsModalManager
        isLowCreditMode={
          animationState !== 'initial' || balances?.credits === 0
        }
        hide={() => {
          postNativeMessage(BspotCashierMessage.CLOSE, 'CLOSE quick Credits');
        }}
        stage={animationState}
        spinsRemaining={spinsRemaining}
      />
    </>
  );
};
