export enum PixelActions {
  PURCHASE = 'Purchase',
  REGISTER = 'CompleteRegistration',
  DEPOSIT_SUCCESS = 'AddPaymentInfo',
  ADD_TO_CART = 'AddToCart',
}

export const sendPixelEvent = (event: PixelActions, data: any) => {
  if (window['fbq']) {
    window['fbq']('track', event, data);
  }
};
