import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'contexts/authentication';

export const useXtremeNotifications = () => {
  const fetchInbox = (): Promise<any> => {
    return new Promise((resolve) => {
      if (!window['xtremepush']) {
        resolve(0);
      }
      window['xtremepush'](
        'inbox',
        'badge',
        {},
        (data) => {
          resolve(data.badge);
        },
        () => {
          resolve(0);
        },
      );
    });
  };

  const { auth } = useAuth();

  return useQuery({
    queryKey: ['xtremepush-inbox', auth.session?.playerId],
    queryFn: fetchInbox,
    keepPreviousData: false,
    refetchInterval: 30000,
    enabled: !!auth.session?.playerId,
  });
};
