import { SvgProps } from './svg';

export function LogoFull({
  size,
  width = '124',
  height = '46',
  fill = '#0AEB88',
  onClick,
  className = '',
}: SvgProps) {
  return (
    <svg
      onClick={onClick}
      width={width}
      height={height}
      viewBox={`0 0 ${size || width} ${size || height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_5221_50963)'>
        <path
          d='M12 10.9932C10.2774 10.9932 8.63955 11.3609 7.15691 12.0177L7.3887 0H0V22.9966C0 29.6152 5.38325 35 12 35C18.6167 35 24 29.6152 24 22.9966C24 16.378 18.6167 10.9932 12 10.9932ZM12 28.4631C8.98673 28.4631 6.53506 26.0107 6.53506 22.9966C6.53506 19.9825 8.98673 17.5301 12 17.5301C15.0133 17.5301 17.4649 19.9825 17.4649 22.9966C17.4649 26.0107 15.0133 28.4631 12 28.4631Z'
          fill='#0AEB88'
        />
      </g>
      <path
        d='M34.002 31.5495L37.5064 26.6024C39.366 28.3508 41.3872 29.2255 43.57 29.2255C44.016 29.2255 44.3924 29.1755 44.7003 29.0754C45.0081 28.9754 45.2424 28.8295 45.404 28.6386C45.5656 28.4467 45.6464 28.213 45.6464 27.9364C45.6464 27.6292 45.3968 27.3342 44.8967 27.0505C44.3975 26.7667 43.4636 26.4105 42.095 25.9808C41.5109 25.7971 40.9729 25.6093 40.4809 25.4174C39.9889 25.2255 39.4898 24.9959 38.9824 24.7274C38.4751 24.459 38.026 24.1793 37.6332 23.8874C37.2415 23.5955 36.8681 23.2546 36.5152 22.8637C36.1613 22.4728 35.8657 22.0543 35.6274 21.6093C35.3891 21.1643 35.2049 20.6622 35.074 20.1028C34.9431 19.5425 34.8776 18.9413 34.8776 18.2963C34.8776 16.8388 35.2622 15.5548 36.0304 14.4423C36.7986 13.3308 37.8368 12.4826 39.143 11.8998C40.4492 11.317 41.917 11.0251 43.5465 11.0251C44.6839 11.0251 45.7446 11.1323 46.7286 11.3476C47.7126 11.562 48.6496 11.8804 49.5415 12.3019C50.4335 12.7235 51.3326 13.2726 52.2388 13.9472L48.7345 19.2159C47.951 18.4341 47.0897 17.8349 46.1517 17.4216C45.2137 17.0072 44.3382 16.8 43.5229 16.8C43.1077 16.8 42.7312 16.8918 42.3927 17.0766C42.0541 17.2613 41.8853 17.5594 41.8853 17.9737C41.8853 18.2657 42.1196 18.5647 42.5891 18.8709C43.0586 19.1771 43.8298 19.5231 44.9059 19.9059C45.4899 20.1202 45.9778 20.3008 46.3696 20.4468C46.7614 20.5928 47.1961 20.7652 47.6727 20.9643C48.1494 21.1633 48.5452 21.344 48.8603 21.5052C49.1753 21.6665 49.5139 21.8543 49.875 22.0686C50.236 22.283 50.5327 22.4902 50.7628 22.6902C50.993 22.8892 51.2313 23.124 51.4778 23.3924C51.7243 23.6609 51.9156 23.9334 52.0547 24.2089C52.1928 24.4845 52.3237 24.7958 52.4465 25.1408C52.5692 25.4858 52.6582 25.8542 52.7114 26.2452C52.7656 26.6361 52.7922 27.0617 52.7922 27.522C52.7922 28.611 52.5999 29.6041 52.2153 30.5013C51.8307 31.3985 51.262 32.1803 50.5092 32.8478C49.7563 33.5153 48.7836 34.0369 47.5919 34.4125C46.4003 34.7881 45.0368 34.9759 43.4994 34.9759C39.7792 34.9759 36.6134 33.8327 34 31.5475L34.002 31.5495Z'
        fill='white'
      />
      <path
        d='M111.932 33.0827C110.702 31.8055 110.087 29.9342 110.087 27.4688V17.8642H107.482V11.9803H110.087V4.76612L117.465 4V11.9813H123.069V17.8652H117.465V26.1166C117.465 26.9287 117.592 27.5598 117.846 28.0109C118.099 28.4619 118.557 28.687 119.217 28.687C119.986 28.687 120.747 28.4989 121.501 28.1239L123.322 33.4447C122.384 33.9558 121.443 34.3429 120.498 34.6059C119.553 34.869 118.342 35 116.867 35C114.807 35 113.162 34.3609 111.933 33.0837L111.932 33.0827Z'
        fill='white'
      />
      <path
        d='M79.3327 23.0199C79.3327 16.406 73.9401 11.0251 67.3119 11.0251C60.6837 11.0251 55.2911 16.406 55.2911 23.0199V46H62.6926L62.4604 33.9909C63.9456 34.6472 65.5863 35.0147 67.3119 35.0147C73.9401 35.0147 79.3327 29.6337 79.3327 23.0199ZM72.7863 23.0199C72.7863 26.0318 70.3304 28.4824 67.3119 28.4824C64.2934 28.4824 61.8375 26.0318 61.8375 23.0199C61.8375 20.0079 64.2934 17.5573 67.3119 17.5573C70.3304 17.5573 72.7863 20.0079 72.7863 23.0199Z'
        fill='white'
      />
      <path
        d='M93.256 35.0147C86.6277 35.0147 81.2352 29.6337 81.2352 23.0199C81.2352 16.406 86.6277 11.0251 93.256 11.0251C99.8842 11.0251 105.277 16.406 105.277 23.0199C105.277 29.6337 99.8842 35.0147 93.256 35.0147ZM93.256 17.5573C90.2375 17.5573 87.7816 20.0079 87.7816 23.0199C87.7816 26.0318 90.2375 28.4824 93.256 28.4824C96.2745 28.4824 98.7304 26.0318 98.7304 23.0199C98.7304 20.0079 96.2745 17.5573 93.256 17.5573Z'
        fill='white'
      />
      <path
        d='M93.256 28.4836C90.2375 28.4836 87.7816 26.033 87.7816 23.021C87.7816 20.0091 90.2375 17.5585 93.256 17.5585C96.2745 17.5585 98.7304 20.0091 98.7304 23.021C98.7304 26.033 96.2745 28.4836 93.256 28.4836Z'
        fill='#0AEB88'
      />
      <defs>
        <clipPath id='clip0_5221_50963'>
          <rect width='24' height='35' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
