import { OutlinedButton } from 'components/atoms/button';
import { useState } from 'react';
import { playAll } from 'services/play-all/play-all';
import NoCreditsModal from 'components/organisms/play-all/modals/no-credits';
import SlotsModal from 'components/organisms/play-all/modals/slots';
import PlayModal from 'components/organisms/play-all/modals/play';
import WonModal from 'components/organisms/play-all/modals/won';
import LostModal from 'components/organisms/play-all/modals/lost';
import { useBalanceAndCredits } from 'hooks/use-balance-and-credits/use-balance-and-credits';

export const PlayAllButton = () => {
  const { balanceAndCredits } = useBalanceAndCredits();
  const [playing, setPlaying] = useState(false);
  const [hasWon, setHasWon] = useState(false);
  const [noCredits, setNoCredits] = useState(false);
  const [slots, setSlots] = useState(false);
  const [amount, setAmount] = useState(null);

  const onPlayAllClick = () => {
    if (balanceAndCredits.credits === 0) {
      setNoCredits(true);
      return;
    }

    setPlaying(true);
  };

  const confirmPlayAll = () => {
    setSlots(true);

    playAll()
      .then((winnings) => {
        setSlots(false);
        setHasWon(winnings > 0);
        setAmount(winnings);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <OutlinedButton text={'Play All'} onClick={onPlayAllClick} />

      {noCredits && (
        <NoCreditsModal show={noCredits} onClose={() => setNoCredits(false)} />
      )}

      {playing && (
        <PlayModal
          show={playing}
          onClose={() => setPlaying(false)}
          onPlayAll={confirmPlayAll}
        />
      )}

      {slots && <SlotsModal show={slots} />}

      {amount && hasWon && (
        <WonModal
          onClose={() => setAmount(null)}
          amount={amount}
          show={amount}
        />
      )}

      {amount && !hasWon && (
        <LostModal
          onClose={() => setAmount(null)}
          amount={amount}
          show={amount && !hasWon}
        />
      )}
    </>
  );
};

export default PlayAllButton;
