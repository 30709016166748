import { FadeInContainer } from 'pages/make-deposit/styled';
import { Routes } from 'common/routes';
import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from 'contexts/authentication';
import { useDepositStatus } from 'hooks/use-deposit-status/use-deposit-status';
import { AppLoader } from 'components/molecules/app-loader/app-loader';

//   0 -> DepositStatus.NOT_FOUND
//   1 -> DepositStatus.PENDING
//   2 -> DepositStatus.CONFIRMED
//   3 -> DepositStatus.REJECTED
//   4 -> DepositStatus.RETURN
//   5 -> DepositStatus.UNVERIFIED
//   11 -> DepositStatus.PENDING_ACTION
// else -> DepositStatus.UNKNOWN

export default function MakeDepositSuccess() {
  const { auth } = useAuth();
  const [searchParams, setURLSearchParams] = useSearchParams();
  const firstName = searchParams.get('first_name');
  const lastName = searchParams.get('last_name');
  const payment_method = searchParams.get('payment_method');
  const nameOnCard = searchParams.get('nameOnCard');
  const itemAmount = searchParams.get('item_amount_1');
  const topURL = new URL(window.top.location.href);
  const inGame = topURL.pathname.includes(Routes.Game.path);

  const clientUniqueID = searchParams.get('ClientUniqueID');
  const status = searchParams.get('Status');
  const message = searchParams.get('message');
  const urlParams = new URLSearchParams(window.location.href);
  const paramsAsObject = Object.fromEntries(urlParams);

  const { data: depositStatus } = useDepositStatus(clientUniqueID);

  const nameMatches =
    payment_method === 'cc_card'
      ? nameOnCard?.toLowerCase() ===
        firstName?.toLowerCase() + ' ' + lastName?.toLowerCase()
      : true;

  const handleSuccessRedirect = useCallback(() => {
    setTimeout(() => {
      window.parent.postMessage(
        {
          event_id: auth.token,
          data: {
            inGame: inGame,
            deposit: itemAmount.toString(),
            payment_method: payment_method,
            clientUniqueID: clientUniqueID,
            paramsAsObject: paramsAsObject,
          },
        },
        '*',
      );
    }, 1500);
  }, [
    auth.token,
    clientUniqueID,
    inGame,
    itemAmount,
    paramsAsObject,
    payment_method,
  ]);

  const sendAnalytics = useCallback(() => {
    searchParams.set('af_url', 'true');
    setURLSearchParams(searchParams);
    handleSuccessRedirect();
  }, [handleSuccessRedirect, searchParams, setURLSearchParams]);

  const handleErrors = useCallback(
    (status) => {
      window.parent.postMessage(
        {
          event_id: auth.token,
          data: {
            inGame: inGame,
            status: status,
            nameOnCard: status === 5 && !nameMatches,
          },
        },
        '*',
      );
    },
    [auth.token, inGame, nameMatches],
  );

  useEffect(() => {
    if (window.self === window.top) return;

    if (payment_method === 'cc_card' && depositStatus?.DepositStatus) {
      if (depositStatus?.DepositStatus === 2) {
        sendAnalytics();
        return;
      }
      handleErrors(depositStatus?.DepositStatus);
    }

    if (payment_method !== 'cc_card') {
      if (status === 'APPROVED' && message === 'Success') {
        sendAnalytics();
        return;
      }
      handleErrors(-1);
    }
  }, [
    depositStatus?.DepositStatus,
    handleErrors,
    message,
    payment_method,
    sendAnalytics,
    status,
  ]);

  return (
    <FadeInContainer className='d-flex col-12 flex-column mx-auto pb-3'>
      <div
        className=''
        style={{
          display: 'flex',
          padding: '150px 0',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AppLoader fullHeight={false} />
      </div>
    </FadeInContainer>
  );
}
