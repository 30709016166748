import React from 'react';

export const useStateRef = <T,>(
  initialState: T,
): [
  T,
  (newState: T | ((prevState: T) => T)) => void,
  React.MutableRefObject<T>,
] => {
  const [state, setState] = React.useState(initialState);
  const stateRef = React.useRef(state);

  return [
    state,
    React.useCallback(
      (newState) => {
        let nextState = newState;
        if (typeof newState === 'function') {
          nextState = (newState as (prevState: T) => T)(stateRef.current);
        }

        stateRef.current = nextState as T;
        setState(nextState);
      },
      [stateRef, setState],
    ),
    stateRef,
  ];
};
