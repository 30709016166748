import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { Auth, getUserStatus, UserStatus } from 'services/auth/login';
import { AuthContextProvider, useAuth } from 'contexts/authentication';
import { useSearchParams } from 'react-router-dom';
export type SdkContextProps = {
  embedded?: boolean;
  getEmbedded?: () => void;
};

const SdkContext = React.createContext<SdkContextProps>({});

export const useSdk = () => {
  return useContext(SdkContext);
};

const resolveToken = (data) => {
  console.log(data);

  const jwtDataPart = data.split('.')[1];
  const jwtData: Auth = JSON.parse(atob(jwtDataPart));
  const auth = {
    token: data,
    ...jwtData,
    kyc: data?.kyc,
    status: data?.status,
  };
  return auth;
};

export const SdkContextProvider = ({ children }) => {
  const { updateAuthAndToken, signOut } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [embedded, setEmbedded] = useState<boolean>(false);

  useLayoutEffect(() => {
    const isEmbedded = searchParams.get('embedded');
    const jwt = searchParams.get('jwt');
    const lat = searchParams.get('lat');
    const lon = searchParams.get('lon');
    const isLogout = searchParams.get('isLogout');
    const isIOS = searchParams.get('isIOS');

    if (lat && lon) {
      localStorage.setItem('geo', lat + '@' + lon);
    }

    if (isEmbedded) {
      localStorage.setItem('embedded', 'true');
      setEmbedded(JSON.parse(isEmbedded));
    }
    if (isIOS) {
      localStorage.setItem('isIOS', 'true');
    }

    if (isEmbedded && jwt) {
      const a = resolveToken(jwt);
      sessionStorage.removeItem('auth');
      updateAuthAndToken(a);
    }
    if (isLogout) {
      sessionStorage.removeItem('auth');
      signOut();
    }

    if (searchParams.has('embedded')) {
      searchParams.delete('embedded');
    }
    if (searchParams.has('jwt')) {
      searchParams.delete('jwt');
    }
    if (searchParams.has('lat')) {
      searchParams.delete('lat');
    }
    if (searchParams.has('lon')) {
      searchParams.delete('lon');
    }
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  function update(newItem: boolean) {
    // setStatus(newItem);
  }
  function getEmbedded() {
    if (localStorage.getItem('embedded')) {
      return JSON.parse(localStorage.getItem('embedded'));
    }
  }
  return (
    <SdkContext.Provider value={{ embedded, getEmbedded }}>
      {children}
    </SdkContext.Provider>
  );
};
