import { Api } from 'common/api';
import { Auth } from 'services/auth/login';

type OptionsMap = Record<string, string>;

const SelectMap: OptionsMap = {
  passport: 'Passport',
  identity_card: 'State ID Card',
  drivers_license: "Driver's License",
};

export const getRequiredDocuments = (): Promise<any> => {
  return Api.get<any>(
    `${API_HOST}/api/strive-proxy/restapi/v3/user/profile/required_documents`,
  ).then((res) => {
    if (!res.ok) {
      throw new Error('Could not retrieve required_documents');
    }
    const options = res.data.fields.find(
      (field) => field.type === 'select',
    ).values;
    options.map((it) => {
      it.label = SelectMap[it.title];
    });
    return [
      options.reverse(),
      res.data.fields.find((field) => field.id === 'image_residence'), // FRONT
      res.data.fields.find((field) => field.id === 'image_payment_proof'), // BACK
    ];
  });
};

export async function uploadRequiredDocuments(
  payload: FormData,
  auth: Auth,
): Promise<any> {
  let headers = new Headers();
  headers.set('Authorization', 'Bearer ' + auth.token);
  const url = `${API_HOST}/api/v1/upload-documents`;
  const request = {
    method: 'POST',
    body: payload,
    headers: headers,
  };
  const response = await fetch(url, request);
  const data = await response.json();
  if (!data.Success) {
    throw new Error(data.Message);
  }
  return getRequiredDocuments().then((required_docs) => {
    return [data.Message, required_docs];
  });
}
