import { ExpandableCard } from 'components/molecules/card-expandable/expandable-card';
import { useForm } from 'react-hook-form';
import { Select } from 'components/atoms/select';
import { Input } from 'components/atoms/input';
import { SelfSuspensionSettings } from 'hooks/use-play-limits/use-play-limits';
import styled from '@emotion/styled';
import { Button } from 'components/atoms/button';
import { useSuspendPlayer } from 'hooks/use-suspend-player/use-suspend-player';
import { formatDistance, addHours } from 'date-fns';
import { BspotAlert } from 'components/molecules/alerts/alert';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/authentication';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type SelfSuspensionProps = SelfSuspensionSettings;

export const SelfSuspension: React.FC<SelfSuspensionProps> = ({ fields }) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm();
  const { error, isError, isLoading, mutate } = useSuspendPlayer();

  const [selectFields, passwordFields] = fields;

  const suspendPlayer = (formData) => {
    mutate(formData, {
      onSuccess: () => {
        ENABLE_NEXT_AUTH === 'true' ? navigate('/en/logout') : signOut();
      },
    });
  };

  const getOption = (value: { id: string; title: string }) => {
    const now = new Date();
    const secondDate = addHours(now, Number(value.id));

    return {
      value: value.id,
      label: formatDistance(secondDate, now, {
        includeSeconds: true,
      }).replace('about ', ''),
    };
  };

  return (
    <ExpandableCard
      description='You can temporarily suspend your account by setting a timeout. Once you set a timeout on your account you will be automatically logged out and this cannot be undone. You must wait for the set timeout to expire before you are allowed to wager or play real money games again.'
      emptyMessage='No timeout set'
      title='Take a break'
      openContent={
        <Form onSubmit={handleSubmit(suspendPlayer)}>
          {isError && (
            <BspotAlert type={'error'} message={(error as Error).message} />
          )}
          <div>
            <Select
              control={control}
              name={selectFields.id}
              label='Select timeout period'
              disabled={selectFields.disabled}
              options={selectFields.values.map(getOption)}
              rules={{
                required: 'This field is mandatory',
              }}
              errors={errors}
            />
          </div>
          <Input
            required
            label='Password'
            type='password'
            {...register(passwordFields.id)}
          />
          <div>
            <Button text='Set timeout' isLoading={isLoading} />
          </div>
        </Form>
      }
    />
  );
};
