import { SvgProps } from './svg';
import styled from '@emotion/styled';
import { Cross } from './cross';

const StyledCross = styled(Cross)`
  cursor: pointer;
`;

export const Close = (props: SvgProps) => {
  return <StyledCross {...props} />;
};
