import { useAboutUs } from 'hooks/use-content';
import { CmsContent } from 'components/organisms/cms/strive-content-page/cms-content';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'common/utils';

export default function About() {
  const { data: about } = useAboutUs();

  const pageTitle = 'About Us - ' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <CmsContent content={about?.content} title={about?.title} />
    </>
  );
}
