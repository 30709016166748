import { useFormContext } from 'react-hook-form';
import { Text } from 'components/atoms/text';
import { Link } from 'react-router-dom';
import { Routes } from 'common/routes';
import { FormInput } from 'components/atoms/input/input';
import { CheckboxInput } from 'components/atoms/checkbox/checkbox-input';
import { useCallback, useEffect, useMemo } from 'react';
import { useStates } from 'hooks/use-states/use-states';

export function SocialSecurityForm() {
  const {
    formState: { errors, defaultValues, isDirty },
    getValues,
    control,
  } = useFormContext();

  const { data: states } = useStates();

  const newYorkId = useMemo(
    () => states?.filter((state) => state.label === 'New York')[0].id,
    [states],
  );

  const validateLength = useCallback(
    (value) => {
      const province_id = getValues().province_id;
      if (value || defaultValues.province_id != province_id) {
        const length = province_id == newYorkId ? 9 : 4;
        const isCorrect =
          value.toString().length === length && /^\d+$/.test(value);
        return isCorrect || `Must contain ${length} digits`;
      }
    },
    [isDirty],
  );

  return (
    <>
      <Text>
        Please enter the last 4 digits of your Social Security Number. If your
        State is New York, please enter your full SSN
      </Text>
      <FormInput
        label='Social security number'
        name='social_security_number'
        data-cy='social_security_number'
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        validation={{
          required: 'This field is required',
          validate: {
            validateLength: (value) => validateLength(value),
          },
        }}
      />

      <div className={'pt-3 flex-column d-flex gap-3'}>
        <CheckboxInput
          control={control}
          rules={{ required: 'This field is required' }}
          name={'agreement'}
          error={errors?.agreement?.message.toString()}
          label={
            <>
              I am 21 or older, I have read and agree to the{' '}
              <Link
                target='_blank'
                to={Routes.TermsOfUse.path}
                style={{ color: 'var(--primary-color)' }}
              >
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link
                target='_blank'
                to={Routes.PrivacyPolicy.path}
                style={{ color: 'var(--primary-color)' }}
              >
                Privacy Policy
              </Link>{' '}
              and I understand that all my payouts are based on the results of
              live horse races.
            </>
          }
        />
      </div>
    </>
  );
}
