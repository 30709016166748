import React, { useState, ReactNode } from 'react';
import { Disabled } from 'common/styles';
import { Check, Cross, Warning, EyeClosed, EyeOpen } from 'icons/svg';
import styled from '@emotion/styled';
import { TextField, TextFieldProps } from '@mui/material';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

const Label = styled.label`
  order: 1;
  margin-bottom: var(--spacing-1);
`;

interface InputField {
  hasStartIcon?: any;
  passwordIsVisible?: any;
  hasEndIcon?: any;
  as?: string;
  ref?: any;
}

const InputField = styled.input<InputField>`
  &:focus {
    outline: none;
  }
  &:focus + label {
    font-weight: 600;
  }
  font-family: var(--font-family), sans-serif;
  border-radius: var(--spacing-2);
  padding: var(--spacing-3);
  order: 2;
  font-size: 1rem;
  margin-bottom: var(--spacing-1);
  ${(props) => props.as === 'textarea' && `width: 100% !important;`}
  background-color: #222222;
  border: 1px solid rgb(74, 74, 74);
  color: rgba(256, 256, 256, 1);
  &:disabled {
    ${Disabled()}
  }

  ${(props) =>
    props.hasStartIcon &&
    `
  padding-left: 2.5em;
  `};

  ${(props) =>
    props.hasEndIcon &&
    `
  padding-right: 2.5em;
  `};

  ${(props) =>
    (props.type === 'password' || props.passwordIsVisible) &&
    props.hasEndIcon &&
    `
  padding-right: 4em;

  `};
`;

export const InputFeedback = styled.div`
  font-size: 0.8em;
  order: 3;
`;
interface InputWrapper {
  hasError?: boolean;
  hasWarning?: boolean;
  hasSuccess?: boolean;
}
export const InputWrapper = styled.div<InputWrapper>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;

  ${(props) =>
    props.hasError &&
    `
  ${InputFeedback}, 
  ${Label} {
    color: var(--error-color);
  }
  ${InputField} {
    border-color: var(--error-color);
  }
`}

  ${(props) =>
    props.hasWarning &&
    `  
  ${InputFeedback} { 
    color: var(--warning-darker-color);
  }
`}
`;

const PasswordToggle = styled.div`
  cursor: pointer;
  padding: 10px 2px;
  &:hover {
    opacity: 0.5;
  }
`;

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: boolean | string | ReactNode;
  success?: boolean | string;
  warning?: boolean | string;
  name?: string;
  id?: string;
  startIcon?: ReactNode;
  onChange?: (e: React.SyntheticEvent) => void;
  onKeyDown?: (e: React.SyntheticEvent) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  className?: string;
  feedback?: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  as?: string;
  InputProps?: any;
};

export function Textarea(props: InputProps) {
  return <TextareaOrInput {...props} as='textarea' />;
}

export const Input = React.forwardRef((props: InputProps, ref) => {
  // @ts-ignore
  return <TextFieldComponent {...props} inputRef={ref} />;
});

const TextFieldComponent = ({
  inputRef,
  onChange,
  onBlur,
  value,
  type,
  label,
  error,
  success,
  warning,
  ...rest
}) => {
  const [passwordIsVisible, setPasswordIsVisbile] = useState(false);

  return (
    <TextField
      {...rest}
      fullWidth
      inputRef={inputRef}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      type={type === 'password' && passwordIsVisible ? 'text' : type}
      label={label}
      helperText={error?.message ? error.message : error}
      error={!!error}
      InputProps={{
        ...rest.InputProps,
        label: label,
        endAdornment: (
          <>
            {type === 'password' && (
              <PasswordToggle
                className='me-1'
                data-testid='toggle-password'
                onClick={() => setPasswordIsVisbile(!passwordIsVisible)}
              >
                {passwordIsVisible ? (
                  <EyeClosed
                    width={'1.2em'}
                    height={'1.2em'}
                    fill={'rgba(256, 256, 256, 0.5)'}
                  />
                ) : (
                  <EyeOpen
                    width={'1.2em'}
                    height={'1.2em'}
                    fill={'rgba(256, 256, 256, 0.5)'}
                  />
                )}
              </PasswordToggle>
            )}

            <div style={{ padding: '10px 2px' }}>
              {success && <Check width={'1em'} height={'1em'} />}
              {error && (
                <Cross
                  stroke={'var(--error-color)'}
                  width={'1em'}
                  height={'1em'}
                />
              )}
              {warning && (
                <Warning
                  fill={'var(--error-color)'}
                  width={'1em'}
                  height={'1em'}
                />
              )}
            </div>
          </>
        ),
      }}
    />
  );
};

export const TextareaOrInput = React.forwardRef(
  (
    {
      label,
      error,
      success,
      warning,
      startIcon,
      name,
      value,
      className = '',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange = () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onKeyDown = () => {},
      onBlur,
      placeholder,
      feedback,
      id,
      type = 'text',
      disabled = false,
      as = 'input',
      ...rest
    }: InputProps,
    ref,
  ) => {
    return (
      <TextFieldComponent
        {...rest}
        inputRef={ref}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        type={type}
        label={label}
        error={error}
        warning={warning}
        success={success}
      />
    );
  },
);

export type FormInputProps = {
  validation?: ControllerProps['rules'];
  name: string;
  label?: string;
  type?: string;
  success?: string;
  warning?: string;
} & TextFieldProps;

export const FormInput = ({
  name,
  validation,
  label,
  type,
  success,
  warning,
  ...rest
}: FormInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <TextFieldComponent
          {...rest}
          inputRef={ref}
          onChange={(ev) => {
            onChange(
              type === 'number' && ev.target.value
                ? +ev.target.value
                : ev.target.value,
            );
            if (typeof rest.onChange === 'function') {
              rest.onChange(ev);
            }
          }}
          onBlur={onBlur}
          value={value ?? ''}
          type={type}
          label={label}
          error={error}
          warning={warning}
          success={success}
        />
      )}
    />
  );
};
