import './index.scss';
import { useState } from 'react';
import { CheckCircle, CrossCircle, Info } from 'icons/svg';
import { Collapse } from '@mui/material';
import { Button, ButtonColor } from '../../atoms/button';
import {
  optinTournament,
  TournamentType,
} from 'services/tournaments/tournaments';
import { useSnackbar } from 'contexts/snackbar';
import { sendGTMEvent } from 'services/_gtm-analytics/gtm-analytics';
import { useLeaderBoard, useNewLeaderBoard } from 'hooks/use-leaderboard';
import { useAuth } from 'contexts/authentication';
import { sendAppsFlyerEvent } from 'services/_af-events/appsflyer';
import {
  Cta,
  Description,
  Timer,
  FlipBox,
  GameSelect,
  Leaderboard,
} from './tournament-components';
import { ToastStatus } from '../toasts/toast';

type TournamentProps = {
  tournament: TournamentType;
  playerId: string;
  refetchTournaments: () => void;
};

export const TournamentCard = ({
  tournament,
  playerId,
  refetchTournaments,
}: TournamentProps) => {
  const { auth } = useAuth();
  const { data: items, refetch: refetchLeaderboards } = useNewLeaderBoard(
    tournament?.id,
    tournament?.rewards,
    auth?.session?.playerId.toString(),
    tournament?.isOptedIn,
    auth?.member?.name,
  );

  const [showInfo, setShowInfo] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(true);
  const [loading, setLoading] = useState(false);
  const { push } = useSnackbar();

  const pushFailToast = () => {
    const id = 'bsp-tournament-optin-error';
    push({
      title: 'We weren’t able to enter you in the tournament.',
      message:
        'Please try again in a few minutes or chat with our Player Experience Team and we’ll lend a hand.',
      id,
      status: ToastStatus.error,
      icon: <CrossCircle />,
      style: { backgroundColor: 'rgba(255, 183, 20, 0.85)' },
      preventDuplicate: true,
    });
  };

  const pushSuccessToast = () => {
    const id = 'bsp-tournament-optin-success';
    push({
      title: 'You are in!',
      message: 'The leaderboard awaits. Happy Spinning!',
      id,
      status: ToastStatus.success,
      icon: <CheckCircle />,
      style: { backgroundColor: 'rgba(255, 255, 255, 0.85)' },
      preventDuplicate: true,
    });
  };

  const trackOptIn = () => {
    sendGTMEvent({
      event: 'join_group',
      tournamentName: tournament?.title,
      tournamentStartTimestamp: tournament?.startTime,
      tournamentPrize: tournament?.subtitle,
      user_id: playerId,
    });

    sendAppsFlyerEvent('opt_in_tournament', {
      tournamentName: tournament?.title,
      tournamentStartTimestamp: tournament?.startTime,
      tournamentPrize: tournament?.subtitle,
      user_id: playerId.toString(),
    });
  };

  const clickOptIn = async () => {
    setLoading(true);

    const tournamentPayload = {
      contestId: tournament?.parentId,
      title: tournament?.title,
      startTime: tournament?.startTime,
      endTime: tournament?.endTime,
    };

    optinTournament(auth.session, tournamentPayload)
      .then((res) => {
        // @ts-ignore
        if (!res || res.data?.errors?.length) {
          pushFailToast();
          console.error('failed to opt in member');
          return;
        }

        trackOptIn();
        pushSuccessToast();
      })
      .catch((e) => {
        pushFailToast();
        return console.error(e);
      })
      .finally(() => {
        setLoading(false);
        refetchTournaments();
        refetchLeaderboards();
      });
  };

  return (
    <div className='tournament-container'>
      <img
        className='logo-container'
        alt='tournament game'
        width={100}
        height={100}
        src={
          tournament.products.length > 1
            ? tournament.logoImage
            : tournament.products[0]?.meta?.image || tournament.logoImage
        }
      />
      <div
        className='card-top'
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), url(${
            tournament.products.length > 1
              ? tournament.backgroundImage
              : tournament.products[0]?.meta?.background ||
                tournament.backgroundImage
          })`,
        }}
      >
        <div className='text-container'>
          <span
            className='subtitle-text'
            data-testid={`${tournament?.id}-subtitle`}
          >
            Top {tournament?.subtitle}
          </span>
          <span className='title-text' data-testid={`${tournament?.id}-title`}>
            {tournament?.title}
          </span>
        </div>
        <Timer
          isActive={tournament?.status === 'Active'}
          startTime={new Date(tournament?.startTime)}
          endTime={new Date(tournament?.endTime)}
          contestId={tournament?.id}
          id={tournament?.id}
          fetchTournament={refetchTournaments}
          removeTournament={refetchTournaments}
        />

        {tournament?.isOptedIn &&
        tournament?.status === 'Active' &&
        tournament?.products.length > 1 ? (
          <Button
            color={showLeaderboard ? ButtonColor.Primary : ButtonColor.White}
            text={showLeaderboard ? 'Show Games' : 'Show Leaderboard'}
            onClick={() => setShowLeaderboard(!showLeaderboard)}
          />
        ) : (
          <Cta
            isOptedIn={tournament?.isOptedIn}
            meta={
              tournament?.products.length > 1
                ? { ...tournament?.meta, ...tournament?.products?.[0].meta }
                : tournament?.meta
            }
            ctaLoading={loading}
            playerId={playerId}
            isActive={tournament?.status === 'Active'}
            onClickOptIn={clickOptIn}
          />
        )}
        <button
          className='info-button'
          aria-label='more information'
          data-testid={`${tournament?.id}-info-toggle`}
          data-toggle='collapse'
          onClick={() => setShowInfo(!showInfo)}
        >
          {showInfo ? (
            <CrossCircle size='24px' color='white' />
          ) : (
            <Info size='24px' color='white' />
          )}
        </button>
      </div>

      <Collapse in={showInfo}>
        <Description
          description={tournament?.description}
          id={tournament?.id}
          tnc={tournament?.tnc}
          handleClose={() => setShowInfo(!showInfo)}
        />
      </Collapse>
      <FlipBox
        flip={!showLeaderboard}
        front={
          <Leaderboard contestId={tournament?.id} leaderboardItems={items} />
        }
        back={<GameSelect products={tournament?.products} />}
      />
    </div>
  );
};
