import React from 'react';
import PlayerItem from './item/player-item';
import SkeletonLoader from './skeleton-loader';
import { LeaderboardEntry } from '../../../../../services/tournaments/tournaments';
import '../flip-box/index.scss';
type LeaderboardProps = {
  contestId: string;
  leaderboardItems: LeaderboardEntry[];
};
export const Leaderboard = ({
  leaderboardItems,
  contestId,
}: LeaderboardProps) => {
  return (
    <div className='stack' data-testid={`${contestId}-leaderboard`}>
      {!leaderboardItems
        ? [1, 2, 3, 4, 5, 6].map((item) => (
            <SkeletonLoader
              key={item}
              bgColor={item % 2 === 0 ? '#141e21' : '#1e262d'}
            />
          ))
        : leaderboardItems &&
          leaderboardItems.map((item, index) => (
            <PlayerItem key={index} {...item} />
          ))}
    </div>
  );
};
