import React, { Suspense, useEffect, useState } from 'react';
import { AppWrapper } from './AppWrapper';
import { Routes as AppRoutes } from 'common/routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFound } from 'pages/not-found';
import { DefaultLayout } from 'components/organisms/layout/layout-default/default-layout';

import {
  FeatureFlags,
  FeatureFlagsService,
} from 'services/feature-flags/feature-flags';
import { AppLoader } from 'components/molecules/app-loader/app-loader';
import { injectGTM } from 'common/gtm/gtm';
import { sendGTMEvent } from './services/_gtm-analytics/gtm-analytics';
import { useAuth } from 'contexts/authentication';

import TermsOfUse from 'pages/terms-of-use/terms-of-use';
import ResponsibleGaming from 'pages/responsible-gaming/responsible-gaming';
import PrivacyPolicy from 'pages/privacy-policy/privacy-policy';
import WagerHistory from 'pages/account-history/wager_history';
import WagersPage from 'pages/wagers/wagers';
import About from 'pages/about/about';
import ResponsibleGamingSettings from 'pages/responsible-gaming-settings/responsible-gaming-settings';
import PlayerInfo from 'pages/player-info/player-info';
import DocumentCenter from 'pages/document-center/document-center';
const LazyLogin = React.lazy(() => import('pages/login/login'));

import Register from 'pages/create-account/create-account';
const LazyForgotPassword = React.lazy(
  () => import('pages/password-forgot/password-forgot'),
);
const LazyResetPassword = React.lazy(
  () => import('pages/password-reset/password-reset'),
);

const LazyChangePassword = React.lazy(
  () => import('pages/password-change/password-change'),
);
import MakeDepositPage from 'pages/make-deposit/make-deposit';

import MakeDepositSuccess from 'pages/make-deposit-success/make-deposit-success';
import MakeDepositError from 'pages/make-deposit-error/make-deposit-error';
import MakeDepositPending from 'pages/make-deposit-pending/make-deposit-pending';
import WithdrawPage from 'pages/withdraw/withdrawPage';

import FooterPage from 'pages/footer/Footer';
import { NoLayout } from 'components/organisms/layout/layout-default/no-layout';
import Tournaments from 'pages/tournaments/tournaments';
import { TournamentsLobbyPage } from 'pages/tournaments-lobby';
import { WagersQuick } from 'pages/wagers-quick/WagersQuick';

export const App = () => {
  const { auth } = useAuth();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await FeatureFlagsService.getFeatureFlags();
        setFeatureFlags(data);
        injectGTM();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  // Track device orientation.
  useEffect(() => {
    const updateOrientationInDataLayer = () => {
      const orientation = window.matchMedia('(orientation: landscape)').matches
        ? 'landscape'
        : 'portrait';
      sendGTMEvent({
        event: 'device-orientation-change',
        orientation,
        user_id: auth?.session?.playerId,
      });
    };
    updateOrientationInDataLayer();
    const mediaQueryList = window.matchMedia('(orientation: landscape)');
    const orientationChangeHandler = () => updateOrientationInDataLayer();
    mediaQueryList.addEventListener('change', orientationChangeHandler);
    return () => {
      mediaQueryList.removeEventListener('change', orientationChangeHandler);
    };
  }, [auth?.session?.playerId]);

  const showDevBanner =
    FeatureFlagsService?.featureFlags?.environment !== 'prod' &&
    location.pathname !== AppRoutes.Game.path;

  return (
    <>
      {featureFlags ? (
        <AppWrapper>
          <Suspense fallback={<AppLoader />}>
            <Routes>
              <Route element={<DefaultLayout />}>
                <Route path={AppRoutes.Login.path} element={<LazyLogin />} />
                <Route
                  path={AppRoutes.CreateAccount.path}
                  element={<Register />}
                />
                <Route
                  path={AppRoutes.ForgotPassword.path}
                  element={<LazyForgotPassword />}
                />
                <Route
                  path={AppRoutes.ResetPassword.path}
                  element={<LazyResetPassword />}
                />
                <Route
                  path={AppRoutes.ChangePassword.path}
                  element={<LazyChangePassword />}
                />

                <Route
                  path={AppRoutes.MakeDepositPage.path}
                  element={<MakeDepositPage isModal={false} />}
                />
                <Route
                  path={AppRoutes.MakeDepositSuccessPage.path}
                  element={<MakeDepositSuccess />}
                />
                <Route
                  path={AppRoutes.MakeDepositErrorPage.path}
                  element={<MakeDepositError />}
                />
                <Route
                  path={AppRoutes.WithdrawPage.path}
                  element={<WithdrawPage />}
                />

                <Route
                  path={AppRoutes.WagersPage.path}
                  element={<WagersPage isModal={false} />}
                />

                <Route
                  path={AppRoutes.WagerHistory.path}
                  element={<WagerHistory />}
                />
                <Route
                  path={AppRoutes.ResponsibleGamingSettings.path}
                  element={<ResponsibleGamingSettings />}
                />

                <Route
                  path={AppRoutes.MakeDepositSuccessIframePage.path}
                  element={<MakeDepositSuccess />}
                />
                <Route
                  path={AppRoutes.MakeDepositErrorIframePage.path}
                  element={<MakeDepositError />}
                />

                <Route
                  path={AppRoutes.MakeDepositPendingIframePage.path}
                  element={<MakeDepositPending />}
                />
                <Route path={AppRoutes.About.path} element={<About />} />
                <Route
                  path={AppRoutes.TermsOfUse.path}
                  element={<TermsOfUse />}
                />
                <Route
                  path={AppRoutes.ResponsibleGaming.path}
                  element={<ResponsibleGaming />}
                />
                <Route
                  path={AppRoutes.PlayerInfo.path}
                  element={<PlayerInfo />}
                />
                <Route
                  path={AppRoutes.DocumentCenter.path}
                  element={<DocumentCenter />}
                />
                <Route
                  path={AppRoutes.PrivacyPolicy.path}
                  element={<PrivacyPolicy />}
                />
                <Route path={AppRoutes.About.path} element={<About />} />
              </Route>

              <Route element={<NoLayout />}>
                <Route path={AppRoutes.Footer.path} element={<FooterPage />} />
                <Route
                  path={AppRoutes.TournamentsLobby.path}
                  element={<TournamentsLobbyPage />}
                />
                <Route
                  path={AppRoutes.Tournaments.path}
                  element={<Tournaments />}
                />
                <Route
                  path={AppRoutes.QuickCredits.path}
                  element={<WagersQuick />}
                />
              </Route>
              <Route element={<DefaultLayout />}>
                <Route
                  path={AppRoutes.PageNotFound.path}
                  element={<NotFound />}
                />
                <Route
                  path={'*'}
                  element={
                    <Navigate replace to={AppRoutes.PageNotFound.path} />
                  }
                />
              </Route>
            </Routes>
          </Suspense>
        </AppWrapper>
      ) : (
        <div className='bg-black'>
          <AppLoader />
        </div>
      )}
    </>
  );
};
