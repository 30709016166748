import { ExpandableCard } from 'components/molecules/card-expandable/expandable-card';
import { Button } from 'components/atoms/button';
import {
  DepositLimit,
  DepositLimitSettings,
} from 'hooks/use-play-limits/use-play-limits';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Text, TextSize } from 'components/atoms/text';
import { useQueryClient } from '@tanstack/react-query';
import format from 'date-fns/format';
import Input from '@mui/material/TextField';
import { InputAdornment, TextField } from '@mui/material';
import { usePostDepositLimit } from 'hooks/use-set-deposit-limit/use-set-deposit-limit';
import { BspotAlert } from 'components/molecules/alerts/alert';
import { toCurrency } from 'common/bspot-shared';
import { NumericFormat } from 'react-number-format';

const DATE_FORMAT = 'MM-dd-yyyy HH:mm';

const LabeledColumnWrapper = styled.div`
  flex: 0 1 150px;
  display: flex;
  flex-direction: column;
`;

type LabeledColumnProps = {
  hint: string;
  value: number | string | null;
};
const LabeledColumn: React.FC<LabeledColumnProps> = ({ hint, value }) => {
  let displayValue = '';
  if (typeof value === 'number') displayValue = toCurrency(value);
  if (typeof value === 'string') {
    const dateValue = new Date(value);
    displayValue = format(dateValue, DATE_FORMAT);
  }

  return (
    <LabeledColumnWrapper>
      <Text size={TextSize.Hint} color={'#999999'}>
        {hint}
      </Text>
      <Text bold size={TextSize.H6}>
        {displayValue}
      </Text>
    </LabeledColumnWrapper>
  );
};

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;
const TextWithPadding = styled(Text)`
  margin-top: 0.5rem;
  display: block;
`;

type LimitsRowProps = DepositLimit;
const LimitsRow: React.FC<LimitsRowProps> = ({
  AmountLeft,
  LimitAmount,
  FutureLimitAmount,
  FutureLimitValidFrom,
  ValidTo,
  LimitType,
}) => {
  const formatValidUntilMessage = (validTo) => {
    return format(new Date(validTo), DATE_FORMAT);
  };

  return (
    <div>
      <Text bold size={TextSize.Body} color={'#999999'}>
        {LimitType}
      </Text>
      <FlexRow>
        <LabeledColumn hint='Current Limit' value={LimitAmount} />
        <LabeledColumn hint='Used' value={LimitAmount - AmountLeft} />
        <LabeledColumn hint='Remaining' value={AmountLeft} />
        {FutureLimitAmount && (
          <LabeledColumn hint='Requested Limit' value={FutureLimitAmount} />
        )}
        {FutureLimitValidFrom && (
          <LabeledColumn
            hint='Requested Limit changes on'
            value={FutureLimitValidFrom}
          />
        )}
      </FlexRow>
      {ValidTo && !FutureLimitValidFrom && (
        <TextWithPadding>
          Limit is valid until <b>{formatValidUntilMessage(ValidTo)}</b>
        </TextWithPadding>
      )}
    </div>
  );
};

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

type DepositLimitsProps = DepositLimitSettings;

export const DepositLimits: React.FC<DepositLimitsProps> = ({
  data: depositLimits,
  onChangeDepositLimits: onChangeDepositLimits,
}) => {
  const queryClient = useQueryClient();
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      limit_amount_day: '',
      limit_amount_week: '',
      limit_amount_month: '',
    },
  });
  const {
    mutate,
    isError,
    isSuccess,
    data: mutateData,
    error,
  } = usePostDepositLimit(queryClient);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const submit = (
    formData,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setLoading(true);
    mutate(formData, {
      onSuccess: () => {
        reset();
        onChangeDepositLimits();
      },
      onSettled: () => setLoading(false),
    });
  };

  const handleEditClick = (formData) => {
    submit(formData, setEditLoading);
  };

  const handleDeleteClick = () => {
    submit(
      {
        limit_action: 1,
        limit_amount_day: 0,
        limit_amount_week: 0,
        limit_amount_month: 0,
      },
      setDeleteLoading,
    );
  };

  const formatAmount = (am) => {
    return parseFloat(am.replace(/,/g, '')).toFixed(2);
  };

  const handleDayChange = (e) => {
    setValue('limit_amount_day', formatAmount(e.target.value), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const handleWeekChange = (e) => {
    setValue('limit_amount_week', formatAmount(e.target.value), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const handleMonthChange = (e) => {
    setValue('limit_amount_month', formatAmount(e.target.value), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <ExpandableCard
      title='Funding Limit'
      description='You can set your deposit limits here'
      defaultContent={
        depositLimits && (
          <div>
            <hr style={{ border: '1px solid rgba(119, 140, 166, 0.15)' }} />
            {depositLimits.map((depositLimit) => (
              <div key={depositLimit.LimitType}>
                <LimitsRow {...depositLimit} />
                <hr style={{ border: '1px solid rgba(119, 140, 166, 0.15)' }} />
              </div>
            ))}
          </div>
        )
      }
      emptyMessage='No limit has yet been defined.'
      openContent={
        <Form>
          {(isError || isSuccess) && (
            <BspotAlert
              type={isError ? 'error' : 'success'}
              message={
                isError ? (error as Error).message : (mutateData as any).Message
              }
            />
          )}
          <NumericFormat
            label='Day'
            thousandsGroupStyle={'thousand'}
            thousandSeparator={','}
            decimalSeparator={'.'}
            decimalScale={2}
            fixedDecimalScale
            customInput={TextField}
            onChange={handleDayChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
          />
          <NumericFormat
            label='Week'
            thousandsGroupStyle={'thousand'}
            thousandSeparator={','}
            decimalSeparator={'.'}
            decimalScale={2}
            fixedDecimalScale
            customInput={TextField}
            onChange={handleWeekChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
          />
          <NumericFormat
            label='Month'
            thousandsGroupStyle={'thousand'}
            thousandSeparator={','}
            decimalSeparator={'.'}
            decimalScale={2}
            fixedDecimalScale
            customInput={TextField}
            onChange={handleMonthChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
          />
          <div className='d-flex'>
            <Button
              text='Set Limits'
              disabled={!isDirty}
              isLoading={editLoading}
              onClick={handleSubmit(handleEditClick)}
            />
            <Button
              text='Delete Limits'
              isLoading={deleteLoading}
              onClick={handleSubmit(handleDeleteClick)}
            />
          </div>
        </Form>
      }
    />
  );
};
