import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'contexts/authentication';
import { getUserStatus } from 'services/auth/login';

export const usePlayerStatus = () => {
  const { auth } = useAuth();

  return useQuery(['player-status', auth?.session?.playerId], {
    queryFn: getUserStatus,
    enabled: !!auth?.session?.playerId,
    staleTime: 5000,
  });
};
