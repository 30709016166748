import React from 'react';
import { useAuth } from 'contexts/authentication';
import {
  injectLiveChat,
  injectSalesforceLiveChat,
} from 'common/live-chat/live-chat';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

type LiveChatAuth = {
  authUserId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const setbspotLiveChat = (bspotLiveChat, auth) => {
  window.Bspot.liveChat = {
    open: () => {
      bspotLiveChat.command('WebChat.open', {
        userData: { autoSubmit: false, ...auth },
      });
    },
    close: () => {
      bspotLiveChat.command('WebChat.endChat');
      bspotLiveChat.command('App.closeAll');
    },
  };
};

const mountLiveChat = async (auth: LiveChatAuth) => {
  // @ts-ignore
  if (window.bspotLiveChat) {
    setbspotLiveChat(window.bspotLiveChat, auth);
  } else {
    injectLiveChat().then(() => {
      setTimeout(() => {
        if (!window['CXBus']) return;
        // @ts-ignore
        window['CXBus'].configure({
          debug: false,
          pluginsPath: 'https://apps.mypurecloud.com/widgets/9.0/plugins/',
        });
        // @ts-ignore
        window['CXBus'].loadPlugin('widgets-core');
        // @ts-ignore
        const liveChat = window['CXBus'].registerPlugin('bspotLiveChat');
        // @ts-ignore
        window.bspotLiveChat = liveChat;
        setbspotLiveChat(liveChat, auth);
      }, 1000);
    });
  }
};

export const LiveChat = () => {
  const { auth } = useAuth();

  window['_genesys'] = {
    widgets: {
      userData: {},
      main: {
        preload: ['webchat'],
        theme: 'dark',
        downloadGoogleFont: false,
      },
      webchat: {
        emojis: true,
        uploadsEnabled: false,
        confirmFormCloseEnabled: true,
        charCountEnabled: true,
        actionsMenu: true,
        maxMessageLength: 140,
        autoInvite: {
          enabled: false,
        },
        ariaCharRemainingIntervals: [75, 25, 10],
        form: {
          autoSubmit: false,
          wrapper: '<table ></table>',
          inputs: [
            {
              id: '"cx_webchat_form_subject"',
              label: '@i18n:webchat.ChatFormSubject',
              maxlength: '200',
              name: 'subject',
              placeholder: '@i18n:webchat.ChatFormPlaceholderSubject',
              type: 'select',
              options: [
                {
                  text: 'Application',
                  value: 'Application',
                },
                {
                  text: 'Deposits',
                  value: 'Deposits',
                },
                {
                  text: 'Withdrawals',
                  value: 'Withdrawals',
                },
                {
                  text: 'Troubleshooting',
                  value: 'Troubleshooting',
                },
                {
                  text: 'Other',
                  value: 'Other',
                },
              ],
            },
          ],
        },
        transport: {
          type: 'purecloud-v2-sockets',
          dataURL: 'https://api.mypurecloud.com', // replace with API URL matching your region
          deploymentKey:
            FeatureFlagsService?.featureFlags?.livechatDeploymentKey,
          orgGuid: FeatureFlagsService?.featureFlags?.livechatOrgGuid,
          interactionData: {
            routing: {
              targetType: 'QUEUE',
              targetAddress: FeatureFlagsService?.featureFlags?.livechatQueue,
              priority: 2,
            },
          },
        },
      },
    },
  };

  React.useEffect(() => {
    if (
      (auth.session?.playerId &&
        FeatureFlagsService?.featureFlags?.salesforceTestPlayerIds?.includes(
          auth.session?.playerId.toString(),
        )) ||
      FeatureFlagsService?.featureFlags?.salesforceLiveAgent
    ) {
      injectSalesforceLiveChat().catch((err) => {
        console.error(err);
      });
      return;
    }

    if (
      auth.session?.playerId &&
      !FeatureFlagsService?.featureFlags?.salesforceLiveAgent
    ) {
      mountLiveChat({
        authUserId: auth?.session?.playerId,
        firstName: auth?.session?.firstName,
        lastName: auth?.session?.lastName,
        email: auth?.session?.email,
        phoneNumber: auth?.session?.telephone,
      }).catch((err) => {
        console.error(err);
      });
      return;
    }
  }, [auth?.session]);

  return null;
};
