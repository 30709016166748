import styled from '@emotion/styled';

interface AccountCardProps {
  isModal?: boolean;
}

export const AccountCard = styled.div<AccountCardProps>`
  padding: ${(props) => (props.isModal ? '0' : '25px')};
  background-color: ${(props) =>
    props.isModal ? 'transparent' : 'rgb(34, 34, 34)'};
  border-radius: 4px;
`;

export const PaymentsContainer = styled.div`
  display: grid;
  gap: 10px;
  @media (min-width: 1366px) {
    grid-template-columns: repeat(4, minmax(92px, 1fr));
  }

  @media only screen and (max-width: 414.98px) {
    grid-template-columns: repeat(2, minmax(110px, 1fr));
  }
`;

export const PaymentItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--text-light-color);
  border: 2px solid var(--text-light-color);

  &.selected {
    border: 2px solid var(--primary-color);
  }

  & img {
    width: 100%;
    height: 60px;
  }
  :hover {
    opacity: 1;
    border: 2px solid var(--primary-color);
  }

  //@media screen and (max-width: 768px) {
  //  .flex-item {
  //    width: 60px;
  //  }
  //  & img {
  //    width: 90px;
  //  }
  //}
`;

export const AmountContainer = styled.div`
  padding: 8px 5px;
  display: flex;
  justify-content: center;
  background-color: var(--text-light-color);
  border-radius: 4px;
  font-weight: bold;
  border: 2px solid var(--text-light-color);
  color: var(--bg-dark-color);
  :hover {
    cursor: pointer;
    border: 2px solid var(--bg-darkish-color);
    background-color: var(--bg-darkish-color);
    color: var(--text-light-color);
  }
  &.selected {
    border: 2px solid var(--bg-darkish-color);
    background-color: var(--bg-darkish-color);
    color: var(--text-light-color);
  }

  @media screen and (max-width: 975px) {
    font-size: 12px;
    padding: 6px 1px;
  }
`;

export const FadeInContainer = styled.div`
  opacity: 0; /* set initial opacity to 0 */
  animation-name: fade-in; /* set animation name */
  animation-duration: 1s; /* set animation duration */
  animation-fill-mode: forwards; /* keep final style of animation */

  @keyframes fade-in {
    from {
      opacity: 0; /* set opacity to 0 at start */
    }
    to {
      opacity: 1; /* set opacity to 1 at end */
    }
  }
`;
